import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "whatsapp"
}
const _hoisted_2 = ["href"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.isMobile && _ctx.enabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("a", { href: _ctx.url }, [
            _createElementVNode("img", { src: _ctx.logo }, null, 8, _hoisted_3)
          ], 8, _hoisted_2)
        ])
      ]))
    : _createCommentVNode("", true)
}