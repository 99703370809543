
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import SpButton from "@/components/ui/SpButton.vue";
import SpFloatingWindow from "@/components/ui/SpFloatingWindow.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "SupportPageFooter",
  components: { SpButton, SpFloatingWindow },
  props: {
    isCustomApi: { type: Boolean, required: true, default: false},
    termsOfUsePolicyUrl: { type: String, required: true, default: "" },
    privacyPolicyUrl: { type: String, required: true, default: "" },
    cookiesPolicyUrl: { type: String, required: true, default: "" },
    
  },
  setup(props) {
    const store = useStore();
    const customApi = computed(() => {
      return store.getters['modules/getCustomApi']
    })
    const isMobile = computed(() => {
      return store.getters["user/getIsMobile"];
    })
    const language = computed(() => {
      return store.getters["user/getLanguage"]
    });
    const modules = computed(()=> {
        return store.getters["modules/getModules"]
    })
    const footer = computed(() => {
      return store.getters["modules/getFooter"]
    });

   

    interface supportPageFooter {
      termsOfUseContent: string,
      privacyPolicyContent: string,
      legalAdviceContent: string,
      selectedLegalInformation: string
    }

    const data = reactive<supportPageFooter>({
      termsOfUseContent: '',
      privacyPolicyContent: '',
      legalAdviceContent: '',
      selectedLegalInformation: ''
    })

    function getLegalInformation (type: string) {
      if (props.isCustomApi) {
        const params = {
          uuid: store.getters['user/getUUID'],
          type: type,
          languageCode: store.getters['user/getLanguage'],
          callbackSuccess: (callbackSuccessData: any) => {
            if (type === 'termsOfUse') data.termsOfUseContent = callbackSuccessData.data.legalInformation
            if (type === 'privacyPolicy') data.privacyPolicyContent = callbackSuccessData.data.legalInformation
            if (type === 'legalAdvice') data.legalAdviceContent = callbackSuccessData.data.legalInformation
          },
          callbackError: () => {}
        }
        store.dispatch('modules/getLegalInformation', params)
      }
    }

    getLegalInformation('termsOfUse')
    getLegalInformation('privacyPolicy')
    getLegalInformation('legalAdvice')

    watch(() => store.getters['user/getLanguage'],() => {
      getLegalInformation('termsOfUse')
      getLegalInformation('privacyPolicy')
      getLegalInformation('legalAdvice')
    })

    return {
      ...toRefs(data),
      isMobile,
      language,
      footer,
      customApi,
      modules
    };
  },
});
