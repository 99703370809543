
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import { useStore } from "vuex";
import SpButtonIcon from "@/components/ui/SpButtonIcon.vue";
import * as Utils from "@/api/Utils";
import SpSelect from "@/components/ui/SpSelect.vue";
import dialphone from "../../assets/json/dial_phone_.json";

export default defineComponent({
  name: "CallMeNow",
  props: {
    enabled: { type: Boolean, required: true, default: false },
    uuid: { type: String, required: true, default: "" },
    callMeNow: { type: Object, required: true },
  },
  components: {
    SpButtonIcon,
    SpSelect,
  },
  setup(props) {
    const store = useStore();

    interface callMeNow {
      language: string;
      callMeNow: any;
      phone: string;
      prefixes: Array<any>;
      prefix: any | null;
      loading: boolean;
    }

    const data = reactive<callMeNow>({
      language: store.getters["user/getLanguage"],
      callMeNow: {
        ...props.callMeNow,
      },
      phone: "",
      loading: false,
      prefixes: [],
      prefix: "+34",
    });

    const isMobile = computed(() => {
      return store.getters["user/getIsMobile"];
    });

    function changeSelectedPrefix(uuid: string | null) {
      data.prefix = uuid;
    }

    function postCallCostumenNow() {
      const params = {
        uuid: props.uuid,
        phone: data.phone,
        prefix: data.prefix,
        callbackSuccess: (callbackSuccessData: any) => {
          store.commit("user/pushNotification", {
            id: Date.now(),
            icon: "",
            message: "notifications.callMeNow",
            color: "success",
          });
          data.phone = "";
        },
        callbackError: (callbackErrorData: any) => {
          store.commit("user/pushNotification", {
            id: Date.now(),
            icon: "",
            message: "redsysErrors.msg18",
            color: "danger",
          });
          data.phone = "";
        },
      };
      store.dispatch("modules/postCallCostumenNow", params);
    }
    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });

    const backgroundColor = computed(() => {
      return props.callMeNow.backgroundColor
        ? props.callMeNow.backgroundColor
        : "#fff";
    });

    const title = computed(() => {
      return props.callMeNow.title
        ? props.callMeNow.title.content[language.value]
        : "";
    });
    const titleColor = computed(() => {
      return props.callMeNow.title ? props.callMeNow.title.color : "";
    });

    const logo = computed(() => {
      return props.callMeNow.logo ? props.callMeNow.logo : "";
    });

    function validateAndSend() {
      !Utils.validatePhone(data.phone)
        ? store.commit("user/pushNotification", {
            id: Date.now(),
            icon: "",
            message: "redsysErrors.msg19",
            color: "danger",
          })
        : postCallCostumenNow();
    }

    const placeholderPhone = computed(() => {
      return props.callMeNow.input ? props.callMeNow.input[language.value] : "";
    });

    function callToPrefixs() {
      data.loading = true;
      const prefix = dialphone
        .filter((c: any, index: any) => {
          return dialphone.indexOf(c) === index;
        })
        .sort((a, b) => parseInt(a) - parseInt(b));
      data.prefixes = prefix.map((prefix: any) => {
        return {
          label: prefix,
          value: prefix,
        };
      });
      data.loading = false;
    }

    watch(
      () => data.callMeNow,
      (next) => {
        data.callMeNow = next;
      }
    );
    callToPrefixs();

    return {
      ...toRefs(data),
      isMobile,
      backgroundColor,
      title,
      language,
      titleColor,
      logo,
      placeholderPhone,
      postCallCostumenNow,
      Utils,
      validateAndSend,
      changeSelectedPrefix,
    };
  },
});
