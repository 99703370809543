import axios from 'axios'
import { ApiResponse, giveAnswer } from '@/api/ApiResponse';
import store from '@/store/index'

export async function deleteDocument(uuid: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid

    try {
        response = await axios.delete(url, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'DELETE DOCUMENT ' + uuid)
}

export async function getAddressMappings (uuid: string, language: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid + '/address-mappings' + '?language=' + language

    try {
        response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'GET ADDRESS MAPPINGS ' + uuid)
}

export async function getCheckPayment(uuid: string, deadLineID: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid + '/payments/' + deadLineID

    try {
        response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'GET CHECK PAYMENT' + uuid)
}

export async function getGeneralModule(uuid: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + '/api/landing_pages/' + uuid + '/general'

    try {
        response = await axios.get(url, { })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'GET GENERAL MODULE ' + uuid)
}

export async function getLandingAuth(uuid: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + '/api/landing_pages/' + uuid + '/landing_auth'

    try {
        response = await axios.get(url, {})
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'GET LANDING AUTH ' + uuid)
}

export async function getLandingPagesCollection(): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + '/api/landing_pages'

    try {
        response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'getLandingPagesCollection')
}

export async function getLandingConfiguration(uuid: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + '/api/landing_pages/' + uuid

    try {
        response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'GET LANDING CONFIGURATION ' + uuid)
}

export async function getLandingDocuments(uuid: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid

    try {
        response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'GET LANDING DOCUMENTS ' + uuid)
}

export async function getLegalInformation(uuid: string, type: string, languageCode: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid + '/legal-information'
    const params = {
        type: type,
        languageCode: languageCode
    }

    try {
        response = await axios.post(url, params, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'GET LEGAL INFORMATION ' + uuid)
}

export async function getLandingPayments(uuid: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid + '/payments'

    try {
        response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'GET LANDING PAYMENTS ' + uuid)
}

export async function getPaymentPlanData(uuid: string, fileID: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid + '/payment_plans'
    const params = {
        fileID: fileID
    }

    try {
        response = await axios.post(url, params, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'GET LANDING PAYMENTS PLAN DATA ' + uuid)
}

export async function getHistoryPayment(uuid: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid + '/ppm-payments'

    try {
        response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'GET HISTORY PAYMENTS' + uuid)
}

export async function getFilesToAddToPayment(uuid: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid + '/files-without-payment-plan'

    try {
        response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'GET FILES TO ADD TO PAYMENT' + uuid)
}

export async function getInfoToPayment(uuid: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid + '/get-virtual-tpv-config'

    try {
        response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'GET INFO TO PAYMENT' + uuid)
}

export async function getJistiUrl(uuid: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid + '/jitsi_url'

    try {
        response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'GET JITSI URL' + uuid)
}

export async function getDocumentTypes(uuid: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid + '/document-types'

    try {
        response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'GET DOCUMENT TYPES' + uuid)
}

export async function patchDocumentTag(uuid: string, tag: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid
    const params = {
        tag: tag
    }

    try {
        response = await axios.patch(url, params, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`,
                'Content-Type': 'application/merge-patch+json'
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'PATCH DOCUMENT TAG ' + uuid)
}

export async function patchSelectedFile(uuid: string, externalId: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid
    const params = {
        fileUuid: externalId
    }

    try {
        response = await axios.patch(url, params, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`,
                'Content-Type': 'application/merge-patch+json'
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'PATCH SELECTED FILE')
}

export async function postCreateFinconectaPayment(uuid: string, amount: number, paymentMethod: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid + '/finconecta-payments'
    const params = {
        amount: amount,
        paymentMethod: paymentMethod
    }

    try {
        response = await axios.post(url, params, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'POST CREATE FINCONECTA PAYMENT ' + uuid)
}

export async function postSendSupportContent(uuid: string, message: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid + '/send-support-content'
    const params = {
        message: message
    }

    try {
        response = await axios.post(url, params, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'POST SEND SUPPORT CONTENT ' + uuid)
}

export async function postSendCustomerCallPreferences (uuid: string, data: object, phone: string, prefix: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid + '/set_customer_call_preferences'
    const params = {
        timeSchedules: data,
        phone,
        prefix
    }

    try {
        response = await axios.post(url, params, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'POST SEND CUSTOMER CALL PREFERENCES ' + uuid)
}

export async function postCheckPin(uuid: string, pin: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + '/api/landing_pages/' + uuid + '/check_pin'
    const params = {
        pin: pin
    }

    try {
        response = await axios.post(url, params, { })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'POST CHECK PIN ' + uuid)
}

export async function postCallCostumenNow(uuid: string, phone: string, prefix: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + '/api/landing_pages/' + uuid + '/call_me_now'
    const params = {
        phone: phone,
        prefix: prefix
    }
    try {
        response = await axios.post(url, params, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'POST CALL COSTUMEN NOW' + uuid)
}

export async function postSendPin(uuid: string, uniqueId: string, phone: string, mail: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + '/api/landing_pages/' + uuid + '/send_pin'
    const params = {
        uniqueId: uniqueId,
        phone: phone,
        mail: mail,
    }
    try {
        response = await axios.post(url, params, { })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'POST SEND PIN ' + uuid)
}

export async function postSendDniBirthday(uuid: string, dni: string, birthDate: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + '/api/landing_pages/' + uuid + '/login-dni-birthdate'
    const params = {
        dni: dni,
        birthDate: birthDate
    }
    try {
        response = await axios.post(url, params)
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'POST SEND DNI BIRTHDAY ' + uuid)
}

export async function postSendRemoval(uuid: string, percentage: number): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + '/api/landing_pages/' + uuid + '/send_removal'
    const params = {
        percentage: percentage
    }

    try {
        response = await axios.post(url, params, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'POST SEND REMOVAL ' + uuid)
}

export async function postNoContact(uuid: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid + '/customer_no_contact'
    const params = {}

    try {
        response = await axios.post(url, params, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'POST NO CONTACT ' + uuid)
}

export async function postSendContactEmail(uuid: string, name: string, message: string, email: string, phone: any, address: any): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid + '/send_customer_contact'
    const params = {
        email: email,
        name: name,
        message: message,
        phone: phone,
        address: address,
    }

    try {
        response = await axios.post(url, params, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'POST SEND CONTACT EMAIL ' + uuid)
}

export async function postSendDebtConsolidationEmail(uuid: string, text: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid + '/send_customer_debt_consolidation'
    const params = {
        text: text
    }

    try {
        response = await axios.post(url, params, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'POST SEND DEBT CONSOLIDATION EMAIL ' + uuid)
}

export async function postPaymentVerify(uuid: string, variables: any): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid + '/verify'
    const params = variables

    try {
        response = await axios.post(url, params, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'POST PAYMENT VERIFY' + uuid)
}

export async function postCreatePayment(uuid: string, quantity: string, operationId: string, paymentPlanDeadLine: string, extra: any): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid + '/payments/create'
    const params = {
        quantity: quantity,
        operationId: operationId,
        paymentPlanDeadLine: paymentPlanDeadLine,
        extra: extra,
    }

    try {
        response = await axios.post(url, params, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'POST CREATE PAYMENT ' + uuid)
}
export async function postPaymentPlanTable(preferred_day: string, monthly_cost: number, months: number, itemsPerPage: number, page: number, totalDebt: any): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + '/api/landing_pages/payment_plan_deadlines_preview?itemsPerPage=' + itemsPerPage + '&page=' + page
    const params = {
        preferred_day: preferred_day,
        monthly_cost: monthly_cost,
        months: months,
        total_debt: totalDebt
    }

    try {
        response = await axios.post(url, params, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'PAYMENT PLAN DEADLINES PREVIEW')
}


export async function postCreatePaymentPlanData(
    uuid: string,
    customPaymentAmount: number,
    customPaymentPeriodMonths: number,
    preferredDueDay: string,
    type: string,
    paymentFrequency: string,
    files: Array<string>,
    origin: string,
    totalDebt: any): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid + '/payment_plan_datas'
    const params = {
        paymentFrequency: paymentFrequency,
        customPaymentAmount: customPaymentAmount,
        customPaymentPeriodMonths: customPaymentPeriodMonths,
        preferredDueDay: preferredDueDay,
        type: type,
        files: files,
        origin: origin,
        totalDebt: totalDebt,
    }

    try {
        response = await axios.post(url, params, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'POST CREATE PAYMENT PLAN DATA ' + uuid)
}

export async function postUploadDocument(uuid: string, files: any, tag: any): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + '/api/documents'

    const formData = new FormData()
    formData.append('landingPage', uuid)

    for (let i = 0; i < files.length; i++) {
        const filesize = Number(((files[i].size / 1024) / 1024).toFixed(4))
        if (filesize > 100) {
            store.commit('user/pushNotification', {
                id: Date.now(),
                icon: 'alert.triangle',
                message: 'notifications.filesError1',
                color: 'warning'
            })
        } else {
            formData.append('files[]', files[i])
        }
    }

    formData.append('types', tag)

    // for (let i = 0; i < files.length; i++) {
    //     formData.append('files[]', files[i])
    // }

    try {
        response = await axios.post(url, formData, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`,
                'Content-Type': 'multipart/form-data'
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'POST UPLOAD DOCUMENT ' + uuid)
}

export async function postCheckoutSession(uuid: string, paymentPlanDeadline?: string, amount?: number, file?: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + '/api/landing_pages/' + uuid + '/stripe/checkout_session'
    const params = {
        paymentPlanDeadline: paymentPlanDeadline,
        amount: amount,
        file: file
    }

    try {
        response = await axios.post(url, params, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'POST Checkout Session ' + uuid)
}

export async function postCheckoutSessionApiMode(uuid: string, paymentPlanDeadline?: string, file?: string, amount?: string, callbackUrl?: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid + '/worldline/checkout_session'
    const params = {
        paymentPlanDeadline: paymentPlanDeadline,
        file: file,
        amount: amount,
        callbackUrl: callbackUrl
    }

    try {
        response = await axios.post(url, params, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'POST Checkout Session worldline' + uuid)
}

export async function postPaymentStripe(uuid: string, deadline?: string, amount?: number, file?: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid + '/stripe/payment'
    const params = {
        deadline: deadline,
        amount: amount,
        file: file
    }

    try {
        response = await axios.post(url, params, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'STRIPE PAYMENT' + uuid)
}

export async function postGetTotalToPay (uuid: string, fileID: string): Promise<ApiResponse> {
    let response = null
    const url = process.env.VUE_APP_BASE_URL + uuid + '/totals_partial_payment'
    const params = {
        fileID: fileID
    }

    try {
        response = await axios.post(url, params, {
            headers: {
                Authorization: `Bearer ${store.getters['security/getToken']}`
            }
        })
    } catch (e: any) {
        response = e.response
    }

    return giveAnswer(response, 'Get Total to Pay' + uuid)
}