import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  id: "files",
  class: "default-box-transparent text-left py-4 w-full px-0 md:px-32 lg:px-40 xl:px-72"
}
const _hoisted_2 = { class: "py-4 px-5 md:px-0" }
const _hoisted_3 = { class: "text-xl text-gray-600" }
const _hoisted_4 = { class: "default-box-white-full p-4" }
const _hoisted_5 = { class: "m-4" }
const _hoisted_6 = { class: "mt-8" }
const _hoisted_7 = ["id", "onUpdate:modelValue"]
const _hoisted_8 = {
  value: "",
  disabled: "",
  hidden: ""
}
const _hoisted_9 = ["value"]
const _hoisted_10 = {
  key: 0,
  class: "w-full rounded flex flex-col justify-center items-center default-box-gray-200-full",
  style: {"min-height":"300px","max-height":"300px"}
}
const _hoisted_11 = { class: "font-semibold text-2xl" }
const _hoisted_12 = { class: "font-semibold" }
const _hoisted_13 = {
  key: 0,
  class: "flex justify-start items-center flex-wrap mt-2"
}
const _hoisted_14 = {
  key: 0,
  class: "flex items-center justify-center"
}
const _hoisted_15 = {
  value: "",
  disabled: "",
  hidden: ""
}
const _hoisted_16 = ["value"]
const _hoisted_17 = {
  key: 1,
  class: "text-gray-400"
}
const _hoisted_18 = {
  key: 3,
  class: "text-gray"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_title = _resolveComponent("sp-title")!
  const _component_sp_button_icon = _resolveComponent("sp-button-icon")!
  const _component_sp_icon = _resolveComponent("sp-icon")!
  const _component_sp_button = _resolveComponent("sp-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_sp_title, {
        message: _ctx.contentData.title[_ctx.language],
        size: "text-4xl"
      }, null, 8, ["message"]),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.contentData.subtitle[_ctx.language]), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.contentData.requestMessage[_ctx.language]), 1),
        (_ctx.uploadFilesData.length > 0)
          ? (_openBlock(), _createBlock(_component_sp_title, {
              key: 0,
              message: _ctx.$t('general.files3'),
              size: "text-2xl",
              class: "mb-2"
            }, null, 8, ["message"]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uploadFilesData, (uploadFile) => {
          return (_openBlock(), _createElementBlock("div", {
            key: uploadFile['@id'],
            class: _normalizeClass(["rounded bg-gray-100 flex p-2 mb-2", _ctx.isMobile ? 'flex-col justify-start items-start' : 'justify-between items-center'])
          }, [
            _createElementVNode("span", null, _toDisplayString(uploadFile.originalFileName), 1),
            _createElementVNode("div", {
              class: _normalizeClass(["flex", _ctx.isMobile ? 'justify-end items-center w-full mt-2' : 'justify-center items-center'])
            }, [
              _withDirectives(_createElementVNode("select", {
                id: 'uploadFile-' + uploadFile['@id'],
                "onUpdate:modelValue": ($event: any) => ((uploadFile.documentType.internalId) = $event),
                name: "select",
                class: "mr-2",
                style: _normalizeStyle(_ctx.isMobile ? 'width: 100%;':'width: 300px;'),
                onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.patchFileTag && _ctx.patchFileTag(...args)))
              }, [
                _createElementVNode("option", _hoisted_8, _toDisplayString(_ctx.$t('general.fileType0')), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.types, (type, index) => {
                  return (_openBlock(), _createElementBlock("option", {
                    disabled: "",
                    key: index,
                    value: type.id
                  }, _toDisplayString(type.name), 9, _hoisted_9))
                }), 128))
              ], 44, _hoisted_7), [
                [_vModelSelect, uploadFile.documentType.internalId]
              ]),
              _createVNode(_component_sp_button_icon, {
                onOnClick: ($event: any) => (_ctx.deleteDocument(uploadFile['@id'])),
                loading: _ctx.filesBeingRemoved.includes(uploadFile['@id']),
                icon: "trash",
                color: "clean"
              }, null, 8, ["onOnClick", "loading"])
            ], 2)
          ], 2))
        }), 128))
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.isMobile ? '': 'p-4')
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["w-full default-box-gray-full flex flex-col justify-center items-center", _ctx.isMobile ? 'overflow-hidden overflow-y-auto' : '']),
          onDragover: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"])),
          onDrop: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"]))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.isMobile ? 'overflow-hidden w-full': 'p-4 w-full')
          }, [
            (_ctx.loadingUpload)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createVNode(_component_sp_icon, {
                    model: "refresh-cw",
                    rotate: "",
                    size: "36",
                    class: "mb-2"
                  }),
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('general.files8')) + ".", 1)
                ]))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  onDrop: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.dragFile && _ctx.dragFile(...args))),
                  class: _normalizeClass([_ctx.isMobile ? 'bg-gray-200 w-full' : 'default-box-gray-200-full', "flex flex-col justify-center items-center w-full overflow-y-auto"]),
                  style: {"min-height":"300px","max-height":"300px"}
                }, [
                  _createVNode(_component_sp_icon, {
                    model: _ctx.contentData.upload.drag.icon,
                    size: "64",
                    class: "mt-4"
                  }, null, 8, ["model"]),
                  _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.contentData.upload.drag.message[_ctx.language]), 1),
                  (_ctx.files.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (file) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: file,
                            class: "m-1 btn-white-rounded-auto"
                          }, _toDisplayString(file.name), 1))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ], 34))
          ], 2),
          (_ctx.files.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tag) = $event)),
                  name: "select",
                  class: "mr-2",
                  style: _normalizeStyle(_ctx.isMobile ? 'width: 100%; height: 50px':'width: 300px; height: 50px'),
                  onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeValue(_ctx.tag)))
                }, [
                  _createElementVNode("option", _hoisted_15, _toDisplayString(_ctx.$t('general.fileType0')), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.types, (type, index) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: index,
                      value: type.id
                    }, _toDisplayString(type.name), 9, _hoisted_16))
                  }), 128))
                ], 36), [
                  [_vModelSelect, _ctx.tag]
                ]),
                _createVNode(_component_sp_button, {
                  onOnClick: _ctx.postUploadFile,
                  disabled: _ctx.loadingUpload || _ctx.tag.length === 0,
                  label: _ctx.$t('general.save'),
                  class: "mt-4 mb-4",
                  size: "auto"
                }, null, 8, ["onOnClick", "disabled", "label"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.files.length === 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.contentData.upload.or[_ctx.language]), 1))
            : _createCommentVNode("", true),
          (_ctx.files.length === 0)
            ? (_openBlock(), _createBlock(_component_sp_button, {
                key: 2,
                id: "btn-upload-files",
                onOnClick: _ctx.onClickUpload,
                disabled: _ctx.loadingUpload,
                label: _ctx.contentData.upload.button.message[_ctx.language],
                class: "mt-4 mb-4",
                size: "auto"
              }, null, 8, ["onOnClick", "disabled", "label"]))
            : _createCommentVNode("", true),
          (_ctx.files.length === 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_18, _toDisplayString(_ctx.contentData.size.message[_ctx.language]) + " " + _toDisplayString(_ctx.contentData.size.max) + _toDisplayString(_ctx.contentData.size.unit), 1))
            : _createCommentVNode("", true),
          (_ctx.files.length === 0)
            ? (_openBlock(), _createElementBlock("input", {
                key: 4,
                id: "upload-file",
                type: "file",
                accept: "*",
                style: {"display":"none"},
                multiple: "",
                onChange: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.uploadFile && _ctx.uploadFile(...args)))
              }, null, 32))
            : _createCommentVNode("", true)
        ], 34)
      ], 2)
    ])
  ]))
}