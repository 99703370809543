import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AxactorView from '../views/AxactorView.vue'
import PinAuthenticationAx from '../views/axactor/PinAuthenticationAx.vue'
import CheckIdKo from '../views/axactor/CheckIdKo.vue'
import CheckPinKo from '../views/axactor/CheckPinKo.vue'
import ThereIsNoData from '../views/axactor/ThereIsNoData.vue'
import DebtsView from '../views/axactor/DebtsView.vue'
import ContactUsMessage from '../views/axactor/ContactUsMessage.vue'
import Interveners from '../views/axactor/Interveners.vue'
import AddDocuments from '../views/axactor/AddDocuments.vue'
import PaymentMethods from '../views/axactor/PaymentMethods.vue'
import PaymentsResume from '../views/axactor/PaymentsResume.vue'
import ConfirmPayment from '../views/axactor/ConfirmPayment.vue'
import ContactUs from '../views/axactor/ContactUs.vue'
import CallUs from '../views/axactor/CallUs.vue'
import CallSaved from '../views/axactor/CallSaved.vue'
import Files from '../views/axactor/Files.vue'
import MyFiles from '../views/axactor/MyFiles.vue'
import SendEmail from '../views/axactor/SendEmail.vue'
import EmailSent from '../views/axactor/EmailSent.vue'
import Notifications from '../views/axactor/Notifications.vue'
import Notification from '../views/axactor/Notification.vue'
import Profile from '../views/axactor/Profile.vue'
import LegalTexts from '../views/axactor/LegalTexts.vue'
import PaymentsMade from '../views/axactor/PaymentsMade.vue'
import PaymentPending from '../views/axactor/PaymentPending.vue'
import FAQs from '../views/axactor/FAQs.vue'
import ScheduledCalls from '../views/axactor/ScheduledCalls.vue'
import Error404 from '@/views/Error/Error404.vue'
import Error403 from '@/views/Error/Error403.vue'
/* import * as canAccess from '@/router/canAccess' */
import AxactorBaseView from '@/components/AxactorBaseView.vue'
import CategorizeDocument from '@/views/axactor/CategorizeDocument.vue'
import BaseView from '@/components/BaseView.vue'
import store from '@/store'

const routes: Array<RouteRecordRaw> = [

  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid',
    name: 'Home',
    component: HomeView,
    props: true,
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/template_1',
    name: 'AxactorView',
    component: AxactorView,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/authentication',
    name: 'PinAuthenticationAx',
    component: PinAuthenticationAx,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/check_pin_ko',
    name: 'CheckPinKo',
    component: CheckPinKo,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/check_id_ko',
    name: 'CheckIdKo',
    component: CheckIdKo,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/no_data',
    name: 'ThereIsNoData',
    component: ThereIsNoData,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/contact_us',
    name: 'ContactUsMessage',
    component: ContactUsMessage,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/debts',
    name: 'DebtsView',
    component: DebtsView,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/interveners',
    name: 'Interveners',
    component: Interveners,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/add_documents',
    name: 'AddDocuments',
    component: AddDocuments,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/categorize_documents',
    name: 'CategorizeDocument',
    component: CategorizeDocument,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/files',
    name: 'Files',
    component: Files,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/my-documents',
    name: 'MyFiles',
    component: MyFiles,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/payment-methods',
    name: 'PaymentMethods',
    component: PaymentMethods,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/payment-resume',
    name: 'PaymentsResume',
    component: PaymentsResume,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/confirm-payment',
    name: 'ConfirmPayment',
    component: ConfirmPayment,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/contact_us_options',
    name: 'ContactUs',
    component: ContactUs,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/call_us',
    name: 'CallUs',
    component: CallUs,
    props: true
  }
  ,
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/call_saved',
    name: 'CallSaved',
    component: CallSaved,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/send_email',
    name: 'SendEmail',
    component: SendEmail,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/sent_email',
    name: 'EmailSent',
    component: EmailSent,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/notifications',
    name: 'Notifications',
    component: Notifications,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/notification',
    name: 'Notification',
    component: Notification,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/profile',
    name: 'Profile',
    component: Profile,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/texts',
    name: 'LegalTexts',
    component: LegalTexts,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/payments_history',
    name: 'PaymentsMade',
    component: PaymentsMade,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/payments_pending',
    name: 'PaymentPending',
    component: PaymentPending,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/faqs',
    name: 'FAQs',
    component: FAQs,
    props: true
  },
  {
    path: '/:c?/:client?/:s?/:strategy?/:a?/:action?/:t/:template/:element/:uuid/scheduled_calls',
    name: 'ScheduledCalls',
    component: ScheduledCalls,
    props: true
  },
  {
    path: '/404',
    name: 'GoError404',
    component: Error404
  }, {
    path: '/403',
    name: 'GoError403',
    component: Error403
  }, {
    path: '/:pathMatch(.*)*',
    name: 'Error404',
    component: Error404
  }
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// router.beforeEach(route => {
//   console.log(route);
// })

export default router

