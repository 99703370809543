import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "faqs",
  class: "default-box-transparent w-full py-4 px-0 md:px-32 lg:px-40 xl:px-72 stext-left"
}
const _hoisted_2 = { class: "py-4 px-5 md:px-0" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "text-gray-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_title = _resolveComponent("sp-title")!
  const _component_sp_accordion = _resolveComponent("sp-accordion")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_sp_title, {
          message: _ctx.title[_ctx.language],
          size: "text-4xl"
        }, null, 8, ["message"]),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.subtitle[_ctx.language]), 1)
      ])
    ]),
    _createVNode(_component_sp_accordion, { questions: _ctx.computedQuestions }, null, 8, ["questions"])
  ]))
}