
import {computed, defineComponent} from 'vue'
import {useStore} from 'vuex'
import SpNotificationCard from "@/components/ui/SpNotificationCard.vue";

export default defineComponent({
  name: "SpNotifications",
  components: {SpNotificationCard},
  setup () {
    const store = useStore()

    const notifications = computed(() => {
      return store.getters['user/getNotifications']
    })
    
    function addNotification () {}

    addNotification()

    return {
      notifications
    }
  }
})
