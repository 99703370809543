
import {computed, defineComponent, reactive, watch} from 'vue'
import {useStore} from 'vuex'
import SpIcon from '@/components/ui/SpIcon.vue'
import * as Utils from '@/api/Utils'
import moment from "moment"

export default defineComponent({
  name: "WelcomeText",
  components: {
    SpIcon
  },
  props: {
    icon: { type: String, required: false, default: 'thumbs-up' },
    fontSize: { type: String, required: false, default: 'base' },
    errorLink: { type: String, required: true, default: '' },
    debtAmount: { type: Number, required: true, default: 0 },
    pendingDebt: { type: Number, required: true, default: 0 },
    paidDebt: { type: Number, required: false, default: 0 },
    currency: { type: String, required: true, default: 'EUR' },
    taxType: { type: String, required: true, default: '' },
    debtDate: { type: String, required: true, default: '' },
    debtContractedWith: { type: String, required: true, default: '' },
    debtOwner: { type: String, required: true, default: '' },
    message: { type: Object, required: true },
    updatedDate: { type: Date, required: true, default: '' },
    agent: { type: String, required: true, default: '' },
    procecuted: { type: Boolean, required: true, default: true },
    userName: { type: String, required: false, default: '' },
    isTemplate: { type: Boolean, required: false, default: false },
  },
  setup (props) {
    const store = useStore()

    const isMobile = computed(() => {
      return store.getters["user/getIsMobile"];
    })

    const yesterday = computed(() => {
      return moment().subtract(1, 'days').toString()
    })

    const messageResult = computed(() => {
      let result: string = props.message.en.slice()
      if (store.getters['user/getLanguage'] === 'es') result = props.message.es.slice()
      if (store.getters['user/getLanguage'] === 'ru') result = props.message.ru.slice()

      result = !props.isTemplate ? result.replaceAll('{debtorName}', props.userName) : result.replaceAll('{debtorName}', store.getters['modules/getSelectedFileData'].debtorName)
      result = result.replaceAll('{debtAmount}', Utils.formatNumberWithCurrency(store.getters['modules/getSelectedFileData'].debtAmount,props.currency))
      result = result.replaceAll('{pendingDebt}', Utils.formatNumberWithCurrency(store.getters['modules/getSelectedFileData'].pendingDebt,props.currency))
      result = result.replaceAll('{debtDate}', Utils.formatDate(store.getters['modules/getSelectedFileData'].debtDate))
      result = result.replaceAll('{debtStartDate}', Utils.formatDate(store.getters['modules/getSelectedFileData'].debtStartDate))
      result = result.replaceAll('{paidDebt}', Utils.formatNumberWithCurrency(store.getters['modules/getSelectedFileData'].paidDebt,props.currency))
      result = result.replaceAll('{debtOwner}', store.getters['modules/getSelectedFileData'].transferor)

      result = result.replaceAll('{debtType}', store.getters['modules/getSelectedFileData'].debtType[store.getters['user/getLanguage']])
      result = result.replaceAll('{agent}', store.getters['modules/getSelectedFileData'].agent)

      for(let fpt in store.getters['modules/getSelectedFileData'].fpt) {
        result = result.replaceAll('{' + fpt + '}', store.getters['modules/getSelectedFileData'].fpt[fpt])
      }

      result = result.replaceAll('{bold}', "<span class='font-semibold'>")
      result = result.replaceAll('{/bold}', "</span>")
      result = result.replaceAll('{gray}', "<span class='text-gray-400'>")
      result = result.replaceAll('{/gray}', "</span>")
      result = result.replaceAll('{success}', "<span class='text-secondary-color'>")
      result = result.replaceAll('{/success}', "</span>")
      result = result.replaceAll('{red}', "<span class='text-primary-color'>")
      result = result.replaceAll('{/red}', "</span>")
      result = result.replaceAll('{enter}', "&#10;&#13;")
      let errorString: string = "<a class='underline' href='" + props.errorLink + "'>"
      result = result.replaceAll('{error}', errorString)
      result = result.replaceAll('{/error}', "</a>")
      return result
    })

    return {
      isMobile,
      messageResult,
      Utils,
      yesterday,
    }
  }
})
