import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"color":"rgb(169, 167, 167)"}
}
const _hoisted_2 = { key: 2 }
const _hoisted_3 = { class: "icon" }
const _hoisted_4 = {
  class: "text-xs italic mb-2",
  style: {"color":"black"}
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 0,
  class: "text-gray-400"
}
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_icon = _resolveComponent("sp-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative flex justify-start items-start flex-col", [..._ctx.inheritedClasses, _ctx.disabled ? 'opacity-50': '']])
  }, [
    (_ctx.label.length > 0)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass([_ctx.titleGray ? 'text-sm text-gray-500' : 'text-sm'])
        }, _toDisplayString(_ctx.label), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeContentState && _ctx.changeContentState(...args))),
      class: _normalizeClass(["select-container", _ctx.siritSelectClass]),
      style: _normalizeStyle('height:' + _ctx.selectHeight+ ';' + 'background-color:' + _ctx.backgroundColor+ ';' + 'border:' + _ctx.border+ ';' + 'border-radius:' + _ctx.borderRadius + '!important')
    }, [
      (_ctx.selectedValue === '' || _ctx.selectedValue === null)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.placeholder), 1))
        : _createCommentVNode("", true),
      (_ctx.selectedValue !== null && _ctx.max === 0)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass(!_ctx.icon ? 'text-center text-xs text-gray-400 pr-2 pl-1':'')
          }, _toDisplayString(_ctx.selectedLabel), 3))
        : _createCommentVNode("", true),
      (_ctx.selectedValue !== null && _ctx.max !== 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.selectedLabel), 1))
        : _createCommentVNode("", true),
      (_ctx.loadingItems)
        ? (_openBlock(), _createBlock(_component_sp_icon, {
            key: 3,
            model: "refresh-cw",
            rotate: "",
            class: "ml-2"
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.loadingItems && _ctx.icon)
          ? (_openBlock(), _createBlock(_component_sp_icon, {
              key: 0,
              model: _ctx.showContent ? 'chevron-up':'chevron-down'
            }, null, 8, ["model"]))
          : _createCommentVNode("", true)
      ])
    ], 6),
    (_ctx.showContent)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeContentState && _ctx.changeContentState(...args))),
          class: "z-10 fixed w-screen h-screen right-0 top-0 opacity-50"
        }))
      : _createCommentVNode("", true),
    (_ctx.showContent)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          onKeydown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.searchContent && _ctx.searchContent(...args))),
          class: _normalizeClass(["siritSelectItems", _ctx.siritSelectItemsClass]),
          style: _normalizeStyle([_ctx.label.length > 0 ? 'margin-top: 20px':'', _ctx.fixedItems ? 'position: fixed !important;':''])
        }, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.search) = $event)),
            type: "text",
            class: "hidden"
          }, null, 512), [
            [_vModelText, _ctx.search]
          ]),
          _createElementVNode("p", _hoisted_4, [
            (_ctx.search.length > 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('general.searching')) + " \"" + _toDisplayString(_ctx.search) + "\".", 1))
              : _createCommentVNode("", true),
            _createElementVNode("span", null, _toDisplayString(_ctx.itemsText === '' ? _ctx.$t('general.selectItem'): _ctx.itemsText), 1)
          ]),
          (_ctx.items.length === 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('general.noData')), 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedItems, (item, index) => {
            return (_openBlock(), _createElementBlock("span", {
              onClick: ($event: any) => (_ctx.selectValue(item.value, item.label)),
              key: index,
              class: _normalizeClass(_ctx.selectedValue === item.value ? 'selected-sirit-select-items-item':'sirit-select-items-item')
            }, _toDisplayString(item.label), 11, _hoisted_7))
          }), 128))
        ], 38))
      : _createCommentVNode("", true)
  ], 2))
}