
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import SpTitle from "@/components/ui/SpTitle.vue";
import FileSelectorCard from "@/components/modules/FileSelectorComponents/FileSelectorCard.vue";
import SpButtonIcon from "@/components/ui/SpButtonIcon.vue";
import { useStore } from "vuex";
import * as Utils from "@/api/Utils";
import SpModal from "@/components/ui/SpModal.vue";
import SpIcon from "@/components/ui/SpIcon.vue";

export default defineComponent({
  name: "FileSelector",
  components: { SpIcon, SpModal, SpButtonIcon, FileSelectorCard, SpTitle },
  props: {
    currency: { type: String, required: true, default: "EUR" },
  },
  setup(props, { emit }) {
    const store = useStore();
    interface fileSelector {
      activeFile: string;
      files: Array<any>;
      from: number;
      loadingSelectFile: boolean;
      to: number;
      selectedFiles: Array<any>;
    }

    const data = reactive<fileSelector>({
      activeFile: store.getters["modules/getFileUuid"],
      files: store.getters["modules/getFilesInfo"],
      from: 0,
      loadingSelectFile: false,
      to: 2,
      selectedFiles: store.getters["modules/getSelectedFiles"]
    });
    
    if (!data.selectedFiles.includes(data.activeFile)){
      data.selectedFiles.push(data.activeFile)
      store.commit("modules/setSelectedFiles", data.selectedFiles);
    }

    const filesToShow = computed(() => {
      let result: Array<any> = [];
      data.files.forEach((item: any, index: any) => (item.index = index + 1));
      if (data.files.length === 0) return result;
      if (data.files.length <= 3) return data.files;

      if (data.from < data.to) {
        return data.files.slice(data.from, data.to + 1);
      } else {
        for (let i = data.from; i < data.files.length; i++) {
          result.push(data.files[i]);
        }

        for (let i = 0; i < data.to + 1; i++) {
          result.push(data.files[i]);
        }
      }

      return result;
    });

    const filesData = computed(() => {
      let totalDebt: number = 0;
      let totalPaidDebt: number = 0;
      let amountError: boolean = false;

      if (data.files.length > 0) {
        data.files.forEach(function (file: any) {
          if (file.totalDebt !== "-") {
            totalDebt += file.totalDebt;
          } else {
            amountError = true;
          }
          if (file.paidDebt !== "-") {
            totalPaidDebt += file.paidDebt;
          } else {
            amountError = true;
          }
        });
      }

      return {
        total: totalDebt,
        paidDebt: totalPaidDebt,
        amountError: amountError,
      };
    });

    const isMobile = computed(() => {
      return store.getters["user/getIsMobile"];
    });

    const debtSelector = computed(() => {
      return store.getters["modules/getDebtSelector"];
    });

    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });

    const message = computed(() => {
      let result: string = debtSelector.value.infoMessage.en.slice();
      if (store.getters["user/getLanguage"] === "es")
        result = debtSelector.value.infoMessage.es.slice();
      if (store.getters["user/getLanguage"] === "ru")
        result = debtSelector.value.infoMessage.ru.slice();

      result = result.replaceAll("{numOfDebts}", data.files.length.toString());
      result = result.replaceAll(
        "{sumPaidDebt}",
        Utils.formatNumberWithCurrency(filesData.value.paidDebt, props.currency)
      );
      result = result.replaceAll(
        "{sumTotalDebt}",
        Utils.formatNumberWithCurrency(filesData.value.total, props.currency)
      );

      for (let fpt in store.getters["modules/getSelectedFileData"].fpt) {
        result = result.replaceAll(
          "{" + fpt + "}",
          store.getters["modules/getSelectedFileData"].fpt[fpt]
        );
      }

      return result;
    });

    function nextFiles() {
      if (data.files.length <= 3) return;
      data.from++;
      data.to++;
      if (data.from > data.files.length - 1) data.from = 0;
      if (data.to > data.files.length - 1) data.to = 0;
    }

    function previousFiles() {
      if (data.files.length <= 3) return;
      data.from--;
      data.to--;
      if (data.from < 0) data.from = data.files.length - 1;
      if (data.to < 0) data.to = data.files.length - 1;
    }

    function selectFile(externalId: string) {
      data.loadingSelectFile = true;
      data.activeFile = externalId;
      const params = {
        uuid: store.getters["user/getUUID"],
        externalId: externalId,
        callbackSuccess: (callbackSuccessData: any) => {
          // data.selectedFiles.includes(externalId)
          //   ? data.selectedFiles.splice(data.selectedFiles.indexOf(externalId), 1)
          //   : data.selectedFiles.push(externalId);
          // store.commit("modules/setSelectedFiles", data.selectedFiles);
          store.commit("modules/setFileUuid", externalId);
          store.commit("user/pushNotification", {
            id: Date.now(),
            icon: "check",
            message: "fileSelector.notifications.changeFileSuccess",
            color: "success",
          });
          data.loadingSelectFile = false;
        },
        callbackError: () => {
          data.loadingSelectFile = false;
        },
      };
      store.dispatch("modules/patchSelectedFile", params);
    }

    function showSelectedFile() {
      data.files.forEach(function (file, index) {
        if (file.externalId === store.getters["modules/getFileExternalId"])
          data.from = index;
      });

      if (data.from + 2 < data.files.length) {
        data.to = data.from + 2;
      } else {
        data.to = data.files.length - data.from - 2;
      }
    }

    showSelectedFile();

    watch(
      () => store.getters["modules/getFilesInfo"],
      () => {
        data.files = store.getters["modules/getFilesInfo"];
      }
    );

    watch(
      () => store.getters["modules/getFileUuid"],
      () => {
        data.activeFile = store.getters["modules/getFileUuid"];
      }
    );

    return {
      ...toRefs(data),
      filesToShow,
      filesData,
      isMobile,
      nextFiles,
      previousFiles,
      selectFile,
      Utils,
      debtSelector,
      language,
      message,
    };
  },
});
