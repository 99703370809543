
import { watch, defineComponent, reactive, toRefs, computed } from "vue";
import { useStore } from "vuex";
import SpIcon from "@/components/ui/SpIcon.vue";
import SpButton from "@/components/ui/SpButton.vue";
import router from "@/router";
import SpButtonIcon from "@/components/ui/SpButtonIcon.vue";

export default {
  name: "MyFiles",
  components: {
    SpIcon,
    SpButton,
    SpButtonIcon,
  },
  props: {
    uuid: { type: String, required: true },
  },
  setup(props: any) {
    const store = useStore();

    interface Files {
      uploadFilesData: Array<any>;
      loadingDelete: boolean;
      loadingDocuments: boolean;
    }

    const data = reactive<Files>({
      uploadFilesData: [],
      loadingDelete: false,
      loadingDocuments: false,
    });
    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });
    const modules = computed(()=> {
        return store.getters["modules/getModules"]
    })
    
    function goTo(value: string) {
      router.push({ name: value });
    }

    function deleteDocument(uuid: string) {
      data.loadingDelete = true;
      const params = {
        uuid: uuid,
        callbackSuccess: () => {
          store.commit("user/pushNotification", {
            id: Date.now(),
            icon: "trash",
            message: "notifications.filesSuccess2",
            color: "success",
          });
          data.loadingDelete = false;
          getLandingDocuments();
        },
        callbackError: () => {
          data.loadingDelete = false;
        },
      };
      store.dispatch("modules/deleteDocument", params);
    }

    function getLandingDocuments() {
      data.loadingDocuments = true;
      const params = {
        uuid: store.getters["user/getUUID"],
        callbackSuccess: (callbackSuccessData: any) => {
          data.uploadFilesData = callbackSuccessData.data.documents;
          data.loadingDocuments = false;
        },
        callbackError: () => {
          data.loadingDocuments = false;
        },
      };
      store.dispatch("modules/getLandingDocuments", params);
    }

    const mobileColors = computed(() => {
      return store.getters["user/getMobileColors"];
    });


    watch(() => store.getters['modules/getFileUuid'], (first, second) => {
      getLandingDocuments()
    })

    getLandingDocuments();

    return {
      ...toRefs(data),
      goTo,
      deleteDocument,
      modules,
      language,
      mobileColors
    };
  },
};
