<template>
  <div class="flex justify-center items-center">
    <!-- <sp-button @onClick="postPage">Page</sp-button> -->
    <form
      v-if="!loading"
      name="from"
      action="https://sis-t.redsys.es:25443/sis/realizarPago"
      method="POST"
    >
      <input type="hidden" name="Ds_SignatureVersion" :value="Ds_SignatureVersion" />
      <input type="hidden" name="Ds_MerchantParameters" :value="Ds_MerchantParameters" />
      <input type="hidden" name="Ds_Signature" :value="Ds_Signature" />
      <input
        type="submit"
        :value="$t('general.paymentPlanProposalSingleOption1')"
        :style="`
              border-radius: 8px;
              background-color: ${mobileColors.primary};
              border: none;
              color: white;`
            "
        class="text-xs pl-5 pr-5 pt-2 pb-2"
      />
    </form>
    <sp-button
      v-if="loading"
      :label="$t('general.paymentPlanProposalSingleOption1')"
      size="auto"
      :loading="loading"
      :fullRounded="false"
      :fontSemibold="false"
      :style="`
              border-radius: 8px;
              background-color: ${mobileColors.primary};
              border: none;
              color: white;
              height: 32px;
              width: 71px`
            "
      class="text-xs pl-3 pr-3"
    />
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  inject,
  toRefs,
  watch,
} from "vue";
import { useStore } from "vuex";
import SpButton from "@/components/ui/SpButton.vue";
import SpModal from "@/components/ui/SpModal.vue";
import SpIcon from "@/components/ui/SpIcon.vue";
import * as Utils from "@/api/Utils";

export default defineComponent({
  name: "Card",
  components: { SpIcon, SpModal, SpButton },
  props: {
    uuid: { type: String, required: true, default: "" },
    debtAmount: { type: Number, required: true, default: 0 },
  },
  setup(props, { emit }) {
    const cryoptojs = inject("cryptojs");
    const store = useStore();

    const primaryColor = computed(() => {
      return store.getters["user/getPrimaryColor"];
    });

    const data = reactive({
      Ds_SignatureVersion: "HMAC_SHA256_V1",
      Ds_MerchantParameters: "",
      Ds_Signature: "",
      transaction: {
        quantity: 10,
        tpvId: "",
        pageType: "c",
      },
      amount: props.debtAmount,
      tpvInfo: {
        fucCode: "999008881",
        currency: "978",
        transaction_type: "0",
        terminal: "001",
        secret: "sq7HjrUOBfKmC576ILgskD5srU870gJ7",
        orderId: "",
      },
      seePageButton: true,
      mParams: {
        DS_MERCHANT_ORDER: "",
        DS_MERCHANT_MERCHANTCODE: "",
        DS_MERCHANT_TERMINAL: "",
        DS_MERCHANT_CURRENCY: "",
        DS_MERCHANT_TRANSACTIONTYPE: "",
        DS_MERCHANT_AMOUNT: "100",
        DS_MERCHANT_MERCHANTURL: "",
        DS_MERCHANT_URLKO:
          process.env.VUE_APP_REDSYS_URL +
          store.getters["modules/getTemplateId"] +
          "/" +
          store.getters["modules/getElementId"] +
          "/" +
          props.uuid +
          "/KO",
        DS_MERCHANT_URLOK:
          process.env.VUE_APP_REDSYS_URL +
          store.getters["modules/getTemplateId"] +
          "/" +
          store.getters["modules/getElementId"] +
          "/" +
          props.uuid +
          "/confirm-payment",
        DS_MERCHANT_PAYMETHODS: "c",
      },
      variablesForVerifyPayment: {
        deadLineId: "",
        fileId: "",
      },
      loading: false,
    });

    watch(
      () => data.tpvInfo.orderId,
      () => {
        (data.mParams.DS_MERCHANT_ORDER = data.tpvInfo.orderId),
          (data.mParams.DS_MERCHANT_MERCHANTCODE = data.tpvInfo.fucCode),
          (data.mParams.DS_MERCHANT_TERMINAL = data.tpvInfo.terminal),
          (data.mParams.DS_MERCHANT_CURRENCY = data.tpvInfo.currency),
          (data.mParams.DS_MERCHANT_TRANSACTIONTYPE =
            data.tpvInfo.transaction_type),
          (data.mParams.DS_MERCHANT_AMOUNT = "100"),
          (data.mParams.DS_MERCHANT_MERCHANTURL =
            process.env.VUE_APP_BASE_URL +
            `/api/landing_pages/${props.uuid}/verify?fileId=${data.variablesForVerifyPayment.fileId}&deadLineId=${data.variablesForVerifyPayment.deadLineId}`),
          (data.mParams.DS_MERCHANT_URLKO =
            process.env.VUE_APP_REDSYS_URL +
            store.getters["modules/getTemplateId"] +
            "/" +
            store.getters["modules/getElementId"] +
            "/" +
            props.uuid +
            "/KO"),
          (data.mParams.DS_MERCHANT_URLOK =
            process.env.VUE_APP_REDSYS_URL +
            store.getters["modules/getTemplateId"] +
            "/" +
            store.getters["modules/getElementId"] +
            "/" +
            props.uuid +
            "/OK"),
          (data.mParams.DS_MERCHANT_PAYMETHODS = "c");

        let merchant = cryoptojs.enc.Utf8.parse(JSON.stringify(data.mParams));
        data.Ds_MerchantParameters = merchant.toString(cryoptojs.enc.Base64);
        let keyBase64 = cryoptojs.enc.Base64.parse(data.tpvInfo.secret);

        let iv = cryoptojs.enc.Hex.parse("0000000000000000");
        let cipher = cryoptojs.TripleDES.encrypt(
          data.mParams.DS_MERCHANT_ORDER,
          keyBase64,
          {
            iv: iv,
            mode: cryoptojs.mode.CBC,
            padding: cryoptojs.pad.ZeroPadding, // Redsys dont like padding
          }
        );
        let signature = cryoptojs.HmacSHA256(
          data.Ds_MerchantParameters,
          cipher.ciphertext
        );

        data.Ds_Signature = signature.toString(cryoptojs.enc.Base64);
      }
    );

    function getInfoToPayment() {
      const params = {
        uuid: "/api/landing_pages/" + props.uuid,
        callbackSuccess: (callbackSuccessData) => {
          if (
            callbackSuccessData.data.length > 0 &&
            callbackSuccessData.data.merchantId &&
            callbackSuccessData.data.merchantId.length > 0 &&
            callbackSuccessData.data.secret &&
            callbackSuccessData.data.secret.length > 0 &&
            callbackSuccessData.data.tpv &&
            callbackSuccessData.data.tpv.length > 0 &&
            callbackSuccessData.data.orderId &&
            callbackSuccessData.data.orderId.length > 0
          ) {
            data.tpvInfo.fucCode = callbackSuccessData.data.merchantId;
            data.tpvInfo.secret = callbackSuccessData.data.secret;
            data.tpvInfo.terminal = callbackSuccessData.data.tpv;
            data.tpvInfo.orderId = callbackSuccessData.data.orderId;
            data.seePageButton = false;
          } else {
            data.seePageButton = true;
          }
          data.loading = false;
        },
        callbackError: () => {
          data.loading = false;
        },
      };
      store.dispatch("modules/getInfoToPayment", params);
    }

    function getPaymentPlanData() {
      data.loading = true;
      const params = {
        uuid: "/api/landing_pages/" + props.uuid,
        fileID: null,
        callbackSuccess: (callbackSuccessData) => {
          callbackSuccessData.data.forEach((item) => {
            if (item.status === "approved") {
              data.variablesForVerifyPayment.deadLineId =
                item.paymentPlanDeadline["@id"].replace(
                  "/api/payment_plan_deadlines/",
                  ""
                );
              data.variablesForVerifyPayment.fileId =
                store.getters["modules/getFileUuid"];
            }
          });
          getInfoToPayment();
        },
        callbackError: () => {},
      };
      store.dispatch("modules/getPaymentPlanData", params);
    }

    const mobileColors = computed(() => {
      return store.getters["user/getMobileColors"];
    });

    getPaymentPlanData();

    return {
      ...toRefs(data),
      cryoptojs,
      Utils,
      primaryColor,
      mobileColors
    };
  },
});
</script>

<style>
</style>     