
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import SpIcon from "@/components/ui/SpIcon.vue";
import SpButton from "@/components/ui/SpButton.vue";
import SpSelect from "@/components/ui/SpSelect.vue";
import SpInputRange from "@/components/axactorModulesMobile/SpInputRangeAxactor.vue";
import router from "@/router";
import SpButtonIcon from "@/components/ui/SpButtonIcon.vue";
import * as Utils from "@/api/Utils";
import Card from "@/components/axactorModulesMobile/paymentMethodsTemplate1/Card.vue";
import Bizum from "@/components/axactorModulesMobile/paymentMethodsTemplate1/Bizum.vue";

export default {
  name: "PaymentPending",
  components: {
    SpIcon,
    SpButton,
    SpSelect,
    SpInputRange,
    SpButtonIcon,
    Card,
    Bizum,
  },
  props: {
    uuid: { type: String, required: true },
  },
  setup(props: any) {
    const store = useStore();

    interface PaymentPending {
      paymentPlans: Array<any>;
      type: string;
      loading: boolean;
    }

    const data = reactive<PaymentPending>({
      paymentPlans: [],
      type: "card",
      loading: false,
    });

    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });
    const modules = computed(() => {
      return store.getters["modules/getModules"];
    });

    const customApi = computed(() => {
      return store.getters["user/getCustomApi"];
    });

    function goTo(value: string) {
      router.push({ name: value });
    }

    function changeType(value: string) {
      data.type = value;
    }

    function getPaymentPlanData() {
      data.loading = true;
      const params = {
        uuid: "/api/landing_pages/" + props.uuid,
        // fileID: store.getters['modules/getFileUuid'],
        fileID: null,
        callbackSuccess: (callbackSuccessData: any) => {
          if (callbackSuccessData.data.length > 0)
            callbackSuccessData.data.forEach((item: any) => {
              item.status === 'approved' ? data.paymentPlans.push(item) : ''
            })

          data.loading = false;
        },
        callbackError: () => {
          data.loading = false;
        },
      };
      store.dispatch("modules/getPaymentPlanData", params);
    }

    function postCheckoutSessionApiMode(amount: number, deadLineId: string) {
      data.loading = true;
      let params = {};
      if (deadLineId && deadLineId.length > 0) {
        params = {
          uuid: "/api/landing_pages/" + props.uuid,
          paymentPlanDeadline: deadLineId,
          callbackUrl: callbackUrl.value,
          callbackSuccess: (callbackSuccessData: any) => {
            window.location.href = callbackSuccessData.data.checkoutSessionUri;
            data.loading = false;
          },
          callbackError: () => {},
        };
      } else {
        params = {
          uuid: "/api/landing_pages/" + props.uuid,
          file: "/api/files/" + store.getters["modules/getFileUuid"],
          amount: amount,
          callbackUrl: callbackUrl.value,
          callbackSuccess: (callbackSuccessData: any) => {
            window.location.href = callbackSuccessData.data.checkoutSessionUri;
            data.loading = false;
          },
          callbackError: () => {},
        };
      }
      store.dispatch("modules/postCheckoutSessionApiMode", params);
    }

    const callbackUrl = computed(() => {
      return window.location.href;
    });
    const headerMobileColors = computed(() => {
      return {
        "--primary-color": `${modules.value.paymentPlan.templates[0].configuration.profileMenu.pendingPayments.card.backgroundColor}`, // Definir una variable CSS
        // Otros estilos según sea necesario
      };
    });

    getPaymentPlanData();

    return {
      ...toRefs(data),
      goTo,
      getPaymentPlanData,
      Utils,
      changeType,
      language,
      modules,
      customApi,
      postCheckoutSessionApiMode,
      callbackUrl,
      headerMobileColors,
    };
  },
};
