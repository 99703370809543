
  import { defineComponent, reactive, toRefs, watch, computed} from 'vue';
  import { useStore } from "vuex";
  import SpIcon from '@/components/ui/SpIcon.vue';
  import SpButtonIcon from '@/components/ui/SpButtonIcon.vue'

  export default defineComponent({
    name: "SpMobileTable",
    components: { 
        SpButtonIcon,
     },
    props: {
        items: { type: Array, required: true},
        pagination: { type: Boolean, required: false, default: false },
        page: { type: Number, required: false, default: 1 },
        itemsPerPage: { type: Number, required: false, default: 1 },
        maxPages: { type: Number, required: false, default: 0 },

    },
    setup(props, { emit }) {
      const store = useStore();
  
      interface spTable {
        actualPage: number,
        disablePreviousPage: boolean,
        disableNextPage: boolean,
        loadindTable: boolean,
      }
  
      const data = reactive<spTable>({
        actualPage: props.page,
        disablePreviousPage: false,
        disableNextPage: false,
        loadindTable: false,
      });
  
  
      const isMobile = computed(() => store.getters["user/getIsMobile"]);
        
      function getNextPage() {
        emit("getNextPage");
      }
  
      function getPreviousPage() {
        emit("getPreviousPage");
      }
  
      function getFirstPage() {
        emit("getFirstPage");
      }
  
      function getLastPage() {
        emit("getLastPage");
      }
  
      function enableChange(index:number) {
          emit("enableChange", index)
      }
  
/*       const disabledNextPage = computed(() => props.maxPages === props.page) */
        
      watch(
        () => props.page,
        (newPage) => {
          data.actualPage = newPage;
          data.disablePreviousPage = data.actualPage === 1;
        }
      );
  
      if (data.actualPage === 1) {
        data.disablePreviousPage = true;
      }
  
      return {
        ...toRefs(data),
        getNextPage,
        getPreviousPage,
        getFirstPage,
        getLastPage,
/*         overflowFunction,
        disabledNextPage, */
        enableChange,
        isMobile,
      };
    },
  })
  