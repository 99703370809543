
import { defineComponent } from 'vue'

export default defineComponent({
  name: "SpModal",
  props: {
    size: { type: String, required: false, default: 'md' },
    hideBackground: { type: Boolean, required: false, default: false },
    withoutPadding: { type: Boolean, required: false, default: false },
    animation: { type: Boolean, required: false, default: false },
  }
})
