import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-screen h-screen flex justify-start items-center flex-col" }
const _hoisted_2 = {
  class: "flex flex-col justify-center items-center",
  style: {"height":"100%"}
}
const _hoisted_3 = {
  class: "p-4",
  style: {"position":"fixed","bottom":"20px","left":"0","width":"70%"}
}
const _hoisted_4 = { class: "font-semibold text-4xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_icon = _resolveComponent("sp-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_sp_icon, {
        model: 'no_data',
        size: "199",
        style: {"margin-top":"-200px"}
      }),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('files.noData')), 1)
      ])
    ])
  ]))
}