
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import SpIcon from "@/components/ui/SpIcon.vue";
import SpButton from "@/components/ui/SpButton.vue";
import SpTimerPickerAx from "@/components/axactorModulesMobile/SpTimerPickerAx.vue";
import SpCalendarAx from "@/components/axactorModulesMobile/SpCalendarAx.vue";
import dialphone from "@/assets/json/dial_phone_.json";
import SpSelect from "@/components/ui/SpSelect.vue";
import moment from "moment/moment";
import router from "@/router";
import SpButtonIcon from "@/components/ui/SpButtonIcon.vue";

export default {
  name: "SendEmail",
  components: {
    SpIcon,
    SpSelect,
    SpButton,
    SpTimerPickerAx,
    SpCalendarAx,
    SpButtonIcon,
  },
  props: {
    uuid: { type: String, required: true },
  },
  setup(props: any) {
    const store = useStore();
    const today = moment().format("YYYY-MM-DD");

    interface SendEmail {
      loading: boolean;
      languages: Array<any>;
      seeInterveners: boolean;
      prefixes: Array<any>;
      prefix: any | null;
      seeTime: boolean;
      time: string;
      timeForProp: number;
      preferredDueDay: string;
      dateForCall: string;
      seeCalendar: boolean;
      dateForShow: string;
      text: string,
      email: string,
    }

    const data = reactive<SendEmail>({
      loading: false,
      languages: [],
      seeInterveners: false,
      prefixes: [],
      prefix: "+34",
      seeTime: false,
      time: "",
      timeForProp: 0,
      preferredDueDay: moment().format("DD/MM/YYYY"),
      dateForCall: today,
      seeCalendar: false,
      dateForShow: moment().format("DD MMMM YYYY"),
      text: '',
      email: ''
    });

    function postSendDebtConsolidationEmail () {
      if (data.text.length === 0) {
        store.commit('user/pushNotification', {
          id: Date.now(),
          icon: 'alert-triangle',
          message: 'notifications.debtConsolidationError1',
          color: 'warning'
        })
        return
      }

      data.loading = true
      const params = {
        uuid: store.getters['user/getUUID'],
        message: data.email + ' ' + data.text,
        callbackSuccess: () => {
          data.text = ''
          data.email = ''
          goTo('EmailSent')
        },
        callbackError: () => {
          data.loading = false
        }
      }
      store.dispatch('modules/postSendSupportContent', params)
    }

    function seeIntervenersFunction() {
      data.seeInterveners = !data.seeInterveners;
    }

    function preferredDueDayFunction(date: any) {
      data.preferredDueDay = date;
    }

    function chageDateforCall(date: any) {
      data.dateForCall = date;
      data.dateForShow = moment(date).format("DD MMMM YYYY");
    }
    function goTo(value: string) {
      router.push({ name: value });
    }
    function saveTime(time: any) {
      data.seeTime = false;
      time < 10 ? (data.time = "0" + time + ":00") : (data.time = time + ":00");
      data.timeForProp = time;
    }

    function callToPrefixs() {
      data.loading = true;
      const prefix = dialphone
        .filter((c: any, index: any) => {
          return dialphone.indexOf(c) === index;
        })
        .sort((a, b) => parseInt(a) - parseInt(b));
      data.prefixes = prefix.map((prefix: any) => {
        return {
          label: prefix,
          value: prefix,
        };
      });
      data.loading = false;
    }

    function changeSelectedPrefix(uuid: string | null) {
      data.prefix = uuid;
    }

    const languageComputed = computed(() => data.languages);
    const seeIntervenersComputed = computed(() => data.seeInterveners);
    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });
    const modules = computed(()=> {
        return store.getters["modules/getModules"]
      })

    const mobileColors = computed(() => {
      return store.getters["user/getMobileColors"];
    });

    callToPrefixs();

    return {
      ...toRefs(data),
      languageComputed,
      seeIntervenersFunction,
      seeIntervenersComputed,
      callToPrefixs,
      changeSelectedPrefix,
      saveTime,
      preferredDueDayFunction,
      chageDateforCall,
      goTo,
      postSendDebtConsolidationEmail,
      language,
      modules,
      mobileColors

    };
  },
};
