
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import SpIcon from "@/components/ui/SpIcon.vue";
import SpButton from "@/components/ui/SpButton.vue";

export default defineComponent({
  name: "SpTimerPickerAx",
  components: {
    SpButton,
    SpIcon,
  },
  props: {
    disableDays: { type: Number, default: 0 },
    value: { type: String, default: "", required: true },
    label: { type: String, required: false, default: "" },
    size: { type: String, required: false, default: "md" },
    placeholder: { type: String, required: false, default: "" },
    disabled: { type: Boolean, required: false, default: false },
    titleGray: { type: Boolean, default: false },
    dateForShow: { type: String, required: false, default: "" },
  },
  emits: ["selectDate", "dateForCall"],
  setup(props, { emit }) {
    const store = useStore();
    interface spCalendar {
      colorToUse: string;
      calendar: Array<any>;
      month: number;
      year: number;
      showContent: boolean;
      dateForShowVariable: string;
    }

    const data = reactive<spCalendar>({
      colorToUse: "black",
      calendar: [],
      month: moment().month(),
      year: moment().year(),
      showContent: false,
      dateForShowVariable: props.dateForShow,
    });

    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });

    watch(
      () => language,
      () => {}
    );

    watch(
      () => props.dateForShow,
      (currentValue, oldValue) => {
        data.dateForShowVariable = props.dateForShow;
      }
    );

    const days = computed(() => {
      switch (language.value) {
        case "es":
          return [
            "LUN",
            "MAR",
            "MIE",
            "JUE",
            "VIE",
            ...getWeekendEnabledDays("es"),
          ];
        case "en":
          return [
            "MON",
            "TUE",
            "WED",
            "THU",
            "FRI",
            ...getWeekendEnabledDays("en"),
          ];
        case "ru":
          return [
            "MON",
            "TUE",
            "WED",
            "THU",
            "FRI",
            ...getWeekendEnabledDays("ru"),
          ];
        default:
          return [
            "MON",
            "TUE",
            "WED",
            "THU",
            "FRI",
            ...getWeekendEnabledDays("en"),
          ];
      }
    });

    const modules = computed(() => {
      return store.getters["modules/getModules"];
    });

    const selectDay = computed(() => {
      const hey = moment(new Date(props.value)).format("DD/MM/YYYY");
      return moment(hey).date();
    });

    function createCalendar() {
      const startDay = moment({ month: data.month, year: data.year })
        .clone()
        .startOf("month")
        .startOf("week");
      const endDay = moment({ month: data.month, year: data.year })
        .clone()
        .endOf("month")
        .endOf("week");

      const index = startDay.clone();
      data.calendar = [];
      while (index.isBefore(endDay, "day")) {
        data.calendar.push(
          new Array(7).fill(0).map(function (n, i) {
            return { date: index.add(1, "day").toArray() };
          })
        );
      }
    }

    function getWeekendEnabledDays(lang: string) {
      const [saturday, sunday] = lang === "es" ? ["SA", "DO"] : ["SAT", "SUN"];
      const weekend = [];
      if (modules.value.contact && modules.value.contact.saturday)
        weekend.push(saturday);
      if (modules.value.contact && modules.value.contact.sunday)
        weekend.push(sunday);
      return weekend;
    }

    function isDisableDate(date: any) {
        const firstDate = moment({
          month: moment().month(),
          date: moment().date(),
          year: moment().year(),
        }).format("YYYY-MM-DD");
        const lastDate = moment(firstDate)
          .add(props.disableDays, "day")
          .format("YYYY-MM-DD");
        const validateDate = moment({
          month: data.month,
          date: date[2],
          year: data.year,
        }).format("YYYY-MM-DD");
        return validateDate < firstDate;
    }

    function itIsMonth(month: any) {
      const actualMonth = data.month;
      return month !== actualMonth;
    }

    function selectDate(date: any) {
      if (!isDisableDate(date)) {
        showCalendar();
        emit("selectDate", `${date[2]}/${date[1] + 1}/${date[0]}`);
        emit("dateForCall", `${date[0]}-${date[1] + 1}-${date[2]}`);
      }
    }
    function nextMonth() {
      if (data.month === 11) {
        data.month = -1;
        data.year++;
      }
      data.month++;

      createCalendar();
    }

    function prevMonth() {
      if (data.month === 0) {
        data.month = 12;
        data.year--;
      }
      data.month--;
      createCalendar();
    }

    function showCalendar() {
      data.showContent = !data.showContent;
    }

    function canBeIncluded(index: number) {
      if (index !== 5 && index !== 6) return true;
      if (index === 5 && modules.value.contact)
        return modules.value.contact.saturday;
      if (index === 6 && modules.value.contact)
        return modules.value.contact.sunday;
    }

    function isSaturday() {
      if (language.value === "es") {
        if (
          !modules.value.contact &&
          modules.value.contact.saturday &&
          modules.value.contact.sunday
        ) {
          return "DOM";
        }
      }
      return "SUN";
    }

    function isSunday() {
      if (language.value === "es") {
        if (
          modules.value.contact &&
          modules.value.contact.saturday &&
          modules.value.contact.sunday
        ) {
          return "DOM";
        }
        return "SAT";
      }
    }

    const siritSelectClass = computed(() => {
      return "sirit-select-" + props.size;
    });

    createCalendar();

    return {
      ...toRefs(data),
      days,
      showCalendar,
      isDisableDate,
      itIsMonth,
      selectDate,
      nextMonth,
      prevMonth,
      siritSelectClass,
      selectDay,
      modules,
      language,
      canBeIncluded,
      isSaturday,
      isSunday,
      getWeekendEnabledDays,
    };
  },
});
