import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  id: "who-is-sirit",
  class: "flex flex-col m-0 w-full overflow-hidden"
}
const _hoisted_2 = { class: "default-box-white-full flex justify-center items-center !px-5 md:!px-32 lg:!px-40 xl:!px-72" }
const _hoisted_3 = { class: "flex text-left py-4 flex-col md:flex-row justify-center items-center' md:justify-between md:items-start gap-6" }
const _hoisted_4 = {
  key: 0,
  class: "w-full flex flex-col"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_title = _resolveComponent("sp-title")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.showWhoIs)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_ctx.language === 'en' && _ctx.allowTitle)
                  ? (_openBlock(), _createBlock(_component_sp_title, {
                      key: 0,
                      message: _ctx.titleContentEn,
                      size: 'text-'+_ctx.titleFontSize,
                      color: _ctx.titleColor
                    }, null, 8, ["message", "size", "color"]))
                  : _createCommentVNode("", true),
                (_ctx.language === 'es' && _ctx.allowTitle)
                  ? (_openBlock(), _createBlock(_component_sp_title, {
                      key: 1,
                      message: _ctx.titleContentEs,
                      size: 'text-'+_ctx.titleFontSize,
                      color: _ctx.titleColor
                    }, null, 8, ["message", "size", "color"]))
                  : _createCommentVNode("", true),
                (_ctx.language === 'ru' && _ctx.allowTitle)
                  ? (_openBlock(), _createBlock(_component_sp_title, {
                      key: 2,
                      message: _ctx.titleContentRu,
                      size: 'text-'+_ctx.titleFontSize,
                      color: _ctx.titleColor
                    }, null, 8, ["message", "size", "color"]))
                  : _createCommentVNode("", true),
                (_ctx.language === 'en' && _ctx.allowContent)
                  ? (_openBlock(), _createBlock(_component_sp_title, {
                      key: 3,
                      message: _ctx.contentContentEn,
                      size: 'text-'+_ctx.contentFontSize,
                      color: _ctx.contentColor
                    }, null, 8, ["message", "size", "color"]))
                  : _createCommentVNode("", true),
                (_ctx.language === 'es' && _ctx.allowContent)
                  ? (_openBlock(), _createBlock(_component_sp_title, {
                      key: 4,
                      message: _ctx.contentContentEs,
                      size: 'text-'+_ctx.contentFontSize,
                      color: _ctx.contentColor
                    }, null, 8, ["message", "size", "color"]))
                  : _createCommentVNode("", true),
                (_ctx.language === 'ru' && _ctx.allowContent)
                  ? (_openBlock(), _createBlock(_component_sp_title, {
                      key: 5,
                      message: _ctx.contentContentRu,
                      size: 'text-'+_ctx.contentFontSize,
                      color: _ctx.contentColor
                    }, null, 8, ["message", "size", "color"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}