interface securityStore {
    token: string,
    fallbackContactInfo: {
        logo: string,
        phone: string,
        address: string,
        email: string,
        text: {
            en: string,
            es: string,
            ru: string
        }
    }
}

const SecurityStore = {
    namespaced: true,
    state: () => ({
        token: '',
        fallbackContactInfo: {
            logo: '',
            phone: '',
            address: '',
            email: '',
            text: {
                en: '',
                es: '',
                ru: ''
            }
        }
    }),
    actions: {},
    getters: {
        getToken (state: securityStore): string {
            return state.token
        },
        getFallbackContactInfo (state: securityStore): any {
            return state.fallbackContactInfo
        }
    },
    mutations: {
        setToken (state: securityStore, token: string) {
            state.token = token
        },
        setFallbackContactInfoLogo (state: securityStore, logo: string) {
            state.fallbackContactInfo.logo = logo
        },
        setFallbackContactInfoPhone (state: securityStore, phone: string) {
            state.fallbackContactInfo.phone = phone
        },
        setFallbackContactInfoAddress (state: securityStore, address: string) {
            state.fallbackContactInfo.address = address
        },
        setFallbackContactInfoTextEn (state: securityStore, text: string) {
            state.fallbackContactInfo.text.en = text
        },
        setFallbackContactInfoTextEs (state: securityStore, text: string) {
            state.fallbackContactInfo.text.es = text
        },
        setFallbackContactInfoTextRu (state: securityStore, text: string) {
            state.fallbackContactInfo.text.ru = text
        },
        setFallbackContactInfoEmail (state: securityStore, email: string) {
            state.fallbackContactInfo.email = email
        },
    }
}

export default SecurityStore
