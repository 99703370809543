
import {computed, defineComponent} from 'vue'
import SpIcon from "@/components/ui/SpIcon.vue";
import {useStore} from "vuex";
import i18n from "@/main";

export default defineComponent({
  name: "Error403View",
  components: { SpIcon },
  setup (props, { emit }) {
    const store = useStore()

    const fallbackContactInfo = computed(() => {
      return store.getters['security/getFallbackContactInfo']
    })

    function changeLocale (locale: any) {
      store.commit('user/setLanguage', locale)
      i18n.global.locale = locale
    }

    const language = computed(() => {
      return store.getters['user/getLanguage']
    })

    const customApi = computed(() => {
      return store.getters['modules/getCustomApi']
    })

    return {
      changeLocale,
      fallbackContactInfo,
      language,
      customApi
    }
  }
})
