<template>
  <div>
    <span :class="titleClass"
          :style="titleStyle">{{ message }}</span>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import {useStore} from 'vuex'

export default defineComponent({
  name: "SpTitle",
  props: {
    size: { type: String, required: true, default: 'text-6xl' },
    message: { type: String, required: true, default: '' },
    color: { type: String, required: false, default: '' },
    bold: { type: Boolean, required: false, default: true }
  },
  setup (props) {
    const store = useStore()

    const titleClass = computed(() => {
      let fontBold = props.bold ? 'font-semibold ' : ''
      return fontBold + props.size
    })

    const titleStyle = computed(() => {
      let colorToUse = store.getters['user/getPrimaryColor']
      if (props.color.length > 0) colorToUse = props.color

      return {
        color: colorToUse
      }
    })

    return {
      titleClass,
      titleStyle
    }
  }
})
</script>

<style scoped>

</style>