
import {computed, defineComponent} from 'vue'

export default defineComponent({
  name: "SpFloatingWindow",
  props: {
    position: { type: String, required: true, default: 'bottom' }
  },
  emits: ['close'],
  setup (props, { emit }) {
    function closeFloatingWindow () {
      emit('close')
    }

    const floatingBackWindowClass = computed(() => {
      let result: string = ''

      if (props.position === 'bottom') result = 'justify-center items-end fadeInLeft'
      if (props.position === 'top') result = 'justify-center items-start'
      if (props.position === 'left') result = 'justify-start items-start'
      if (props.position === 'right') result = 'justify-end items-start'

      return result
    })

    const floatingWindowClass = computed(() => {
      let result: string = ''

      if (props.position === 'bottom') result = 'w-full h-3/4 fadeInUp'
      if (props.position === 'top') result = 'w-full h-3/4 fadeInDown'
      if (props.position === 'left') result = 'w-3/4 h-full fadeInLeft'
      if (props.position === 'right') result = 'w-3/4 h-full fadeInRight'

      return result
    })

    return {
      closeFloatingWindow,
      floatingBackWindowClass,
      floatingWindowClass
    }
  }
})
