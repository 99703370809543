
import {computed, defineComponent, reactive, toRefs} from 'vue'
import SpButtonIcon from "@/components/ui/SpButtonIcon.vue"
import {useStore} from "vuex";

export default defineComponent({
  name: "SpAccordion",
  components: { SpButtonIcon },
  props: {
    questions: { type: Array, default: [] }
  },
  setup (props) {
    const store = useStore()
    interface spAccordion {
      questionsData: Array<any>
    }

    const language = computed(() => {
      return store.getters["user/getLanguage"];
    })

    const data = reactive<spAccordion>({
      questionsData: []
    })

    function parseData () {
      let result: Array<any> = []
      props.questions?.forEach(function (question: any) {
        result.push({
          title: question.title,
          description: question.description,
          show: false
        })
      })
      data.questionsData = result
    }

    parseData()

    return {
      ...toRefs(data),
      language
    }
  }
})
