import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "flex items-center w-full h-full justify-between",
  style: {"min-height":"50px"}
}
const _hoisted_2 = { class: "flex justify-center items-start" }
const _hoisted_3 = { class: "font-semibold" }
const _hoisted_4 = { class: "flex items-start justify-start" }
const _hoisted_5 = {
  key: 0,
  class: "flex justify-start items-start p-3",
  style: {"min-height":"70px","width":"90%","border-radius":"10px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_button_icon = _resolveComponent("sp-button-icon")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionsData, (question, index) => {
    return (_openBlock(), _createElementBlock("div", {
      key: 'action' + index,
      class: "flex flex-col items-center justify-between p-2 w-full mb-2",
      style: {"min-height":"70px","border-radius":"15px","border":"1px solid #000000"}
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(question.title[_ctx.language]), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_sp_button_icon, {
            onOnClick: ($event: any) => (question.show = !question.show),
            icon: !question.show? 'chevron-right' : 'chevron-down',
            size: "15"
          }, null, 8, ["onOnClick", "icon"])
        ])
      ]),
      (question.show)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("span", null, _toDisplayString(question.description[_ctx.language]), 1)
          ]))
        : _createCommentVNode("", true)
    ]))
  }), 128))
}