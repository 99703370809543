
import {computed, defineComponent, reactive, toRefs, onBeforeMount} from 'vue'
import {useStore} from "vuex";
import SpIcon from '@/components/ui/SpIcon.vue'

export default defineComponent({
  name: "BottomNavigationBar",
  components: {SpIcon},
  props: {
    modules: { type: Array, required: true, default: [] },
    currentSection: { type: String, required: true, default: '' }
  },
  emits: ['goTo'],
  setup (props, { emit }) {
    const store = useStore()

    interface bottomNavigationBar {
      // activeButtons: Array<any>,
      modulesToShow: Array<any>,
      actualLanguage: string
    }

    const data = reactive<bottomNavigationBar>({
      // activeButtons: [],
      modulesToShow: ['welcomeText', 'paymentPlan', 'files', 'contactUs', 'faqs'],
      actualLanguage: store.getters['user/getLanguage']
    })

    const isMobile = computed(() => {
      return store.getters["user/getIsMobile"]
    })

    const primaryColor = computed(() => {
      return store.getters['user/getPrimaryColor']
    })

    const userLanguage = computed(() => {
      return store.getters['user/getLanguage']
    })

    function getButtonStyle (index: number, section: string) {
      let color = 'white'
      let background = primaryColor.value

      if (section === props.currentSection) {
        color = primaryColor.value
        background = 'white'
      }

      if (index !== 0) {
        return {
          color: color,
          background: background,
          'border-left': '1px solid rgba(220, 220, 220, 0.2)'
        }
      }
      return {
        color: color,
        background: background
      }
    }

    function goTo (value: string) {
      emit('goTo', value)
    }

    const activeButtons = computed( () => {
      let resultLanguage: Array<any> = []
      if (userLanguage.value === 'es') {
        resultLanguage = [
          { icon: 'euro', label: 'Tu deuda', value: 'welcomeText' },
          { icon: 'credit-card', label:  'Pago', value: 'paymentPlan' },
          { icon: 'upload', label:  'Archivos', value: 'files' },
          { icon: 'message-circle', label: 'Contacto', value: 'contactUs' },
          { icon: 'help-circle', label:  'Preguntas', value: 'faqs' } 
        ]
      }
      if (userLanguage.value === 'ru') {
        resultLanguage = [
          { icon: 'euro', label: 'Ваш долг', value: 'welcomeText' },
          { icon: 'credit-card', label:  'Оплата', value: 'paymentPlan' },
          { icon: 'upload', label:  'Файлы', value: 'files' },
          { icon: 'message-circle', label:  'Контакт', value: 'contactUs' },
          { icon: 'help-circle', label:  'Часто задаваемые вопросы', value: 'faqs' } 
        ]
      }
      if (userLanguage.value === 'en') {
        resultLanguage =  [
          { icon: 'euro', label: 'Your debt', value: 'welcomeText' },
          { icon: 'credit-card', label:  'Payment', value: 'paymentPlan' },
          { icon: 'upload', label:  'Files', value: 'files' },
          { icon: 'message-circle', label: 'Contact', value: 'contactUs' },
          { icon: 'help-circle', label:  'FAQs', value: 'faqs' } 
        ]
      }

      let result: Array<any> = []


      result = resultLanguage.filter((item: any) => props.modules.includes(item.value))
      // data.activeButtons = result
      return result
    })

    // activeButtons()

    // watch(() => store.getters['user/getLanguage'], (first, second) => {
    //   data.actualLanguage = store.getters['user/getLanguage']
    //   activeButtons()
    // })

    
 
    return {
      ...toRefs(data),
      isMobile,
      getButtonStyle,
      goTo,
      primaryColor,
      userLanguage,
      activeButtons,
      // activeButtonsComputed
    }
  }
})
