import { createStore } from 'vuex'
import UserStore from '@/store/UserStore'
import ModulesStore from '@/store/ModulesStore'
import SecurityStore from '@/store/SecurityStore'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user: UserStore,
    modules: ModulesStore,
    security: SecurityStore
  }
})
