import * as Routing from '@/router/Routing'
import router from '@/router/index'

export interface ApiResponse {
    success: boolean,
    code: number,
    data: any
}

export function giveAnswer (response: any, tag: any = ''): ApiResponse {
    if (process.env.VUE_APP_DEBUG === 'true') {
        tag
            ? console.log('API,', tag, 'response:', response)
            : console.log('API - response:', response)
    }

    if (response.status === 401) {
        if (process.env.VUE_APP_DEBUG === 'true') console.log('Unauthorized', response)
        // Routing.openLogin()
    }

    if (response.status === 404) {
        if (process.env.VUE_APP_DEBUG === 'true') console.log('Error 404', response)
        router.push('/404')
    }

    return {
        success: ((response.status === 200) || (response.status === 201) || (response.status === 204)),
        code: response.status,
        data: response.data
    }
}
