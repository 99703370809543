
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  onUnmounted,
  onMounted,
  inject,
  watch,
} from "vue";
import { useStore } from "vuex";
import SpButton from "@/components/ui/SpButton.vue";
import * as Utils from "@/api/Utils";
import SpModal from "@/components/ui/SpModal.vue";
import SpIcon from "@/components/ui/SpIcon.vue";
import { getLandingPayments } from "@/api/Modules";
import Card from "./paymentsMethods/Card.vue";
import PayPal from "./paymentsMethods/PayPal.vue";
import Bizum from "./paymentsMethods/Bizum.vue";
import CardStripe from "./paymentsMethods/CardStripe.vue";
import Transfer from "./paymentsMethods/Transfer.vue";
import SpTitle from "@/components/ui/SpTitle.vue";
import router from "@/router";

export default defineComponent({
  name: "PaymentModule",
  components: {
    SpIcon,
    SpModal,
    SpButton,
    Card,
    PayPal,
    Bizum,
    Transfer,
    CardStripe,
    SpTitle,
  },
  props: {
    uuid: { type: String, required: true, default: "" },
    debtAmount: { type: Number, required: true, default: 0 },
    currency: { type: String, required: true, default: "EUR" },
    result: { type: String, default: "", required: false },
    paymentPlanMethod: { type: Object, required: true },
    title: { type: Object, required: true },
    subtitle: { type: Object, required: true },
    isStripe: { type: Boolean, default: false, required: true },
    removalsPercentage: { type: Number, required: true, default: 0 },
    allowBizum: { type: Boolean, default: true, required: true },
    allowCreditCard: { type: Boolean, default: true, required: true },
    isSiritStripe: { type: Boolean, default: false, required: true },
  },
  setup(props, { emit }) {
    const store = useStore();
    const cryoptojs = inject("cryptojs");
    const isMobile = computed(() => {
      return store.getters["user/getIsMobile"];
    });

    // interface paymentModule {
    //   amountToPay: number,
    //   firstLoadingPayments: boolean,
    //   loading: boolean,
    //   loadingPayments: boolean,
    //   payments: Array<any>,
    //   selectedMethod: string,
    //   showPaymentModal: boolean,
    //   totalPayment: number
    // }

    const data = reactive({
      amountToPay: props.debtAmount,
      firstLoadingPayments: false,
      loading: false,
      loadingPayments: true,
      loadingData: true,
      payments: [],
      selectedMethod: "",
      showPaymentModal: false,
      isDoneResultPayment: true,
      totalPayment: 0,
      Ds_SignatureVersion: "HMAC_SHA256_V1",
      Ds_MerchantParameters: "",
      Ds_Signature: "",
      transaction: {
        quantity: 10,
        tpvId: "",
        pageType: "c",
      },
      amount: props.debtAmount,
      debtAmountDato: 0,
      seePayments: true,
      deadLineId: "",
      loadingPayment: false,
    });

    const callbackUrl = computed(() => {
      return window.location.href;
    });

    if (props.isStripe || props.isSiritStripe) data.selectedMethod = "creditCardStripe";

    watch(
      () => store.getters["modules/getTotalToPay"],
      (first, second) => {
        data.amount = store.getters["modules/getTotalToPay"];
        getLandingPayments();
        getPaymentPlanData();
      }
    );
    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });

    const modules = computed(() => {
      return store.getters["modules/getModules"];
    });

    const paymentMethodsTitle = computed(() => {
      return store.getters["modules/getPaymentMethodsTitle"];
    });

    const debtAmountData = computed<number>(() => {
      return store.getters["modules/getTotalToPay"] > 0
        ? store.getters["modules/getTotalToPay"]
        : data.debtAmountDato;
    });

    const customApi = computed(() => {
      return store.getters["user/getCustomApi"];
    });

    function getLandingPayments() {
      const params = {
        uuid: props.uuid,
        callbackSuccess: (callbackSuccessData: any) => {
          data.firstLoadingPayments = true;
          data.totalPayment = 0;
          callbackSuccessData.data["hydra:member"].forEach(function (
            payment: any
          ) {
            data.totalPayment += payment.amount;
          });
          data.payments = callbackSuccessData.data["hydra:member"];
          data.loadingPayments = false;
        },
        callbackError: () => {
          data.loadingPayments = false;
        },
      };
      store.dispatch("modules/getLandingPayments", params);
    }

    function getFilesToAddToPayment() {
      const params = {
        uuid: props.uuid,
        callbackSuccess: (callbackSuccessData: any) => {
          if (
            callbackSuccessData.data.availableFilesToAddToAPaymentPlan.length >
            0
          ) {
            if (props.removalsPercentage > 0) {
              data.debtAmountDato =
                callbackSuccessData.data.totalAmountIncludingRemoval;
            } else {
              data.debtAmountDato =
                callbackSuccessData.data.totalAmountWithoutIncludingRemoval;
            }
            callbackSuccessData.data.availableFilesToAddToAPaymentPlan.forEach(
              (item: any) => {}
            );
          }
        },
        callbackError: () => {},
      };
      store.dispatch("modules/getFilesToAddToPayment", params);
    }

    function postGetTotalToPay() {
      const params = {
        uuid: props.uuid,
        fileID: store.getters["modules/getFileUuid"],
        callbackSuccess: (callbackSuccessData: any) => {
          data.debtAmountDato = callbackSuccessData.data.pendingTotalDebt;
        },
        callbackError: () => {},
      };
      store.dispatch("modules/postGetTotalToPay", params);
    }

    getFilesToAddToPayment();

    watch(
      () => store.getters["modules/getFileUuid"],
      () => {
        getPaymentPlanData();
        postGetTotalToPay();
      }
    );

    /* function postCreateFinconectaPayment() {
      data.loading = true;
      const params = {
        uuid: props.uuid,
        amount: data.amountToPay,
        paymentMethod: data.selectedMethod,
        callbackSuccess: () => {
          console.log("MODAL PAY?")
          data.showPaymentModal = true;
          getLandingPayments();
          data.loading = false;
        },
        callbackError: () => {
          data.loading = false;
        },
      };
      store.dispatch("modules/postCreateFinconectaPayment", params);
    }

    function postCreatePayment() {
      data.loading = true;
      const params = {
        uuid: props.uuid,
        quantity: data.transaction.quantity,
        tpvId: data.transaction.tpvId,
        extra: { mode: data.selectedMethod },
        callbackSuccess: () => {
          data.loading = false;
        },
        callbackError: () => {
          data.loading = false;
        },
      };
      store.dispatch("modules/postCreatePayment", params);
    } */

    function getPaymentPlanData() {
      const params = {
        uuid: props.uuid,
        fileID: store.getters["modules/getFileUuid"],
        callbackSuccess: (callbackSuccessData: any) => {
          callbackSuccessData.data.length > 0
            ? (data.seePayments = true)
            : (data.seePayments = false);
          callbackSuccessData.data.length > 0
            ? (data.amount = callbackSuccessData.data[0].customPaymentAmount)
            : "";

          if (callbackSuccessData.data.length > 0) {
            callbackSuccessData.data.forEach((item: any) => {
              if (item.status === "approved") {
                data.deadLineId = item.paymentPlanDeadline["@id"];
              }
            });
          }

          data.loadingData = false;
        },
        callbackError: () => {
          data.loadingData = false;
        },
      };
      store.dispatch("modules/getPaymentPlanData", params);
    }

    function postCheckoutSessionApiMode() {
      data.loadingPayment = true;
      let params = {};
      if (data.deadLineId.length > 0) {
        params = {
          uuid: props.uuid,
          paymentPlanDeadline: data.deadLineId,
          callbackUrl: callbackUrl.value,
          callbackSuccess: (callbackSuccessData: any) => {
            window.location.href = callbackSuccessData.data.checkoutSessionUri;
            data.loadingPayment = false;
          },
          callbackError: () => {},
        };
      } else {
        params = {
          uuid: props.uuid,
          file: "/api/files/" + store.getters["modules/getFileUuid"],
          amount: debtAmountData.value,
          callbackUrl: callbackUrl.value,
          callbackSuccess: (callbackSuccessData: any) => {
            window.location.href = callbackSuccessData.data.checkoutSessionUri;
            data.loadingPayment = false;
          },
          callbackError: () => {},
        };
      }
      store.dispatch("modules/postCheckoutSessionApiMode", params);
    }

    function openResultPaymentModal() {
      if (props.result === "") return;
      data.isDoneResultPayment = props.result === "OK";
      data.showPaymentModal = true;
    }

    openResultPaymentModal();
    getLandingPayments();
    getPaymentPlanData();

    return {
      ...toRefs(data),
      cryoptojs,
      isMobile,
      //postCreateFinconectaPayment,
      Utils,
      getPaymentPlanData,
      language,
      debtAmountData,
      openResultPaymentModal,
      customApi,
      paymentMethodsTitle,
      modules,
      postCheckoutSessionApiMode,
    };
  },
});
