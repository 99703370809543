import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  id: "contact",
  class: "default-box-transparent w-full py-4 px-0 md:px-32 lg:px-40 xl:px-72 text-left"
}
const _hoisted_2 = { class: "flex justify-between items-start py-4 px-5 md:px-0 flex-col md:flex-row" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "text-gray-600" }
const _hoisted_5 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = {
  key: 1,
  class: "flex flex-col"
}
const _hoisted_8 = { class: "mb-0" }
const _hoisted_9 = { class: "flex justify-center items-center" }
const _hoisted_10 = ["placeholder"]
const _hoisted_11 = {
  key: 2,
  class: "flex flex-col"
}
const _hoisted_12 = ["placeholder"]
const _hoisted_13 = { key: 3 }
const _hoisted_14 = { class: "mb-3 p-0" }
const _hoisted_15 = ["disabled", "placeholder"]
const _hoisted_16 = ["disabled", "placeholder"]
const _hoisted_17 = ["disabled", "placeholder"]
const _hoisted_18 = ["disabled", "placeholder"]
const _hoisted_19 = {
  key: 4,
  class: "flex flex-col"
}
const _hoisted_20 = ["placeholder"]
const _hoisted_21 = {
  key: 5,
  class: "mt-2 mb-4 p-4 bg-gray-100 rounded text-black"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_title = _resolveComponent("sp-title")!
  const _component_sp_select = _resolveComponent("sp-select")!
  const _component_contact_preferences_days_and_time = _resolveComponent("contact-preferences-days-and-time")!
  const _component_sp_button = _resolveComponent("sp-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_sp_title, {
          message: _ctx.title[_ctx.language],
          size: "text-4xl"
        }, null, 8, ["message"]),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.subtitle[_ctx.language]), 1)
      ])
    ]),
    _createElementVNode("div", {
      class: "p-4 rounded text-white w-full",
      style: _normalizeStyle('background: ' + _ctx.primaryColor + ';')
    }, [
      (_ctx.contactData.form.fullName.enabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("p", null, _toDisplayString(_ctx.contactData.form.fullName.label[_ctx.language]) + " " + _toDisplayString(_ctx.contactData.form.fullName.required  ? '*' : ''), 1),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
              type: "text",
              class: "p-2 rounded w-full",
              placeholder: _ctx.contactData.form.fullName.placeholder[_ctx.language]
            }, null, 8, _hoisted_6), [
              [_vModelText, _ctx.name]
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.contactData.form.phoneNumber.enabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.contactData.form.phoneNumber.label[_ctx.language]) + " " + _toDisplayString(_ctx.contactData.form.phoneNumber.required  ? '*' : ''), 1),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.isMobile ? 'w-full':'flex flex-col')
              }, [
                _createVNode(_component_sp_select, {
                  onSelectedValue: _ctx.changeSelectedPrefix,
                  value: _ctx.prefix,
                  placeholder: _ctx.$t('general.prefix'),
                  items: _ctx.prefixes,
                  loadingItems: _ctx.loading,
                  size: "xs",
                  "select-height": '0px !important'
                }, null, 8, ["onSelectedValue", "value", "placeholder", "items", "loadingItems"])
              ], 2),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.phone) = $event)),
                type: "tel",
                onKeypress: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isNumber($event))),
                placeholder: _ctx.contactData.form.phoneNumber.placeholder[_ctx.language],
                class: "ml-3 m-0"
              }, null, 40, _hoisted_10), [
                [_vModelText, _ctx.phone]
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.contactData.form.email.enabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("p", null, _toDisplayString(_ctx.contactData.form.email.label[_ctx.language]) + " " + _toDisplayString(_ctx.contactData.form.email.required  ? '*' : ''), 1),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.email) = $event)),
              type: "text",
              class: "p-2 rounded w-full",
              placeholder: _ctx.contactData.form.email.placeholder[_ctx.language]
            }, null, 8, _hoisted_12), [
              [_vModelText, _ctx.email]
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.contactData.form.address.enabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.contactData.form.address.label[_ctx.language]), 1),
            _createElementVNode("div", {
              class: _normalizeClass(["flex flex-wrap", _ctx.isMobile ? 'w-full':''])
            }, [
              (_ctx.contactData.form.address.columns.streetType.enabled )
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["mr-2", _ctx.isMobile ? 'w-full':''])
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.contactData.form.address.columns.streetType.label[_ctx.language]) + " " + _toDisplayString(_ctx.contactData.form.address.columns.streetType.required ? '*' : ''), 1),
                    _createVNode(_component_sp_select, {
                      onSelectedValue: _ctx.changeStreetType,
                      class: "w-full",
                      size: "full",
                      itemsText: _ctx.generalModule.dropDownListHeader.text[_ctx.language],
                      value: _ctx.typeStreet,
                      items: _ctx.streetTypes,
                      placeholder: _ctx.$t('general.contact17')
                    }, null, 8, ["onSelectedValue", "itemsText", "value", "items", "placeholder"])
                  ], 2))
                : _createCommentVNode("", true),
              (_ctx.contactData.form.address.columns.street.enabled )
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(["mr-2", _ctx.isMobile ? 'w-full':''])
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.contactData.form.address.columns.street.label[_ctx.language]) + " " + _toDisplayString(_ctx.contactData.form.address.columns.street.required ? '*' : ''), 1),
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.street) = $event)),
                      type: "text",
                      disabled: _ctx.isNoContactActive,
                      class: "p-2 rounded text-dark w-full",
                      placeholder: _ctx.contactData.form.address.columns.street.label[_ctx.language]
                    }, null, 8, _hoisted_15), [
                      [_vModelText, _ctx.street]
                    ])
                  ], 2))
                : _createCommentVNode("", true),
              (_ctx.contactData.form.address.columns.number.enabled )
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass(["mr-2", _ctx.isMobile ? 'w-full':'flex flex-col'])
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.contactData.form.address.columns.number.label[_ctx.language]) + " " + _toDisplayString(_ctx.contactData.form.address.columns.number.required ? '*' : ''), 1),
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.number) = $event)),
                      type: "text",
                      disabled: _ctx.isNoContactActive,
                      class: "p-2 rounded text-dark w-full",
                      style: {"width":"100px"},
                      placeholder: _ctx.contactData.form.address.columns.number.label[_ctx.language]
                    }, null, 8, _hoisted_16), [
                      [_vModelText, _ctx.number]
                    ])
                  ], 2))
                : _createCommentVNode("", true),
              (_ctx.contactData.form.address.columns.province.enabled )
                ? (_openBlock(), _createElementBlock("div", {
                    key: 3,
                    class: _normalizeClass(["mr-2", _ctx.isMobile ? 'w-full':''])
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.contactData.form.address.columns.province.label[_ctx.language]) + " " + _toDisplayString(_ctx.contactData.form.address.columns.province.required ? '*' : ''), 1),
                    _createVNode(_component_sp_select, {
                      onSelectedValue: _ctx.changeProvince,
                      size: "md",
                      items: _ctx.provinces,
                      value: _ctx.province,
                      placeholder: _ctx.contactData.form.address.columns.province.label[_ctx.language]
                    }, null, 8, ["onSelectedValue", "items", "value", "placeholder"])
                  ], 2))
                : _createCommentVNode("", true),
              (_ctx.contactData.form.address.columns.city.enabled )
                ? (_openBlock(), _createElementBlock("div", {
                    key: 4,
                    class: _normalizeClass(["mr-2", _ctx.isMobile ? 'w-full':''])
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.contactData.form.address.columns.city.label[_ctx.language]) + " " + _toDisplayString(_ctx.contactData.form.address.columns.city.required ? '*' : ''), 1),
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.city) = $event)),
                      type: "text",
                      disabled: _ctx.isNoContactActive,
                      class: "p-2 rounded text-dark w-full",
                      placeholder: _ctx.contactData.form.address.columns.city.label[_ctx.language]
                    }, null, 8, _hoisted_17), [
                      [_vModelText, _ctx.city]
                    ])
                  ], 2))
                : _createCommentVNode("", true),
              (_ctx.contactData.form.address.columns.postalCode.enabled)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 5,
                    class: _normalizeClass(["mr-2", _ctx.isMobile ? 'w-full':'flex flex-col'])
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.contactData.form.address.columns.postalCode.label[_ctx.language]) + " " + _toDisplayString(_ctx.contactData.form.address.columns.postalCode.required ? '*' : ''), 1),
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.postalCode) = $event)),
                      type: "text",
                      disabled: _ctx.isNoContactActive,
                      class: "p-2 rounded text-dark w-full",
                      style: {"width":"150px"},
                      placeholder: _ctx.contactData.form.address.columns.postalCode.label[_ctx.language]
                    }, null, 8, _hoisted_18), [
                      [_vModelText, _ctx.postalCode]
                    ])
                  ], 2))
                : _createCommentVNode("", true),
              (_ctx.contactData.form.address.columns.country.enabled )
                ? (_openBlock(), _createElementBlock("div", {
                    key: 6,
                    class: _normalizeClass(_ctx.isMobile ? 'w-full':'')
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.contactData.form.address.columns.country.label[_ctx.language]) + " " + _toDisplayString(_ctx.contactData.form.address.columns.country.required ? '*' : ''), 1),
                    _createVNode(_component_sp_select, {
                      onSelectedValue: _ctx.changeCountry,
                      items: _ctx.countries,
                      value: _ctx.country,
                      size: 'lg',
                      disabled: _ctx.isNoContactActive,
                      class: "rounded text-dark",
                      placeholder: _ctx.contactData.form.address.columns.country.label[_ctx.language]
                    }, null, 8, ["onSelectedValue", "items", "value", "disabled", "placeholder"])
                  ], 2))
                : _createCommentVNode("", true)
            ], 2)
          ]))
        : _createCommentVNode("", true),
      (_ctx.contactData.form.moreInfo.enabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
            _createElementVNode("p", null, _toDisplayString(_ctx.contactData.form.moreInfo.label[_ctx.language]) + " " + _toDisplayString(_ctx.contactData.form.moreInfo.required ? '*' : ''), 1),
            _withDirectives(_createElementVNode("textarea", {
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.message) = $event)),
              row: "5",
              type: "text",
              class: "p-2 rounded w-full text-dark",
              placeholder: _ctx.contactData.form.moreInfo.placeholder[_ctx.language]
            }, null, 8, _hoisted_20), [
              [_vModelText, _ctx.message]
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.contactData.schedule.days.enabled || _ctx.contactData.schedule.hours.enabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.daysAndTimeSlots, (item) => {
              return (_openBlock(), _createElementBlock("div", { key: item }, [
                _createVNode(_component_contact_preferences_days_and_time, {
                  onRemove: _ctx.removeDaysAndTimeSlots,
                  onUpdate: _ctx.updateDaysAndTimeSlots,
                  id: item.id,
                  days: item.days,
                  "since-time": item.sinceTheTime,
                  "until-time": item.untilTheTime,
                  saturday: _ctx.saturday,
                  sunday: _ctx.sunday
                }, null, 8, ["onRemove", "onUpdate", "id", "days", "since-time", "until-time", "saturday", "sunday"])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["mt-4 flex", _ctx.isMobile ? 'justify-center' : 'justify-end'])
      }, [
        _createVNode(_component_sp_button, {
          id: "send-form-contact-us",
          onOnClick: _ctx.postSendCustomerCallPreferences,
          loading: _ctx.loadingSend,
          label: _ctx.modules.contact.form.button.label.text[_ctx.language],
          size: "xs",
          style: _normalizeStyle('border-radius: 15px; background-color: '+ _ctx.modules.contact.form.button.backgroundColor +'; border: none; color: '+ _ctx.modules.contact.form.button.label.color+'; height: 50px;'),
          color: "white-primary"
        }, null, 8, ["onOnClick", "loading", "label", "style"])
      ], 2)
    ], 4)
  ]))
}