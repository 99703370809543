
import {computed, defineComponent, reactive, toRefs, watch} from 'vue'
import SpIcon from '@/components/ui/SpIcon.vue'
import SpButton from '@/components/ui/SpButton.vue'
import SpButtonIcon from "@/components/ui/SpButtonIcon.vue";
import SpTitle from '@/components/ui/SpTitle.vue'
import {useStore} from "vuex";

export default defineComponent({
  name: "FilesModule",
  components: { SpButtonIcon, SpIcon, SpButton, SpTitle },
  props: {
    contentData: { type: Object, required: true }
  },
  emits: ['updateFiles'],
  setup (props, { emit }) {
    const store = useStore()

    interface filesModule {
      files: Array<any>,
      filesBeingRemoved: Array<any>,
      loadingDelete: boolean,
      loadingUpload: boolean,
      uploadFilesData: Array<any>
      tag: any,
      types: any
    }

    const data = reactive<filesModule>({
      files: [],
      filesBeingRemoved: [],
      loadingDelete: false,
      loadingUpload: false,
      uploadFilesData: [],
      tag: '',
      types: []
    })

    function changeValue(tag: any) {
      data.tag = tag
    }

    const isMobile = computed(() => {
      return store.getters['user/getIsMobile']
    })
    const language = computed(() => {
      return store.getters["user/getLanguage"];
    })
    function deleteDocument (uuid: string) {
      data.filesBeingRemoved.push(uuid)
      data.loadingDelete = true
      const params = {
        uuid: uuid,
        callbackSuccess: () => {
          store.commit('user/pushNotification', {
            id: Date.now(),
            icon: 'trash',
            message: 'notifications.filesSuccess2',
            color: 'success'
          })
          data.loadingDelete = false
          data.filesBeingRemoved.splice(data.filesBeingRemoved.indexOf(uuid), 1)
          getLandingDocuments()
        },
        callbackError: () => {
          data.loadingDelete = false
          data.filesBeingRemoved.splice(data.filesBeingRemoved.indexOf(uuid), 1)
        }
      }
      store.dispatch('modules/deleteDocument', params)
    }

    function getLandingDocuments () {
      const params = {
        uuid: store.getters['user/getUUID'],
        callbackSuccess: (callbackSuccessData: any) => {
          data.uploadFilesData = callbackSuccessData.data.documents
        },
        callbackError: () => {}
      }
      store.dispatch('modules/getLandingDocuments', params)
    }

    function dragFile (e: any) {
      data.files = e.dataTransfer.files
      // postUploadFile()
    }

    function patchFileTag (event: any) {
      let fileUUID: string = event.target.id.replace('uploadFile-', '')
      const params = {
        uuid: fileUUID,
        tag: event.target.value,
        callbackSuccess: (callbackSuccessData: any) => {
          store.commit('user/pushNotification', {
            id: Date.now(),
            icon: 'check',
            message: 'notifications.successChangeTag',
            color: 'success'
          })
          getLandingDocuments()
        },
        callbackError: () => {
        }
      }
      store.dispatch('modules/patchDocumentTag', params)
    }

    function getDocumentTypes () {
      const params = {
        uuid: store.getters['user/getUUID'],
        callbackSuccess: (callbackSuccessData: any) => {
          data.types = callbackSuccessData.data.documentTypes
        },
        callbackError: () => {
        }
      }
      store.dispatch('modules/getDocumentTypes', params)
    }
    

    function onClickUpload() {
      let fileUpload = document.getElementById('upload-file')
      if (fileUpload !== null)  {
        fileUpload.click()
      }
    }
    
    function uploadFile (e: any) {
      data.files = e.target.files
      // postUploadFile()
      // e.target.value = ''
    }
    
    function postUploadFile () {
      data.loadingUpload = true
      const params = {
        uuid: store.getters['user/getUUID'].replace('/api/landing_pages/', ''),
        files: data.files,
        tag: data.tag,
        callbackSuccess: () => {
          data.files = []
          store.commit('user/pushNotification', {
            id: Date.now(),
            icon: 'upload-cloud',
            message: 'notifications.filesSuccess1',
            color: 'success'
          })
          getLandingDocuments()
          data.loadingUpload = false

        },
        callbackError: () => {
          data.loadingUpload = false
        }
      }
      store.dispatch('modules/postUploadDocument', params)
    }

    watch(() => store.getters['modules/getFileUuid'], () => {
      getLandingDocuments()
    })

    getLandingDocuments()
    getDocumentTypes()

    return {
      ...toRefs(data),
      isMobile,
      deleteDocument,
      dragFile,
      onClickUpload,
      patchFileTag,
      uploadFile,
      language,
      postUploadFile,
      changeValue,
      getDocumentTypes
    }
  }
})
