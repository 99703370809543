
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import SpButton from "@/components/ui/SpButton.vue";
import BottomNavigationBarAx from "@/components/axactorModulesMobile/BottomNavigationBarAx.vue";
import SpSelect from "@/components/ui/SpSelect.vue";
import router from "@/router";
import { useI18n } from 'vue-i18n'

export default {
  name: "CategorizeDocument",
  components: {
    SpButton,
    BottomNavigationBarAx,
    SpSelect,
  },
  props: {
    uuid: { type: String, required: true },
  },
  setup(props: any) {
    const store = useStore();

    interface CategorizeDocument {
      tag: string | null,
      fileId: string,
      tags: Array<any>
    }

    const data = reactive<CategorizeDocument>({
      tag: null,
      fileId: store.getters["modules/getFileForCategorice"],
      tags: [
        {label: 'Payroll', value: 'fileType1',translationKey: 'fileType1'},
        {label: 'Personal income tax (Form 100). (Facilitated by the Tax Agency) or Income Certificate', value: 'fileType2', translationKey: 'fileType2'},
        {label: 'Pension Income Certificate (Facilitated by the National Social Security Institute)', value: 'fileType3', translationKey: 'fileType3'},
        {label: 'SEPE income certificate (Facilitated by the Public State Employment Service)', value: 'fileType4', translationKey: 'fileType4'},
        {label: 'Work Life (Facilitated by the National Institute of Social Security)', value: 'fileType5', translationKey: 'fileType5'},
        {label: 'Certificate confirming that you do not receive benefits from the INSS (Facilitated by the National Institute of Social Security)', value: 'fileType6', translationKey: 'fileType6'},
        {label: 'Work Life (Facilitated by the National Institute of Social Security)', value: 'fileType7', translationKey: 'fileType7'},
        {label: 'IRPF (Form 130 or 131). (Facilitated by the Tax Agency)', value: 'fileType8', translationKey: 'fileType8'},
        {label: 'VAT Annual VAT summary (Mod. 303 / Mod. 390). (Facilitated by the Tax Agency)', value: 'fileType9', translationKey: 'fileType9'},
        {label: 'DNI – INE – Passport', value: 'fileType10', translationKey: 'fileType10'},
        {label: 'Others', value: 'fileType11', translationKey: 'fileType11'},
      ]
    });

    function changeTag (tag: any) {
      data.tag = tag
    }

    function goTo(value: string) {
      router.push({ name: value });
    }

    function patchFileTag () {
      const params = {
        uuid: data.fileId,
        tag: data.tag,
        callbackSuccess: (callbackSuccessData: any) => {
          store.commit('user/pushNotification', {
            id: Date.now(),
            icon: 'check',
            message: 'notifications.successChangeTag',
            color: 'success'
          })
          goTo('Files')
        },
        callbackError: () => {
        }
      }
      store.dispatch('modules/patchDocumentTag', params)
    }

    return {
      ...toRefs(data),
      goTo,
      patchFileTag,
      changeTag
    };
  },
};
