
import { defineComponent, reactive, toRefs, computed, watch } from "vue";
import SpIcon from "@/components/ui/SpIcon.vue";

export default defineComponent({
  name: "SiritSelect",
  props: {
    label: { type: String, required: false, default: "" },
    inheritedClasses: { type: Array, required: false, default: () => [] },
    items: { type: Array, required: true },
    size: { type: String, required: false, default: "md" },
    value: { required: false, default: null },
    placeholder: { type: String, required: false, default: "" },
    loadingItems: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    maxCharacters: { type: Number, required: false, default: 0 },
    fixedItems: { type: Boolean, default: false },
    titleGray: { type: Boolean, default: false },
    selectHeight: { type: String, required: false, default: "" },
    backgroundColor: { type: String, required: false, default: "" },
    border: { type: String, required: false, default: "" },
    borderRadius: { type: String, required: false, default: "" },
    icon: { type: Boolean, required: false, default: true },
    itemsText: { type: String, required: false, default: '' },
    example: {type: String, required: false, default: ""}
  },
  components: {
    SpIcon,
  },
  emits: ["selectedValue"],
  setup(props, { emit }) {
    interface siritSelect {
      showContent: boolean;
      search: string;
      selectedValue: any;
      selectedLabel: string;
      max: number;
    }

    const data = reactive<siritSelect>({
      showContent: false,
      search: "",
      selectedValue: null,
      selectedLabel: "",
      max: 0,
    });

    function setDefaultValue() {
      if (props.value !== null) {
        data.selectedValue = props.value;
        let selValue: string = "";
        let selLabel: string = "";
        props.items.forEach(function (item: any) {
          if (item instanceof String) {
            if (item === props.value) {
              selValue = item;
              selLabel = item;
            }
          } else {
            if (item.value === props.value) {
              selValue = item.value;
              selLabel = item.label;
            }
          }
        });
        data.selectedValue = selValue;
        data.selectedLabel = selLabel;
      }
    }

    setDefaultValue();

    function changeContentState() {
      if (!props.loadingItems && !props.disabled) {
        data.showContent = !data.showContent;
      }
    }

    const computedItems = computed(() => {
      const result: Array<any> = [];
      let selValue: string = "";
      let selLabel: string = "";

      props.items.forEach(function (item: any) {
        if (item instanceof String) {
          result.push({ label: item, value: item });
          if (item === props.value) {
            selValue = item;
            selLabel = item;
          }
        } else {
          if (item.value === props.value) {
            selValue = item.value;
            selLabel = item.label;
          }
        }
      });

      data.selectedValue = selValue;
      data.selectedLabel = selLabel;

      if (result.length === 0) {
        if (data.search.length > 0) {
          const searchResult: Array<any> = [];
          props.items.forEach(function (item: any) {
            let itemLabel: string = item.label.toLowerCase();
            if (itemLabel.search(data.search) !== -1) searchResult.push(item);
          });
          return searchResult;
        }
        return props.items;
      }

      return result;
    });

    function searchContent(event: any) {
      if (data.showContent) {
        if (event.key === "Backspace") {
          data.search = "";
        } else {
          if (/^[a-z0-9]$/i.test(event.key)) {
            const excludedKeys: Array<any> = ["Shift"];
            if (!excludedKeys.includes(event.key)) data.search += event.key;
          }
        }
        data.search = data.search.toLowerCase();
      }
    }

    function selectValue(value: any, label: string) {
      data.selectedValue = value;
      data.selectedLabel = label;
      emit("selectedValue", value);
      changeContentState();
    }

    const siritSelectClass = computed(() => {
      return "sirit-select-" + props.size;
    });

    const siritSelectItemsClass = computed(() => {
      return "sirit-select-items-" + props.size + "  overflow-scroll !max-h-40";
    });

    props.items.forEach(function (item: any) {
      if (typeof item) {
        if (item === props.value) {
          data.selectedValue = item;
        }
      }
    });

    if (props.maxCharacters !== 0) data.max = props.maxCharacters;

    if (props.size === "lg") data.max = 20;
    if (props.size === "xl") data.max = 1000;

    watch(
      () => props.value,
      (newValue: any) => {
        setDefaultValue();
      }
    );

    watch(
      () => props.items,
      (newValue: any) => {
        setDefaultValue();
      }
    );

    window.addEventListener("keydown", (e) => {
      searchContent(e);
    });

    return {
      ...toRefs(data),
      changeContentState,
      computedItems,
      searchContent,
      selectValue,
      siritSelectClass,
      siritSelectItemsClass,
    };
  },
});
