
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import SpIcon from "@/components/ui/SpIcon.vue";
import SpButton from "@/components/ui/SpButton.vue";
import router from "@/router";

export default {
  name: "CallSaved",
  components: { SpIcon, SpButton },
  props: {
    uuid: { type: String, required: true },
  },
  setup(props: any) {
    const store = useStore();

    interface CallSaved {
    }

    const data = reactive<CallSaved>({
    });

    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });

    const getPhoneNumberScheduled = computed(
      () => store.getters["modules/getPhoneNumberScheduled"]
    );

    function goTo(value: string) {
      router.push({ name: value });
    }

    return {
      ...toRefs(data),
      language,
      getPhoneNumberScheduled,
      goTo,
    };
  },
};
