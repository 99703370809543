
import SpNotifications from "@/components/ui/SpNotifications.vue";
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRefs,
  watch,
} from "vue";
// import { useI18n } from 'vue-i18n'
import { useStore } from "vuex";
import router from "@/router";
import { useRoute } from "vue-router";
import BottomNavigationBarAx from "@/components/axactorModulesMobile/BottomNavigationBarAx.vue";
import SupportPageHeaderAx from "@/components/axactorModulesMobile/SupportPageHeaderAx.vue";

export default defineComponent({
  name: "App",
  components: {
    SpNotifications,
    BottomNavigationBarAx,
    SupportPageHeaderAx
  },
  setup(props: any) {
    const store = useStore();
    const route = useRoute();

    const windowSize = ref(window.innerWidth);
    store.commit("user/setIsMobile", window.innerWidth < 420);

    onMounted(() => {
      let actualUrl = window.location.href

      if (actualUrl.includes('568bebf1-9ad9-4317-b062-8a20ed05f1e2')) {
        document.addEventListener('DOMContentLoaded', function() {
          let script = document.createElement('script')
          script.textContent = `
          var _mtm = window._mtm = window._mtm || [];
          _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
          (function() {
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src='https://sirit.matomo.cloud/js/container_hIEE7bf2.js'; s.parentNode.insertBefore(g,s);
          })();
        `;
          document.body.insertBefore(script, document.body.firstChild);
        });
      } else {
        document.addEventListener('DOMContentLoaded', function() {
          let script = document.createElement('script')
          script.textContent = `
          var _mtm = window._mtm = window._mtm || [];
          _mtm.push({ 'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start' });
          (function () {
            var d = document, g = d.createElement('script'),
              s = d.getElementsByTagName('script')[0];
            g.async = true; g.src = 'https://sirit.matomo.cloud/js/container_t22NO5i1.js';
            s.parentNode.insertBefore(g, s);
          })();
        `;
          document.body.insertBefore(script, document.body.firstChild);
        });
      }

      window.addEventListener("resize", () => {
        store.commit("user/setIsMobile", window.innerWidth < 420);
        windowSize.value = window.innerWidth;
      });
    });

    onUnmounted(() => {
      window.removeEventListener("resize", () => {
        windowSize.value = window.innerWidth;
        store.commit("user/setIsMobile", window.innerWidth < 420);
      });
    });

    interface App {
      languages: Array<any>;
      currentSection: string;
      excludedPathViews: Array<string>;
    }

    const data = reactive<App>({
      languages: [],
      currentSection: "welcomeText",
      excludedPathViews: [
        "authentication",
        "check_pin_ko",
        "check_id_ko",
        "no_data",
      ],
    });

    const viewsForBottomNavigationBar = computed(() => {
      let result = false;
      data.excludedPathViews.forEach((view) => {
        if (route.fullPath.includes(view)) result = true;
      });
      return result;
    });

    const currentSectionComputed = computed(() => data.currentSection);

    const isMobile = computed(() => {
      return store.getters["user/getIsMobile"];
    });

    const showSideMenu = computed(() => {
            return useRoute().name !== 'Home' &&
                   useRoute().name !== 'CheckPinKo' &&
                   useRoute().name !== 'CheckIdKo' &&
                   useRoute().name !== 'ThereIsNoData' &&
                   useRoute().name !== 'GoError404' &&
                   useRoute().name !== 'GoError403' &&
                   useRoute().name !== 'Error404' &&
                   useRoute().name !== undefined
        })

    // function getGeneralModule() {
    //   let id: any = props.uuid;
    //   const params = {
    //     uuid: props.uuid,
    //     callbackSuccess: (callbackSuccessData: any) => {
    //       store.commit(
    //         "security/setFallbackContactInfoLogo",
    //         callbackSuccessData.data.fallbackContactInfo.logo
    //       );
    //       store.commit(
    //         "security/setFallbackContactInfoAddress",
    //         callbackSuccessData.data.fallbackContactInfo.address
    //       );
    //       store.commit(
    //         "security/setFallbackContactInfoPhone",
    //         callbackSuccessData.data.fallbackContactInfo.phone
    //       );
    //       store.commit(
    //         "security/setFallbackContactInfoEmail",
    //         callbackSuccessData.data.fallbackContactInfo.email
    //       );
    //       store.commit(
    //         "security/setFallbackContactInfoTextRu",
    //         callbackSuccessData.data.fallbackContactInfo.text.ru
    //       );
    //       store.commit(
    //         "security/setFallbackContactInfoTextEs",
    //         callbackSuccessData.data.fallbackContactInfo.text.es
    //       );
    //       store.commit(
    //         "security/setFallbackContactInfoTextEn",
    //         callbackSuccessData.data.fallbackContactInfo.text.en
    //       );
    //       store.commit(
    //         "modules/setAuthMode",
    //         callbackSuccessData.data.authMode
    //       );
    //       store.commit(
    //         "modules/setCustomApi",
    //         callbackSuccessData.data.customApi
    //       );
    //       store.commit(
    //         "modules/setPinPageCustomization",
    //         callbackSuccessData.data.pinPageCustomization
    //       );

    //       if (
    //         callbackSuccessData.data.uxMode === "template1" &&
    //         callbackSuccessData.data.authMode !== "noAuth"
    //       ) {
    //         router.push({ name: "PinAuthenticationAx", params: id });
    //       }

    //       if (callbackSuccessData.data.uxMode === "template1") {
    //         if (
    //           callbackSuccessData.data.authMode === "noAuth" &&
    //           isMobile.value
    //         ) {
    //           return router.push({ name: "DebtsView", params: props.uuid });
    //         } else if (
    //           callbackSuccessData.data.authMode !== "noAuth" &&
    //           isMobile.value
    //         ) {
    //           return router.push({
    //             name: "PinAuthenticationAx",
    //             params: props.uuid,
    //           });
    //         } else if (!isMobile.value) {
    //           return router.push({ name: "AxactorView", params: props.uuid });
    //         }
    //       }

    //       if (
    //         callbackSuccessData.data.uxMode !== "template1"
    //       ) {
    //         router.push({ name: "Home", params: id });
    //       }

    //       // redirectToViewBasedOnTemplate(props.uuid);
    //     },
    //     callbackError: () => {},
    //   };
    //   store.dispatch("modules/getGeneralModule", params);
    // }

    // getGeneralModule();

    // const { t, locale } = useI18n({ useScope: 'global' })
    // locale.value = store.getters["user/getLanguage"]

    return {
      ...toRefs(data),
      isMobile,
      currentSectionComputed,
      viewsForBottomNavigationBar,
      showSideMenu
    };
  },
});
