import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "support-header",
  class: "mt-0 default-box-white-full flex justify-between items-end md:items-center !px-5 md:!px-32 lg:!px-40 xl:!px-72"
}
const _hoisted_2 = { class: "w-1/3 max-w-[272px] lg:w-[272px]" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex flex-col items-end md:justify-center" }
const _hoisted_5 = { class: "w-sm flex justify-end items-center text-xs" }
const _hoisted_6 = ["id", "onClick"]
const _hoisted_7 = {
  key: 0,
  class: "flex flex-col mt-4 justify-center items-end md:items-center"
}
const _hoisted_8 = { class: "text-gray-400 text-xs mr-2" }
const _hoisted_9 = { class: "flex justify-end md:justify-center items-center" }
const _hoisted_10 = ["href"]
const _hoisted_11 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.fallbackContactInfo.logo,
        style: {"height":"100px"}
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeLanguages, (lang) => {
          return (_openBlock(), _createElementBlock("div", {
            id: 'lang-' + lang.value,
            onClick: ($event: any) => (_ctx.changeLocale(lang.value)),
            class: _normalizeClass(["m-1", _ctx.language === lang.value ? 'font-semibold':'cursor-pointer']),
            key: lang
          }, _toDisplayString(lang.label), 11, _hoisted_6))
        }), 128))
      ]),
      (_ctx.inputData.companies.length > 0 && _ctx.inputData.showCompanies)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.inputData.supportedBy[_ctx.language]), 1),
            _createElementVNode("div", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inputData.companies, (supportedCompany) => {
                return (_openBlock(), _createElementBlock("a", {
                  key: supportedCompany.link,
                  href: supportedCompany.link,
                  target: "_blank",
                  class: "w-3/12 max-w-[84px] md:w-[84px] mx-2.5"
                }, [
                  _createElementVNode("img", {
                    class: "support-company-logo",
                    src: supportedCompany.logo,
                    alt: ""
                  }, null, 8, _hoisted_11)
                ], 8, _hoisted_10))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}