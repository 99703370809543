
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import * as Utils from "@/api/Utils";

export default defineComponent({
  name: "WhatsApp",
  props: {
    enabled: { type: Boolean, required: true, default: false },
    url: { type: String, required: true, default: '' },
    logo: { type: String, required: true, default: '' },
  },
  components: {
  },
  setup(props) {
    const store = useStore();
    interface whatsApp {
      loading: boolean;
    }

    const data = reactive<whatsApp>({
      loading: false,
    });

    const isMobile = computed(() => {
      return store.getters["user/getIsMobile"];
    });


    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });

    return {
      ...toRefs(data),
      isMobile,
      language,
      Utils,
    
    };
  },
});
