import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full flex justify-between items-center" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        onClick: ($event: any) => (_ctx.onClick(item.value)),
        class: _normalizeClass(["cursor-pointer flex-auto flex justify-center items-center p-2 box-content", _ctx.selectedItem === item.value ? 'text-gray-800 border-b-2 border-gray-800' : 'border-b text-gray-400 border-gray-400']),
        style: _normalizeStyle(_ctx.selectedItem === item.value ? 'border-color: ' + _ctx.colorToShow + ';':'')
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.selectedItem === item.value ? 'font-semibold' : ''),
          style: _normalizeStyle(_ctx.selectedItem === item.value ? 'color: ' + _ctx.colorToShow + ';':'')
        }, _toDisplayString(item.label), 7)
      ], 14, _hoisted_2))
    }), 256))
  ]))
}