
import { defineComponent } from "vue";

export default defineComponent({
  name: "SpIcon",
  props: {
    model: { type: String, required: true },
    size: { type: String, required: false, default: "20" },
    rotate: { type: Boolean, required: false, default: false },
    color: { type: String, required: false, default: "#000000" },
  },
});
