import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-2 mb-4 p-4 bg-gray-100 rounded text-black" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-start mb-2 flex-col"
}
const _hoisted_3 = { class: "font-semibold mb-2" }
const _hoisted_4 = { class: "flex items-center flex-wrap" }
const _hoisted_5 = {
  key: 1,
  class: "mt-4"
}
const _hoisted_6 = { class: "font-semibold" }
const _hoisted_7 = { class: "flex items-center w-full lg:w-1/2 mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_button = _resolveComponent("sp-button")!
  const _component_sp_time_picker = _resolveComponent("sp-time-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.contactData.schedule.days.enabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.contactData.schedule.title[_ctx.language]) + " " + _toDisplayString(_ctx.contactData.schedule.days.required ? '*' : ''), 1),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_sp_button, {
              onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeDayStatus('monday'))),
              label: _ctx.$t('general.monday'),
              "full-rounded": "",
              size: "xs",
              class: "m-1",
              style: {"font-size":"14px"},
              color: _ctx.activeDays.indexOf('monday') !== -1 ? 'secondary' : 'white-primary'
            }, null, 8, ["label", "color"]),
            _createVNode(_component_sp_button, {
              onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeDayStatus('tuesday'))),
              label: _ctx.$t('general.tuesday'),
              "full-rounded": "",
              size: "xs",
              class: "m-1",
              style: {"font-size":"14px"},
              color: _ctx.activeDays.indexOf('tuesday') !== -1 ? 'secondary' : 'white-primary'
            }, null, 8, ["label", "color"]),
            _createVNode(_component_sp_button, {
              onOnClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeDayStatus('wednesday'))),
              label: _ctx.$t('general.wednesday'),
              "full-rounded": "",
              size: "xs",
              class: "m-1",
              style: {"font-size":"14px"},
              color: _ctx.activeDays.indexOf('wednesday') !== -1 ? 'secondary' : 'white-primary'
            }, null, 8, ["label", "color"]),
            _createVNode(_component_sp_button, {
              onOnClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeDayStatus('thursday'))),
              label: _ctx.$t('general.thursday'),
              "full-rounded": "",
              size: "xs",
              class: "m-1",
              style: {"font-size":"14px"},
              color: _ctx.activeDays.indexOf('thursday') !== -1 ? 'secondary' : 'white-primary'
            }, null, 8, ["label", "color"]),
            _createVNode(_component_sp_button, {
              onOnClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeDayStatus('friday'))),
              label: _ctx.$t('general.friday'),
              "full-rounded": "",
              size: "xs",
              class: "m-1",
              style: {"font-size":"14px"},
              color: _ctx.activeDays.indexOf('friday') !== -1 ? 'secondary' : 'white-primary'
            }, null, 8, ["label", "color"]),
            (_ctx.saturday)
              ? (_openBlock(), _createBlock(_component_sp_button, {
                  key: 0,
                  onOnClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.changeDayStatus('saturday'))),
                  label: _ctx.$t('general.saturday'),
                  "full-rounded": "",
                  size: "xs",
                  class: "m-1",
                  style: {"font-size":"14px"},
                  color: _ctx.activeDays.indexOf('saturday') !== -1 ? 'secondary' : 'white-primary'
                }, null, 8, ["label", "color"]))
              : _createCommentVNode("", true),
            (_ctx.sunday)
              ? (_openBlock(), _createBlock(_component_sp_button, {
                  key: 1,
                  onOnClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.changeDayStatus('sunday'))),
                  label: _ctx.$t('general.sunday'),
                  "full-rounded": "",
                  size: "xs",
                  class: "m-1",
                  style: {"font-size":"14px"},
                  color: _ctx.activeDays.indexOf('sunday') !== -1 ? 'secondary':'white-primary'
                }, null, 8, ["label", "color"]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.contactData.schedule.hours.enabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.contactData.schedule.hours.label[_ctx.language]) + " " + _toDisplayString(_ctx.contactData.schedule.hours.required ? '*' : ''), 1),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_sp_time_picker, {
              min: _ctx.minValue,
              max: _ctx.maxValue,
              items: _ctx.contactData.schedule.hours.range,
              value: _ctx.since,
              "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.since) = $event)),
              class: "w-full",
              onChange: _ctx.updateData
            }, null, 8, ["min", "max", "items", "value", "onChange"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}