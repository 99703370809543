import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["fixed top-0 left-0 z-50 h-screen w-screen flex justify-center items-center", _ctx.hideBackground ? '' : 'bg-black bg-opacity-50'])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([[
                _ctx.size === 'xs' ? 'w-3/12' : '',
                _ctx.size === 'md' ? 'w-6/12' : '',
                _ctx.size === 'lg' ? 'w-8/12' : '',
                _ctx.size === 'xl' ? 'w-10/12 h-1/2' : '',
                _ctx.size === '2xl' ? 'w-10/12 h-3/4' : '',
                _ctx.size === 'full' ? 'w-full h-full' : '',
                _ctx.withoutPadding ? '' : 'p-6',
                _ctx.animation ? 'flipInX' : ''
             ], "overflow-auto rounded shadow bg-white sirit-modal-content"])
    }, [
      _renderSlot(_ctx.$slots, "modalContent")
    ], 2)
  ], 2))
}