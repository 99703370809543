import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-2 text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_icon = _resolveComponent("sp-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeNotification(_ctx.notification.id))),
      class: _normalizeClass(_ctx.spNotificationCardClass)
    }, [
      _createElementVNode("div", _hoisted_1, [
        ('icon' in _ctx.notification)
          ? (_openBlock(), _createBlock(_component_sp_icon, {
              key: 0,
              model: _ctx.notification.icon
            }, null, 8, ["model"]))
          : _createCommentVNode("", true),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t(_ctx.notification.message)), 1)
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["w-full rounded-b flex justify-start", _ctx.spNotificationCardFooterClass]),
      style: {"height":"6px","width":"95%","margin-top":"-8px"}
    }, [
      _createElementVNode("div", {
        class: "rounded",
        style: _normalizeStyle('transition: all ease 0.3s; height: 6px; width: ' + _ctx.percentageSize + '%;')
      }, null, 4)
    ], 2)
  ], 64))
}