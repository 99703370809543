
import {computed, defineComponent, reactive, toRefs} from 'vue'
import SpAccordion from '@/components/ui/SpAccordion.vue'
import SpTitle from "@/components/ui/SpTitle.vue";
import {useStore} from 'vuex'

export default defineComponent({
  name: "FaqsModule",
  components: { SpAccordion, SpTitle },
  props: {
    items: { type: Array, required: true, default: [] },
    title: { type: Object, required: true },
    subtitle: { type: Object, required: true }
  },
  setup (props) {
    const store = useStore()

    const computedQuestions = computed(() =>{
      let result: Array<any> = []
      props.items.forEach(function (item: any) {
        result.push({
          title: item.question,
          description: item.answer,
          show: false
        })
      })

      return result
    })

    const language = computed(() => {
      return store.getters["user/getLanguage"];
    })

    const isMobile = computed(() => {
      return store.getters['user/getIsMobile']
    })

    return {
      computedQuestions,
      isMobile,
      language
    }
  }
})
