import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/scss/base.scss";
import { createI18n } from "vue-i18n";
import { messages, defaultLocale } from "@/translations/index";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import VueCryptojs from "vue-cryptojs";

const i18n = createI18n({
  compostion: false,
  // legacy: false,
  messages,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
});
export default i18n;

createApp(App).use(store).use(router).use(VueCryptojs).use(i18n).directive('click-outside', {
  mounted(el, binding, vnode) {
    el.clickOutsideEvent = function(event:any) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
}).mount("#app");
