import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center" }
const _hoisted_2 = { class: "font-semibold text-2xl" }
const _hoisted_3 = {
  key: 0,
  class: "w-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_button_icon = _resolveComponent("sp-button-icon")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionsData, (question, index) => {
    return (_openBlock(), _createElementBlock("div", {
      key: 'action' + index,
      class: _normalizeClass([[
             index === 0 ? 'rounded-t border-t border-r border-l' : '',
             index !== 0 && index !== _ctx.questions.length -1 ? 'border' : '',
             index === _ctx.questions.length - 1 ? 'rounded-b border-b border-r border-l' : '',
             index === _ctx.questions.length - 1 && _ctx.questions.length === 2 ? 'rounded-b border-t border-b border-r border-l' : '',
         ], "p-4 border-gray-200 bg-white flex flex-col"])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(question.title[_ctx.language]), 1),
        _createVNode(_component_sp_button_icon, {
          onOnClick: ($event: any) => (question.show = !question.show),
          icon: question.show ? 'chevron-up':'chevron-down',
          color: "clean"
        }, null, 8, ["onOnClick", "icon"])
      ]),
      (question.show)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(question.description[_ctx.language]), 1))
        : _createCommentVNode("", true)
    ], 2))
  }), 128))
}