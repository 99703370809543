
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import { useStore } from "vuex";
import i18n from "@/main";
import SiritSelect from "@/components/ui/SpSelect.vue";
let langs = require('langs')

export default defineComponent({
name: "SupportPageHeader",
  components: {SiritSelect},
  props: {
    inputData: { type: Object, required: true },
    languages: { type: Array, required: true },
  },
  setup(props, { emit }) {
    const store = useStore();

    const isMobile = computed(() => {
      return store.getters["user/getIsMobile"];
    });

    const activeLanguages = computed(() => {
      let result: Array<any> = []
      langs.all().forEach(function (lang: any) {
        if (props.languages.includes(lang['1'])) {
          result.push({
            label: lang.local,
            value: lang['1']
          })
        }
      })
      return result
    })

    const fallbackContactInfo = computed(() => {
      return store.getters['security/getFallbackContactInfo']
    })

    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });

    interface supportPageHeader {
      languages: {
        en: boolean;
        es: boolean;
        ru: boolean;
      };
      defaultLanguge: string;
    }

    const data = reactive<supportPageHeader>({
      languages: {
        en: false,
        es: false,
        ru: false,
      },
      defaultLanguge: "",
    });

    function changeLocale(locale: any) {
      store.commit("user/setLanguage", locale);
      localStorage.setItem("language",locale)
      i18n.global.locale = locale;
    }

    function setEnabledLanguages(){
      props.languages.forEach((lang: any) => {
        data.languages = {
          ...data.languages,
          [lang]: lang
        }
      }) 
    }
    setEnabledLanguages();

    return {
      ...toRefs(data),
      activeLanguages,
      changeLocale,
      isMobile,
      fallbackContactInfo,
      language,
      setEnabledLanguages,
    };
  },
});
