import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "w-screen h-screen flex justify-center items-center flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "m-0 mb-4"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "m-0 mt-2 mb-2"
}
const _hoisted_5 = {
  key: 2,
  class: "m-0 mt-2 mb-2"
}
const _hoisted_6 = {
  key: 3,
  class: "m-0 mt-2 mb-2"
}
const _hoisted_7 = {
  key: 4,
  class: "mt-4 flex justify-center items-center"
}
const _hoisted_8 = {
  key: 0,
  class: "flex justify-center items-start ml-2 mr-2"
}
const _hoisted_9 = {
  key: 1,
  class: "flex justify-center items-start ml-2 mr-2"
}
const _hoisted_10 = {
  key: 2,
  class: "flex justify-center items-start ml-2 mr-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_icon = _resolveComponent("sp-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.customApi)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
          _createElementVNode("span", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeLocale('en'))),
            class: _normalizeClass(["cursor-pointer mr-2 ml-2", _ctx.language === 'en' ? 'font-semibold':'text-gray-400'])
          }, "English", 2),
          _createElementVNode("span", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeLocale('es'))),
            class: _normalizeClass(["cursor-pointer mr-2 ml-2", _ctx.language === 'es' ? 'font-semibold':'text-gray-400'])
          }, "Español", 2),
          _createElementVNode("span", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeLocale('ru'))),
            class: _normalizeClass(["cursor-pointer mr-2 ml-2", _ctx.language === 'ru' ? 'font-semibold':'text-gray-400'])
          }, "Русский", 2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("img", {
      src: _ctx.fallbackContactInfo.logo,
      alt: "",
      style: {"max-height":"50px"}
    }, null, 8, _hoisted_3),
    (_ctx.language === 'en')
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.fallbackContactInfo.text.en), 1))
      : _createCommentVNode("", true),
    (_ctx.language === 'es')
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.fallbackContactInfo.text.es), 1))
      : _createCommentVNode("", true),
    (_ctx.language === 'ru')
      ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.fallbackContactInfo.text.ru), 1))
      : _createCommentVNode("", true),
    (!_ctx.customApi)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (_ctx.fallbackContactInfo.phone.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_sp_icon, {
                  model: "phone",
                  class: "mr-2"
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.fallbackContactInfo.phone), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.fallbackContactInfo.email.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_sp_icon, {
                  model: "mail",
                  class: "mr-2"
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.fallbackContactInfo.email), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.fallbackContactInfo.address.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_sp_icon, {
                  model: "home",
                  class: "mr-2"
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.fallbackContactInfo.address), 1)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}