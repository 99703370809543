import { Locales } from '@/translations/keys'
import en from '@/translations/languages/en'
import es from '@/translations/languages/es'
import ru from '@/translations/languages/ru'

export const messages = {
    [Locales.EN]: en,
    [Locales.ES]: es,
    [Locales.RU]: ru,
}

export const defaultLocale = Locales.EN