
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import SpIcon from "@/components/ui/SpIcon.vue";
import SpButton from "@/components/ui/SpButton.vue";
import SpTabMenu from "@/components/ui/SpTabMenu.vue";
import SpSelect from "@/components/ui/SpSelect.vue";

export default {
  name: "Notifications",
  components: {
    SpIcon,
    SpButton,
    SpSelect,
    SpTabMenu,
  },
  props: {
    uuid: { type: String, required: true },
  },
  setup(props: any) {
    const store = useStore();

    interface Notifications {
      languages: Array<any>;
      seeInterveners: boolean;
      selectedTabElement: string;
      strategiesTabElements: Array<any>;
    }

    const data = reactive<Notifications>({
      languages: [],
      seeInterveners: false,
      selectedTabElement: "notificaciones",
      strategiesTabElements: [
        {
          label: "Notificaciones",
          value: "notificaciones",
          translationKey: "activeStrategies",
        },
        {
          label: "Mensajes",
          value: "mensajes",
          translationKey: "allStrategies",
        },
      ],
    });

    const languageComputed = computed(() => data.languages);

    function changeSelectedTab(tab: string) {
      data.selectedTabElement = tab;
    }

    function getLandingConfiguration() {
      const params = {
        uuid: props.uuid,
        callbackSuccess: (callbackSuccessData: any) => {
          data.languages =
            callbackSuccessData.data.modules.general.extraLanguages;
        },
        callbackError: (callbackErrorData: any) => {},
      };
      store.dispatch("modules/getLandingConfiguration", params);
    }

    getLandingConfiguration();

    return {
      ...toRefs(data),
      languageComputed,
      changeSelectedTab,
    };
  },
};
