
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "SpTabMenu",
  props: {
    items: { type: Array, required: true },
    preSelectedItem: { type: String, required: true },
    color: { type: String, required: false, default: "" },
  },
  emits: ["changeSelectedTab"],
  setup(props, { emit }) {
    const store = useStore();
    interface SpTabMenu {
      selectedItem: string;
    }

    const data = reactive<SpTabMenu>({
      selectedItem: props.preSelectedItem,
    });

    const theme = computed(() => {
      return store.getters["usersAndTeams/getTheme"];
    });

    const colorToShow = computed(() => {
      return props.color?.length > 0
        ? props.color
        : 'black'
    });

    function onClick(itemValue: string) {
      data.selectedItem = itemValue;
      emit("changeSelectedTab", data.selectedItem);
    }
    return {
      ...toRefs(data),
      colorToShow,
      onClick,
      theme,
    };
  },
});
