<template>
  <div class="time-picker" @wheel="onWheel">
    <div class="flex flex-col">
      <div
        v-for="time in rangeHours"
        :key="time"
        :style="getHourStyle(time === selectedTime)"
        @click="selectTime(time)"
      >{{ time }}</div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "SpTimerPickerAx",
  props: {
    time: { type: String, required: false },
  },
  emits: ['saveTime'],
  setup(props, { emit }) {
    const store = useStore();
    const showTimePicker = ref(false);
    const selectedTime = ref(props.time);
    const isScrolling = ref(false);
    const rangeHours = computed(()=> {
        return store.getters["modules/getModules"].contact.schedule.hours.range || []
    })

    const getHourStyle = (isSelected) => {
      const baseStyle = {
        fontSize: "14px",
        opacity: 0.4,
      };

      if (isSelected) {
        return {
          ...baseStyle,
          fontSize: "16px",
          opacity: 1,
          color: "black",
          "margin-left": "0",
        };
      } else {
        return baseStyle;
      }
    };


    const onWheel = (event) => {
      event.preventDefault();
    };


    const selectTime = (time) => {
      selectedTime.value = time;
      emit("update:selectedTime", time); // Emitimos el evento de selección
      showTimePicker.value = false
      saveTime()
    };

    const saveTime =() => {
     emit('saveTime', selectedTime.value)
    }

    return {
      getHourStyle,
      onWheel,
      selectTime,
      selectedTime,
      isScrolling,
      showTimePicker,
      saveTime,
      rangeHours
    };
  },
});
</script>

<style scoped lang="scss">
.time-picker {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 100px;
  width: 50%;
  height: 100px;
  overflow-y: scroll;
  border-radius: 12px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: white;
  z-index: 9999;
  border: none;
}

.time-picker div {
  margin: .2em 0;
  width: 150px;
  // margin-left: 5px;
  max-height: 100%;
  padding: 2px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 8px;
  font-weight: bold;


}

// .time-picker div:nth-child(2) {
//   font-size: 14px;
//   color: black;
//   // margin-left: 0;
// }

// .time-picker div:nth-child(1),
// .time-picker div:nth-child(3) {
//   font-size: 12px;
//   color: gray;
//   // margin-left: 10px;
// }
</style>



