import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_select = _resolveComponent("sp-select")!
  const _component_sp_button_icon = _resolveComponent("sp-button-icon")!

  return (!_ctx.isMobile && _ctx.enabled)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "call-me-now",
        style: _normalizeStyle('background-color:' + _ctx.backgroundColor)
      }, [
        _createElementVNode("div", null, [
          _createElementVNode("img", {
            src: _ctx.logo,
            alt: ""
          }, null, 8, _hoisted_1),
          _createElementVNode("p", {
            style: _normalizeStyle('color:' + _ctx.titleColor)
          }, _toDisplayString(_ctx.title), 5)
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_sp_select, {
            onSelectedValue: _ctx.changeSelectedPrefix,
            value: _ctx.prefix,
            placeholder: _ctx.$t('general.prefix'),
            items: _ctx.prefixes,
            loadingItems: _ctx.loading,
            fixedItems: true,
            inheritedClasses: ['prefix'],
            size: "xs"
          }, null, 8, ["onSelectedValue", "value", "placeholder", "items", "loadingItems"]),
          _withDirectives(_createElementVNode("input", {
            type: "tel",
            placeholder: _ctx.placeholderPhone,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phone) = $event)),
            style: {"width":"200px"}
          }, null, 8, _hoisted_2), [
            [_vModelText, _ctx.phone]
          ]),
          _createVNode(_component_sp_button_icon, {
            icon: "chevron-right",
            color: 'clean',
            onClick: _ctx.validateAndSend
          }, null, 8, ["onClick"])
        ])
      ], 4))
    : _createCommentVNode("", true)
}