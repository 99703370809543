
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import SpIcon from "@/components/ui/SpIcon.vue";
import SpButton from "@/components/ui/SpButton.vue";
import SupportPageHeaderAx from "@/components/axactorModulesMobile/SupportPageHeaderAx.vue";
import BottomNavigationBarAx from "@/components/axactorModulesMobile/BottomNavigationBarAx.vue";
import SpTabMenu from "@/components/ui/SpTabMenu.vue";
import SpSelect from "@/components/ui/SpSelect.vue";
import router from "@/router";

export default {
  name: "Profile",
  components: {
    SpIcon,
    SpButton,
    SpSelect,
    SpTabMenu,
  },
  props: {
    uuid: { type: String, required: true },
  },
  setup(props: any) {
    const store = useStore();

    interface Profile {
      languages: Array<any>;
      seeInterveners: boolean;
      selectedTabElement: string;
      strategiesTabElements: Array<any>;
      interveners: any;
    }

    const data = reactive<Profile>({
      languages: [],
      interveners: store.getters["modules/getRelatedInterveners"],
      seeInterveners: false,
      selectedTabElement: "notificaciones",
      strategiesTabElements: [
        {
          label: "Notificaciones",
          value: "notificaciones",
          translationKey: "activeStrategies",
        },
        {
          label: "Mensajes",
          value: "mensajes",
          translationKey: "allStrategies",
        },
      ],
    });

    const languageComputed = computed(() => data.languages);
    const modules = computed(() => {
      return store.getters["modules/getModules"];
    });
    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });
    const loading = computed(() => {
      return store.getters["modules/getLoading"];
    });

    function changeSelectedTab(tab: string) {
      data.selectedTabElement = tab;
    }

    function goTo(value: string) {
      router.push({ name: value });
    }

    function getLandingConfiguration() {
      const params = {
        uuid: props.uuid,
        callbackSuccess: (callbackSuccessData: any) => {
          data.languages =
            callbackSuccessData.data.modules.general.extraLanguages;
        },
        callbackError: (callbackErrorData: any) => {},
      };
      store.dispatch("modules/getLandingConfiguration", params);
    }

    getLandingConfiguration();

    return {
      ...toRefs(data),
      languageComputed,
      changeSelectedTab,
      goTo,
      modules,
      language,
      loading,
    };
  },
};
