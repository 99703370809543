export default {
    debtRemoval: {
        title: 'Debt relief',
        subtitle: 'Send a proposal with the amount you request to subtract from your debt',
        amountToRelief: 'Percentage to relief (%)',
        sendProposal: 'Send proposal'
    },
    error403: {
        title: 'This Link page not available anymore',
        subtitle: 'The access to this page has expired. If you have any questions, please contact us'
    },
    error404: {
        title: 'It seems that what you are looking for is not available right now',
    },
    fileInterveners: {
        title: 'These are the other individuals who are involved with you in the file case we are handling',
        title2: 'There are no other individuals involved in this case.',
        subtitle: 'The other interveners in your file are detailed below',
        debtor: 'Intervener'
    },
    fileSelector: {
        title: 'Debt selector',
        subtitle: 'Select the debt you want to see',
        message: 'You have {debts} debts, of which you have paid {paidDebt} out of {totalDebt}',
        messageShort: 'You have {debts} debts',
        messageSingle: 'You have {debts} debt, of which you have paid {paidDebt} out of {totalDebt}',
        messageSingleShort: 'You have {debts} debt',
        card: {
            debt: 'Debt',
            debtContracted: 'Debt incurred on {debtStart} with {debtOwner}',
            debtContracted2: 'Debt incurred on {debtStart}',
            paidDebt: 'Paid debt',
            pendingDebt: 'Pending debt',
            totalDebt: 'Total debt'
        },
        notifications: {
            changeFileSuccess: 'The debt has been changed to display correctly.'
        }
    },
    general: {
        save: 'Save',
        date: 'DATE',
        amount: 'AMOUNT',
        supportPage: 'Support page',
        platformSupportedBy: 'Platform supported by',
        virtualAvatarTitle: 'We can help you to regularize your debt',
        virtualAvatarDescription: 'Tell us how and when you want to pay it',
        saveAsFavourite1: "Save this link in your browser's \"favorites\". That way you can to stay in touch with us and see the most up-to-date status of your file.",
        saveAsFavourite2: "click here",
        paymentPlan: 'Payment plan',
        paymentPlanProposalSingleOption: 'Pay in Full',
        paymentPlanProposalPartialOption: 'Partial Payment',
        paymentPlanProposalPartialOptionDescription: 'Take charge of your debt journey! Make smaller payments and watch your progress grow.',
        paymentPlanProposalSingleOption1: 'Pay',
        paymentPlanProposalSingleOptionDescription: 'Clear your debt with one payment, quickly achieve financial freedom!',
        paymentPlanProposal0: 'Payment plan',
        paymentPlanProposal1: 'Choose the option that best suits you.',
        paymentPlanProposal1Question: 'How would you like to proceed?',
        paymentPlanProposal2: 'Remember that we are here to make things easier for you',
        paymentPlanProposal3: 'How often do you want to pay',
        paymentPlanProposal4: 'A single playment',
        paymentPlanProposal5: 'This modality includes a 10% discount',
        paymentPlanProposal6: 'In 2 months',
        paymentPlanProposal7: 'In 4 months',
        paymentPlanProposal8: 'Payment Plan',
        paymentPlanProposal9: 'Gradually settle your debt, one step at a time balance your obligations while paving the path to a debt-free life!',
        paymentPlanProposal10: 'Send payment proposal',
        paymentPlanProposal11: 'Or just propose a different payment method',
        paymentPlanProposal12: 'Amount to pay per month',
        paymentPlanProposal13: 'Months period for paying',
        paymentPlanProposal14: '5 months',
        paymentPlanProposal15: '32 months',
        paymentPlanProposal16: 'Payment summary',
        months: 'Months',
        paymentMethod1: 'Choose the payment method',
        paymentMethod2: 'Select the payment method that best suits you',
        paymentMethod3: 'Add another payment method',
        paymentMethod4: 'Make a proposal, we are listening you',
        paymentMethod5: 'Enter the payment method you want to use and our team will contact you to try to help with it.',
        paymentMethod6: 'Method to use',
        paymentMethod7: 'Send the payment method',
        paymentMethod8: "Congratulations!, your payment has been done correctly. You are nearer to your debt cancellation.",
        paymentMethod9: "Something was wrong with your payment. Please review your payment method data.",
        support1: 'Support',
        support2: 'Find an answer',
        support3: 'VideoConference',
        support4: 'Chat',
        support5: 'Talk to one of our team advisers',
        support6: 'If you have any type of doubt and want to speak with one of our advisors, this is the ideal way to do it. There will always be someone ready to help you.',
        support7: 'Get started with video conferencing',
        support8: 'Talk to one of our help center agents',
        support9: 'Hello! How can we help you?',
        support10: 'Write message',
        contact1: "Do you want us to contact you?",
        contact2: "Please let us know when we can contact you.",
        contact3: "Enter your phone number",
        contact4: "For instance, 612285745",
        contact5: "Enter your e-mail address",
        contact6a: "For instance, info",
        contact6b: "youremail.com",
        contact7: "Enter your address",
        contact8: "For instance, Calle Marley, 17",
        contact9: "Send",
        contact10: "Contact preferences",
        contact11: "I don't want you to contact me",
        contact12: "Add more days and time slots",
        contact13: "You don't want us to contact you?",
        contact14: "Is there anything else you would like to tell us before we reach out to you?",
        contact15: "Hello, I need more information",
        contact16: "Enter your first and last name",
        contact16a: "Jhon Smith / Sara Smith ",
        contact17: "Street type",
        contact18: "Street",
        contact19: "Number",
        contact20: "Province",
        contact21: "City",
        contact22: "Postal code",
        contact23: "Country",
        history1: 'Records',
        history2: 'Check what you have done so far',
        history3: '2022/01/01',
        history4: 'Contact email sent',
        history5: '2022/02/10',
        history6: 'Installment payment request',
        history7: '2022/03/20',
        history8: 'Videoconference communication with Pedro from Sirit',
        debtConsolidation1: 'Do you have other debt than the one currently visible here? We can help you.',
        debtConsolidation2: `If you have other outstanding debt and want us to help you consolidate and manage everything in one place, let us know. Include how we can contact you and we'll get to work together.`,
        debtConsolidation3: 'Tell us how we could help you',
        debtConsolidation4: 'Send',
        max: 'Maximun',
        min: 'Minimun',
        faqs1: 'Do you have any doubt?',
        faqs2: 'Find the answer',
        faqs3: 'Why did I get access to this landing page?',
        faqs4: 'If you have received this page access, it is because you have an outstanding debt and it has not been paid. Therefore, the mission of this page is to help you pay your debt.',
        faqs5: 'Can I get a write off of my debt?',
        faqs6: 'Yes, as long as you make the payment of your debt in a single payment.',
        faqs7: 'Can I modify my installments and monthly payment?',
        faqs8: 'ou can select in how many installments and the amount you want to pay monthly. The important thing is that you have the choice for selecting a payment method that suits your needs and reduce your debt.',
        whoIsSirit1: 'What is Sirit?',
        whoIsSirit2: 'We are a company dedicated to help you with your unpaid bills. Our mission is to provide you the tools for paying off your debt and get rid of all previous worries about your debt.',
        whoIsSirit3: 'One of our many goals is to make managing your debt simple and get rid of heavy banking procedures that are not very adaptable to your current needs.',
        whoIsSirit4: 'Contact us',
        whoIsSirit5: 'If you have any type of doubt, do not hesitate to contact us.',
        whoIsSirit6: 'Contact us',
        whoIsSirit7: 'Terms of use of the landing page',
        whoIsSirit8: 'Privacy Policy',
        whoIsSirit9: 'Cookies use policy',
        welcome1: 'Hello',
        welcome2: 'Juan Suñer Parra',
        welcome3: 'If this is not you',
        welcome4: 'please click here',
        welcome5: 'Within this landing page, you will be able to manage your debt. Our mission is to help you with it iso you can pay it in the most comfortable, agile and simple possible way. If you need any kind of help, do not hesitate to contact us.',
        welcome6: 'The debt amounts to',
        welcome7: '23.392€ + VAT',
        welcome8: 'Debt contracted on',
        welcome9: 'January 30th, 1993 with BBVA,',
        welcome10: 'referring to a mortgage loan.',
        error404: 'Error 404',
        pageNotFound: 'Page not found',
        videoChatBot1: 'Video chat bot',
        videoChatBot2: "Start a video chat with one of our virtual assistants. It's like chatting with an assistant but with the comfort of going step by step.",
        videoChatBot3: 'Start video chat',
        videoChatBot4: 'Start',
        totalDebt: 'Total debt {debtAmount}.',
        aSinglePaymentSummary: 'Single payment, to pay { debtAmount }.',
        inTwoMonthsPaymentSummary: 'Split payment over two months, to pay { debtAmount } per month.',
        inFourMonthsPaymentSummary: 'Split payment over four months, to pay { debtAmount } per month.',
        summary: 'Summary',
        pay: 'Pay',
        payWith: 'with { method }',
        paymentRecords1: 'Payment records',
        paymentRecords2: 'Obtain the information of all the payments carried out',
        paymentRecords3: 'No payments has been taken',
        paymentRecords4: 'Payment may take up to 24 hours to appear reflected',
        files1: 'Files',
        files2: 'Here we need you to add the required documents which are important for your file case.',
        files3: 'Uploaded files',
        files4: 'No files have been uploaded yet',
        files5: 'Drag the files here',
        files6: 'or',
        files7: 'Browse files',
        files8: 'Uploading files..',
        maxFileSize: 'Max size per file is 100MB',
        paymentSubtitle: 'We offer you different ways to pay your debt',
        debtAmountTo: 'Your next payment is',
        selectPayment: 'Select your payment method',
        transaction: 'Transfer between accounts',
        transfer: 'Transaction',
        creditCard: 'Credit card',
        payPal: 'Paypal',
        bizum: 'Bizum',
        amountToPay: 'Amount to pay',
        sourceBankAccount: 'Write the details of the bank account from which you are going to make the transfer',
        placeholderSourceBankAccount: 'Enter your source bank account',
        cardNumber: 'Card number',
        name: 'Name',
        placeholderName: 'Enter card name',
        expiryDate: 'Expiry date',
        cvv: 'CVV / CVC',
        email: 'Email',
        emailPlaceholder: 'Enter your email',
        password: 'Password',
        passwordPlaceholder: 'Enter your password',
        paymentWith: 'Payment made with',
        paymentWithCard: 'Pay with card',
        paymentWithBizum: 'Pay with bizum',
        paymentWithTransfer: 'Pay with direct bank transfer',
        paymentWithPaypal: 'Pay with PayPal',
        cannotPay: 'You cannot make the payment, please contact us',
        paymentGenerated: 'Payment generated on',
        amountPaid: 'Amount paid: ',
        contactMe: 'Contact me',
        dontContactMe: 'Dont contact me',
        showContactOptions: 'Show contact options',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
        from: 'From',
        inRangeHour: 'In this range hours',
        to: 'To',
        savePreferences: 'Save preferences',
        bestContactHoursByPhone: 'Best contact hours',
        fileType0: 'Select a tag',
        fileType1: 'Payroll',
        fileType2: 'Personal income tax (Form 100). (Facilitated by the Tax Agency) or Income Certificate',
        fileType3: 'Pension Income Certificate (Facilitated by the National Social Security Institute)',
        fileType4: 'SEPE income certificate (Facilitated by the Public State Employment Service)',
        fileType5: 'Work Life (Facilitated by the National Institute of Social Security)',
        fileType6: 'Certificate confirming that you do not receive benefits from the INSS (Facilitated by the National Institute of Social Security)',
        fileType7: 'Work Life (Facilitated by the National Institute of Social Security)',
        fileType8: 'IRPF (Form 130 or 131). (Facilitated by the Tax Agency)',
        fileType9: 'VAT Annual VAT summary (Mod. 303 / Mod. 390). (Facilitated by the Tax Agency)',
        fileType10: 'DNI – INE – Passport',
        fileType11: 'Others',
        selectCategory: 'Select a category',
        dataUpdatedOn: 'Data updated on ',
        paymentPlanApplication: 'Payment Plan Application',
        prefix: 'Select a prefix',
        true: 'True',
        false: 'False',
        selectItem: 'Select a item',
        noData: 'No data',
        searching: 'Searching for',
        needPaymentPlan: 'You must create a payments plan before making the payment',
        alreadyPlan: 'A payment plan already exists for this file.',
        amountError: 'The selected quantity is less than the minimum value',
        birthDate: 'Birthdate'
    },
    notifications: {
        contactError1: 'You must enter a valid email. Please enter a valid email and try again.',
        contactError2: 'You must fill in the mandatory fields of the form. Add the missing data and submit the form again.',
        contactError3: 'You must enter a valid telephone. Please enter a valid telephone and try again.',
        contactSuccess1: 'We have received your contact details. We will contact you as soon as possible.',
        filesSuccess1: 'The uploaded files have been successfully uploaded.',
        filesSuccess2: 'The uploaded file has been successfully removed.',
        filesError1: 'The size of the files to upload cannot exceed 100MB',
        debtConsolidationError1: 'You must enter which debts you want to consolidate. Enter the text and try again.',
        debtConsolidationSuccess1: 'We have received your debts details. We will contact you as soon as possible.',
        successPayment: 'Congratulations! Your payment has been done correctly. You are one step closer to conquering your debt. Payment may take up to 24 hours to appear reflected',
        wrongPayment: 'Ups, seems like something went wrong when processing your payment. Please review the payment method information.',
        accept: "Accept",
        successChangeTag: 'The type of the uploaded file has been changed correctly.',
        successRemoval: 'Your debt removal proposal has been successfully sent. After analyzing they will contact you to inform you of the result.',
        dangerSendPin: 'The PIN has been sent, check that the PIN has arrived by the way in which this link has notified you.',
        successSendSupportMessage: 'Your message has been sent successfully.',
        errorSendSupportMessage: 'You must write your message before you can send it. Type the message you want to send and try again.',
        payError:  'The amount to be paid must be greater than min amount',
        paymentSuccess: 'Thank you for your payment! Your transaction has been successfully processed.',
        paymentError: 'We apologize for the inconvenience. There was an error with your payment. Please try again later or contact our customer support for assistance.',
        payErrorMax: 'The amount to be paid must be greater than the total amount of the debt',
        callMeNow: 'The phone was sent successfully',
        errorTotal: 'The amount entered is less than the minimum',
        inNumber: 'The amount entered is not a number',
        selectDebt: 'You must select a debt',
    },
    redsysErrors: {
        msg1: 'You have to fill in the card details.',
        msg2: 'The card is mandatory.',
        msg3: 'The card must be numeric.',
        msg4: 'The card cannot be negative.',
        msg5: 'The expiration month of the card is mandatory.',
        msg6: 'The expiration month of the card must be numeric.',
        msg7: 'The expiration month of the card is incorrect.',
        msg8: 'The expiration year of the card is mandatory.',
        msg9: 'The expiration year of the card must be numeric.',
        msg10: 'The expiration year of the card cannot be negative.',
        msg11: 'Card security code is not the correct length.',
        msg12: 'The security code of the card must be numeric.',
        msg13: 'The card security code cannot be negative.',
        msg14: 'The security code is not required for your card.',
        msg15: 'The card length is not correct.',
        msg16: 'You must enter a valid card number (no spaces or dashes).',
        msg17: 'Incorrect validation by the merchant.',
        msg18: 'Could not schedule phone call for given intervener',
        msg19: 'The phone number entered is not valid',
    },
    pinAuthentication: {
        title: 'PIN Authentication',
        description: "To ensure it's you, enter the PIN code we send to your phone number or email.",
        pin: 'PIN',
        pinPlaceholder: 'Enter your PIN',
        enter: 'Enter',
        sendPin: 'Send PIN',
        message1: 'Once you log in, we can give you detailed information about the debt that has been reported to us.',
        message2: 'We can provide several channels for you to choose from, so that you can communicate with us and we can resolve your debt together.',
        message3: 'We can also propose different ways to cancel it today or in the next few days.',
        message4: 'If you have difficulties accessing, please write to us at {mail} or call us at {phone}'
    },
    pinUnique: {
        title: 'PIN Authentication',
        description: 'We make this private website available to you so that you can manage your debt.',
        instructions: 'To make sure it\'s you, enter your ID and click on "Request PIN" to receive the PIN code to access your #communication method# and press "Access"',
        labelId: 'DNI / NIE / CIF',
        placeholderId: 'Enter your DNI / NIE / CIF',
        labelPin: 'PIN',
        placeholderPin: 'Enter your PIN',
        phone: 'Phone',
        email: 'Email',
        selectPhone: 'Select a phone',
        selectEmail: 'Select an email',
        errorId: 'Given document id does not match with our data for this link page',
        toastMessage: 'The pin has been requested successfully'
    },

    support: {
        title: 'Contact us',
        subtitle: 'We offer several communication tools to give you the opportunity to choose the one you are most comfortable with.',
        videoconference: 'VideoConference',
        chat: 'Chat',
        start: 'Start',
        writeUs: 'Write us',
        writeUsPlaceholder: 'Write us a message with what you need',
        send: 'Send'
    },
    ui: {
        clickHere: 'click here'
    },
    table: {
        amount: "Amount",
        months: "Months",
        frequency: "Frequency",
        status: "Status",
        paidDeadlines: 'paid Deadlines',
        deadLineDate: 'Deadline date',
        totalToPay: 'Total to pay',
        page: 'Page',
        pagination: 'Page %{actualPageKey} of %{totalPagesKey}',
        origin: 'Origin',
        type: 'Type'
    },
    paymentFrequency: {
        Monthly: 'Monthly',
        single: 'Unique'
    },
    status: {
        approved: 'APPROVED',
        failed: 'FAILED',
        closed: 'CLOSED'
    },
    months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December'
    },
    buttomNavar: {
        debt: 'Your debt',
        payment: 'Payment',
        files: 'Files',
        contact: 'Contact',
        faqs: 'FAQs',
    },
    pinAuthenticationAx: {
        greating: '¡Hello!',
        intro: ' We make this private website available so that you can manage your debt.',
        description: `To make sure it's you, enter your ID and click on "Request PIN" to receive the PIN code to access your #communication method#`,
    
    },
    generalAx:{
        spinnerMessage: 'Loading your support page',
    },
    debtsAx: {
        paymentsMade: 'Payments made',
        pendingPayments: 'Pending payments',
        greating: 'Hello',
        title1: 'Update',
        title2: 'on my payments',
        welcomeMessage: 'Welcome to your private website. From here we will help you to be up to date with your payments',
        paymentPlan: 'Act on all your debt or select the debts you want to pay',
        myProducts: 'My products',
        interveners: 'Interveners',
        total: 'Total',
        moreInfo: '+info',
        pay: 'Pay',
        next: 'Updated'
    },
    paymentMethodsAx: {
        paymentPlan: 'Payment methods',
        importToPay: 'Amount to pay',
        selectMethod: 'Select the payment method that best suits your situation.',
        singlePayment: 'Single payment',
        partialPayment: 'Partial payment',
        total: 'Total amount',
        min: 'Minimum amount',
        month: ' months',
        amountPerMonth: 'Amount per month',
        continue: 'Continue',
        enterAmount: 'Enter amount...',
        payment: 'Payment Plan'
    }
    ,
    paymentResumeAx: {
        paymentPlanResume: 'Payment Plan Summary',
        description: 'Below we show you your payment plan with the amounts and dates on which to make them:',
        paymentPlan: 'Payment plan',
        pay: 'Pay',
        edit: 'Edit proposal',
    },
    paymentsMadeAx: {
        paymentsMade: 'Payments made',
        confirmed: 'Confirmed',
    },
    addDocumentAx: {
        attachDocument: 'Attach the necessary documents',
        selectDocuments: 'Select or drag the necessary documents for your file.',
        camera: 'Camera',
        image: 'Image',
        document: 'Document',
        attach: 'Attach'
    },
    callAx: {
        scheduledCalls: 'Scheduled call',
        callSoon: 'We will call you as soon as possible on the ',
        close: 'Close',
        weCallYou: 'We call you!',
        indicatePhone: 'Indicate your telephone number and we will call you in the next few minutes.',
        enterPhone: 'Enter your phone',
        callNow: 'Call me now!',
        scheduleYourCalls: 'Or if you prefer, schedule your call.',
        enterMail: 'Enter your email',
        selectTime: 'Select the time',
        schedule: 'Schedule the call',
        selectDay: 'Select the day',
        apply: 'Apply',

    },
    categorizeDocumentAx: {
        title: 'Categorize the attached document',
        description: `You can then review the categorization of the document you have attached. By default, we have assigned the category that we understand is closest to the attached document. But if you think it's wrong, you can change it without problems.`,

    },
    checkIdKoAx: {
        misinformed: 'Misinformed document',
        review: 'Please review the included document'
        
    },
    checkPinKo: {
        wrongPin: 'Misinformed PIN',  
        check: 'Please check thce included PIN',
    },
    confirmPayment: {
        title: 'Payment confirmation',
        description: 'You can see your payments in my account, you will receive a confirmation email to your email.',
        ok: 'Ok'
    },
    contactUs: {
        contactUs: 'Contact us',
        weCallYou: 'We call you!',
        schedule: 'Schedule a call',
        mail: 'Email',
        writeMessage: 'Write us a message',
        chatBot: 'Chat with our bot',
        contactUsPlease: 'Sorry for the inconvenience, please contact #phone',
        contactYou: 'We will contact you as soon as possible by email #mail.',
        close: 'Close',
        dude: 'Do you have any doubt?',
        document: 'Documents',
        checkDocument: 'Review the attached documents and add those you think are necessary.',
        pay: 'Pay',
        attach: 'Attach document',
    },
    interveners: {
        debtIntervener: 'Participants in your debt',
        people: 'Below, you can see the rest of the people involved in your %{keyDate} debt.',
        interveners: 'Interveners',

    },
    legalTexts: {
        title: 'Legal texts',
        legalWarning: 'Legal warning',
        policy: 'Privacy Policy',
        cookies: 'Cookies policy',
    },
    files: {
        myFiles: 'My Documents',
        payRoll: 'Payroll',
        landingSent: 'Landing sent',
        to: 'to',
        yourNotification: 'Your notifications',
        reminder: 'Reminder',
        pending: 'Pending',
        accepted: 'Accepted payment methods',
        noData: 'Sorry for the inconvenience, there is no data in our systems',
        account: 'My account',
        myPayments: 'My payments made',
        pendingPayment: 'My pending payments',
        help: 'Help',
        calls: 'Scheduled calls',
        getOut: 'Exit account',
        myCalls: 'My scheduled calls',
        sentEmail: 'Send us an email',
        email: 'Indicate your email and the message you want to send us and we will answer you as soon as possible.',
        canHelp: 'Tell us how we can help you',

        }
    
}
