
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  watch,
} from "vue";
import { useStore } from "vuex";
import SpIcon from "@/components/ui/SpIcon.vue";
import router from "@/router";
import { useRoute } from "vue-router";

/* ctiveButton.value === currentSection ? '#EC0000': '#939393' */
export default defineComponent({
  name: "BottomNavigationBarAx",
  components: { SpIcon },
  props: {
    currentSection: { type: String, required: true, default: "" },
  },
  emits: ["goTo"],
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();

    interface bottomNavigationBarAx {
      // activeButtons: Array<any>,
      modulesToShow: Array<any>;
      actualLanguage: string;
      debtsRoutes: Array<any>;
      paymentRoutes: Array<any>;
      contactRoutes: Array<any>;
      profileRoutes: Array<any>;
    }

    const data = reactive<bottomNavigationBarAx>({
      // activeButtons: [],
      modulesToShow: ["welcomeText", "paymentPlan", "contactUs", "faqs"],
      actualLanguage: store.getters["user/getLanguage"],
      debtsRoutes: [
        "debts",
        "interveners",
        "categorize_document",
        "files",
      ],
      paymentRoutes: ["payment-methods", "payment-resume", "confirm-payment"],
      contactRoutes: [
        "contact_us_options",
        "call_us",
        "call_saved",
        "send_email",
        "sent_email",
      ],
      profileRoutes: [
        "notifications",
        "notification",
        "profile",
        "texts",
        "payments_history",
        "payments_pending",
        "faqs",
        "scheduled_calls",
        "my-documents",
        "add_documents",
      ],
    });

    const authMode = computed(() => {
      return store.getters["modules/getAuthMode"];
    });
    const contactEnable = computed(() => {
        if (modules.value.contact.enabled) {
          return true;
        }
        if (modules.value.support.showWriteUs) {
          return true;
        }
         return false
    });
    const isMobile = computed(() => {
      return store.getters["user/getIsMobile"];
    });

    const loading = computed(() => {
      return store.getters["modules/getLoading"];
    });

    const primaryColor = computed(() => {
      return store.getters["user/getPrimaryColor"];
    });

    const mobileColors = computed(() => {
      return store.getters["user/getMobileColors"];
    });

    const userLanguage = computed(() => {
      return store.getters["user/getLanguage"];
    });
    const modules = computed(() => {
      return store.getters["modules/getModules"];
    });

    const showProfile = computed(() => {
         return modules.value.files.enabled ||
            modules.value.paymentRecords.enabled ||
            modules.value.faqs.enabled ||
            modules.value.contact.enabled ||
            modules.value.footer.enabled

    });

    function getButtonStyle(index: number, section: string) {
      let color = "#939393";
      let background = "white";

      if (section === props.currentSection) {
        color = "#E3A60C";
        background = "white";
      }

      if (index !== 0) {
        return {
          color: color,
          background: background,
        };
      }
      return {
        color: color,
        background: background,
      };
    }

    function goTo(value: string) {
      router.push({ name: value });
    }
    const isLogin = computed(()=> {
      const url = useRoute().path?.toString()
      if(!url) return false
      return url.includes('/authentication')
    })

    function isActiveButton(routePath: string) {
      let activeRoutes = [];
      let color: string =
        modules.value.general.templates[0].configuration.menu.unselectedColor;
      let url = route.fullPath;

      routePath === "debts" ? (activeRoutes = data.debtsRoutes) : "";
      routePath === "payment-methods"
        ? (activeRoutes = data.paymentRoutes)
        : "";
      routePath === "contact_us_options"
        ? (activeRoutes = data.contactRoutes)
        : "";
      routePath === "profile" ? (activeRoutes = data.profileRoutes) : "";

      activeRoutes.forEach((route: any) => {
        url.includes(route)
          ? (color =
              modules.value.general.templates[0].configuration.menu
                .selectedColor)
          : "";
      });

      return { color, background: "white" };
    }

    const activeButtons = computed(() => {
      let resultLanguage: Array<any> = [];
      resultLanguage.push({
        icon:
          modules.value.general.templates[0].configuration.menu.debt.logo
            .length > 0
            ? modules.value.general.templates[0].configuration.menu.debt.logo
            : "your_debt_euro",
        label:
          modules.value.general.templates[0].configuration.menu.debt.title[
            userLanguage.value
          ],
        value: "welcomeText",
        routeName: "DebtsView",
        routePath: "debts",
        isActive: true,
        isIMG:
          modules.value.general.templates[0].configuration.menu.debt.logo
            .length > 0,
      });
      resultLanguage.push({
        icon:
          modules.value.general.templates[0].configuration.menu.payment.logo
            .length > 0
            ? modules.value.general.templates[0].configuration.menu.payment.logo
            : "payment-methods",
        label:
          modules.value.general.templates[0].configuration.menu.payment.title[
            userLanguage.value
          ],
        value: "paymentPlan",
        routeName: "PaymentMethods",
        routePath: "payment-methods",
        isActive: modules.value.paymentPlan
          ? modules.value.paymentPlan.enabled
          : "",
        isIMG:
          modules.value.general.templates[0].configuration.menu.payment.logo
            .length > 0,
      });
      modules.value.contact.enabled ||
      (modules.value.support.enabled && modules.value.support.showWriteUs)
        ? resultLanguage.push({
            icon:
              modules.value.general.templates[0].configuration.menu.contact.logo
                .length > 0
                ? modules.value.general.templates[0].configuration.menu.contact
                    .logo
                : "contact",
            label:
              modules.value.general.templates[0].configuration.menu.contact
                .title[userLanguage.value],
            value: "contactUs",
            routeName: "ContactUs",
            routePath: "contact_us_options",
            isActive: contactEnable.value,
            isIMG:
              modules.value.general.templates[0].configuration.menu.contact.logo
                .length > 0,
          })
        : "";
      resultLanguage.push({
        icon:
          modules.value.general.templates[0].configuration.menu.profile.logo
            .length > 0
            ? modules.value.general.templates[0].configuration.menu.profile.logo
            : "profile",
        label:
          modules.value.general.templates[0].configuration.menu.profile.title[
            userLanguage.value
          ],
        value: "faqs",
        routeName: "Profile",
        routePath: "profile",
        isActive: showProfile.value ? showProfile.value : "",
        isIMG:
          modules.value.general.templates[0].configuration.menu.profile.logo
            .length > 0,
      });
      return resultLanguage;
    });

    return {
      ...toRefs(data),
      authMode,
      isMobile,
      goTo,
      primaryColor,
      userLanguage,
      activeButtons,
      isActiveButton,
      showProfile,
      modules,
      loading,
      contactEnable,
      mobileColors,
      isLogin
    };
  },
});
