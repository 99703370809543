import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  id: "support-footer",
  class: "flex flex-col m-0 w-full overflow-hidden"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "default-box-white-full flex flex-col md:flex-row justify-center md:justify-between items-center !px-5 md:!px-32 lg:!px-40 xl:!px-72" }
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_floating_window = _resolveComponent("sp-floating-window")!
  const _component_sp_button = _resolveComponent("sp-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.selectedLegalInformation.length > 0)
      ? (_openBlock(), _createBlock(_component_sp_floating_window, {
          key: 0,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedLegalInformation = '')),
          position: "bottom"
        }, {
          content: _withCtx(() => [
            (_ctx.selectedLegalInformation === 'termsOfUse')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  innerHTML: _ctx.termsOfUseContent,
                  class: "overflow-y-auto"
                }, null, 8, _hoisted_2))
              : _createCommentVNode("", true),
            (_ctx.selectedLegalInformation === 'privacyPolicy')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  innerHTML: _ctx.privacyPolicyContent,
                  class: "overflow-y-auto"
                }, null, 8, _hoisted_3))
              : _createCommentVNode("", true),
            (_ctx.selectedLegalInformation === 'legalAdvice')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  innerHTML: _ctx.legalAdviceContent,
                  class: "overflow-y-auto"
                }, null, 8, _hoisted_4))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.termsOfUsePolicyUrl.length > 0 && _ctx.termsOfUseContent.length === 0)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: _ctx.modules.footer.termsOfUsePolicyUrl,
            target: "_blank"
          }, [
            _createVNode(_component_sp_button, {
              label: _ctx.footer.termsOfUsePolicy.text[_ctx.language],
              color: "white",
              size: "auto",
              "font-semibold": false,
              class: "mr-2"
            }, null, 8, ["label"])
          ], 8, _hoisted_6))
        : (_openBlock(), _createBlock(_component_sp_button, {
            key: 1,
            onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedLegalInformation = 'termsOfUse')),
            label: _ctx.footer.termsOfUsePolicy.text[_ctx.language],
            color: "white",
            size: "auto",
            "font-semibold": false,
            class: "mr-2"
          }, null, 8, ["label"])),
      (_ctx.privacyPolicyUrl.length > 0 && _ctx.privacyPolicyContent.length === 0)
        ? (_openBlock(), _createElementBlock("a", {
            key: 2,
            href: _ctx.modules.footer.privacyPolicy.url,
            target: "_blank"
          }, [
            _createVNode(_component_sp_button, {
              label: _ctx.footer.privacyPolicy.text[_ctx.language],
              color: "white",
              size: "auto",
              class: "mr-2",
              "font-semibold": false
            }, null, 8, ["label"])
          ], 8, _hoisted_7))
        : (_openBlock(), _createBlock(_component_sp_button, {
            key: 3,
            onOnClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectedLegalInformation = 'privacyPolicy')),
            label: _ctx.footer.privacyPolicy.text[_ctx.language],
            color: "white",
            size: "auto",
            class: "mr-2",
            "font-semibold": false
          }, null, 8, ["label"])),
      (_ctx.cookiesPolicyUrl.length > 0 && _ctx.legalAdviceContent.length === 0)
        ? (_openBlock(), _createElementBlock("a", {
            key: 4,
            href: _ctx.modules.footer.cookiesPolicy.url,
            target: "_blank"
          }, [
            _createVNode(_component_sp_button, {
              label: _ctx.footer.cookiesPolicy.text[_ctx.language],
              color: "white",
              size: "auto",
              "font-semibold": false
            }, null, 8, ["label"])
          ], 8, _hoisted_8))
        : (_openBlock(), _createBlock(_component_sp_button, {
            key: 5,
            onOnClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectedLegalInformation = 'legalAdvice')),
            label: _ctx.footer.cookiesPolicy.text[_ctx.language],
            color: "white",
            size: "auto",
            "font-semibold": false
          }, null, 8, ["label"]))
    ])
  ]))
}