
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import SpButton from "@/components/ui/SpButton.vue";
import SpSelect from "@/components/ui/SpSelect.vue";
import { useStore } from "vuex";
import i18n from "@/main";
import * as Utils from "@/api/Utils";
import moment from "moment/moment";
import SpCalendarAx from "@/components/axactorModulesMobile/SpCalendarAx.vue";
import router from "@/router";

export default defineComponent({
  name: "PinAuthenticationAx",
  components: { SpButton, SpSelect, SpCalendarAx },
  emits: ["successfulAuthentication", "goToTheAxactorRoute"],
  props: {
    c: { type: String, default: "", required: false },
    client: { type: String, default: "", required: false },
    s: { type: String, default: "", required: false },
    strategy: { type: String, default: "", required: false },
    a: { type: String, default: "", required: false },
    action: { type: String, default: "", required: false },
    t: { type: String, default: "", required: false },
    template: { type: String, default: "", required: true },
    element: { type: String, default: "", required: true },
    uuid: { type: String, default: "", required: true },
    result: { type: String, default: "", required: false },
  },
  setup(props, { emit }) {
    const store = useStore();
    const today = moment().format("YYYY-MM-DD");

    interface PinAuthenticationAx {
      languages: Array<any>;
      pin: string;
      languageData: string;
      seeForm: boolean;
      identification: string;
      phone: any;
      email: any;
      dni: string;
      birthDate: string | null;
      loading: boolean;
    }

    const data = reactive<PinAuthenticationAx>({
      languages: [],
      pin: "",
      languageData: "",
      seeForm: false,
      identification: "",
      phone: null,
      email: null,
      dni: "",
      birthDate: null,
      loading: false
    });

    getGeneralModule();
    function getGeneralModule() {
      const params = {
        uuid: props.uuid,
        callbackSuccess: (callbackSuccessData: any) => {
          store.commit(
            "user/setLanguage",
            callbackSuccessData.data.defaultLanguage
          );
          store.commit(
            "security/setFallbackContactInfoAddress",
            callbackSuccessData.data.fallbackContactInfo.address
          );
          store.commit(
            "security/setFallbackContactInfoPhone",
            callbackSuccessData.data.fallbackContactInfo.phone
          );
          store.commit(
            "security/setFallbackContactInfoEmail",
            callbackSuccessData.data.fallbackContactInfo.email
          );
          store.commit(
            "security/setFallbackContactInfoTextRu",
            callbackSuccessData.data.fallbackContactInfo.text.ru
          );
          store.commit(
            "security/setFallbackContactInfoTextEs",
            callbackSuccessData.data.fallbackContactInfo.text.es
          );
          store.commit(
            "security/setFallbackContactInfoTextEn",
            callbackSuccessData.data.fallbackContactInfo.text.en
          );
          store.commit(
            "modules/setAuthMode",
            callbackSuccessData.data.authMode
          );
          store.commit(
            "modules/setCustomApi",
            callbackSuccessData.data.customApi
          );
          store.commit(
            "modules/setPinPageCustomization",
            callbackSuccessData.data.pinPageCustomization
          );
          // data.dynamicTemplate = callbackSuccessData.data.uxMode;
          // data.pinContentHtml = callbackSuccessData.data.pinContentHtml;
          // data.languages = callbackSuccessData.data.extraLanguage;
          // data.pinPageCustomization =
          //   callbackSuccessData.data.pinPageCustomization;
          // data.customApi = callbackSuccessData.data.customApi;
          // data.authModeNew = callbackSuccessData.data.authMode;
          // redirectToViewBasedOnTemplate(props.uuid);
          if(callbackSuccessData.data.authMode === 'noAuth') {
           store.commit( "modules/setLoading", true)
          }
        },
        callbackError: () => {},
      };
      store.dispatch("modules/getGeneralModule", params);
    }

    const authMode = computed(() => {
      return store.getters["modules/getAuthMode"];
    });

    const customApi = computed(() => {
      return store.getters["modules/getCustomApi"];
    });

    const emailsContacts = computed(() => {
      return store.getters["modules/getEmailsContacts"];
    });

    const phonesContacts = computed(() => {
      return store.getters["modules/getPhonesContacts"];
    });

    const fallbackContactInfo = computed(() => {
      return store.getters["security/getFallbackContactInfo"];
    });
    const loading = computed(() => {
      return store.getters["modules/getLoading"];
    });


    const sendMethod = computed(() => {
      return store.getters["modules/getSendMethod"];
    });

    const pinPageCustomization = computed(() => {
      return store.getters["modules/getPinPageCustomization"];
    });

    const hasEmailOrPhone = computed(() => {
      if (data.phone === null && data.email === null) return true;
      return false;
    });

    const isCustomApi = computed(() => {
      return customApi.value === true ? checkDocument(data.identification) : "";
    });

    const isPhone = computed(() => {
      return sendMethod.value === "twilio_sms" || sendMethod.value === "sms";
    });
    const isEmail = computed(() => {
      return sendMethod.value === "mail";
    });

    const isValidDocument = computed(() => {
      return checkDocument(data.identification);
    });

    function changePhone(value: string) {
      data.phone = value;
    }

    function changeEmail(value: string) {
      data.email = value;
    }

    function changeLocale(locale: any) {
      store.commit("user/setLanguage", locale);
      i18n.global.locale = locale;
    }

    function checkDocument(text: string) {
      return Utils.validateIdentification(text);
    }

    function postSendPin() {
      const params = {
        uuid: props.uuid,
        uniqueId: data.identification,
        phone: data.phone,
        mail: data.email,
        callbackSuccess: () => {
          store.commit("user/pushNotification", {
            id: Date.now(),
            icon: "alert-triangle",
            message: "pinUnique.toastMessage",
            color: "success",
          });
        },
        callbackError: (callbackErrorData: any) => {
          store.commit("user/pushNotification", {
            id: Date.now(),
            icon: "",
            message: "pinUnique.errorId",
            color: "danger",
          });
        },
      };
      store.dispatch("modules/postSendPin", params);
    }
    function checkPin() {
      const params = {
        uuid: props.uuid,
        pin: data.pin,
        callbackSuccess: (callbackSuccessData: any) => {
          store.commit("security/setToken", callbackSuccessData.data.token);
          emit("successfulAuthentication");
        },
        callbackError: (callbackErrorData: any) => {
          store.commit("user/pushNotification", {
            id: Date.now(),
            icon: "",
            message: callbackErrorData.data,
            color: "danger",
          });
        },
      };
      store.dispatch("modules/postCheckPin", params);
    }

    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });
    const generalModule = computed(() => {
      return store.getters["modules/getGeneral"];
    });

    changeLocale(language.value);

    watch(language, (lang) => {
      changeLocale(lang);
    });

    const hasDniOrBirthday = computed(() => {
      if (data.dni.length === 0 || data.birthDate === null) return true;
      return false;
    });

    function postSendDniBirthday() {
      const params = {
        uuid: props.uuid,
        dni: data.dni,
        birthDate: data.birthDate,
        callbackSuccess: (callbackSuccessData: any) => {
          store.commit("security/setToken", callbackSuccessData.data.token);
          getLandingConfiguration()
        },
        callbackError: (callbackErrorData: any) => {
          store.commit("user/pushNotification", {
            id: Date.now(),
            icon: "",
            message: callbackErrorData.data.message,
            color: "danger",
          });
        },
      };
      store.dispatch("modules/postSendDniBirthday", params);
    }

    function getLandingConfiguration() {
      data.loading = true
      const params = {
        uuid: props.uuid,
        callbackSuccess: (callbackSuccessData: any) => {

          i18n.global.locale = callbackSuccessData.data.modules.general.defaultLanguage

      let filesInfoResult: Array<any> = [];
      for (let fileKey in callbackSuccessData.data.filesInfo) {
        filesInfoResult.push(callbackSuccessData.data.filesInfo[fileKey]);
      }
      store.commit("modules/setFilesInfo", filesInfoResult)

      if (callbackSuccessData.data.debtorInfo !== null) {
        store.commit("modules/setDebtorInfo", callbackSuccessData.data.debtorInfo)
      }

      if (callbackSuccessData.data.modules.general.paymentConfig.platform === "stripe") {
        store.commit("user/setStripePublicKey", callbackSuccessData.data.modules.general.paymentConfig.public_key)
      }

      if (callbackSuccessData.data.fileUuid !== null) {
        store.commit("modules/setFileUuid", callbackSuccessData.data.fileUuid)
      }

      if (callbackSuccessData.data.modules.supportPageHeader && callbackSuccessData.data.modules.supportPageHeader.logo) {
        store.commit("security/setFallbackContactInfoLogo", callbackSuccessData.data.modules.supportPageHeader.logo)
      }

      store.commit("modules/setModules", callbackSuccessData.data.modules)
      store.commit("user/setUUID", callbackSuccessData.data["@id"])
      store.commit("user/setLanguage", callbackSuccessData.data.modules.general.defaultLanguage)
      store.commit("user/setCustomApi", callbackSuccessData.data.modules.general.customApi)
      store.commit("user/setPrimaryColor", callbackSuccessData.data.modules.general.primaryColor)
      store.commit("modules/setSendMethod", callbackSuccessData.data.authMode)
      store.commit("modules/setGeneral", callbackSuccessData.data.modules.general)
      store.commit("modules/setDefaultFileInfo", callbackSuccessData.data.modules.intervenerAndFilesInfo.filesInfo)
      store.commit("modules/setFaqs", callbackSuccessData.data.modules.faqs.collection)
      store.commit("modules/setDebtSelector", callbackSuccessData.data.modules.welcomeMessage.debtSelector)
      store.commit("modules/setCard", callbackSuccessData.data.modules.paymentPlan.card)
      store.commit("modules/setPaymentPlanDataModule", callbackSuccessData.data.modules.paymentPlan)
      store.commit("modules/setPaymentMethodsTitle", callbackSuccessData.data.modules.paymentMethod.methods)
      store.commit("modules/setContactData", callbackSuccessData.data.modules.contact)
      store.commit("modules/setFooter", callbackSuccessData.data.modules.footer)
      store.commit("modules/setDebtModule", callbackSuccessData.data.modules.debtor)
      store.commit("modules/setSupport", callbackSuccessData.data.modules.support)
      store.commit("modules/setLoading", false)
      data.loading = false
      router.push({ name: "DebtsView" })
    },
    callbackError: (callbackErrorData: any) => {
      data.loading = false
    }
  };
  store.dispatch("modules/getLandingConfiguration", params);
}


    return {
      ...toRefs(data),
      fallbackContactInfo,
      changeLocale,
      checkPin,
      language,
      loading,
      postSendPin,
      isPhone,
      isEmail,
      hasEmailOrPhone,
      changePhone,
      changeEmail,
      isCustomApi,
      isValidDocument,
      authMode,
      customApi,
      sendMethod,
      phonesContacts,
      emailsContacts,
      checkDocument,
      pinPageCustomization,
      generalModule,
      hasDniOrBirthday,
      postSendDniBirthday,
    };
  },
});
