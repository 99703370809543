import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-center items-center flex-col"
}
const _hoisted_2 = { class: "mt-2" }
const _hoisted_3 = {
  key: 1,
  class: "border-collapse sirit-table w-full"
}
const _hoisted_4 = { class: "w-full flex flex-row items-center gap-1" }
const _hoisted_5 = ["name", "onChange", "checked"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = ["colspan"]
const _hoisted_9 = { class: "text-center text-gray-400 text-xs italic" }
const _hoisted_10 = { class: "flex justify-center items-center" }
const _hoisted_11 = { class: "text-sm ml-2 mr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_mobile_table = _resolveComponent("sp-mobile-table")!
  const _component_sp_button_icon = _resolveComponent("sp-button-icon")!
  const _component_sp_icon = _resolveComponent("sp-icon")!

  return (_ctx.isMobile)
    ? (_openBlock(), _createBlock(_component_sp_mobile_table, {
        key: 0,
        items: _ctx.items,
        pagination: "",
        itemsPerPage: _ctx.itemsPerPage,
        page: _ctx.page,
        onGetNextPage: _ctx.getNextPage,
        onGetPreviousPage: _ctx.getPreviousPage,
        onGetFirstPage: _ctx.getFirstPage,
        onGetLastPage: _ctx.getLastPage,
        maxPages: _ctx.maxPages
      }, null, 8, ["items", "itemsPerPage", "page", "onGetNextPage", "onGetPreviousPage", "onGetFirstPage", "onGetLastPage", "maxPages"]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "w-full p-1",
        style: _normalizeStyle(_ctx.overflowFunction)
      }, [
        (_ctx.loading || _ctx.loadindTable)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_sp_button_icon, {
                model: "refresh-cw",
                rotate: ""
              }),
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("general.loading")), 1)
            ]))
          : (_openBlock(), _createElementBlock("table", _hoisted_3, [
              (_ctx.headers.length > 0)
                ? (_openBlock(), _createElementBlock("tr", {
                    key: 0,
                    style: _normalizeStyle(_ctx.headerStyle)
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, idx) => {
                      return (_openBlock(), _createElementBlock("th", {
                        key: idx,
                        class: _normalizeClass(["uppercase font-semibold", [
              header.align === 'left' ? 'text-left' : '',
              header.align === 'center' ? 'text-center' : '',
              header.align === 'right' ? 'text-right' : '',
            ]])
                      }, [
                        _createElementVNode("div", _hoisted_4, [
                          (header.enableable)
                            ? (_openBlock(), _createElementBlock("input", {
                                key: 0,
                                type: "checkbox",
                                name: header.label,
                                onChange: ($event: any) => (_ctx.enableChange(idx)),
                                checked: header.enabled
                              }, null, 40, _hoisted_5))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", null, _toDisplayString(header.label), 1)
                        ])
                      ], 2))
                    }), 128))
                  ], 4))
                : _createCommentVNode("", true),
              (_ctx.items.length > 0)
                ? (_openBlock(), _createElementBlock("tbody", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (row, index) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: index,
                        class: _normalizeClass(index % 2 === 0 ? 'bg-gray-100' : '')
                      }, [
                        _renderSlot(_ctx.$slots, "default", { item: row }, () => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (value, idx) => {
                            return (_openBlock(), _createElementBlock("td", { key: idx }))
                          }), 128))
                        ])
                      ], 2))
                    }), 128)),
                    _createElementVNode("tr", {
                      style: _normalizeStyle(_ctx.lastrowStyle)
                    }, [
                      _renderSlot(_ctx.$slots, "lastrow")
                    ], 4)
                  ]))
                : (_openBlock(), _createElementBlock("tbody", _hoisted_7, [
                    _createElementVNode("tr", null, [
                      (_ctx.headers)
                        ? (_openBlock(), _createElementBlock("td", {
                            key: 0,
                            class: "text-center",
                            colspan: _ctx.headers.length
                          }, [
                            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("general.noDataToShow")), 1)
                          ], 8, _hoisted_8))
                        : _createCommentVNode("", true)
                    ])
                  ])),
              _createElementVNode("tfoot", null, [
                _renderSlot(_ctx.$slots, "footer")
              ])
            ])),
        (!_ctx.loading && _ctx.pagination && _ctx.items.length > 0)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: _normalizeClass(["flex items-center mr-3", _ctx.showItemsPerPage ? 'justify-between' : 'justify-end'])
            }, [
              _createElementVNode("div", _hoisted_10, [
                (_ctx.loadingPage)
                  ? (_openBlock(), _createBlock(_component_sp_icon, {
                      key: 0,
                      model: "refresh-cw",
                      class: "mr-2",
                      rotate: ""
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_sp_button_icon, {
                  onOnClick: _ctx.getFirstPage,
                  icon: "chevrons-left",
                  color: "clean",
                  disabled: _ctx.disablePreviousPage
                }, null, 8, ["onOnClick", "disabled"]),
                _createVNode(_component_sp_button_icon, {
                  onOnClick: _ctx.getPreviousPage,
                  icon: "chevron-left",
                  color: "clean",
                  disabled: _ctx.disablePreviousPage,
                  class: "ml-1 mr-1"
                }, null, 8, ["onOnClick", "disabled"]),
                _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("table.pagination", {
          actualPageKey: _ctx.actualPage,
          totalPagesKey: _ctx.maxPages,
          })), 1),
                _createVNode(_component_sp_button_icon, {
                  onOnClick: _ctx.getNextPage,
                  icon: "chevron-right",
                  color: "clean",
                  disabled: _ctx.disabledNextPage || _ctx.maxPages === 1,
                  class: "ml-1 mr-1"
                }, null, 8, ["onOnClick", "disabled"]),
                _createVNode(_component_sp_button_icon, {
                  onOnClick: _ctx.getLastPage,
                  icon: "chevrons-right",
                  color: "clean",
                  disabled: _ctx.disabledNextPage || _ctx.maxPages === 1
                }, null, 8, ["onOnClick", "disabled"])
              ])
            ], 2))
          : _createCommentVNode("", true)
      ], 4))
}