
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import SpIcon from "@/components/ui/SpIcon.vue";
import SpButton from "@/components/ui/SpButton.vue";
import SpSelect from "@/components/ui/SpSelect.vue";
import SpButtonIcon from "@/components/ui/SpButtonIcon.vue";
import router from "@/router";

export default {
  name: "Files",
  components: {
    SpIcon,
    SpButton,
    SpSelect,
    SpButtonIcon,
  },
  props: {
    uuid: { type: String, required: true },
  },
  setup(props: any) {
    const store = useStore();

    interface Files {
      languages: Array<any>;
      currentSection: string;
      seeInterveners: boolean;
    }

    const data = reactive<Files>({
      languages: [],
      currentSection: "welcomeText",
      seeInterveners: false,
    });

    const languageComputed = computed(() => data.languages);

    function goTo(value: string) {
      router.push({ name: value });
    }

    function getLandingConfiguration() {
      const params = {
        uuid: props.uuid,
        callbackSuccess: (callbackSuccessData: any) => {
          data.languages =
            callbackSuccessData.data.modules.general.extraLanguages;
        },
        callbackError: (callbackErrorData: any) => {},
      };
      store.dispatch("modules/getLandingConfiguration", params);
    }

    getLandingConfiguration();

    return {
      ...toRefs(data),
      languageComputed,
      goTo,
    };
  },
};
