import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "save-as-favourite",
  class: "default-box-transparent w-full py-4 px-0 md:px-32 lg:px-40 xl:px-72"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_alert = _resolveComponent("sp-alert")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_sp_alert, {
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = false)),
          type: "warning",
          "icon-name": _ctx.icon,
          message: _ctx.message[_ctx.language]
        }, null, 8, ["icon-name", "message"])
      ]))
    : _createCommentVNode("", true)
}