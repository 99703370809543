import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-012c6d75"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "sirit-button-icon-notification"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_icon = _resolveComponent("sp-icon")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
    class: _normalizeClass([
        _ctx.buttonClass,
        _ctx.disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
    ])
  }, [
    (_ctx.notification)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.icon !== undefined && _ctx.icon !== '' && !_ctx.loading)
      ? (_openBlock(), _createBlock(_component_sp_icon, {
          key: 1,
          model: _ctx.icon,
          size: _ctx.size
        }, null, 8, ["model", "size"]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_sp_icon, {
          key: 2,
          model: "refresh-cw",
          rotate: ""
        }))
      : _createCommentVNode("", true)
  ], 2))
}