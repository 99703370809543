
import {defineComponent, computed, reactive, toRefs} from 'vue'
import SpIcon from '@/components/ui/SpIcon.vue'

export default defineComponent({
  name: "SpButtonIcon",
  components: { SpIcon},
  props: {
    color: {type: String, required: false, default: 'secondary'},
    icon: {type: String, required: false, default: ''},
    size: {type: String, required: false, default: '20'},
    disabled: {type: Boolean, required: false, default: false},
    loading: {type: Boolean, required: false, default: false},
    notification: {type: Boolean, required: false, default: false},
    tooltip: { type: String, default: '' },
    tooltipPosition: { type: String, default: 'top' }
  },
  emits: ['onClick'],
  setup(props, {emit}) {
    interface spButtonIcon {
      showTooltip: boolean
    }

    const data = reactive<spButtonIcon>({
      showTooltip: false
    })

    const buttonClass = computed(() => {
      return 'btn-icon-' + props.color
    })

    function onClick() {
      if (!props.disabled) {
        emit('onClick')
      }
    }

    return {
      ...toRefs(data),
      buttonClass,
      onClick
    }
  }
})
