import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  id: "file-interveners",
  class: "default-box-transparent w-full py-4 px-0 md:px-32 lg:px-40 xl:px-72 text-left"
}
const _hoisted_2 = { class: "px-5 md:px-0 py-4" }
const _hoisted_3 = { class: "default-box-white-full" }
const _hoisted_4 = { class: "pl-4 p-2" }
const _hoisted_5 = {
  key: 0,
  class: ""
}
const _hoisted_6 = { class: "font-semibold" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { key: 3 }
const _hoisted_11 = { key: 4 }
const _hoisted_12 = { key: 5 }
const _hoisted_13 = { key: 6 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_title = _resolveComponent("sp-title")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_sp_title, {
        message: _ctx.debtorData.title[_ctx.language],
        size: "text-4xl"
      }, null, 8, ["message"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.fileInfo.relatedInterveners.length <= 0)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.debtorData.noIndividuals[_ctx.language]), 1))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fileInfo.relatedInterveners, (debtor, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "flex flex-col"
          }, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.modules.debtor.columnsTitle[_ctx.language]), 1),
            _createElementVNode("p", null, [
              (_ctx.enabledFullName)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(debtor.fullName), 1))
                : _createCommentVNode("", true),
              (!_ctx.enabledFullName ?  _ctx.enabledFullName : _ctx.enabledDocumentUniqueId || _ctx.positionType || _ctx.position)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, " | "))
                : _createCommentVNode("", true),
              (_ctx.enabledDocumentUniqueId)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(debtor.documentUniqueId ? debtor.documentUniqueId : '-'), 1))
                : _createCommentVNode("", true),
              (!_ctx.enabledDocumentUniqueId ?  _ctx.enabledDocumentUniqueId : _ctx.position || _ctx.positionType )
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, " | "))
                : _createCommentVNode("", true),
              (_ctx.position)
                ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(debtor.position), 1))
                : _createCommentVNode("", true),
              (!_ctx.position ?  _ctx.position : _ctx.positionType )
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, " | "))
                : _createCommentVNode("", true),
              (_ctx.positionType)
                ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(debtor.positionType), 1))
                : _createCommentVNode("", true)
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}