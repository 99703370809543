import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-041a84a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "bottom-navigation-bar",
  style: {"box-shadow":"rgba(0, 0, 0, 0.1) 0px 4px 12px","border-radius":"15px 15px 0 0"}
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { style: {"font-size":"12px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_icon = _resolveComponent("sp-icon")!

  return (!_ctx.loading && !_ctx.isLogin)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeButtons, (activeButton) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: activeButton.value
          }, [
            (activeButton.isActive)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: ($event: any) => (_ctx.goTo(activeButton.routeName)),
                  style: _normalizeStyle([{"padding":"0 !important"}, _ctx.isActiveButton(activeButton.routePath)]),
                  class: "bottom-navigation-bar-button"
                }, [
                  (activeButton.isIMG)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: activeButton.icon,
                        style: {"width":"40px","height":"40px"}
                      }, null, 8, _hoisted_3))
                    : (_openBlock(), _createBlock(_component_sp_icon, {
                        key: 1,
                        model: activeButton.icon
                      }, null, 8, ["model"])),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(activeButton.label), 1)
                ], 12, _hoisted_2))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}