
import {computed, defineComponent, reactive, toRefs, watch} from 'vue'
import {useStore} from 'vuex'

export default defineComponent({
  name: "SpSquareButton",
  props: {
    id: { type: String, default: '' },
    isActive: { type: Boolean, default: false, required: true },
    onlyBorders: { type: Boolean, default: false },
    title: { type: String, default: '' },
    img: { type: String, default: '', required: false },
    description: { type: String, default: '', required: false },
    color: { type: String, default: 'primary', required: false }, // primary, gray100-primary
    backgroundColor: { type: String, default: '', required: false },
    backgroundColorSelected: { type: String, default: '', required: false },
    colorText: { type: String, default: '', required: false },
    colorTextSelected: { type: String, default: '', required: false },
  },
  emits: ['changeStatus'],
  setup (props, { emit }) {
    const store = useStore()

    interface spSquareButton {
      active: boolean,
      colorToUse: string
    }

    const data = reactive<spSquareButton>({
      active: false,
      colorToUse: store.getters['user/getPrimaryColor']
    })

    function changeIsActive () {
      emit('changeStatus', { id: props.id, active: data.active })
    }

    const spSquareButtonClass = computed(() => {
      let result: string = 'btn-square-button'
      result += '-' + props.color
      if (props.onlyBorders) result += '-only-border'
      return result
    })

    const buttonStyleSelected = computed(() => {
      return {
        background: props.backgroundColorSelected,
        color: props.colorTextSelected,
        border: '1px solid ' + props.backgroundColorSelected
      }
    })

    const buttonStyle = computed(() => {
      return {
        background: props.backgroundColor,
        color: props.colorText,
        border: '1px solid ' + props.backgroundColor
      }
    })

    data.active = props.isActive
    watch(() => props.isActive, (newValue) => {
      data.active = newValue
    })

    return {
      ...toRefs(data),
      changeIsActive,
      spSquareButtonClass,
      buttonStyle,
      buttonStyleSelected
    }
  }
})
