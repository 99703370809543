
import {computed, defineComponent} from 'vue'
import {useStore} from "vuex";
import SpIcon from "@/components/ui/SpIcon.vue";

export default {
  name: "Error404",
  components: {SpIcon},
  setup () {
    const store = useStore()

    const fallbackContactInfo = computed(() => {
      return store.getters['security/getFallbackContactInfo']
    })

    return {
      fallbackContactInfo
    }
  }
}
