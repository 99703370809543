import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "text-xs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_icon = _resolveComponent("sp-icon")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
    class: _normalizeClass([
        _ctx.buttonClass,
        _ctx.disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
    ]),
    style: _normalizeStyle(_ctx.buttonStyle)
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_sp_icon, {
          key: 0,
          model: "refresh-cw",
          rotate: ""
        }))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.iconRight ? 'flex justify-center items-center flex-row-reverse' : 'flex justify-center items-center')
        }, [
          (_ctx.icon !== undefined && _ctx.icon !== '')
            ? (_openBlock(), _createBlock(_component_sp_icon, {
                key: 0,
                model: _ctx.icon,
                class: _normalizeClass(_ctx.iconRight ? 'ml-2':'mr-4'),
                size: _ctx.iconSize ? _ctx.iconSize : '15'
              }, null, 8, ["model", "class", "size"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.fontSemibold ? 'font-semibold':'')
            }, _toDisplayString(_ctx.label), 3),
            (_ctx.sublabel.length > 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.sublabel), 1))
              : _createCommentVNode("", true)
          ])
        ], 2))
  ], 6))
}