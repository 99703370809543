export default {

  debtRemoval: {
    title: "Quita de deuda",
    subtitle: "Envíe una propuesta del porcentaje de la deuda que solicita reducir.",
    amountToRelief: "Porcentaje a reducir (%)",
    sendProposal: "Enviar propuesta",
  },
  error403: {
    title: "Esta página ya no está disponible",
    subtitle:
      "El acceso a esta página ha caducado. Si tiene alguna pregunta, por favor contáctenos",
  },
  error404: {
    title: 'Parece que lo que buscas no está disponible ahora',
    },
    fileInterveners: {
        title: 'Estas son las personas que participan en tu expediente',
        title2: 'No hay otras personas que intervengan con usted',
        subtitle: 'A continuación se detallan los demás intervinientes de su expediente',
        debtor: 'Interviniente'
    },
    fileSelector: {
        title: 'Selector de deuda',
        subtitle: 'Selecciona la deuda que desea visualizar',
        message: 'Tienes {debts} deudas, de las que has pagado {paidDebt} de {totalDebt}',
        messageShort: 'Tienes {debts} deudas',
        messageSingle: 'Tienes {debts} deuda, de la que has pagado {paidDebt} de {totalDebt}',
        messageSingleShort: 'Tienes {debts} deuda',
        card: {
            debt: 'Deuda',
            debtContracted: 'Deuda contraída el {debtStart} con {debtOwner}',
            debtContracted2: 'Deuda contraída el {debtStart}',
            paidDebt: 'Deuda pagada',
            pendingDebt: 'Deuda pendiente',
            totalDebt: 'Deuda total'
        },
        notifications: {
            changeFileSuccess: 'Se ha cambiado la deuda a visualizar correctamente.'
        }
    },
    general: {
        save: 'Guardar',
        date: 'FECHA',
        amount: 'CANTIDAD',
        supportPage: 'Página de soporte',
        platformSupportedBy: 'Plataforma con el respaldo de',
        virtualAvatarTitle: 'Te ayudamos a regularizar tu deuda',
        virtualAvatarDescription: 'Dinos cómo y cuando quieres pagar tu deuda',
        saveAsFavourite1:
            "Guarda este enlace en “favoritos” de tu navegador. Podrás estar en contacto con nosotros y ver el estado del expediente actualizado",
        saveAsFavourite2: "haz click aquí,",
        paymentPlan: 'Plan de pago',
        paymentPlanProposalSingleOption: 'Pago único',
        paymentPlanProposalPartialOption: 'Pago parcial',
        paymentPlanProposalPartialOptionDescription: '¡Hágase cargo de su viaje de deuda!. Realice pagos más pequeños y vea crecer su progreso.',
        paymentPlanProposalSingleOption1: 'Pagar',
        paymentPlanProposalSingleOptionDescription: 'Limpie su deuda con un solo pago, logre rápidamente la libertad financiera',
        paymentPlanProposal0: "Plan de pagos",
        paymentPlanProposal1: 'Te proponemos estas opciones para que selecciones la que mejor te convenga.',
        paymentPlanProposal1Question: '¿Cómo prefieres hacerlo?',
        paymentPlanProposal2: "Recuerda que estamos aquí para facilitarte las cosas",
        paymentPlanProposal3: "En qué frecuencia quieres pagar",
        paymentPlanProposal4: "Un solo pago",
        paymentPlanProposal5: "Esta modalidad incluye una quita del 10%",
        paymentPlanProposal6: "En 2 meses",
        paymentPlanProposal7: "En 4 meses",
        paymentPlanProposal8: 'Proponer un plan de pagos',
        paymentPlanProposal9: 'Liquide gradualmente su deuda, un paso a la vez equilibre sus obligaciones mientras allana el camino hacia una vida libre de deudas.',
        paymentPlanProposal10: 'Enviar propuesta de pagos',
        paymentPlanProposal11: "o propón tú una forma de pago",
        paymentPlanProposal12: "Cantidad a pagar al mes",
        paymentPlanProposal13: "Meses en los que pagarás",
        paymentPlanProposal14: "1 mes",
        paymentPlanProposal15: "120 mes",
        paymentPlanProposal16: "Resumen de pago",
        paymentPlanProposal17: "%fee% en %months% meses",
        months: "Meses",
        paymentMethod1: "Elige el método de pago",
        paymentMethod2: "Selecciona el método de pago que más se adapte a ti",
        paymentMethod3: "Añade otro método de pago",
        paymentMethod4: "Haz una propuesta, te escuchamos",
        paymentMethod5:
            "Introduzca el método o forma de pago que desea utilizar y nuestro equipo se pondrá en contacto con usted para intentar ayudarle con su elección.",
        paymentMethod6: "Método a utilizar",
        paymentMethod7: "Enviar método de pago",
        paymentMethod8: "¡Enhorabuena!, tu pago se ha realizado correctamente. Estás más cerca de la cancelación de tu deuda.",
        paymentMethod9: "Algo estaba mal con su pago. Por favor revise los datos de su método de pago.",
        support1: 'Soporte',
        support2: 'Encuentre la respuesta',
        support3: 'Videoconferencia',
        support4: 'Chat',
        support5: 'Habla con uno de nuestros asesores',
        support6: 'Si tienes cualquier tipo de duda y quieres hablar con uno de nuestros asesores, esta es la forma ideal de hacerlo. Siempre habrá alguien detrás para ayudarte.',
        support7: 'Empezar videoconferencia',
        support8: 'Habla con uno de nuestro centro de ayuda',
        support9: 'Hola! ¿En qué podemos ayudarte?',
        support10: 'Escribir mensaje',
        contact1: "¿Quieres que nos pongamos en contacto contigo?",
        contact2: "Indícanos por favor cuándo podemos ponernos en contacto contigo.",
        contact3: "Indica tu teléfono",
        contact4: "Ej: 612285745",
        contact5: "Indica tu correo electrónico.",
        contact6a: "Ej: info",
        contact6b: "tuemail.com",
        contact7: "Indica tu dirección",
        contact8: "Ej: Calle Marley, 17",
        contact9: "Enviar",
        contact10: "Preferencias de contacto",
        contact11: "Prefiero que no me contacten",
        contact12: "Añadir más días y tramos horarios",
        contact13: "¿No deseas que te contactemos?",
        contact14: "¿Quieres contarnos algo más que debamos saber antes de volver a contactar contigo?",
        contact15: "Ej: Hola, necesito más información",
        contact16: "Indica tu nombre y apellidos",
        contact16a: "Jhon Smith / Sara Smith ",
        contact17: "Tipo de calle",
        contact18: "Calle",
        contact19: "Número",
        contact20: "Provincia",
        contact21: "Ciudad",
        contact22: "Código postal",
        contact23: "País",
        history1: 'Historial',
        history2: 'Revisa lo que has hecho hasta ahora',
        history3: '01/01/2022',
        history4: 'Envío de email de contacto',
        history5: '10/02/2022',
        history6: 'Solicitud de pago fraccionado',
        history7: '20/03/2022',
        history8: 'Comunicación mediante videoconferencia con Pedro de Sirit',
        debtConsolidation1: '¿Tienes más deudas de las que gestiona este expediente? Te ayudamos.',
        debtConsolidation2: 'Si tienes más deudas y quieres que te ayudemos a consolidarlas y gestionarlas juntas, cuéntanoslo. Incluye cómo podemos contactar contigo y nos ponemos a trabajar juntos.',
        debtConsolidation3: 'Explícanos cómo te podríamos ayudar',
        debtConsolidation4: 'Enviar',
        max: 'Máximo',
        min: 'Mínimo',
        faqs1: '¿Tienes alguna duda?',
        faqs2: 'Encuentra la respuesta',
        faqs3: '¿Por qué me ha llegado esta página?',
        faqs4: 'Si te ha llegado esta página es porque tienes una deuda pendiente y no se ha producido el pago de la misma. Por tanto, la misión de esta página es ayudarte a realizar el pago de tu deuda.',
        faqs5: '¿Puedo obtener una quita de mi deuda?',
        faqs6: 'Si, siempre que realices el pago de tu deuda de un sólo pago.',
        faqs7: '¿Puedo modificar mis cuotas y el pago mensual?',
        faqs8: 'Puedes seleccionar en cuántas cuotas y la cantidad que deseas pagar mes a mes. Lo importante es que selecciones un forma de pago que se adapte a tus necesidades y consigas reducir tu deuda.',
        whoIsSirit1: '¿Quién es Sirit?',
        whoIsSirit2: 'Somos una empresa dedicada a la gestión de tus impagos. Nuestra misión es ayudarte a que consigas pagar tu deuda y librarte de todas las preocupaciones anteriores frente a tu deuda.',
        whoIsSirit3: 'Uno de nuestros muchos objetivos es que la gestión de tu deuda sea simple y librarte de gestiones bancarias pesadas y poco adaptables a tus necesidades actuales.',
        whoIsSirit4: 'Contacta con nosotros.',
        whoIsSirit5: 'Si tienes cualquier tipo de duda no dudes en ponerte en contacto con nosotros.',
        whoIsSirit6: 'Contacta con nosotros',
        whoIsSirit7: 'Condiciones de uso de la web',
        whoIsSirit8: 'Política de privacidad',
        whoIsSirit9: 'Política de uso de cookies',
        welcome1: 'Hola',
        welcome2: 'Juan Suñer Parra',
        welcome3: 'Si no eres tu',
        welcome4: 'Haz click aquí',
        welcome5: 'Desde esta página podrás gestionar tu deuda. Nuestra misión es ayudarte a gestionarla y que logres pagar tu deuda de la forma más cómoda, ágil y sencilla posible. Si necesitas cualquier tipo de ayuda no dudes en ponerte en contacto con nosotros.',
        welcome6: 'La deuda asciende a',
        welcome7: '23.392€ + IVA',
        welcome8: 'Deuda contraida el',
        welcome9: '30 de Enero de 1993 con el BBVA,',
        welcome10: 'referente a un préstamo hipotecario.',
        error404: 'Error 404',
        pageNotFound: 'Page not found',
        videoChatBot1: 'Video chat bot',
        videoChatBot2: 'Empieza un video chat con unos de nuestros asistentes virtuales. Es cómo chatear con un asistente pero con la comodidad de ir paso a paso.',
        videoChatBot3: 'Empezar video chat',
        videoChatBot4: 'Empezar',
        totalDebt: 'Total adeudado {debtAmount}',
        aSinglePaymentSummary: 'Pago único, a pagar { debtAmount }.',
        inTwoMonthsPaymentSummary: 'Pago fraccionado en dos meses, a pagar { debtAmount } al mes.',
        inFourMonthsPaymentSummary: 'Pago fraccionado en cuatro meses, a pagar { debtAmount } al mes.',
        summary: 'Resumen',
        pay: 'Pagar',
        payWith: 'con { method }',
        paymentRecords1: 'Historial de pagos',
        paymentRecords2: 'Obtenga información de los pagos realizados',
        paymentRecords3: 'No se ha realizado ningún pago',
        paymentRecords4: 'El pago puede tardar hasta 24 horas en aparecer reflejado',
        files1: 'Documentos',
        files2: 'Selecciona o arrastra aquí aquellos documentos que te pidamos y sean importantes para tu expediente',
        files3: 'Documentos subidos',
        files4: 'No se han subido documentos todavía',
        files5: 'Arrastre sus documentos aquí',
        files6: 'o',
        files7: 'Seleccionar documentos',
        files8: 'Subiendo documentos..',
        maxFileSize: 'El tamaño máximo de fichero es 100MB',
        paymentSubtitle: 'Tú decides cómo realizar el pago',
        debtAmountTo: 'Tu próximo pago es',
        selectPayment: 'Selecciona un método de pago',
        transaction: 'Transferencia entre cuentas',
        transfer: 'Transacción',
        creditCard: 'Tarjeta de crédito',
        payPal: 'Paypal',
        bizum: 'Bizum',
        amountToPay: 'Cantidad a pagar',
        sourceBankAccount: 'Introduce el número de la cuenta bancaria desde la que quieres realizar la transferencia',
        placeholderSourceBankAccount: 'Número de cuenta',
        cardNumber: 'Número de la tarjeta',
        name: 'Nombre',
        placeholderName: 'Introduzca el nombre',
        expiryDate: 'Fecha de expiración',
        cvv: 'CVV / CVC',
        email: 'Email',
        emailPlaceholder: 'Introduza su email',
        password: 'Contraseña',
        passwordPlaceholder: 'Introduza su contraseña',
        paymentWith: 'Pago realizado con ',
        paymentWithCard: 'Pagar con tarjeta',
        paymentWithBizum: 'Pagar con bizum',
        cannotPay: 'No puede realizar el pago, por favor contáctanos',
        paymentWithTransfer: 'Pagar con transferencia bancaria',
        paymentWithPaypal: 'Pagar con PayPal',
        paymentGenerated: 'Pago generado el',
        amountPaid: 'Cantidad pagada: ',
        contactMe: 'Contáctame',
        dontContactMe: 'No me contactes',
        showContactOptions: 'Mostrar preferencias de contacto',
        monday: 'Lunes',
        tuesday: 'Martes',
        wednesday: 'Miércoles',
        thursday: 'Jueves',
        friday: 'Viernes',
        saturday: 'Sábado',
        sunday: 'Domingo',
        from: 'Desde',
        inRangeHour: 'en este rango de horas',
        to: 'Hasta',
        savePreferences: 'Guardar preferencias',
        bestContactHoursByPhone: 'Mejores horas de contacto',
        fileType0: 'Seleccione un tipo',
        fileType1: 'Nóminas',
        fileType2: 'IRPF (Modelo 100). (Facilitados por la Agencia Tributaria) o Certificado de Renta',
        fileType3: 'Certificado de ingresos de la Pensión (Facilitado por el Instituto Nacional de la Seguridad Social)',
        fileType4: 'Certificado de ingresos del SEPE (Facilitado por el Servicio Público de Empleo Estatal)',
        fileType5: 'Vida Laboral (Facilitada por el Instituto Nacional de la Seguridad Social)',
        fileType6: 'Certificado conforme no percibe Prestación del INSS (Facilitada por el Instituto Nacional de la Seguridad Social)',
        fileType7: 'Vida Laboral (Facilitada por el Instituto Nacional de la Seguridad Social)',
        fileType8: 'IRPF (Modelo 130 o 131). (Facilitados por la Agencia Tributaria) ',
        fileType9: 'IVA Resumen anual IVA (Mod. 303 / Mod. 390). (Facilitados por la Agencia Tributaria) ',
        fileType10: 'DNI – INE – Pasaporte',
        fileType11: 'Otros',
        selectCategory: 'Seleccione una categoría',
        dataUpdatedOn: 'Datos actualizados el ',
        paymentPlanApplication: 'Solicitud de plan de pago',
        prefix: 'Selecciona un prefijo',
        true: 'Verdadero',
        false: 'Falso',
        selectItem: 'Selecciona un valor',
        noData: 'Sin datos',
        searching: 'Buscando',
        needPaymentPlan: 'Debes crear un plan de pago antes de realizar el pago',
        alreadyPlan: 'Ya existe un plan de pago para este archivo',
        amountError: 'La cantidad seleccionada es menor que el valor mínimo a pagar',
        birthDate: 'Fecha de nacimiento'
    },
    generalAx:{
        spinnerMessage: 'Cargando tu Página Personal',
    },
    notifications: {
        contactError1: 'Debe introducir un correo electrónico válido. Introduzca un correo válido y vuelva a intentarlo.',
        contactError2: 'Debes rellenar los campos obligatorios del formulario. Agregue los datos faltantes y envíe el formulario nuevamente.',
        contactError3: 'Debe introducir un teléfono válido. Introduzca un teléfono válido y vuelva a intentarlo.',
        contactSuccess1: 'Hemos recibido sus datos de contacto. Nos pondremos en contacto con usted a la mayor brevedad posible.',
        filesSuccess1: 'Se ha subido correctamente los ficheros cargados.',
        filesSuccess2: 'Se ha eliminado correctamente el fichero cargado.',
        debtConsolidationError1: 'Debe introducir qué deudas desea consolidar. Introduzca el texto y vuelva a intentarlo.',
        debtConsolidationSuccess1: 'Hemos recibido la información de sus deudas. Nos pondremos en contacto con usted a la mayor brevedad posible.',
        successPayment: '¡Felicidades! Su pago se ha realizado correctamente. Está un paso más cerca de saldar su deuda. El pago puede tardar hasta 24 horas en aparecer reflejado',
        wrongPayment: 'Ups, parece que algo salió mal al procesar su pago. Por favor revise la información del método de pago.',
        accept: "Aceptar",
        successChangeTag: 'Se ha cambiado el tipo del fichero subido correctamente.',
        successRemoval: 'Su propuesta de eliminación de deuda ha sido enviada con éxito. Después de analizarlo se pondrán en contacto con usted para informarle del resultado.',
        successSendSupportMessage: 'Su mensaje se ha enviado correctamente.',
        errorSendSupportMessage: 'Debe escribir su mensaje para poder enviarlo. Escriba el mensaje que desea enviar y vuelva a intentarlo.',
        payError:  'El valor a pagar debe ser mayor al importe mínimo',
        payErrorMax: 'El valor a pagar debe ser mayor al importe total de la deuda',
        paymentSuccess: '¡Gracias por tu pago! Tu transacción ha sido procesada exitosamente.',
        paymentError: 'Lamentamos el inconveniente. Ha ocurrido un error con tu pago. Por favor, intenta nuevamente más tarde o contacta a nuestro servicio de atención al cliente para obtener ayuda.',
        callMeNow: 'El teléfono se envió correctamente',
        errorTotal: 'El importe introducido es inferior al mínimo',
        inNumber: 'El importe introducido no es un número',
        selectDebt: 'Debe seleccionar una deduda',
    },
    redsysErrors: {
        msg1: 'Ha de rellenar los datos de la tarjeta.',
        msg2: 'La tarjeta es obligatoria.',
        msg3: 'La tarjeta ha de ser numérica.',
        msg4: 'La tarjeta no puede ser negativa.',
        msg5: 'El mes de caducidad de la tarjeta es obligatorio.',
        msg6: 'El mes de caducidad de la tarjeta ha de ser numérico.',
        msg7: 'El mes de caducidad de la tarjeta es incorrecto.',
        msg8: 'El año de caducidad de la tarjeta es obligatorio',
        msg9: 'El año de caducidad de la tarjeta ha de ser numérico.',
        msg10: 'El año de caducidad de la tarjeta no puede ser negativo.',
        msg11: 'El código de seguridad de la tarjeta no tiene la longitud correcta.',
        msg12: 'El código de seguridad de la tarjeta ha de ser numérico.',
        msg13: 'El código de seguridad de la tarjeta no puede ser negativo.',
        msg14: 'El código de seguridad no es necesario para su tarjeta.',
        msg15: 'La longitud de la tarjeta no es correcta.',
        msg16: 'Debe Introducir un número de tarjeta válido (sin espacios ni guiones).',
        msg17: 'Validación incorrecta por parte del comercio.',
        msg18: 'No se pudo programar una llamada telefónica para el interventor dado',
        msg19: 'El teléfono introducido no es válido',
    },
    pinAuthentication: {
        title: 'Autenticación por PIN',
        description: 'Para asegurarnos que eres tú, introduce el código PIN que estamos enviando a tu número de teléfono o email.',
        pin: 'PIN',
        pinPlaceholder: 'Introduce tu PIN',
        enter: 'Acceder',
        sendPin: 'Solicitar PIN',
        message1: 'Una vez que accedas, podemos darte información detallada sobre una deuda que nos ha sido reportada.',
        message2: 'Podemos darte varios canales para que elijas el que prefieras y te comuniques con nosotros y resolverlo.',
        message3: 'Podremos proponerte diferentes formas de cancelarla hoy mismo o en los próximos días.',
        message4: 'Si tienes dificultades para acceder, escríbenos {mail} o llámanos a {phone}'
    },
    pinUnique: {
        title: 'Autenticación PIN',
        description: 'Ponemos a tu disposición esta web privada para que puedas gestionar tu deuda. ',
        instructions: 'Para asegurarnos que eres tú, introduce tu DNI y pulsa en “Solicitar PIN” para recibir en tu #método de comunicación# el código PIN para acceder y pulsa “Acceder”',
        labelId: 'DNI / NIE / CIF',
        placeholderId: 'Introduce tu DNI / NIE /CIF',
        labelPin: 'PIN',
        placeholderPin: 'Introduce tu PIN',
        phone: 'Teléfono',
        email: 'Correo',
        selectPhone: 'Seleccionar teléfono',
        selectEmail: 'Seleccionar correo',
        errorId: 'La identificación del documento dado no coincide con nuestros datos para esta página de enlace',
        toastMessage: 'Se ha solicitado el pin correctamente',
    },
    support: {
        title: 'Contacta con nosotros',
        subtitle: 'Te ofrecemos muchas formas de comunicarte para que puedas elegir la que más cómoda te resulte.',
        videoconference: 'VideoConference',
        chat: 'Chat',
        start: 'Comenzar',
        writeUs: 'Escríbenos',
        writeUsPlaceholder: 'Escríbenos tus dudas y nos pondremos en contacto lo antes posible',
        send: 'Enviar'
    },
    ui: {
        clickHere: 'click here'
    },
    table: {
        amount: "Cantidad",
        months: "Meses",
        frequency: "Frecuencia",
        status: "Estado",
        paidDeadlines: 'Plazos pagados',
        deadLineDate: 'Fecha limite',
        totalToPay: 'Total a pagar',
        pagination: 'Página %{actualPageKey} de %{totalPagesKey}',
        origin: 'Origen',
        type: 'Tipo'

    },
    paymentFrequency: {
        monthly: 'Mensual',
        single: 'Unico',
    },
    status: {
        approved: 'APROBADO',
        failed: 'FALLIDO',
        closed: 'CERRADO'
    },
    months: {
        1: 'Enero',
        2: 'Febrero',
        3: 'Marzo',
        4: 'Abril',
        5: 'Mayo',
        6: 'Junio',
        7: 'Julio',
        8: 'Agosto',
        9: 'Septiembre',
        10: 'Octubre',
        11: 'Noviembre',
        12: 'Diciembre'
    },
    buttomNavar: {
        debt: 'Tu deuda',
        payment: 'Pago',
        files: 'Archivos',
        contact: 'Contacto',
        faqs: 'Preguntas',
    },
    pinAuthenticationAx: {
        greating: '¡Hola!',
        intro: ' Ponemos en disposición esta web privada para que puedas gestionar tu deuda.',
        description: 'Para asegurarnos que eres tú, introduce tu DNI y pulsa en “Solicitar PIN” para recibir en tu #método de comunicación# el código PIN para acceder',
    },
    debtsAx: {
        paymentsMade: 'Pagos realizados',
        pendingPayments: 'Pagos pendientes',
        greating: 'Hola',
        title1: 'Puesta al día',
        title2: 'en mis pagos',
        welcomeMessage: 'Bienvenido a tu web privada. Desde aquí te ayudaremos a estar al día con tus pagos',
        paymentPlan: 'Actúa sobre toda tu deuda o selecciona las deudas que quieres pagar',
        myProducts: 'Mis productos',
        interveners: 'Intervinientes',
        total: 'Total',
        moreInfo: '+info',
        pay: 'Pagar',
        next: 'Actualizado'
    },
    paymentMethodsAx: {
        paymentPlan: 'Métodos de pago',
        importToPay: 'Importe a pagar',
        selectMethod: 'Selecciona la forma de pago que mejor se adapte a tu situación.',
        singlePayment: 'Pago único',
        partialPayment: 'Pago parcial',
        total: 'Importe total',
        min: 'Importe minimo',
        month: 'meses',
        mes: 'meses',
        amountPerMonth: 'Cantidad por mes',
        continue: 'Continuar',
        enterAmount: 'Introduce cantidad...',
        payment: 'Plan Pagos'
    },
    paymentResumeAx: {
        paymentPlanResume: 'Resumen del plan de pagos',
        description: 'A continuación te mostramos tu plan de pago con los importes y las fechas en las que realizarlos:',
        paymentPlan: 'Plan de pagos',
        pay: 'Pago',
        edit: 'Editar propuesta',
    },
    paymentsMadeAx: {
        paymentsMade: 'Pagos realizados',
        confirmed: 'Confirmados',
    },
    addDocumentAx: {
        attachDocument: 'Adjunta los documentos que sean necesarios',
        selectDocuments: 'Selecciona o arrastra los documentos necesarios para tu expediente.',
        camera: 'Cámara',
        image: 'Imagen',
        document: 'Documento',
        attach: 'Adjuntar',
    },
    callAx: {
        scheduledCalls: 'Llamada agendada',
        callSoon: 'Te llamaremos a la mayor brevedad al ',
        close: 'Cerrar',
        weCallYou: '¡Te llamamos!',
        indicatePhone: 'Indica tu teléfono y te llamaremos en los próximos minutos.',
        enterPhone: 'Introduce tu teléfono',
        callNow: '¡Llámame ahora!',
        scheduleYourCalls: 'O si lo prefieres, agenda tu llamada.',
        enterMail: 'Introduce tu email',
        selectTime: 'Selecciona la hora',
        schedule: 'Agenda la llamada',
        selectDay: 'Selecciona el día',
        apply: 'Aplicar',
    },
    categorizeDocumentAx: {
        title: 'Categoriza el documento adjuntado',
        description: 'A continuación, puedes revisar la categorización del documento que has adjuntado. Por defecto, hemos asignado la categoría que entendemos que más se acerca al documento adjunto. Pero siconsideras que es incorrecto, puedes cambiarlo sin problemas.'
    },
    checkIdKoAx: {
        misinformed: 'Documento mal informado',
        review: 'Por favor, revisa el documento incluido'
    },
    checkPinKo: {
        wrongPin: 'PIN mal informado',
        check: 'Por favor, revisa el PIN incluido',
    },
    confirmPayment: {
        title: 'Confirmación del pago',
        description: 'Puedes ver tus pagos en mi cuenta, te llegará un mail de confirmación a tu correo.',
        ok: 'Vale'
    },
    contactUs: {
        contactUs: 'Contáctanos',
        weCallYou: '¡Te llamamos!',
        schedule: 'Agenda una llamada',
        mail: 'Mail',
        writeMessage: 'Escríbenos un mensaje',
        chatBot: 'Chatea con nuestro bot',
        contactUsPlease: 'Disculpe las molestias, por favor póngase en contacto con #teléfono',
        contactYou: 'Nos pondremos en contacto contigo a la mayor brevedad en el mail #mail.',
        close: 'Cerrar',
        dude: '¿Tienes alguna duda?',
        document: 'Documentos',
        checkDocument: 'Revisa los documentos adjuntados y añade aquellos que creas necesarios.',
        pay: 'Pagar',
        attach: 'Adjuntar documentos',
    },
    interveners: {
        debtIntervener: 'Intervinientes en tu deuda',
        people: 'A continuación, puedes visualizar el resto de personas involucradas en tu deuda %{keyDate}.',
        interveners: 'Intervinientes'
    },
    legalTexts: {
        title: 'Textos legales',
        legalWarning: 'Aviso Legal',
        policy: 'Política de privacidad',
        cookies: 'Política de cookies',
    },
    files: {
        myFiles: 'Mis Documentos',
        payRoll: 'Nómina',
        landingSent: 'Landing enviada',
        to: 'para',
        yourNotification: 'Tus notificaciones',
        reminder: 'Recordatorio',
        pending: 'Pendientes',
        accepted: 'Métodos de pago aceptados',
        noData: 'Disculpe las molestias, no existen datos en nuestros sistemas',
        account: 'Mi cuenta',
        myPayments: 'Mis pagos realizados',
        pendingPayment: 'Mis pagos pendientes',
        help: 'Ayuda',
        calls: 'Llamadas agendadas',
        getOut: 'Salir de la cuenta',
        myCalls: 'Mis llamadas agendadas',
        sentEmail: 'Envíanos un email',
        email: 'Indica tu mail y el mensaje que deseas enviarnos y te contestaremos lo antes posible.',
        canHelp: 'Indícanos en qué podemos ayudarte',
    },
}
