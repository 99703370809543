
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import SpIcon from "@/components/ui/SpIcon.vue";
import SpButton from "@/components/ui/SpButton.vue";
import router from "@/router";
import SpButtonIcon from "@/components/ui/SpButtonIcon.vue";
import SpSelect from "@/components/ui/SpSelect.vue";

export default {
  name: "AddDocuments",
  components: { SpIcon, SpButton, SpButtonIcon, SpSelect },
  props: {
    uuid: { type: String, required: true },
  },
  setup(props: any) {
    const store = useStore();

    interface AddDocuments {
      files: Array<any>;
      loadingUpload: boolean;
      types: any
      tag: any
    }

    const data = reactive<AddDocuments>({
      files: [],
      loadingUpload: false,
      types: [],
      tag: ''
    });

    function changeTag (tag: any) {
      data.tag = tag
    }

    function getDocumentTypes () {
      const params = {
        uuid: store.getters['user/getUUID'].includes('/api/landing_pages/') ? store.getters['user/getUUID']: '/api/landing_pages/' + store.getters['user/getUUID'],
        callbackSuccess: (callbackSuccessData: any) => {
          const result: Array<any> = []
          callbackSuccessData.data.documentTypes.forEach((e: any) => {
            result.push({
              label: e.name,
              value: e.id
            })
          });
          data.types = result
        },
        callbackError: () => {
        }
      }
      store.dispatch('modules/getDocumentTypes', params)
    }
    const modules = computed(()=> {
        return store.getters["modules/getModules"]
    })
    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });

    function goTo(value: string) {
      router.push({ name: value });
    }

    function dragFile(e: any) {
      data.files = e.dataTransfer.files;
      // postUploadFile();
    }

    function uploadFile (e: any) {
      data.files = e.target.files
      // postUploadFile()
      // e.target.value = ''
    }

    function onClickUpload() {
      let fileUpload = document.getElementById('upload-file')
      if (fileUpload !== null)  {
        fileUpload.click()
      }
    }

    function postUploadFile() {
      data.loadingUpload = true;
      const params = {
        uuid: store.getters["user/getUUID"].replace('/api/landing_pages/', ''),
        files: data.files,
        tag: data.tag,
        callbackSuccess: (callbackSuccessData: any) => {
          store.commit("modules/setFileForCategorice", callbackSuccessData.data['hydra:member'][0]['@id'])
          data.files = [];
          store.commit("user/pushNotification", {
            id: Date.now(),
            icon: "upload-cloud",
            message: "notifications.filesSuccess1",
            color: "success",
          });
          goTo('MyFiles')
          data.loadingUpload = false;
        },
        callbackError: () => {
          data.loadingUpload = false;
        },
      };
      store.dispatch("modules/postUploadDocument", params);
    }

    const mobileColors = computed(() => {
      return store.getters["user/getMobileColors"];
    });

    getDocumentTypes()

    return {
      ...toRefs(data),
      goTo,
      dragFile,
      onClickUpload,
      uploadFile,
      postUploadFile,
      getDocumentTypes,
      changeTag,
      modules,
      language,
      mobileColors
    };
  },
};
