
import {computed, defineComponent, reactive, toRefs} from 'vue'
import {useStore} from "vuex";
import SpTitle from "@/components/ui/SpTitle.vue";

export default defineComponent({
  name: "FileInterveners",
  components: { SpTitle },
  props: {
    items: { type: Object, required: true },
    uxMode: { type: String, required: true }
  },
  
  setup (props) {
    const store = useStore()
    
    interface fileInterveners{
      enabledFullName: boolean
      enabledDocumentUniqueId: boolean
      position: boolean,
      positionType: boolean
    }

    const data = reactive<fileInterveners>({
      enabledFullName: props.items?.columns?.fullName?.show,
      enabledDocumentUniqueId: props.items?.columns?.documentUniqueId?.show,
      position: props.items?.columns?.position?.show,
      positionType: props.items?.columns?.positionType?.show,
    })

    const isMobile = computed(() => {
      return store.getters['user/getIsMobile']
    })

    const modules = computed(()=> {
        return store.getters["modules/getModules"]
    })

    const debtorData = computed(()=> {
      return store.getters['modules/getDebtModule']
    })

    const fileInfo = computed(() => {
      return store.getters['modules/getSelectedFileData']
    })
    const language = computed(() => {
      return store.getters["user/getLanguage"]
    });


    return {
      ...toRefs(data),
      isMobile,
      fileInfo,
      debtorData,
      language,
      modules
    }
  }
})
