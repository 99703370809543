
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import SpIcon from "@/components/ui/SpIcon.vue";
import SpButtonIcon from "@/components/ui/SpButtonIcon.vue";
import router from "@/router";

export default {
  name: "ContactUs",
  components: { SpIcon, SpButtonIcon },
  props: {
    uuid: { type: String, required: true },
    videoconferenceUrl: { type: String, required: true, default: '' },
    chatUrlEn: { type: String, required: true, default: '' },
    chatUrlEs: { type: String, required: true, default: '' },
    chatUrlRu: { type: String, required: true, default: '' },
  },
  setup(props: any) {
    const store = useStore();

    interface ContactUs {
      languages: Array<any>;
      phone: string;
      config: {
        videoconferenceUrl: string,
        chatUrlEn: string,
        chatUrlEs: string,
        chatUrlRu: string
      }
    }

    const data = reactive<ContactUs>({
      languages: [],
      phone: '',
      config: {
        videoconferenceUrl: props.videoconferenceUrl,
        chatUrlEn: props.chatUrlEn,
        chatUrlEs: props.chatUrlEs,
        chatUrlRu: props.chatUrlRu
      }
    });

    const languageComputed = computed(() => data.languages);
    const loading = computed(() => {
      return store.getters["modules/getLoading"];
    });
    const supportVideoUrl = computed(()=> store.getters["modules/getSupport"].form.videoconferenceUrl)
    const support = computed(()=> {
        return store.getters["modules/getModules"].support
      })

    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });

    const modules = computed(()=> {
        return store.getters["modules/getModules"]
    })

    function goTo(value: string) {
      router.push({ name: value });
    }

    function openVideoconference () {
      window.open(supportVideoUrl.value, '_blank')
    }

    function getLandingConfiguration() {
      const params = {
        uuid: props.uuid,
        callbackSuccess: (callbackSuccessData: any) => {
          data.languages =
            callbackSuccessData.data.modules.general.extraLanguages;
        },
        callbackError: (callbackErrorData: any) => {},
      };
      store.dispatch("modules/getLandingConfiguration", params);
    }

    const mobileColors = computed(() => {
      return store.getters["user/getMobileColors"];
    });

    getLandingConfiguration();

    return {
      ...toRefs(data),
      languageComputed,
      goTo,
      openVideoconference,
      supportVideoUrl,
      support,
      modules,
      language,
      loading,
      mobileColors
      
    };
  },
};
