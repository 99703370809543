<template>
  <div class="mt-4 p-4">
    <div v-if="(debtAmount > 0 && customApi) || !customApi" class="rounded bg-gray-100 p-4">
      <p class="font-semibold">{{ title }}</p>
      <div class="flex w-full" v-if="loading">
        <sp-icon model="refresh-cw" rotate />
      </div>
      <form name="from" :action="tpvInfo.redsysUrl" method="POST" v-else>
        <input type="hidden" name="Ds_SignatureVersion" :value="Ds_SignatureVersion" />
        <input type="hidden" name="Ds_MerchantParameters" :value="Ds_MerchantParameters" />
        <input type="hidden" name="Ds_Signature" :value="Ds_Signature" />
        <input
          type="submit"
          :value="text"
          class="rounded btn-primary md cursor-pointer"
          :style="'background: ' + primaryColor + '; border-color: ' + primaryColor + ';'"
        />
      </form>
    </div>
    <div v-else class="rounded bg-gray-100 p-4">
      <p class="font-semibold">{{ $t('general.needPaymentPlan') }}</p>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  inject,
  toRefs,
  watch,
} from "vue";
import { useStore } from "vuex";
import SpButton from "@/components/ui/SpButton.vue";
import SpModal from "@/components/ui/SpModal.vue";
import SpIcon from "@/components/ui/SpIcon.vue";
import * as Utils from "@/api/Utils";

export default defineComponent({
  name: "Bizum",
  components: { SpIcon, SpModal, SpButton },
  props: {
    uuid: { type: String, required: true, default: "" },
    debtAmount: { type: Number, required: true, default: 0 },
    text: { type: String, required: true },
    title: { type: String, required: true },
  },
  setup(props, { emit }) {
    const cryoptojs = inject("cryptojs");
    const store = useStore();

    const primaryColor = computed(() => {
      return store.getters["user/getPrimaryColor"];
    });

    const data = reactive({
      loading: false,
      Ds_SignatureVersion: "HMAC_SHA256_V1",
      Ds_MerchantParameters: "",
      Ds_Signature: "",
      transaction: {
        quantity: 10,
        tpvId: "",
        pageType: "c",
      },
      amount: props.debtAmount,
      tpvInfo: {
        fucCode: "999008881",
        currency: "978",
        transaction_type: "0",
        terminal: "001",
        secret: "sq7HjrUOBfKmC576ILgskD5srU870gJ7",
        orderId: "",
        redsysUrl: "",
      },
      seePageButton: true,
      mParams: {
        DS_MERCHANT_ORDER: "",
        DS_MERCHANT_MERCHANTCODE: "",
        DS_MERCHANT_TERMINAL: "",
        DS_MERCHANT_CURRENCY: "",
        DS_MERCHANT_TRANSACTIONTYPE: "",
        DS_MERCHANT_AMOUNT: Math.ceil(props.debtAmount * 100).toString(),
        DS_MERCHANT_MERCHANTURL: "",
        DS_MERCHANT_URLKO:
          process.env.VUE_APP_REDSYS_URL +
          store.getters["modules/getTemplateId"] +
          "/" +
          store.getters["modules/getElementId"] +
          "/" +
          props.uuid +
          "/KO",
        DS_MERCHANT_URLOK:
          process.env.VUE_APP_REDSYS_URL +
          store.getters["modules/getTemplateId"] +
          "/" +
          store.getters["modules/getElementId"] +
          "/" +
          props.uuid +
          "/OK",
        DS_MERCHANT_PAYMETHODS: "z",
        DS_MERCHANT_CONSUMERLANGUAGE: 2,
      },
      variablesForVerifyPayment: {
        deadLineId: "",
        fileId: "",
      },
    });

    const changeVariables = computed(() => {
      return {
        orderId: data.tpvInfo.orderId,
        language: store.getters["user/getLanguage"],
      };
    });

    watch(
      () => changeVariables.value,
      () => {
        (data.mParams.DS_MERCHANT_ORDER = data.tpvInfo.orderId),
          (data.mParams.DS_MERCHANT_MERCHANTCODE = data.tpvInfo.fucCode),
          (data.mParams.DS_MERCHANT_TERMINAL = data.tpvInfo.terminal),
          (data.mParams.DS_MERCHANT_CURRENCY = data.tpvInfo.currency),
          (data.mParams.DS_MERCHANT_TRANSACTIONTYPE =
            data.tpvInfo.transaction_type),
          (data.mParams.DS_MERCHANT_AMOUNT = Math.ceil(
            props.debtAmount * 100
          ).toString()),
          (data.mParams.DS_MERCHANT_MERCHANTURL =
            process.env.VUE_APP_BASE_URL +
            `/api/landing_pages/${props.uuid}/verify?fileId=${data.variablesForVerifyPayment.fileId}&deadLineId=${data.variablesForVerifyPayment.deadLineId}`),
          (data.mParams.DS_MERCHANT_URLKO =
            process.env.VUE_APP_REDSYS_URL +
            store.getters["modules/getTemplateId"] +
            "/" +
            store.getters["modules/getElementId"] +
            "/" +
            props.uuid +
            "/KO"),
          (data.mParams.DS_MERCHANT_URLOK =
            process.env.VUE_APP_REDSYS_URL +
            store.getters["modules/getTemplateId"] +
            "/" +
            store.getters["modules/getElementId"] +
            "/" +
            props.uuid +
            "/OK"),
          (data.mParams.DS_MERCHANT_PAYMETHODS = "z");
        data.mParams.DS_MERCHANT_CONSUMERLANGUAGE = language.value;

        let merchant = cryoptojs.enc.Utf8.parse(JSON.stringify(data.mParams));
        data.Ds_MerchantParameters = merchant.toString(cryoptojs.enc.Base64);
        let keyBase64 = cryoptojs.enc.Base64.parse(data.tpvInfo.secret);

        let iv = cryoptojs.enc.Hex.parse("0000000000000000");
        let cipher = cryoptojs.TripleDES.encrypt(
          data.mParams.DS_MERCHANT_ORDER,
          keyBase64,
          {
            iv: iv,
            mode: cryoptojs.mode.CBC,
            padding: cryoptojs.pad.ZeroPadding, // Redsys dont like padding
          }
        );
        let signature = cryoptojs.HmacSHA256(
          data.Ds_MerchantParameters,
          cipher.ciphertext
        );

        data.Ds_Signature = signature.toString(cryoptojs.enc.Base64);
      }
    );

    function getInfoToPayment() {
      const params = {
        uuid: "/api/landing_pages/" + props.uuid,
        callbackSuccess: (callbackSuccessData) => {
          if (
            callbackSuccessData.data.merchantId &&
            callbackSuccessData.data.merchantId.length > 0 &&
            callbackSuccessData.data.secret &&
            callbackSuccessData.data.secret.length > 0 &&
            callbackSuccessData.data.tpv &&
            callbackSuccessData.data.tpv.length > 0 &&
            callbackSuccessData.data.orderId &&
            callbackSuccessData.data.orderId.length > 0 &&
            callbackSuccessData.data.url &&
            callbackSuccessData.data.url.length > 0
          ) {
            data.tpvInfo.fucCode = callbackSuccessData.data.merchantId;
            data.tpvInfo.secret = callbackSuccessData.data.secret;
            data.tpvInfo.terminal = callbackSuccessData.data.tpv;
            data.tpvInfo.orderId = callbackSuccessData.data.orderId;
            data.tpvInfo.redsysUrl = callbackSuccessData.data.url;
            data.seePageButton = false;
          } else {
            data.seePageButton = true;
          }
          data.loading = false;
        },
        callbackError: () => {
          data.loading = false;
        },
      };
      store.dispatch("modules/getInfoToPayment", params);
    }

    function getPaymentPlanData() {
      data.loading = true;
      const params = {
        uuid: "/api/landing_pages/" + props.uuid,
        fileID: store.getters["modules/getFileUuid"],
        callbackSuccess: (callbackSuccessData) => {
          callbackSuccessData.data.forEach((item) => {
            if (item.status === "approved") {
              data.variablesForVerifyPayment.deadLineId =
                item.paymentPlanDeadline["@id"].replace(
                  "/api/payment_plan_deadlines/",
                  ""
                );
              data.variablesForVerifyPayment.fileId =
                store.getters["modules/getFileUuid"];
            }
          });
          getInfoToPayment();
        },
        callbackError: () => {},
      };
      store.dispatch("modules/getPaymentPlanData", params);
    }

    getPaymentPlanData();

    const customApi = computed(() => {
      return store.getters["user/getCustomApi"];
    });

    const redsysCheckoutUrl = computed(() => {
      return process.env.VUE_APP_REDSYS_CHECKOUT_URL;
    });

    const language = computed(() => {
      return store.getters["user/getLanguage"] === "es" ? 1 : 2;
    });

    return {
      ...toRefs(data),
      cryoptojs,
      Utils,
      primaryColor,
      customApi,
      redsysCheckoutUrl,
    };
  },
});
</script>

<style>
</style> 
