import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "debt-consolidation",
  class: "w-full py-4 px-0 md:px-32 lg:px-40 xl:px-72 sborder-secondary-color"
}
const _hoisted_2 = {
  style: {"border":"3px solid #22c55e"},
  class: "default-box-white-full"
}
const _hoisted_3 = { class: "p-4 flex justify-start items-start flex-col md:flex-row" }
const _hoisted_4 = { class: "flex flex-col text-left w-full" }
const _hoisted_5 = { class: "p-4" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_icon = _resolveComponent("sp-icon")!
  const _component_sp_title = _resolveComponent("sp-title")!
  const _component_sp_button = _resolveComponent("sp-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_sp_icon, {
          model: "message-square",
          size: "80",
          class: "mr-4"
        }),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_sp_title, {
            message: _ctx.$t('general.debtConsolidation1'),
            size: "text-2xl"
          }, null, 8, ["message"]),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('general.debtConsolidation2')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _withDirectives(_createElementVNode("textarea", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.text) = $event)),
          rows: "4",
          class: "rounded border p-2 w-full",
          placeholder: _ctx.$t('general.debtConsolidation3')
        }, null, 8, _hoisted_6), [
          [_vModelText, _ctx.text]
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", {
          class: _normalizeClass(["flex", _ctx.isMobile ? 'justify-center':'justify-end'])
        }, [
          _createVNode(_component_sp_button, {
            onOnClick: _ctx.postSendDebtConsolidationEmail,
            loading: _ctx.loading,
            label: _ctx.$t('general.debtConsolidation4'),
            size: "md"
          }, null, 8, ["onOnClick", "loading", "label"])
        ], 2)
      ])
    ])
  ]))
}