
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import SpIcon from "@/components/ui/SpIcon.vue";
import SpSelect from "@/components/ui/SpSelect.vue";
import SpButtonIcon from "@/components/ui/SpButtonIcon.vue";
import router from "@/router";
import SpAccordion from '@/components/axactorModulesMobile/SpAccordionAx.vue'

export default {
  name: "FAQs",
  components: {
    SpIcon,
    SpButtonIcon,
    SpSelect,
    SpAccordion
  },
  props: {
    uuid: { type: String, required: true },
  },
  setup(props: any) {
    const store = useStore();

    interface FAQs {
      faqs: Array<any>;
    }

    const data = reactive<FAQs>({
      faqs: store.getters["modules/getFaqs"],
    });

    const computedQuestions = computed(() => {
      let result: Array<any> = [];
      store.getters["modules/getFaqs"].forEach(function (item: any) {
        result.push({
          title: item.question,
          description: item.answer,
          show: false,
        });
      });
      
      return result;
    });

    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });

    const modules = computed(() => {
      return store.getters["modules/getModules"];
    });

    function goTo(value: string) {
      router.push({ name: value });
    }

    const mobileColors = computed(() => {
      return store.getters["user/getMobileColors"];
    });
    

    return {
      ...toRefs(data),
      goTo,
      computedQuestions,
      modules,
      language,
      mobileColors
    };
  },
};
