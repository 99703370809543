
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
  onMounted,
} from "vue";
import * as Utils from "@/api/Utils";
import { useStore } from "vuex";

const __default__ = defineComponent({
  name: "SpInputRangeAxactor",
  props: {
    min: { type: Number, required: true, default: 1 },
    max: { type: Number, required: true, default: 100 },
    value: { type: Number, required: true, default: 0 },
    showValue: { type: Boolean, required: false },
    showUnit: { type: Boolean, required: false, default: false },
    unitToShow: { type: String, required: false, default: "" },
    disabled: { type: Boolean, required: false, default: false },
    currency: { type: String, required: false, default: "EUR" },
    quantity: { type: Boolean, required: false, default: false },
    stepFloat: { type: Boolean, required: false, default: false },
    valueChangeProportional: { type: Boolean, required: false, default: false },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const store = useStore();

    interface SpInputRangeAxactor {
      inputMin: number;
      inputMax: number;
      inputValue: number;
      inputValueToShow: number;
      colorToUse: string;
    }

    const data = reactive<SpInputRangeAxactor>({
      inputMin: 0,
      inputMax: 100,
      inputValue: 20,
      inputValueToShow: 20,
      // colorToUse: store.getters["user/getPrimaryColor"],
      colorToUse: store.getters["user/getMobileColors"].primary
    });

    data.inputValue = parseFloat(`${props.value}`)
    data.inputMin = parseFloat(`${props.min}`)
    data.inputMax = parseFloat(`${props.max}`)

    watch(
      () => props.value,
      (currentValue, oldValue) => {
        data.inputValue = parseFloat(`${props.value}`)
        data.inputMin = parseFloat(`${props.min}`)
        data.inputMax = parseFloat(`${props.max}`)
      }
    );

    watch(
      () => props.min,
      (currentValue, oldValue) => {
        data.inputValue = parseFloat(`${props.value}`)
        data.inputMin = parseFloat(`${props.min}`)
        data.inputMax = parseFloat(`${props.max}`)
      }
    );

    watch(() => props.valueChangeProportional, (currentValue, oldValue) => {
      data.inputValue = parseFloat(`${props.value}`)
      data.inputMin = parseFloat(`${props.min}`)
      data.inputMax = parseFloat(`${props.max}`)
    })

    watch(
      () => props.max,
      (currentValue, oldValue) => {
        data.inputValue = parseFloat(`${props.value}`)
        data.inputMin = parseFloat(`${props.min}`)
        data.inputMax = parseFloat(`${props.max}`)
      }
    );

    function change(event: Event) {
      // const target = event.target as HTMLInputElement;
      // const percent =
      //   ((Number(target.value) - Number(target.min)) /
      //     (Number(target.max) - Number(target.min))) *
      //   100;
      // target.style.background = `linear-gradient(to right, black 0%, black ${percent}%, #EDEDED ${percent}%, #EDEDED 100%)`;
      emit("change", props.stepFloat ? Math.ceil(data.inputValue* 100) / 100 : data.inputValue);
    }

    // onMounted(() => {
    //   change({ target: document.getElementById('custom-range' + props.unitToShow) } as Event);
    // });

    const inputValuePercent = computed(() => {
      let auxMax: number = data.inputMax;
      if (data.inputMin !== 0) auxMax = data.inputMax - data.inputMin;
      let result: number = (data.inputValue * 100) / auxMax;
      if (data.inputMin !== 0) result -= 100;
      return result;
    });

    const inputValueToShow = computed(() => {
      return props.quantity
        ? Utils.formatNumberWithCurrency(data.inputValue, props.currency)
        : data.inputValue;
    });

    return {
      ...toRefs(data),
      change,
      inputValuePercent,
      inputValueToShow,
      Utils,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "7546fcce": (_ctx.colorToUse)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__