import * as ModulesActions from '@/store/actions/ModulesActions'

interface modulesStore {
    filesInfo: Array<any>,
    fileUuid: string,
    defaultFileInfo: any,
    paymentPlanDataModule: object,
    amountWithRemoval: number,
    customApi: boolean
    template: string,
    element: string,
    authMode: string,
    uxMode: string,
    selectedFiles: Array<any>,
    amountToPage: number
    relatedInterveners: Array<any>,
    sendMethod: string,
    phonesContacts: any,
    emailsContacts: any,
    support: any
    fileForCategorice: any
    faqs: any
    footer: any,
    productName: any
    debtorInfo: any
    pinPageCustomization: any,
    debtSelector: any,
    card: any,
    totalToPay: any,
    paymentPlansData: any
    paymentType: string
    paymentFrequency: string
    paymentMethodsTitle: any,
    contactData: any,
    debtModule: any,
    general: any,
    modules: any,
    loading: boolean,
    isTemplate: boolean,
    phoneScheduleCall: string
    paymentMonths: number,
    phoneNumberScheduled: any
}

const ModulesStore = {
    namespaced: true,
    state: () => ({
        filesInfo: [],
        fileUuid: '',
        defaultFileInfo: null,
        paymentPlanDataModule: null,
        amountWithRemoval: 0,
        customApi: false,
        template: '',
        element: '',
        authMode: '',
        uxMode: '',
        selectedFiles: [],
        amountToPage: 0,
        relatedInterveners: [],
        sendMethod: '',
        phonesContacts: [],
        emailsContacts: [],
        support: null,
        fileForCategorice: null,
        faqs: [],
        footer: [],
        productName: '',
        debtorInfo: null,
        pinPageCustomization: {},
        debtSelector: {},
        card: {},
        totalToPay: 0,
        paymentPlansData: [],
        paymentType: '',
        paymentFrequency: '',
        paymentMethodsTitle: {},
        contactData: {},
        debtModule: {},
        general: {},
        modules: {},
        loading: false,
        isTemplate: false,
        phoneScheduleCall: '',
        paymentMonths: 1,
        phoneNumberScheduled: ''
    }),
    actions: ModulesActions,
    getters: {
        getTemplateId(state: modulesStore) {
            return state.template;
        },
        getElementId(state: modulesStore) {
            return state.element;
        },
        getFilesInfo(state: modulesStore) {
            return state.filesInfo
        },
        getDefaultFileInfo(state: modulesStore) {
            return state.defaultFileInfo
        },
        getFileUuid(state: modulesStore) {
            return state.fileUuid
        },
        getSelectedFileData(state: modulesStore) {
            if (state.fileUuid === '') return state.defaultFileInfo
            let result: any = null
            state.filesInfo.forEach(function (file: any) {
                if (file.fileUuid === state.fileUuid) result = file
            })
            return result
        },
        getPaymentPlanDataModule(state: modulesStore) {
            return state.paymentPlanDataModule;
        },
        getAmountWithRemoval(state: modulesStore) {
            return state.amountWithRemoval;
        },
        getCustomApi(state: modulesStore) {
            return state.customApi;
        },
        getAuthMode(state: modulesStore) {
            return state.authMode;
        },
        getUxMode(state: modulesStore) {
            return state.uxMode;
        },
        getSelectedFiles (state: modulesStore) {
            return state.selectedFiles
        },
        getAmountToPage(state: modulesStore) {
            return state.amountToPage;
        },
        getRelatedInterveners(state: modulesStore) {
            return state.relatedInterveners;
        },
        getSendMethod(state: modulesStore) {
            return state.sendMethod;
        },
        getPhonesContacts(state: modulesStore) {
            return state.phonesContacts;
        },
        getEmailsContacts(state: modulesStore) {
            return state.emailsContacts;
        },
        getSupport(state: modulesStore) {
            return state.support;
        },
        getFileForCategorice(state: modulesStore) {
            return state.fileForCategorice;
        },
        getFaqs(state: modulesStore) {
            return state.faqs
        },
        getFooter(state: modulesStore) {
            return state.footer
        },
        getProductName(state: modulesStore) {
            return state.productName
        },
        getDebtorInfo(state: modulesStore) {
            return state.debtorInfo
        },
        getPinPageCustomization(state: modulesStore) {
            return state.pinPageCustomization
        },
        getDebtSelector(state: modulesStore) {
            return state.debtSelector
        },
        getCard(state: modulesStore) {
            return state.card
        },
        getTotalToPay(state: modulesStore) {
            return state.totalToPay
        },
        getPaymentPlansData(state: modulesStore) {
            return state.paymentPlansData
        },
        getPaymentType(state: modulesStore) {
            return state.paymentType
        },

        getPaymentFrequency(state: modulesStore) {
            return state.paymentFrequency
        },

        getPaymentMethodsTitle(state: modulesStore) {
            return state.paymentMethodsTitle
        },
        getContactData(state: modulesStore) {
            return state.contactData
        },
        getDebtModule(state: modulesStore) {
            return state.debtModule
        },
        getGeneral(state: modulesStore) {
            return state.general
        },
        getModules(state: modulesStore) {
            return state.modules
        },
        getLoading(state: modulesStore) {
            return state.loading
        },
        getIsTemplate(state: modulesStore) {
            return state.isTemplate
        },
        getPhoneScheduleCall(state: modulesStore) {
            return state.phoneScheduleCall
        },
        getPaymentMonths(state: modulesStore) {
            return state.paymentMonths
        },
        getPhoneNumberScheduled(state: modulesStore) {
            return state.phoneNumberScheduled
        },
    },
    mutations: {
        setTemplateId(state: modulesStore, payload: string) {
            state.template = payload
        },
        setElementId(state: modulesStore, payload: string) {
            state.element = payload;
        },
        setFilesInfo(state: modulesStore, filesInfo: Array<any>) {
            state.filesInfo = filesInfo
        },
        setFileUuid(state: modulesStore, fileUuid: string) {
            state.fileUuid = fileUuid
        },
        setDefaultFileInfo(state: modulesStore, fileInfo: any) {
            state.defaultFileInfo = fileInfo
        },
        setPaymentPlanDataModule(state: modulesStore, payload: any) {
            state.paymentPlanDataModule = payload;
        },
        setAmountWithRemoval(state: modulesStore, payload: any) {
            state.amountWithRemoval = payload;
        },
        setCustomApi(state: modulesStore, payload: any) {
            state.customApi = payload;
        },
        setAuthMode(state: modulesStore, payload: any) {
            state.authMode = payload;
        },
        setUxMode(state: modulesStore, payload: any) {
            state.uxMode = payload;
        },
        setSelectedFiles(state: modulesStore, files: any) {
            state.selectedFiles = files
        },
        setAmountToPage(state: modulesStore, amountToPage: number) {
            state.amountToPage = amountToPage
        },
        setRelatedInterveners(state: modulesStore, relatedInterveners: any) {
            state.relatedInterveners = relatedInterveners
        },
        setSendMethod(state: modulesStore, payload: any) {
            state.sendMethod = payload;
        },
        setPhonesContacts(state: modulesStore, payload: any) {
            state.phonesContacts = payload;
        },
        setEmailsContacts(state: modulesStore, payload: any) {
            state.emailsContacts = payload;
        },
        setSupport(state: modulesStore, payload: any) {
            state.support = payload;
        },
        setFileForCategorice(state: modulesStore, file: string) {
            state.fileForCategorice = file;
        },
        setFaqs(state: modulesStore, faqs: string) {
            state.faqs = faqs;
        },
        setFooter(state: modulesStore, footer: string) {
            state.footer = footer;
        },
        setProductName(state: modulesStore, productName: any) {
            state.productName = productName
        },
        setDebtorInfo(state: modulesStore, debtorInfo: any) {
            state.debtorInfo = debtorInfo
        },
        setPinPageCustomization(state: modulesStore, pinPageCustomization: any) {
            state.pinPageCustomization = pinPageCustomization
        },
        setDebtSelector(state: modulesStore, debtSelector: any) {
            state.debtSelector = debtSelector
        },
        setCard(state: modulesStore, card: any) {
            state.card = card
        },
        setTotalToPay(state: modulesStore, totalToPay: any) {
            state.totalToPay = totalToPay
        },
        setPaymentPlansData(state: modulesStore, paymentPlansData: any) {
            state.paymentPlansData = paymentPlansData
        },
        setPaymentType(state: modulesStore, paymentType: any) {
            state.paymentType = paymentType
        },
        setPaymentFrequency(state: modulesStore, paymentFrequency: any) {
            state.paymentFrequency = paymentFrequency
        },
        setPaymentMonths(state: modulesStore, months: any) {
            state.paymentMonths = months
        },
        setPaymentMethodsTitle(state: modulesStore, paymentMethodsTitle: any) {
            state.paymentMethodsTitle = paymentMethodsTitle
        },
        setContactData(state: modulesStore, contactData: any) {
            state.contactData = contactData
        },
        setDebtModule(state: modulesStore, debtModule: any) {
            state.debtModule = debtModule
        },
        setGeneral(state: modulesStore, general: any) {
            state.general = general
        },
        setModules(state: modulesStore, modules: any) {
            state.modules = modules
        },
        setLoading(state: modulesStore, loading: boolean) {
            state.loading = loading
        },
        setIsTemplate(state: modulesStore, isTemplate: boolean) {
            state.isTemplate = isTemplate
        },
        setPhoneScheduleCall(state: modulesStore, phoneScheduleCall: string) {
            state.phoneScheduleCall = phoneScheduleCall
        },
        setPhoneNumberScheduled(state: modulesStore, phoneNumberScheduled: boolean) {
            state.phoneNumberScheduled = phoneNumberScheduled
        },
    }
}

export default ModulesStore