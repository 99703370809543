import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["top-0 fixed w-screen h-screen flex", _ctx.floatingBackWindowClass])
  }, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeFloatingWindow && _ctx.closeFloatingWindow(...args))),
      class: "transition-all z-10 fadeIn fixed top-0 left-0 w-screen h-screen bg-dark-opacity-75"
    }),
    _createElementVNode("div", {
      class: _normalizeClass(["z-20 transition-all bg-white text-dark p-4 flex flex-col text-left justify-start items-start w-full", _ctx.floatingWindowClass])
    }, [
      _renderSlot(_ctx.$slots, "content")
    ], 2)
  ], 2))
}