
import { defineComponent, computed } from 'vue'
import SpIcon from '@/components/ui/SpIcon.vue'
import {useStore} from "vuex";

export default defineComponent({
  name: "SpButton",
  components: { SpIcon },
  props: {
    label: { type: String, required: true, default: '' },
    sublabel: { type: String, required: false, default: '' },
    color: { type: String, required: false, default: 'primary' },
    size: { type: String, required: false, default: 'md' },
    icon: { type: String, required: false, default: '' },
    iconRight: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    fullRounded: { type: Boolean, required: false, default: true },
    loading: { type: Boolean, required: false, default: false },
    fontSemibold: { type: Boolean, required: false, default: true },
    iconSize: { type: String, required: false, default: '15' }
  },
  emits: ['onClick'],
  setup (props, { emit }) {
    const store = useStore()

    const buttonClass = computed(() => {
      let rounded = props.fullRounded ? '-rounded-' : '-'
      return 'btn-' + props.color + rounded + props.size
    })

    const primaryColor = computed(() => {
      return store.getters['user/getPrimaryColor']
    })

    const buttonStyle = computed(() => {
      if (props.color === 'primary') return {
        background: store.getters['user/getPrimaryColor'],
        'border-color': store.getters['user/getPrimaryColor']
      }

      if (props.color === 'white-primary' || props.color === 'white') return {
        color: store.getters['user/getPrimaryColor']
      }

      return ''
    })

    function onClick() {
      if (!props.disabled) {
        emit('onClick')
      }
    }

    return {
      buttonClass,
      buttonStyle,
      onClick
    }
  }
})
