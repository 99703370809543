
import { defineComponent, reactive, toRefs, watch, computed } from "vue";
import SpB from "@/components/SiritIcon.vue";
import SpIcon from "@/components/ui/SpIcon.vue";
import SpButtonIcon from "@/components/ui/SpButtonIcon.vue";
import SpMobileTable from "@/components/ui/SpMobileTable.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "SpTable",
  components: {
    SpButtonIcon,
    SpMobileTable,
  },
  props: {
    headers: { type: Array, required: false },
    items: { type: Array, required: true },
    loading: { type: Boolean, required: false, default: false },
    loadingPage: { type: Boolean, required: false, default: false },
    pagination: { type: Boolean, required: false, default: false },
    page: { type: Number, required: false, default: 1 },
    itemsPerPage: { type: Number, required: false, default: 6 },
    maxPages: { type: Number, required: false, default: 0 },
    showItemsPerPage: { type: Boolean, required: false, default: true },
    overflow: { type: Boolean, required: false, default: false },
    footer: { type: Array, required: false },
    headerStyle: { type: String, required: false },
    lastrowStyle: { type: String, required: false },
  },
  emits: [
    "getNextPage",
    "getPreviousPage",
    "getFirstPage",
    "getLastPage",
    "enableChange",
  ],
  setup(props, { emit }) {
    const store = useStore();

    interface spTable {
      actualPage: number;
      disablePreviousPage: boolean;
      disableNextPage: boolean;
      loadindTable: boolean;
    }

    const data = reactive<spTable>({
      actualPage: props.page,
      disablePreviousPage: false,
      disableNextPage: false,
      loadindTable: false,
    });

    const overflowFunction = computed(() =>
      props.overflow ? "overflow-y: scroll" : ""
    );
    const isMobile = computed(() => store.getters["user/getIsMobile"]);

    function getNextPage() {
      emit("getNextPage");
    }

    function getPreviousPage() {
      emit("getPreviousPage");
    }

    function getFirstPage() {
      emit("getFirstPage");
    }

    function getLastPage() {
      emit("getLastPage");
    }

    function enableChange(index: number) {
      emit("enableChange", index);
    }

    const disabledNextPage = computed(() => props.maxPages === props.page);

    watch(
      () => props.page,
      (newPage) => {
        data.actualPage = newPage;
        data.disablePreviousPage = data.actualPage === 1;
      }
    );

    if (data.actualPage === 1) {
      data.disablePreviousPage = true;
    }

    return {
      ...toRefs(data),
      getNextPage,
      getPreviousPage,
      getFirstPage,
      getLastPage,
      overflowFunction,
      disabledNextPage,
      enableChange,
      isMobile,
    };
  },
});
