<template>
  <div class="mt-4 p-4">
    <div v-if="(debtAmount > 0 && customApi) || !customApi" class="rounded bg-gray-100 p-4">
      <p class="font-semibold">{{ $t("general.creditCard") }}</p>
      <button id="checkout-button" class="rounded btn-primary md pointer cursor-pointer" :style="'background: ' + primaryColor + '; border-color: ' + primaryColor + ';'">Pay with Card</button>
    </div>
    <div v-else class="rounded bg-gray-100 p-4">
      <p class="font-semibold">{{ $t('general.needPaymentPlan') }}</p>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, inject, toRefs, watch, onMounted } from "vue";
import { useStore } from "vuex";
import SpButton from "@/components/ui/SpButton.vue";
import SpModal from "@/components/ui/SpModal.vue";
import SpIcon from "@/components/ui/SpIcon.vue";

export default defineComponent({
  name: "CardStripe",
  components: { SpIcon, SpModal, SpButton},
  props: {
    uuid: { type: String, required: true, default: "" },
    debtAmount: { type: Number, required: true, default: 0 },
  },
  setup(props, { emit }) {
    const store = useStore()

    const data = reactive({
      deadLineId: '',
      seePageButton: true,
      checkoutSessionId: '',
    });

    onMounted(() => {
      const stripe = Stripe(process.env.VUE_APP_SIRIT_STRIPE_CHECKOUT_KEY);
      const checkoutButton = document.getElementById('checkout-button');
      
      if (checkoutButton) {
        checkoutButton.addEventListener('click', () => {
          stripe.redirectToCheckout({
            sessionId: data.checkoutSessionId
          })
        });
      } 
    })

    const stripeKey = computed(() => {
      return store.getters["user/getStripePublicKey"];
    });

    const hey = computed(() => data.seePageButton)
    
    watch(() => store.getters['user/getPaymentAmount'], () => {
      getPaymentPlanData()
    })

    watch(() => store.getters['modules/getFileUuid'], () => {
      getPaymentPlanData()
    })

    function getPaymentPlanData() {
      const params = {
        uuid: '/api/landing_pages/' + props.uuid,
        fileID: store.getters['modules/getFileUuid'],
        callbackSuccess: (callbackSuccessData) => {
          callbackSuccessData.data.forEach((item)  => {
            if(item.status === "approved") {
              data.deadLineId = item.paymentPlanDeadline['@id']
            }
          })
          postCheckoutSession()
        },
        callbackError: () => {},
      };
      store.dispatch("modules/getPaymentPlanData", params);
    }

    function postCheckoutSession() {
      let params = {}
      if (data.deadLineId.length > 0) {
        params = {
          uuid: props.uuid,
          paymentPlanDeadline: data.deadLineId,
          callbackSuccess: (callbackSuccessData) => {
            data.checkoutSessionId = callbackSuccessData.data.checkoutSessionId
            // data.deadLineId.length === 0 ? data.seePageButton = false : ''
          },
          callbackError: () => {
            data.seePageButton = false
          },
        };
      } else {
        params = {
          uuid: props.uuid,
          amount: props.debtAmount,
          file: '/api/files/' + store.getters['modules/getFileUuid'],
          callbackSuccess: (callbackSuccessData) => {
            data.checkoutSessionId = callbackSuccessData.data.checkoutSessionId
            // data.deadLineId.length === 0 ? data.seePageButton = false : ''
          },
          callbackError: () => {
            data.seePageButton = false
          },
        };
      }
      store.dispatch("modules/postCheckoutSession", params);
    }

    watch(() => props.debtAmount, (first, second) => {
      getPaymentPlanData()
    })

    // ?state=ok&deadline=dc2aea0c-2bce-425e-8cb1-8cda0730cad6&landing=1ee0a89c-16e1-6bfe-b120-b52a1867249f
      
    getPaymentPlanData()
    

    const primaryColor = computed(() => {
      return store.getters['user/getPrimaryColor']
    })

    const customApi = computed(() => {
      return store.getters["user/getCustomApi"];
    })


    return {
      primaryColor,
      hey,
      customApi,
      stripeKey
    };
  }
})
</script>

<style>
</style> 