
import {computed, defineComponent} from 'vue'
import {useStore} from 'vuex'
import SpTitle from "@/components/ui/SpTitle.vue";

export default defineComponent({
  name: "WelcomeTitle",
  components: { SpTitle },
  props: {
    url: { type: String, required: true, default: '' },
    title: { type: Object, required: true },
    titleColor: { type: String, required: false, default: '#22c55e' },
    titleSize: { type: String, required: false, default: '6xl' },
    description: { type: Object, required: true },
    descriptionColor: { type: String, required: false, default: '#fff' },
    descriptionSize: { type: String, required: false, default: '2xl' },
    showAvatar: { type: Boolean, required: true, default: true },
    showTitle: { type: Boolean, required: true, default: true },
    showDescription: { type: Boolean, required: true, default: true },
  },
  setup (props) {
    const store = useStore()

    const isMobile = computed(() => {
      return store.getters['user/getIsMobile']
    })

    const selectedLanguage = computed(() => {
      return store.getters['user/getLanguage']
    })

    const primaryColor = computed(() => {
      return store.getters['user/getPrimaryColor']
    })

    const avatarClass = computed(() => {
      if (!props.showAvatar) return "display-none";
      if (!props.showTitle && !props.showDescription) {
        return "w-full flex justify-center";
      } else {
        return "w-350 flex justify-center md:mr-4 md:w-[624px]";
      }
    })

    const computedDescriptionSize = computed(() => {
      if (props.descriptionSize === null || props.descriptionSize.length === 0) return 'text-2xl'
      return 'text-' + props.descriptionSize
    })

    const computedTitleSize = computed(() => {
      if (props.titleSize === null || props.titleSize.length === 0) return 'text-6xl'
      return 'text-' + props.titleSize
    })

    const descriptionToShow = computed(() => {
      if (store.getters['user/getLanguage'] === 'en') return props.description.en
      if (store.getters['user/getLanguage'] === 'es') return props.description.es
      if (store.getters['user/getLanguage'] === 'ru') return props.description.ru
      return ''
    })

    const titleDescriptionClass = computed(() => {
      let result: string = ''

      if (!props.showAvatar) {
        result += isMobile.value ? 'w-full text-center p-4' : 'w-full pt-4 text-left'
      } else {
        result += "w-full text-center mt-4 flex flex-wrap md:text-left";
      }

      return result
    })

    const titleToShow = computed(() => {
      if (store.getters['user/getLanguage'] === 'en') return props.title.en
      if (store.getters['user/getLanguage'] === 'es') return props.title.es
      if (store.getters['user/getLanguage'] === 'ru') return props.title.ru
      return ''
    })

    return {
      isMobile,
      avatarClass,
      computedDescriptionSize,
      computedTitleSize,
      descriptionToShow,
      primaryColor,
      selectedLanguage,
      titleDescriptionClass,
      titleToShow
    }
  }
})
