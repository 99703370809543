import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70fd0c66"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "font-semibold text-xs mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_icon = _resolveComponent("sp-icon")!

  return (_ctx.isMobile)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "bottom-navigation-bar",
        style: _normalizeStyle('background :' + _ctx.primaryColor + ';')
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeButtons, (activeButton, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: activeButton.value,
            onClick: ($event: any) => (_ctx.goTo(activeButton.value)),
            style: _normalizeStyle(_ctx.getButtonStyle(index, activeButton.value)),
            class: "bottom-navigation-bar-button"
          }, [
            _createVNode(_component_sp_icon, {
              color: activeButton.value === _ctx.currentSection ? '#EC0000': '#FFFFFF',
              model: activeButton.icon
            }, null, 8, ["color", "model"]),
            _createElementVNode("span", _hoisted_2, _toDisplayString(activeButton.label), 1)
          ], 12, _hoisted_1))
        }), 128))
      ], 4))
    : _createCommentVNode("", true)
}