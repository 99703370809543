
import {computed, defineComponent, reactive, toRefs, watch} from 'vue'
import {useStore} from "vuex";
import moment from "moment";
import SpIcon from "@/components/ui/SpIcon.vue";

export default defineComponent({
  name: "SpCalendar",
  components: { SpIcon },
  props: {
    disableDays: { type: Number, default: 0 },
    value: { type: String, default: '', required: true },
    label: {type: String, required: false, default: ''},
    size: {type: String, required: false, default: 'md'},
    placeholder: {type: String, required: false, default: ''},
    disabled: {type: Boolean, required: false, default: false},
    titleGray: { type: Boolean, default: false }
  },
  emits: ['selectDate', 'dateForCall'],
  setup (props, {emit}) {
    const store = useStore();
    interface spCalendar {
      colorToUse: string,
      calendar: Array<any>,
      month: number,
      year: number,
      showContent: boolean,
    }

    const data = reactive<spCalendar>({
      colorToUse: store.getters['user/getPrimaryColor'],
      calendar: [],
      month: moment().month(),
      year: moment().year(),
      showContent: false
    })
    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });
    watch(()=> language, ()=> {

    })
    const days = computed(()=> {
      switch (language.value) {
        case 'es': return ['L','M','X','J','V','S','D'];
        case 'en': return ['M','T','W','T','F','S','S'];
        case 'ru': return ['M','T','W','T','F','S','S'];
        default: return ['M','T','W','T','F','S','S'];
      }
    })

    const selectDay = computed(()=> {
      const hey = moment(new Date(props.value)).format("DD/MM/YYYY")
      return moment(hey).date()
    })

    function createCalendar() {
      const startDay = moment({month: data.month, year: data.year}).clone().startOf('month').startOf('week');
      const endDay = moment({month: data.month, year: data.year}).clone().endOf('month').endOf('week');

      const index = startDay.clone();
      data.calendar = [];
      while (index.isBefore(endDay, 'day')) {
        data.calendar.push(
            new Array(7).fill(0).map(
                function(n, i) {
                  return {date: index.add(1, 'day').toArray()};
                }
            )
        );
      }
    }
    
    function isDisableDate (date: any) {
      if (props.disableDays >= 0) {
        const firstDate = moment({month: moment().month(), date: moment().date(),year: moment().year()}).format("YYYY-MM-DD")
        const lastDate = moment(firstDate).add(props.disableDays,"day").format("YYYY-MM-DD");
        const validateDate = moment({month: data.month, date: date[2], year: data.year}).format("YYYY-MM-DD")
        return validateDate > lastDate || validateDate < firstDate
      }
    }

    function itIsMonth(month: any) {
      const actualMonth = data.month;
      return month !== actualMonth
    }

    function selectDate(date: any) {
      if (!isDisableDate(date)) {
        showCalendar()
        emit("selectDate", `${date[2]}/${date[1] + 1}/${date[0]}`)
        emit('dateForCall', `${date[0]}-${date[1] + 1}-${date[2]}`)
      }
    }
    function nextMonth() {
      if (data.month === 11) {
        data.month = -1;
        data.year++;
      }
      data.month++;

      createCalendar()
    }

    function prevMonth() {

      if (data.month === 0) {
        data.month = 12;
        data.year--;
      }
      data.month--;
      createCalendar()
    }

    function showCalendar() {
      data.showContent = !data.showContent;
    }

    const siritSelectClass = computed(() => {
      return 'sirit-select-' + props.size
    })

    createCalendar();

    return {
      ...toRefs(data),
      days,
      showCalendar,
      isDisableDate,
      itIsMonth,
      selectDate,
      nextMonth,
      prevMonth,
      siritSelectClass,
      selectDay
    }
  }
})
