<template>
  <div
    id="paymeny-plan-proposal"
    class="default-box-transparent text-left py-4 Fw-full px-0 md:px-32 lg:px-40 xl:px-72"
  >
    <sp-modal v-if="isModalPaymentActive" :size="isMobile ? 'full' : 'md'">
      <template v-slot:modalContent>
        <div class="payment-confirm-container">
          <section class="button-container">
            <sp-button-icon
              @onClick="isModalPaymentActive = false"
              icon="close"
              color="clean-transparent-black"
            />
          </section>
          <section class="title-container text-center">
            <sp-title
              :message="
                paymentPlanData.confirmationPreview.titleConfirm.text[language]
              "
              :color="paymentPlanData.confirmationPreview.titleConfirm.color"
              :size="'text-2xl'"
            ></sp-title>
          </section>
          <section class="content-container">
            <span
              color="paymentPlanData.confirmationPreview.confirmTextContent.color"
              >{{
                paymentPlanData.confirmationPreview.confirmTextContent[language]
              }}</span
            >
          </section>
          <section class="table-container">
            <sp-table
              :items="tableItems"
              :items-per-page="itemsPerPage"
              :pagination="true"
              :show-items-per-page="false"
              :max-pages="maxNumberOfPages"
              :headers="tableConfirmHeaders"
              :page="page"
              class="border-collapse sirit-table w-full"
              @getNextPage="getPage('next')"
              @getPreviousPage="getPage('previous')"
              @getFirstPage="getPage('first')"
              @getLastPage="getPage('last')"
            >
              <template v-slot="row">
                <td>{{ row.item.deadlineDate }}</td>
                <td>{{ row.item.number }}</td>
                <td>{{ Utils.formatNumberWithCurrency(row.item.amount) }}</td>
              </template>
            </sp-table>
          </section>
          <section v-show="selectEnable" class="flex flex-col items-center">
            <span class="mb-2">{{
              paymentPlanData.confirmationPreview.selectConfirmText[language]
            }}</span>
            <sp-select
              :fixedItems="true"
              :size="'xl'"
              :items="selectConfirmOptions"
              :value="selectedOption"
              @selectedValue="changeSelectedOption"
              :placeholder="
                paymentPlanData.confirmationPreview.originPlaceholder[language]
              "
              class="mb-3"
            ></sp-select>
          </section>
          <section class="buttons-container">
            <sp-button
              :label="
                paymentPlanData.confirmationPreview.paymentConfirmButtons
                  .button1.text[language]
              "
              :style="
                ' border: none; margin-bottom: .3em; background-color:' +
                paymentPlanData.confirmationPreview.paymentConfirmButtons
                  .button1.color
              "
              :size="'md'"
              @click="editProposal"
            ></sp-button>
            <sp-button
              :label="
                paymentPlanData.confirmationPreview.paymentConfirmButtons
                  .button2.text[language]
              "
              :style="
                ' border: none;background-color:' +
                paymentPlanData.confirmationPreview.paymentConfirmButtons
                  .button2.color
              "
              :size="'md'"
              @click="sendConfirm"
            ></sp-button>
          </section>
        </div>
      </template>
    </sp-modal>

    <sp-modal v-if="chooseFilesModal" :size="isMobile ? 'full' : 'md'">
      <template v-slot:modalContent>
        <div class="payment-confirm-container">
          <section class="button-container">
            <sp-button-icon
              @onClick="chooseFilesModal = false"
              icon="close"
              color="clean-transparent-black"
            />
          </section>
          <section class="title-container text-center">
            <sp-title
              :message="'Payment Management and Payment Plans'"
              :color="paymentPlanData.confirmationPreview.titleConfirm.color"
              :size="'text-2xl'"
            ></sp-title>
          </section>
          <section class="table-container">
            <sp-table
              :items="files"
              :items-per-page="itemsPerPage"
              :pagination="false"
              :show-items-per-page="false"
              :max-pages="maxNumberOfPages"
              :headers="headersFiles"
              :page="page"
              class="border-collapse sirit-table w-full"
              @getNextPage="getPage('next')"
              @getPreviousPage="getPage('previous')"
              @getFirstPage="getPage('first')"
              @getLastPage="getPage('last')"
            >
              <template v-slot="props">
                <td>
                  <input v-model="props.item.check" type="checkbox" />
                </td>
                <td>{{title( props.item)  }}</td>
                <td>
                  {{
                    Utils.formatNumberWithCurrency(
                      props.item.pendingDebt,
                      currency
                    )
                  }}
                </td>
                <td>
                  {{
                    Utils.formatNumberWithCurrency(
                      props.item.totalDebt,
                      currency
                    )
                  }}
                </td>
              </template>
            </sp-table>
          </section>
          <section class="flex p-4 justify-end items-center">
            <span class="mr-2 text-lg">Total amount of debts:</span>
            <span class="text-red-500">{{
              Utils.formatNumberWithCurrency(totalAmountToPageInTable, currency)
            }}</span>
          </section>
          <section v-if="activePayment === 'custom'">
            <div class="p-4 w-full">
              <div v-if="selectedCheck" class="w-full">
                <p>
                  {{ card.plan.configuration.monthsPeriod.title[language] }}
                </p>
                <sp-input-range
                  @change="monthsToPayChange"
                  show-value
                  :value="months"
                  :min="minMonths"
                  :max="maxMonthsToPay"
                  show-unit
                  :unit-to-show="
                    card.plan.configuration.sliderText.title[language]
                  "
                />
                <p>{{ card.plan.configuration.amountToPay.title[language] }}</p>
                <sp-input-range
                  @change="feeToPayChange"
                  :value="quantity"
                  :min="minFeeToPay"
                  :max="maxFeeToPay"
                  show-unit
                  unit-to-show="€"
                  show-value
                  disabled
                  :currency="currency"
                  :quantity="true"
                  :stepFloat="true"
                  :valueChangeProportional="valueChangeProportional"
                />
              </div>
              <div
                v-if="paymentPlanData.frequency.enabled && selectedCheck"
                class="flex justify-between items-center flex-wrap mt-4"
              >
                <p>{{ paymentPlanData.frequency.text[language] }}</p>
                <sp-calendar
                  :disable-days="planLimitDays"
                  :value="preferredDueDay"
                  @select-date="preferredDueDayFunction"
                  @dateForCall="chageDateforCall"
                ></sp-calendar>
              </div>
            </div>
          </section>
          <section
            v-if="activePayment === 'partial'"
            class="flex justify-center items-center"
          >
            <div
              class="flex p-4 justify-center items start w-full flex-col md:flex-row"
            >
              <div
                class="flex flex-col mb-2 text-center md:text-left"
                style="width: 200px"
              >
                <span
                  class="font-semibold"
                  :style="'color:' + modules.paymentPlan.totalDebt.color"
                  >{{
                    modules.paymentPlan.totalDebt.label
                      ? modules.paymentPlan.totalDebt.label[language]
                      : "" +
                        " " +
                        Utils.formatNumberWithCurrency(getAmountToPay, currency)
                  }}</span
                >
                <span v-if="getAmountToPay > 0" class="text-xs text-gray-400"
                  >{{
                    modules?.paymentPlan?.card?.partial?.minAmountText[
                      language
                    ]
                  }}:
                  {{
                    Utils.formatNumberWithCurrency(minPartialAmount, currency)
                  }}</span
                >
                <span v-else class="text-xs text-gray-400"
                  >Min: {{ Utils.formatNumberWithCurrency(0, currency) }}</span
                >
              </div>
              <div class="flex justify-start items-start w-full pl-3">
                <input
                  v-model="quantityPartial"
                  @keypress="
                    () => {
                      Utils.isNumber($event);
                    }
                  "
                  type="text"
                  class="rounded text-dark w-full text-right"
                  style="border: 1px solid black; max-width: 180px"
                  :placeholder="'0'"
                />
              </div>
            </div>
            <sp-button
              @onClick="onClickHandler"
              id="checkout-button"
              :label="button[language]"
              size="auto"
              :loading="loading"
              class="md:mt-0 mt-3"
              :style="`
            background-color: ${buttonBackgroundColor};
            color: ${buttonTextColor};
            border: none;
          `"
            />
          </section>
          <section v-if="activePayment !== 'partial'">
            <sp-button
              @onClick="onClickHandler"
              id="checkout-button"
              :label="button[language]"
              size="auto"
              :loading="loading"
              :style="`
            background-color: ${buttonBackgroundColor};
            color: ${buttonTextColor};
            border: none;
          `"
            />
          </section>
        </div>
      </template>
    </sp-modal>

    <div class="py-4 px-5 md:px-0">
      <sp-title :message="title[language]" size="text-4xl" />
      <span class="text-xl text-gray-600">{{ subtitleComputed }}</span>
    </div>

    <div
      v-if="
        !initialLoading.getPaymentPlanData &&
        !initialLoading.postGetTotalToPay &&
        !initialLoading.getFilesToAddToPayment
      "
    >
      <div
        v-if="(seeModule && customApi) || !customApi || isTemplate"
        class="default-box-white-full"
      >
        <div class="p-4">
          <sp-title
            v-if="
              removalsPercentage > 0 &&
              paymentPlanData.removals.enabled &&
              seeTextQuita
            "
            :message="getRemovalTitle"
            size="text-2xl"
            :color="paymentPlanData.removals.title.color"
          />
          <p
            v-if="
              removalsPercentage > 0 &&
              paymentPlanData.removals.enabled &&
              seeTextQuita
            "
            class="text-gray-400"
            style="margin-top: -5px"
          >
            {{ getRemovalSubtitle }}
          </p>
          <div class="mt-4 flex items-center justify-between flex-wrap">
            <sp-square-button
              v-for="(paymentOption, index) in options"
              :key="index"
              @changeStatus="changeSelectedPaymentPlanProposal"
              id="single"
              :is-active="activePayment === 'single'"
              :title="card.full.title[language]"
              :description="card.full.subtitle[language]"
              :background-color="
                card.cardConfiguration.unselectedCard.backgroundColor
              "
              :background-color-selected="
                card.cardConfiguration.selectedCard.backgroundColor
              "
              :color-text="card.cardConfiguration.unselectedCard.colorText"
              :color-text-selected="
                card.cardConfiguration.selectedCard.colorText
              "
              class="grow shrink basis-1/3 lg:basis-1"
            />
            <sp-square-button
              @changeStatus="changeSelectedPaymentPlanProposal"
              id="partial"
              :is-active="activePayment === 'partial'"
              :title="card.partial.title[language]"
              :description="card.partial.subtitle[language]"
              :background-color="
                card.cardConfiguration.unselectedCard.backgroundColor
              "
              :background-color-selected="
                card.cardConfiguration.selectedCard.backgroundColor
              "
              :color-text="card.cardConfiguration.unselectedCard.colorText"
              :color-text-selected="
                card.cardConfiguration.selectedCard.colorText
              "
              class="grow shrink basis-1/3 lg:basis-1"
            />
            <sp-square-button
              v-if="allowSelection"
              @changeStatus="changeSelectedPaymentPlanProposal"
              id="custom"
              :is-active="activePayment === 'custom'"
              :title="card.plan.title[language]"
              :description="card.plan.subtitle[language]"
              :background-color="
                card.cardConfiguration.unselectedCard.backgroundColor
              "
              :background-color-selected="
                card.cardConfiguration.selectedCard.backgroundColor
              "
              :color-text="card.cardConfiguration.unselectedCard.colorText"
              :color-text-selected="
                card.cardConfiguration.selectedCard.colorText
              "
              class="grow shrink basis-1/3 lg:basis-1"
            />
          </div>
        </div>

        <div
          v-if="activePayment !== 'partial'"
          class="p-5 flex flex-col md:flex-row justify-end md:justify-end"
        >
          <sp-button
            @onClick="chooseFilesModal = true"
            :label="button[language]"
            size="auto"
            :loading="loading"
            :style="`
            background-color: ${buttonBackgroundColor};
            color: ${buttonTextColor};
            border: none;
          `"
          />
        </div>
        <div
          v-if="activePayment === 'partial'"
          class="p-5 flex flex-col md:flex-row justify-end md:justify-end"
        >
          <sp-button
            @onClick="chooseFilesModal = true"
            :label="button[language]"
            size="auto"
            :loading="loading"
            class="md:mt-0 mt-3"
            :style="`
            background-color: ${buttonBackgroundColor};
            color: ${buttonTextColor};
            border: none;
          `"
          />
        </div>
        <div v-if="paymentPlans.length > 0" class="w-full p-5">
          <sp-title
            :message="card.plan.application.title[language]"
            :color="primaryColor"
            :size="'text-2xl'"
          ></sp-title>
          <table class="border-collapse sirit-table w-full mt-4">
            <thead>
              <tr>
                <th
                  v-for="(header, idx) in [
                    { label: '' },
                    {
                      label:
                        card.plan.application.columns.amount.text[language],
                    },
                    {
                      label:
                        card.plan.application.columns.deadlineDate.text[
                          language
                        ],
                    },
                    {
                      label:
                        card.plan.application.columns.months.text[language],
                    },
                    {
                      label:
                        card.plan.application.columns.paidDeadlines.text[
                          language
                        ],
                    },
                    {
                      label:
                        card.plan.application.columns.totalToPay.text[language],
                    },
                    {
                      label:
                        card.plan.application.columns.status.text[language],
                    },
                    {
                      label: card.plan.application.columns.type.text[language],
                    },
                  ]"
                  :key="idx"
                  style="padding-left: 0.2em"
                  class="uppercase text-base font-semibold text-left"
                >
                  {{ header.label }}
                </th>
              </tr>
            </thead>
            <tbody v-if="paymentPlans.length > 0">
              <tr
                v-for="(row, index) in paymentPlans"
                :key="row['@id']"
                :class="index % 2 === 0 ? 'bg-gray-100' : ''"
              >
                <td>
                  <input
                    type="checkbox"
                    class="mr-1"
                    v-model="row.check"
                    :disabled="row.status === 'cancelled'"
                    name
                    id
                    @change="selectDeadLine(row)"
                  />
                </td>
                <td :data-label="$t('table.amount')" class="text-left">
                  <span v-if="row.paymentPlanDeadline !== null">{{
                    Utils.formatNumberWithCurrency(
                      row.paymentPlanDeadline.amount,
                      currency
                    )
                  }}</span>
                  <span v-else>-</span>
                </td>
                <td :data-label="$t('table.deadLineDate')" class="text-left">
                  <span v-if="row.paymentPlanDeadline !== null">{{
                    Utils.formatDate(row.paymentPlanDeadline.deadlineDate)
                  }}</span>
                  <span v-else>-</span>
                </td>
                <td :data-label="$t('table.months')" class="text-left">
                  {{ row.months }}
                </td>
                <td :data-label="$t('table.paidDeadlines')" class="text-left">
                  {{ row.paidDeadlines }}
                </td>
                <td :data-label="$t('table.totalToPay')" class="text-left">
                  {{ Utils.formatNumberWithCurrency(row.totalToPay, currency) }}
                </td>
                <td :data-label="$t('table.status')" class="text-left">
                  <div class="flex flex-col">
                    <span v-if="row.status === 'approved'">{{
                      card.plan.application.status.approved[language]
                    }}</span>
                    <span v-if="row.status === 'cancelled'">{{
                      card.plan.application.status.cancelled[language]
                    }}</span>
                    <span v-if="row.status === 'closed'">{{
                      card.plan.application.status.closed[language]
                    }}</span>
                    <span v-if="row.status === 'failed'">{{
                      card.plan.application.status.failed[language]
                    }}</span>
                    <span v-if="row.closedDate !== null" class="text-xs"
                      >on {{ Utils.formatDate(row.closedDate) }}</span
                    >
                  </div>
                </td>

                <td :data-label="$t('table.type')" class="ml-2">
                  <p v-if="row.type === 'partial'" class="ml-4">
                    {{ $t(`general.paymentPlanProposalPartialOption`) }}
                  </p>
                  <p v-if="row.type === 'single'" class="ml-4">
                    {{ $t(`general.paymentPlanProposalSingleOption`) }}
                  </p>
                  <p v-if="row.type === 'monthly'" class="ml-4">
                    {{ $t(`general.paymentPlan`) }}
                  </p>
                </td>

                <td v-if="row.status === 'approved' && row.months > 1">
                  <sp-button-icon
                    @onClick="
                      paymentPlansSelected !== null
                        ? selectPlan(null)
                        : selectPlan(row, row.totalToPay)
                    "
                    :icon="
                      paymentPlansSelected !== null
                        ? 'chevron-up'
                        : 'chevron-down'
                    "
                    icon-right
                    color="clean"
                    size="15"
                  />
                  
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="text-center" :colspan="4">
                  <span class="text-center text-gray-400 text-xs italic"
                    >there is no data</span
                  >
                </td>
              </tr>
            </tbody>
          </table>

          <table
            v-if="paymentPlansSelected !== null"
            class="border-collapse sirit-table w-full mt-3 text-center"
          >
            <thead>
              <tr
                :style="
                  'background-color: ' +
                  getTableHeader.color +
                  '; color: ' +
                  getTableHeader.textColor +
                  ';'
                "
              >
                <th
                  v-for="(header, idx) in [
                    { label: '' },
                    { label: getTableHeader.amount },
                    { label: getTableHeader.date },
                    { label: getTableHeader.monthly },
                    { label: getTableHeader.status },
                    { label: '' },
                  ]"
                  :key="idx"
                  class="uppercase font-semibold"
                >
                  {{ header.label }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(
                  row, index
                ) in paymentPlansSelected.paymentPlanDeadlines"
                :key="row['@id']"
                :class="index % 2 === 0 ? 'bg-gray-100' : ''"
              >
                <td></td>
                <td :data-label="$t('table.amount')">
                  {{ Utils.formatNumberWithCurrency(row.amount, currency) }}
                </td>
                <td :data-label="$t('table.deadLineDate')">
                  {{ Utils.formatDate(row.deadlineDate) }}
                </td>
                <td :data-label="'Monthly payment'">{{ row.number }}</td>
                <td :data-label="$t('table.status')">{{ row.status }}</td>
              </tr>
              <tr
                :data-label="getTableTotals.title"
                :style="
                  'background-color: ' +
                  getTableTotals.color +
                  '; color: ' +
                  getTableTotals.textColor +
                  '; font-weight: bold;'
                "
              >
                <td>{{ getTableTotals.title }}</td>
                <td>
                  {{ Utils.formatNumberWithCurrency(totalToPay, currency) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-else class="p-4 default-box-white-full">
        <div class="p-4 mt-4 flex items-center justify-between flex-wrap">
          <sp-square-button
            @changeStatus="changeSelectedPaymentPlanProposal"
            id="partial"
            :is-active="activePayment === 'partial'"
            :title="card.partial.title[language]"
            :description="card.partial.subtitle[language]"
            class="grow shrink basis-1/3 lg:basis-1"
          />
        </div>

        <div
          v-if="activePayment === 'partial'"
          class="p-5 flex flex-col md:flex-row justify-center md:justify-between"
        >
          <div
            class="flex justify-center items start w-full flex-col md:flex-row"
          >
            <div
              class="flex flex-col mb-2 text-center md:text-left"
              style="width: 200px"
            >
              <span
                class="font-semibold"
                :style="'color:' + modules.paymentPlan.totalDebt.color"
                >{{
                  Utils.formatNumberWithCurrency(getAmountToPay, currency)
                }}</span
              >
              <span v-if="getAmountToPay > 0" class="text-xs text-gray-400"
                >Min:
                {{
                  Utils.formatNumberWithCurrency(minPartialAmount, currency)
                }}</span
              >
              <span v-else class="text-xs text-gray-400"
                >Min: {{ Utils.formatNumberWithCurrency(0, currency) }}</span
              >
            </div>
            <div class="flex justify-start items-start w-full pl-3">
              <input
                v-model="quantityPartial"
                @keypress="
                  () => {
                    Utils.isNumber($event);
                  }
                "
                type="text"
                class="rounded text-dark w-full text-right"
                style="border: 1px solid black; max-width: 180px"
                :placeholder="'0'"
              />
            </div>
          </div>
          <sp-button
            @onClick="chooseFilesModal = true"
            :label="
              activePayment
                ? $t('general.paymentPlanProposalSingleOption1')
                : button[language]
            "
            size="auto"
            :loading="loading"
            class="md:mt-0 mt-3"
            :style="`
            background-color: ${buttonBackgroundColor};
            color: ${buttonTextColor};
            border: none;
          `"
          />
        </div>
        <div v-if="paymentPlans.length > 0" class="w-full p-5">
          <!-- <table class="border-collapse sirit-table w-full">
            <thead>
              <tr>
                <th
                  v-for="(header, idx) in [
                    { label: '' },
                    {
                      label:
                        card.plan.application.columns.amount.text[language],
                    },
                    {
                      label:
                        card.plan.application.columns.deadlineDate.text[
                          language
                        ],
                    },
                    {
                      label:
                        card.plan.application.columns.months.text[language],
                    },
                    {
                      label:
                        card.plan.application.columns.paidDeadlines.text[
                          language
                        ],
                    },
                    {
                      label:
                        card.plan.application.columns.totalToPay.text[language],
                    },
                    {
                      label:
                        card.plan.application.columns.status.text[language],
                    },
                    {
                      label: card.plan.application.columns.type.text[language],
                    },
                    { label: '' },
                  ]"
                  :key="idx"
                  class="uppercase font-semibold text-left"
                >
                  {{ header.label }}
                </th>
              </tr>
            </thead>
            <tbody v-if="paymentPlans.length > 0">
              <tr
                v-for="(row, index) in paymentPlans"
                :key="row['@id']"
                :class="index % 2 === 0 ? 'bg-gray-100' : ''"
              >
                <td>
                  <input
                    type="checkbox"
                    class="mr-1"
                    v-model="row.check"
                    name
                    id
                    @change="selectDeadLine(row)"
                  />
                </td>
                <td :data-label="$t('table.amount')" class="text-left">
                  <span v-if="row.paymentPlanDeadline !== null">{{
                    Utils.formatNumberWithCurrency(
                      row.paymentPlanDeadline.amount,
                      currency
                    )
                  }}</span>
                  <span v-else>-</span>
                </td>
                <td :data-label="$t('table.deadLineDate')" class="text-left">
                  <span v-if="row.paymentPlanDeadline !== null">{{
                    Utils.formatDate(row.paymentPlanDeadline.deadlineDate)
                  }}</span>
                  <span v-else>-</span>
                </td>
                <td :data-label="$t('table.months')" class="text-left">
                  {{ row.months }}
                </td>
                <td :data-label="$t('table.paidDeadlines')" class="text-left">
                  {{ row.paidDeadlines }}
                </td>
                <td :data-label="$t('table.totalToPay')" class="text-left">
                  {{ Utils.formatNumberWithCurrency(row.totalToPay, currency) }}
                </td>
                <td :data-label="$t('table.status')" class="text-left">
                  <div class="flex flex-col">
                    <span>{{ $t(`status.${row.status}`) }}</span>
                    <span v-if="row.closedDate !== null" class="text-xs"
                      >on {{ Utils.formatDate(row.closedDate) }}</span
                    >
                  </div>
                </td>
                <td v-if="row.status === 'approved' && row.months > 1">
                  <sp-button-icon
                    @onClick="
                      paymentPlansSelected !== null
                        ? selectPlan(null)
                        : selectPlan(row, row.totalToPay)
                    "
                    :icon="
                      paymentPlansSelected !== null
                        ? 'chevron-up'
                        : 'chevron-down'
                    "
                    icon-right
                    color="clean"
                    size="15"
                  />
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="text-center" :colspan="4">
                  <span class="text-center text-gray-400 text-xs italic"
                    >there is no data</span
                  >
                </td>
              </tr>
            </tbody>
          </table> -->

          <table
            v-if="paymentPlansSelected !== null"
            class="border-collapse sirit-table w-full mt-3 text-center"
          >
            <thead>
              <tr
                :style="
                  'background-color: ' +
                  getTableHeader.color +
                  '; color: ' +
                  getTableHeader.textColor +
                  ';'
                "
              >
                <th
                  v-for="(header, idx) in [
                    { label: '' },
                    { label: getTableHeader.amount },
                    { label: getTableHeader.date },
                    { label: getTableHeader.monthly },
                    { label: getTableHeader.status },
                    { label: '' },
                  ]"
                  :key="idx"
                  class="uppercase font-semibold"
                >
                  {{ header.label }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(
                  row, index
                ) in paymentPlansSelected.paymentPlanDeadlines"
                :key="row['@id']"
                :class="index % 2 === 0 ? 'bg-gray-100' : ''"
              >
                <td></td>
                <td :data-label="$t('table.amount')">
                  {{ Utils.formatNumberWithCurrency(row.amount, currency) }}
                </td>
                <td :data-label="$t('table.deadLineDate')">
                  {{ Utils.formatDate(row.deadlineDate) }}
                </td>
                <td :data-label="'Monthly payment'">{{ row.number }}</td>
                <td :data-label="$t('table.status')">{{ row.status }}</td>
              </tr>
              <tr
                :data-label="getTableTotals.title"
                :style="
                  'background-color: ' +
                  getTableTotals.color +
                  '; color: ' +
                  getTableTotals.textColor +
                  '; font-weight: bold;'
                "
              >
                <td>{{ getTableTotals.title }}</td>
                <td>
                  {{ Utils.formatNumberWithCurrency(totalToPay, currency) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-else class="flex justify-center items-center">
      <sp-icon model="refresh-cw" rotate class="rotating mr-2" />
    </div>
  </div>
</template>

<script>

import { computed, ComputedRef, defineComponent, reactive, toRefs, watch, onMounted} from "vue";
import SpButton from "@/components/ui/SpButton.vue";
import SpInputRange from "@/components/ui/SpInputRange.vue";
import SpSquareButton from "@/components/ui/SpSquareButton.vue";
import { useStore } from "vuex";
import * as Utils from "@/api/Utils";
import SpTitle from "@/components/ui/SpTitle.vue";
import SpCalendar from "@/components/ui/SpCalendar.vue";
import moment from "moment/moment";
import SpSelect from "@/components/ui/SpSelect.vue";
import SpModal from "@/components/ui/SpModal.vue";
import SpIcon from "@/components/ui/SpIcon.vue";
import SpTable from "@/components/ui/SpTable.vue";
import SpButtonIcon from "@/components/ui/SpButtonIcon.vue";

export default defineComponent({
  name: "PaymentPlanProposal",
  components: {
    SpIcon,
    SpSquareButton,
    SpButton,
    SpInputRange,
    SpTitle,
    SpCalendar,
    SpSelect,
    SpModal,
    SpButtonIcon,
    SpTable,
  },
  props: {
    uuid: { type: String, required: true, default: "" },
    title: { type: Object, required: true },
    subtitle: { type: Object, required: true },
    button: { type: Object, required: true },
    buttonTextColor: { type: String, required: true, default: "" },
    buttonBackgroundColor: { type: String, required: true, default: "" },
    moduleHeader: { type: String, required: true, default: "" },
    options: { type: Array, required: true, default: [] },
    minMonths: { type: Number, required: true, default: 2 },
    maxMonths: { type: Number, required: true, default: 12 },
    minFee: { type: Number, required: true, default: 1 },
    maxFee: { type: Number, required: true, default: 120 },
    minPartialAmount: { type: Number, required: true, default: 1 },
    currency: { type: String, required: true, default: "EUR" },
    allowSelection: { type: Boolean, required: true, default: true },
    removalsLimitDate: { type: String, required: true, default: "" },
    removalsPercentage: { type: Number, required: true, default: 0 },
    paymentPlanTableHtml: { type: Object, required: true },
    planLimitDays: { type: Number, required: true, default: 1 },
    proportionalSlider: { type: Boolean, required: true, default: false },
    isTemplate: { type: Boolean, required: true, default: false },
  },
  emits: ["selectedPayment"],
  setup(props, { emit }) {
    const store = useStore();
    const isMobile = computed(() => {
      return store.getters["user/getIsMobile"];
    });

    const today = moment().format("YYYY-MM-DD");
  
    const data = reactive({
      initialLoading: {
        postGetTotalToPay: true,
        getPaymentPlanData: true,
        getFilesToAddToPayment: true,
      },
      loadingPaymentPlan: true,
      activePayment: "single",
      activePaymentSingle: false,
      quantity: 0,
      quantityPartial: "0",
      months: props.minMonths,
      loading: false,
      isModalPaymentActive: false,
      paymentPlans: [],
      headers: [
        { label: "Amount" },
        { label: "Months" },
        { label: "Frequency" },
        { label: "Table" },
      ],
      headersFiles: [
        { label: "" },
        { label: "Name" },
        { label: "Pending Debt" },
        { label: "Total Debt" },
      ],
      tableItems: [],
      colorToUse: store.getters["user/getPrimaryColor"],
      paymentPlanData: store.getters["modules/getPaymentPlanDataModule"],
      preferredDueDay: moment().format("DD/MM/YYYY"),
      disabledForm: false,
      debtAmountDato: 0,
      seeTextQuita: false,
      seeModule: false,
      dateForCall: today,
      paymentOriginOptions: [
        {
          label: "Nómina",
          value: "Nomina",
        },
        {
          label: "Pensión",
          value: "Pension",
        },
        {
          label: "Subsidio de desempleo ",
          value: "Subsidio",
        },
        {
          label: "Subsidio de desempleo ",
          value: "Subsidio",
        },
        {
          label: "Subsidio de desempleo ",
          value: "Subsidio",
        },
      ],
      selectEnable:
        store.getters["modules/getPaymentPlanDataModule"].confirmationPreview
          .enableOrigin,
      page: 1,
      maxNumberOfPages: 1,
      paymentPlansSelected: null,
      totalToPay: "",
      minValue: 50,
      minMonthsFinal: props.minMonths,
      maxMonthsFinal: props.maxMonths,
      minAmountFinal: 0,
      maxAmountFinal: 0,
      selectedOption: null,
      valoresPermitidos: [],
      valueChangeProportional: false,
      files: store.getters["modules/getFilesInfo"],
      chooseFilesModal: false,
      totalPlan: 0,
      selectedDeadLine: null,
      checkoutSessionId: ''
    });
    
    const amountToPage = computed(() => {
      let result = 0;
      data.files.forEach((item, index) => {
        if (
          item["@id"].replace("/api/files/", "") ===
          store.getters["modules/getFileUuid"]
        ) {
          result = item.totalDebt;
        }
      });
      return result;
    });


    const isAboveMaximum = computed(() => {
      return parseInt(data.quantityPartial) > totalAmountToPageInTable.value;
    });

    const isAboveMinimum = computed(() => {
      return props.minPartialAmount <= parseInt(data.quantityPartial);
    });

    const isTemplate = computed(() => {
      return store.getters["modules/getIsTemplate"];
    });

    const selectedCheck = computed(() => {
      const countCheckSelected = data.files.filter(
        (item) => item.check === true
      ).length;
      return countCheckSelected > 0;
    });

    const subtitleComputed = computed(() => {
      let result = props.subtitle[language.value].slice();
      for (let fpt in store.getters["modules/getSelectedFileData"].fpt) {
        result = result.replaceAll(
          "{" + fpt + "}",
          store.getters["modules/getSelectedFileData"].fpt[fpt]
        );
      }
      return result;
    });

    const primaryColor = computed(() => {
      return store.getters["user/getPrimaryColor"];
    });

    const totalAmountToPageInTable = computed(() => {
      return data.files.reduce((total, file) => {
        if (file.check) {
          return total + file.pendingDebt;
        }
        return total;
      }, 0);
    });

    const customApi = computed(() => {
      return store.getters["user/getCustomApi"];
    });
    const card = computed(() => {
      return store.getters["modules/getCard"];
    });

    const modules = computed(() => {
      return store.getters["modules/getModules"];
    });

    const tableConfirmHeaders = computed(() => {
      const translatedHeaders = [];
      const headers = Object.values(
        data.paymentPlanData.confirmationPreview.tableHeaderContent
      );
      headers.forEach((header) => {
        translatedHeaders.push(header[language.value]);
      });

      return translatedHeaders.map((header) => ({
        label: header,
        align: "left",
      }));
    });
    const itemsPerPage = computed(() => {
      return isMobile.value ? 1 : 6;
    });

    const selectConfirmOptions = computed(() => {
      const translatedSelect = [];
      const selects  = Object.values(
        data.paymentPlanData.confirmationPreview.originOptions
      );
      selects.forEach((selects ) => {
        translatedSelect.push(selects[language.value]);
      });

      return translatedSelect.map((selects) => ({
        label: selects,
        value: selects,
      }));
    });


    function redirectStripe() {
      const stripe = Stripe(process.env.VUE_APP_SIRIT_STRIPE_CHECKOUT_KEY);
      stripe.redirectToCheckout({
             sessionId: data.checkoutSessionId
    })
    }
   
    function changeSelectedOption(option) {
      data.selectedOption = option;
    }

    function selectDeadLine(deadLine) {
      let total = 0
      data.paymentPlans.forEach((item) => {
        if (item["@id"] === deadLine["@id"]) {
          item.check && item.paymentPlanDeadline
            ? (total = item.paymentPlanDeadline.amount)
            : "";
          data.selectedDeadLine = item.paymentPlanDeadline
        } else {
          item.check = false;
        }
        store.commit("modules/setTotalToPay", total);
      });
    }

    function preferredDueDayFunction(date) {
      data.preferredDueDay = date;
    }

    const debtAmount = computed(() => {
      return data.debtAmountDato;
    });

  

    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });

    const seeButtons = computed(() => {
      if (isTemplate.value) return true;

      let seeBoolean = true;
      data.paymentPlans.forEach((element) => {
        element.status === "approved" && element.type === "monthly"
          ? (seeBoolean = false)
          : "";
      });
      return seeBoolean;
    });

    const seePartial = computed(() => {
      let seeBoolean = false;
      data.paymentPlans.forEach((element) => {
        element.status === "approved" && element.type === "monthly"
          ? (seeBoolean = true)
          : "";
      });
      return seeBoolean;
    });

    const getRemovalTitle = computed(() => {
      let title =
        data.paymentPlanData.removals.title.text[
          store.getters["user/getLanguage"]
        ];
      for (let fpt in store.getters["modules/getSelectedFileData"].fpt) {
        title = title.replaceAll(
          "{" + fpt + "}",
          store.getters["modules/getSelectedFileData"].fpt[fpt]
        );
      }
      return replaceVariables(title);
    });

    const getRemovalSubtitle = computed(() => {
      let subtitle =
        data.paymentPlanData.removals.subtitle.text[
          store.getters["user/getLanguage"]
        ];
      for (let fpt in store.getters["modules/getSelectedFileData"].fpt) {
        subtitle = subtitle.replaceAll(
          "{" + fpt + "}",
          store.getters["modules/getSelectedFileData"].fpt[fpt]
        );
      }
      return replaceVariables(subtitle);
    });

    const getTableTotals = computed(() => {
      const title =
        props.paymentPlanTableHtml.totalsText[
          store.getters["user/getLanguage"]
        ];
      const color = props.paymentPlanTableHtml.totalsColor;
      const textColor = props.paymentPlanTableHtml.totalsTextColor;

      return { title, color, textColor };
    });

    const getTableHeader = computed(() => {
      const amount =
        props.paymentPlanTableHtml.headerText.header1[
          store.getters["user/getLanguage"]
        ];
      const date =
        props.paymentPlanTableHtml.headerText.header2[
          store.getters["user/getLanguage"]
        ];
      const monthly =
        props.paymentPlanTableHtml.headerText.header3[
          store.getters["user/getLanguage"]
        ];
      const status =
        props.paymentPlanTableHtml.headerText.header4[
          store.getters["user/getLanguage"]
        ];
      const color = props.paymentPlanTableHtml.headerColor;
      const textColor = props.paymentPlanTableHtml.headerTextColor;

      return { amount, date, monthly, status, color, textColor };
    });

    const getTableSubTitle = computed(() => {
      const title =
        props.paymentPlanTableHtml.subtitleText[
          store.getters["user/getLanguage"]
        ];

      const color = props.paymentPlanTableHtml.subtitleColor;

      return { title, color };
    });

    const getAmountToPay = computed(() => {
      let dateToCompare = moment(data.paymentPlanData.removalsLimitDate);
      if (dateToCompare.diff(moment(), "days") > 0) {
        data.seeTextQuita = true;
        return debtAmount.value;
      } else {
        data.seeTextQuita = false;
        return debtAmount.value;
      }
    });

    const maxMonthsToPay = computed(() => {
      const minFeeMultipliedByMaxMonths = minFeeToPay.value * props.maxMonths;
      const maxMonths =
        minFeeMultipliedByMaxMonths > totalAmountToPageInTable.value
          ? Math.ceil(totalAmountToPageInTable.value / minFeeToPay.value)
          : props.maxMonths;

      return maxMonths;
    });

    const minFeeToPay = computed(() => {
      const smallestFee = Math.round(
        totalAmountToPageInTable.value / props.maxMonths
      );
      return smallestFee >= props.minFee ? smallestFee : props.minFee;
    });

    const maxFeeToPay = computed(() => parseFloat((totalAmountToPageInTable.value / props.minMonths).toFixed(2))
)
     
    function monthsToPayChange(months){
      changePaymentMonths(months)
      const newPaymentAmount= (maxMonthsToPay.value * minFeeToPay.value ) / months
      changePaymentAmount(newPaymentAmount)
    }

    function feeToPayChange(feeToPay){
      const newMonthsToPay= Math.floor((maxMonthsToPay.value * minFeeToPay.value ) / feeToPay)
      changePaymentAmount(feeToPay)
      data.quantity = feeToPay
      changePaymentMonths(newMonthsToPay)
    }

    function changePaymentMonths(months) {
      data.months = Number(months);
    }

    function changePaymentAmount(amount) {
      data.quantity = amount
    }

    function replaceVariables(text) {
      const fileData = store.getters["modules/getSelectedFileData"];
      return text
        .replaceAll(
          "{removalsPercentage}",
          data.paymentPlanData.removalsPercentage
        )
        .replaceAll(
          "{removalsLimitDate}",
          Utils.formatDate(data.paymentPlanData.removalsLimitDate)
        )
        .replaceAll(
          "{pendingDebtWithRemoval}",
          Utils.formatNumberWithCurrency(
            fileData.pendingDebtWithRemoval,
            props.currency
          )
        )
        .replaceAll(
          "{removalAmount}",
          Utils.formatNumberWithCurrency(fileData.removalAmount, props.currency)
        )
        .replaceAll(
          "{totalDebt}",
          Utils.formatNumberWithCurrency(fileData.totalDebt, props.currency)
        );
    }

    function changeSelectedPaymentPlanProposal(status) {
      data.activePayment = status.id;
      data.activePayment === "custom"
        ? (data.months = modules.value
            ? modules.value.paymentPlan.minMonths
            : 2)
        : (data.months = 1);
      data.quantity =
        data.months === 0
          ? getAmountToPay.value
          : getAmountToPay.value / data.months;
    }

    function chageDateforCall(date) {
      data.dateForCall = date;
    }

    function setIsModalPaymentActive() {
      data.isModalPaymentActive = !data.isModalPaymentActive;
    }

    async function postCreatePaymentPlanData() {
      let type = "";
      let frequency = "";
      let customPaymentAmount = 0;
      if (data.activePayment === "custom") {
        type = "custom";
        frequency = "monthly";
        customPaymentAmount = data.quantity;
      }
      if (data.activePayment === "partial") {
        type = "partial";
        frequency = "partial";
        customPaymentAmount = parseInt(data.quantityPartial)
      }
      if (data.activePayment === "single") {
        type = "unified";
        frequency = "single";
        customPaymentAmount = totalAmountToPageInTable.value
      }

      let filesToSend = [];
      data.files.forEach((file) => {
        if (file.check) {
          filesToSend.push(file.fileUuid);
        }
      });

      data.loading = true;
      const params = {
        uuid: props.uuid,
        customPaymentAmount: customPaymentAmount,
        customPaymentPeriodMonths: data.months,
        preferredDueDay: data.dateForCall,
        type: type,
        paymentFrequency: frequency,
        files: filesToSend,
        origin: data.selectedOption,
        totalDebt: totalAmountToPageInTable.value,
        callbackSuccess: (callbackSuccessData) => {
          data.selectedDeadLine = callbackSuccessData.data.createdPaymentPlan.paymentPlanDeadline
          getPaymentPlanData();
          data.quantityPartial = "";
          data.chooseFilesModal = false;
          data.loading = false;
          data.files.forEach((element) => {
            element.check = false;
          });
        },
        callbackError: () => {
          data.chooseFilesModal = false;
          data.loading = false;
        },
      };
      await store.dispatch("modules/postCreatePaymentPlanData", params);
    }
    async function onClickHandler() {
      if (data.paymentPlanData.confirmationPreview.enablePaymentConfirm) {
        if (data.activePayment === "partial") {
          if (!selectedCheck.value)
            return Utils.showWarningNotification("notifications.selectDebt");
          if (isAboveMaximum.value)
            return Utils.showWarningNotification("notifications.payErrorMax");
          !isAboveMinimum.value
            ? Utils.showWarningNotification("notifications.payError")
            : await postCreatePaymentPlanData();
            await postCheckoutSession()
            redirectStripe()
          store.commit("modules/setTotalToPay", data.quantityPartial);
        } else if (data.activePayment !== "custom") {
          if (!selectedCheck.value)
            return Utils.showWarningNotification("notifications.selectDebt");
             await postCreatePaymentPlanData();
            await postCheckoutSession()
            redirectStripe()
          store.commit("modules/setTotalToPay", totalAmountToPageInTable.value);
        } else {
          if (!selectedCheck.value)
            return Utils.showWarningNotification("notifications.selectDebt");
          activeConfirmPaymentPlan();
        }
      } else {
        postCreatePaymentPlanData();
      }
    }

     function getPaymentPlanData() {
      data.initialLoading.getPaymentPlanData = true;
      data.loadingPaymentPlan = true;
      const params = {
        uuid: props.uuid,
        fileID: store.getters["modules/getFileUuid"],
        callbackSuccess: (callbackSuccessData) => {
          if (callbackSuccessData.data.length > 0)
            data.paymentPlans = callbackSuccessData.data;
            data.paymentPlans.length > 0 ? data.paymentPlans[0].check = true : ''
            selectDeadLine(data.paymentPlans[0])
          data.paymentPlans.forEach(item => {
            if (item.status === "approved")
              store.commit(
                "user/setPaymentAmount",
                item.paymentPlanDeadline.amount
              );
            if (item.status === "cancelled") item.check = false;
          });
          data.initialLoading.getPaymentPlanData = false;
          data.loadingPaymentPlan = false;
        },
        callbackError: () => {
          data.loadingPaymentPlan = false;
          data.initialLoading.getPaymentPlanData = false;
        },
      };
      store.dispatch("modules/getPaymentPlanData", params);
    }

    function postGetTotalToPay() {
      data.initialLoading.postGetTotalToPay = true;
      const params = {
        uuid: props.uuid,
        fileID: store.getters["modules/getFileUuid"],
        callbackSuccess: (callbackSuccessData) => {
          data.debtAmountDato = amountToPage.value;
          data.initialLoading.postGetTotalToPay = false;
          getFilesToAddToPayment();
        },
        callbackError: () => {
          data.initialLoading.postGetTotalToPay = false;
        },
      };
      store.dispatch("modules/postGetTotalToPay", params);
    }
    async function postCheckoutSession() {
      let params = {}
      if (data.selectedDeadLine['@id'].length > 0) {
        const deadLine = data.selectedDeadLine['@id']
        params = {
          uuid: props.uuid.replace('/api/landing_pages/', ''),
          paymentPlanDeadline: deadLine,
          callbackSuccess: (callbackSuccessData) => {
            data.checkoutSessionId = callbackSuccessData.data.checkoutSessionId
            // data.deadLineId.length === 0 ? data.seePageButton = false : ''
          },
          callbackError: () => {}

          }
        
      } else {
        params = {
          uuid: props.uuid.replace('/api/landing_pages/', ''),
          amount: totalAmountToPageInTable.value,
          file: '/api/files/' + store.getters['modules/getFileUuid'],
          callbackSuccess: (callbackSuccessData) => {
            data.checkoutSessionId = callbackSuccessData.data.checkoutSessionId
            // data.deadLineId.length === 0 ? data.seePageButton = false : ''
          },
          callbackError: () => {}
        };

      }
       await store.dispatch("modules/postCheckoutSession", params);
    }

    function getFilesToAddToPayment() {
      data.initialLoading.getFilesToAddToPayment = true;
      const params = {
        uuid: props.uuid,
        callbackSuccess: (callbackSuccessData) => {
          if (
            callbackSuccessData.data.availableFilesToAddToAPaymentPlan.length >
            0
          ) {
            callbackSuccessData.data.availableFilesToAddToAPaymentPlan.forEach(
              (item) => {
                item.fileUuid === store.getters["modules/getFileUuid"]
                  ? (data.seeModule = true)
                  : "";
              }
            );
          }
          /*   months(); */
          data.initialLoading.getFilesToAddToPayment = false;
        },
        callbackError: () => {
          data.initialLoading.getFilesToAddToPayment = false;
        },
      };
      store.dispatch("modules/getFilesToAddToPayment", params);
    }

    postGetTotalToPay();
    getPaymentPlanData();



    function activeConfirmPaymentPlan() {
      postTableData();
      setIsModalPaymentActive();
    }
    function editProposal() {
      setIsModalPaymentActive()
      data.chooseFilesModal = true
    }
    function sendConfirm() {
      postCreatePaymentPlanData();
      setIsModalPaymentActive();
      
    }

    function postTableData() {
      const params = {
        preferred_day: data.dateForCall,
        monthly_cost: data.quantity,
        months: data.months,
        itemsPerPage: itemsPerPage.value,
        page: data.page,
        totalDebt: getAmountToPay.value,
        callbackSuccess: (callbackSuccessData ) => {
          data.tableItems = isMobile.value
            ? callbackSuccessData.data.data.map((item ) => {
                const [date, payment, quantity] = tableConfirmHeaders.value.map(
                  (header ) => header.label
                );
                const { amount, deadlineDate, number } = item;
                return {
                  [date]: deadlineDate,
                  [payment]: number,
                  [quantity]: Utils.formatNumberWithCurrency(amount),
                };
              })
            : callbackSuccessData.data.data;
          data.maxNumberOfPages = Math.ceil(
            callbackSuccessData.data.totalItems / itemsPerPage.value
          );
          data.chooseFilesModal = false;
        },
        callbackError: () => {
          data.chooseFilesModal = false;
        },
      };
      store.dispatch("modules/postPaymentPlanTable", params);
    }

    function getPage(type ) {
      if (type === "first") {
        data.page = 1;
        postTableData();
      } else if (type === "last") {
        data.page = data.maxNumberOfPages;
        postTableData();
      } else if (type === "next" && data.page < data.maxNumberOfPages) {
        data.page++;
        postTableData();
      } else if (type === "previous" && data.page > 1) {
        data.page--;
        postTableData();
      }
    }

    function selectPlan(plan , totalToPay ) {
      data.paymentPlansSelected = plan;
      data.totalToPay = totalToPay;
    }

    function minValue() {
      return parseInt(data.quantityPartial) === data.minValue;
    }

    const debtSelector = computed(() => {
      return store.getters["modules/getDebtSelector"];
    });

    function title(item ) {
      let result = debtSelector.value.card.title.en.slice();
      if (store.getters["user/getLanguage"] === "es")
        result = debtSelector.value.card.title.es.slice();
      if (store.getters["user/getLanguage"] === "ru")
        result = debtSelector.value.card.title.ru.slice();

      result = result.replaceAll("{numOfDebt}", item.index);
      result = result.replaceAll('{debtAmount}',  item ? item.fpt.legalAgent : ''  )

      for (let fpt in store.getters["modules/getSelectedFileData"].fpt) {
        result = result.replaceAll(
          "{" + fpt + "}",
          store.getters["modules/getSelectedFileData"].fpt[fpt]
        );

      }

      return result;
    }

    watch(debtAmount, () => {
      data.quantity = store.getters["modules/getAmountWithRemoval"];
    });

    watch(
      () => store.getters["modules/getFileUuid"],
      () => {
        getPaymentPlanData();
        postGetTotalToPay();
      }
    );

    return {
      ...toRefs(data),
      changePaymentMonths,
      changeSelectedPaymentPlanProposal,
      postCreatePaymentPlanData,
      getFilesToAddToPayment,
      getRemovalTitle,
      getRemovalSubtitle,
      getAmountToPay,
      debtAmount,
      isMobile,
      Utils,
      seeButtons,
      language,
      customApi,
      chageDateforCall,
      preferredDueDayFunction,
      setIsModalPaymentActive,
      tableConfirmHeaders,
      selectConfirmOptions,
      onClickHandler,
      activeConfirmPaymentPlan,
      getPage,
      itemsPerPage,
      postTableData,
      sendConfirm,
      selectPlan,
      // getTabletitle,
      getTableSubTitle,
      getTableHeader,
      getTableTotals,
      selectDeadLine,
      minValue,
      card,
      primaryColor,
      changeSelectedOption,
      changePaymentAmount,
      modules,
      seePartial,
      subtitleComputed,
      isTemplate,
      amountToPage,
      debtSelector,
      title,
      totalAmountToPageInTable,
      isAboveMinimum,
      isAboveMaximum,
      selectedCheck,
      minFeeToPay,
      maxFeeToPay,
      maxMonthsToPay,
      monthsToPayChange,
      postCheckoutSession,
      redirectStripe,
      feeToPayChange,
      editProposal
    };
  },
});
</script>

<style lang="scss" scoped>
@media (max-width: 1000px) {
  table {
    border: 1px solid #ccc;
    width: 100%;
    margin: 0;
    padding: 0;
    border-collapse: collapse;
    border-spacing: 0;
  }
  table tr {
    border: 1px solid #ddd;
    padding: 5px;
  }
  table th,
  table td {
    padding: 10px;
    text-align: center;
  }
  table th {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
  }
  table {
    border: 0;
  }
  table thead {
    display: none;
  }
  table tr {
    margin-bottom: 10px;
    display: block;
    border-bottom: 2px solid #ddd;
  }
  table td {
    display: block;
    text-align: right;
    font-size: 13px;
    border-bottom: 1px dotted #ccc;
  }
  table td:last-child {
    border-bottom: 0;
  }
  table td:before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-weight: bold;
  }
}
</style>

function onMounted(arg0: () => void) {
  throw new Error("Function not implemented.");
}
