
import { computed, defineComponent, reactive, toRefs } from "vue";
import SpIcon from "@/components/ui/SpIcon.vue";
import SpButtonIcon from "@/components/ui/SpButtonIcon.vue";
import SpAlert from "@/components/ui/SpAlert.vue";
import * as Utils from "@/api/Utils";
import store from "@/store";

export default defineComponent({
  name: "SaveAsFavourite",
  components: {
    SpAlert,
  },
  props: {
    message: { type: Object, required: true },
    icon: { type: String, required: false, default: "heart" },
    color: { type: String, required: false, default: "#fbbf24" },
    fontSize: { type: String, required: false, default: "base" },
    url: { type: String, required: false, default: "base" },
  },
  setup(props) {
    interface saveAsFavourite {
      show: boolean;
    }
    const language = computed(() => {
      return store.getters["user/getLanguage"];
    })
    const data = reactive<saveAsFavourite>({
      show: true,
    });

    return {
      ...toRefs(data),
      language
    };
  },
});
