import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center py-4 w-full md:flex-row justify-center md:justify-between px-5 md:px-32 lg:px-40 xl:px-72" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_title = _resolveComponent("sp-title")!

  return (_openBlock(), _createElementBlock("div", {
    id: "welcome-title",
    class: "w-full bg-primary-color flex justify-center",
    style: _normalizeStyle('background: ' + _ctx.primaryColor + ';')
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.showAvatar)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.avatarClass)
          }, [
            (_ctx.showAvatar)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.url,
                  alt: "",
                  class: _normalizeClass(_ctx.isMobile ? 'w-350' : 'w-full')
                }, null, 10, _hoisted_2))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.showTitle || _ctx.showDescription)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass([_ctx.titleDescriptionClass, "flex flex-col"])
          }, [
            (_ctx.showTitle)
              ? (_openBlock(), _createBlock(_component_sp_title, {
                  key: 0,
                  message: _ctx.titleToShow,
                  color: _ctx.titleColor,
                  size: _ctx.computedTitleSize
                }, null, 8, ["message", "color", "size"]))
              : _createCommentVNode("", true),
            (_ctx.showDescription)
              ? (_openBlock(), _createBlock(_component_sp_title, {
                  key: 1,
                  message: _ctx.descriptionToShow,
                  color: _ctx.descriptionColor,
                  size: _ctx.computedDescriptionSize,
                  bold: false
                }, null, 8, ["message", "color", "size"]))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true)
    ])
  ], 4))
}