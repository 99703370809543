import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "w-full overflow-y-auto overflow-x-hidden" }
const _hoisted_2 = {
  key: 0,
  style: {"margin-top":"70px"}
}
const _hoisted_3 = {
  key: 2,
  style: {"margin-top":"50px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_support_page_header_ax = _resolveComponent("support-page-header-ax")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_bottom_navigation_bar_ax = _resolveComponent("bottom-navigation-bar-ax")!
  const _component_sp_notifications = _resolveComponent("sp-notifications")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isMobile && _ctx.showSideMenu)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.isMobile && _ctx.showSideMenu)
      ? (_openBlock(), _createBlock(_component_support_page_header_ax, {
          key: 1,
          languages: [],
          uuid: _ctx.uuid,
          class: "w-full p-3"
        }, null, 8, ["uuid"]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view),
    (_ctx.isMobile && _ctx.showSideMenu)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.isMobile && _ctx.showSideMenu)
      ? _withDirectives((_openBlock(), _createBlock(_component_bottom_navigation_bar_ax, {
          key: 3,
          "current-section": _ctx.currentSectionComputed
        }, null, 8, ["current-section"])), [
          [_vShow, !_ctx.viewsForBottomNavigationBar]
        ])
      : _createCommentVNode("", true),
    _createVNode(_component_sp_notifications)
  ]))
}