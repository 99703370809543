<template>
  <div class="sp-alert" :class="[
      type === 'success' ? 'sp-alert-success' : '',
      type === 'warning' ? 'sp-alert-warning' : '',
      type === 'danger' ? 'sp-alert-danger' : '',
  ]">
    <div class="ml-4 flex justify-center items-center">
      <sp-icon v-if="iconName.length > 0" :model="iconName" size="30" class="mr-2" />
      <p>{{ message }}<span><span v-if="includeClickHere" class="underline cursor-pointer">, {{ $t('ui.clickHere') }}</span></span></p>
    </div>
    <sp-button-icon @onClick="close"
                    icon="close" color="clean-transparent-black" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import SpIcon from '@/components/ui/SpIcon.vue'
import SpButtonIcon from '@/components/ui/SpButtonIcon.vue'

export default defineComponent({
  name: "SpAlert",
  components: { SpIcon, SpButtonIcon },
  emits: ['close', 'clickHere'],
  props: {
    type: { type: String, default: 'success' },
    iconName: { type: String, required: false, default: '' },
    message: { type: String, default: '' },
    includeClickHere: { type: Boolean, required: false, default: false }
  },
  setup (props, { emit }) {
    function close () {
      emit('close')
    }

    return {
      close
    }
  }
})
</script>

<style scoped>

</style>