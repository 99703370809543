
import Vue, { computed, defineComponent, reactive, toRefs, onUpdated } from "vue";
import SpIcon from "@/components/ui/SpIcon.vue";

export default defineComponent({
  name: "SpTimePicker",
  components: { SpIcon },
  props: {
    min: { type: String, default: "00:00" },
    max: { type: String, default: "00:00" },
    items: {type: Array, default: () => []},
    value: { type: String, default: "00:00" },
  },
  emits: ["update:value", "change"],
  setup(props, { emit }) {
    interface spTimePickerData {
      hour: string;
      min: string;
      hourValues: Array<number>
      minValues: Array<number>,
      showSelector: boolean,
      selectedHour: string
    }
    const computeValue = computed(() => props.value);
    const arrayRange = (start:number, stop:number, step:number) =>
      Array.from(
      { length: (stop - start) / step + 1 },
      (value, index) => start + index * step
    );
    const data = reactive<spTimePickerData>({
      hour: computed(() => props.value).value.split(":")[0],
      min: computed(() => props.value).value.split(":")[1],
      showSelector: false,
      minValues: Array.from(Array(1).keys()),
      hourValues: arrayRange(parseInt(props.min.split(":")[0]),parseInt(props.max.split(":")[0]),1),
      selectedHour: ''
    });
    
    onUpdated(() => {
      // text content should be the same as current `count.value`
      data.hour = props.value.split(":")[0];
      data.min = props.value.split(":")[1];
    });
    function onClickHour(hour: any) {
      data.selectedHour = hour
      emit("update:value", hour);
      emit("change", hour);
    }
    function onClickMinutes(minute: any) {
      data.min = minute >= 10?minute:'0'+minute;
      emit("update:value", `${data.hour}:${data.min}`);
      emit("change", `${data.hour}:${data.min}`);

    }
    function open() {
      setTimeout(()=>{
        data.showSelector = true
      },100)
    }
    function close() {
      setTimeout(()=>{
        data.showSelector = false
      },10)
    }
    return {
      ...toRefs(data),
      onClickHour,
      onClickMinutes,
      open,
      close
    };
  },
});
