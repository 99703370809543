import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "welcome-text",
  class: "default-box-white-full flex justify-center items-center !px-5 md:!px-32 lg:!px-40 xl:!px-72"
}
const _hoisted_2 = { class: "default-box-transparent w-full flex justify-start items-start" }
const _hoisted_3 = { class: "w-full py-8" }
const _hoisted_4 = { class: "flex justify-between items-start w-full" }
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = { class: "text-gray-400" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_icon = _resolveComponent("sp-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_sp_icon, {
            model: _ctx.icon,
            size: "80",
            class: "mr-2"
          }, null, 8, ["model"]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('general.dataUpdatedOn')) + " " + _toDisplayString(_ctx.Utils.formatDate(_ctx.yesterday)), 1)
          ])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["flex flex-col text-left", 'text-' + _ctx.fontSize])
        }, [
          _createElementVNode("p", {
            style: {"white-space":"pre-wrap"},
            innerHTML: _ctx.messageResult
          }, null, 8, _hoisted_7)
        ], 2)
      ])
    ])
  ]))
}