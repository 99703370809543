
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import SpIcon from "@/components/ui/SpIcon.vue";
import SpButton from "@/components/ui/SpButton.vue";
import router from "@/router";
import * as Utils from "@/api/Utils";

export default {
  name: "DebtsView",
  components: { SpIcon, SpButton },
  props: {
    uuid: { type: String, required: true },
  },
  setup(props: any) {
    const store = useStore();

    interface DebtsView {
      loading: boolean;
      languages: Array<any>;
      currentSection: string;
      seeInterveners: boolean;
      debtClasses: any;
      files: Array<any>;
      defaultFile: Array<any>;
      debtorInfo: any;
    }

    const data = reactive<DebtsView>({
      loading: false,
      languages: [],
      currentSection: "welcomeText",
      seeInterveners: false,
      files: store.getters["modules/getFilesInfo"],
      defaultFile: store.getters["modules/getDefaultFileInfo"],
      debtorInfo: store.getters["modules/getDebtorInfo"],
      debtClasses: {
        SAME: "",
        GREATER: "greater-debt",
        LESSER: "lesser-debt",
      },
    });

    const loading = computed(() => {
      return store.getters["modules/getLoading"];
    });

    const files = computed(() => {
      return store.getters["modules/getFilesInfo"];
    });

    const defaultFile = computed(() => {
      return store.getters["modules/getDefaultFileInfo"];
    });

    const debtorInfo = computed(() => {
      return store.getters["modules/getDebtorInfo"];
    });

    const modules = computed(() => {
      return store.getters["modules/getModules"];
    });

    const totalDebt = computed(() => {
      let total = 0;
      if (store.getters["modules/getIsTemplate"]) {
        total = store.getters["modules/getDefaultFileInfo"].pendingDebt;
      } else {
        files.value.forEach((item: any) => {
          total += item.pendingDebt;
        });
      }
      store.commit("modules/setAmountToPage", total);
      return total;
    });

    function selectFiles() {
      let filesArray: any = [];
      files.value.forEach((item: any) => {
        item.check ? filesArray.push(item) : "";
      });
      store.commit("modules/setSelectedFiles", filesArray);
    }

    function seeIntervenersFunction() {
      data.seeInterveners = !data.seeInterveners;
    }

    function goTo(value: string, relatedInterveners: any, productName: any) {
      value === "Interveners"
        ? store.commit("modules/setRelatedInterveners", relatedInterveners)
        : "";
      value === "Interveners"
        ? store.commit("modules/setProductName", productName)
        : "";
      router.push({ name: value });
    }
    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });
    const languageComputed = computed(() => data.languages);
    const currentSectionComputed = computed(() => data.currentSection);
    const seeIntervenersComputed = computed(() => data.seeInterveners);

    const headerMobileColors = computed(() => {
      return {
        // '--primary-color': `${store.getters["user/getMobileColors"].primary}`, // Definir una variable CSS
        "--primary-color": `${modules.value.welcomeMessage.debtSelector.htmlCustomizableContent.selector.backgroundColor}`,
        // Otros estilos según sea necesario
      };
    });

    const mobileColors = computed(() => {
      return store.getters["user/getMobileColors"];
    });

    return {
      ...toRefs(data),
      languageComputed,
      currentSectionComputed,
      seeIntervenersFunction,
      seeIntervenersComputed,
      goTo,
      Utils,
      totalDebt,
      selectFiles,
      modules,
      language,
      files,
      defaultFile,
      debtorInfo,
      loading,
      mobileColors,
      headerMobileColors,
    };
  },
};
