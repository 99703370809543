import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b895a482"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col justify-start items-start w-full mt-2" }
const _hoisted_2 = {
  key: 0,
  class: "text-xs text-gray-400 mb-1"
}
const _hoisted_3 = { class: "flex w-full items-center" }
const _hoisted_4 = { style: `background-color: white; color: black; border-radius: 15px;` }
const _hoisted_5 = { style: {"border-radius":"15px"} }
const _hoisted_6 = { colspan: "5" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_button = _resolveComponent("sp-button")!
  const _component_sp_icon = _resolveComponent("sp-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex justify-start items-start w-full flex-col", _ctx.disabled ? 'opacity-50': ''])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.modules && _ctx.modules.contact && _ctx.modules.contact.schedule)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.modules.contact.schedule.title[_ctx.language]), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          onFocus: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showCalendar && _ctx.showCalendar(...args))),
          type: "text",
          placeholder: '',
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dateForShowVariable) = $event)),
          class: "w-full bg-gray-100 border-none",
          style: {"height":"50px","border-top-left-radius":"0.5rem","border-bottom-left-radius":"0.5rem","border-top-right-radius":"0px","border-bottom-right-radius":"0px","color":"#939393"}
        }, null, 544), [
          [_vModelText, _ctx.dateForShowVariable]
        ]),
        _createVNode(_component_sp_button, {
          onOnClick: _ctx.showCalendar,
          class: "text-gray-400 bg-gray-100 flex items-center pr-3",
          model: 'calendar',
          size: "20",
          icon: 'calendar',
          color: '#f3f4f6',
          style: {"height":"50px","border-top-right-radius":"15px","border-bottom-right-radius":"15px"},
          label: ''
        }, null, 8, ["onOnClick"])
      ])
    ]),
    _createElementVNode("table", {
      class: _normalizeClass(`sp-calendar-ax absolute ${_ctx.showContent ? 'visible':'hidden'}`)
    }, [
      _createElementVNode("thead", _hoisted_4, [
        _createElementVNode("tr", _hoisted_5, [
          _createElementVNode("th", {
            class: "cursor-pointer",
            style: {"border-radius":"15px"},
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.prevMonth && _ctx.prevMonth(...args)))
          }, [
            _createVNode(_component_sp_icon, { model: "chevron-left" })
          ]),
          _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.$t(`months.${_ctx.month+1}`)), 1),
          _createElementVNode("th", {
            class: "cursor-pointer",
            style: {"border-radius":"15px"},
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.nextMonth && _ctx.nextMonth(...args)))
          }, [
            _createVNode(_component_sp_icon, { model: "chevron-right" })
          ])
        ]),
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.days, (day, index) => {
            return (_openBlock(), _createElementBlock("th", {
              style: {"color":"#D9D9D9 !important"},
              key: index
            }, _toDisplayString(day), 1))
          }), 128))
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.calendar, (week, index) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: index+'-week'
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(week, (day, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: index+'-day'
              }, [
                (_ctx.canBeIncluded(index))
                  ? (_openBlock(), _createElementBlock("td", {
                      key: 0,
                      class: _normalizeClass(`${_ctx.isDisableDate(day.date)?'disabled':'pointer'} ${_ctx.itIsMonth(day.date[1])? 'no-is-month':''}`),
                      onClick: ($event: any) => (_ctx.selectDate(day.date))
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(`date ${_ctx.selectDay === day.date[2]? 'select':''}`),
                        style: _normalizeStyle(`background-color: ${_ctx.selectDay === day.date[2]?_ctx.colorToUse:'white'}`)
                      }, _toDisplayString(day.date[2]), 7)
                    ], 10, _hoisted_7))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ]))
        }), 128))
      ])
    ], 2),
    (_ctx.showContent)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.showCalendar && _ctx.showCalendar(...args))),
          class: "z-10 fixed w-screen h-screen right-0 top-0 opacity-50"
        }))
      : _createCommentVNode("", true)
  ], 2))
}