
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import SpButton from "@/components/ui/SpButton.vue";
import { useStore } from "vuex";
import ContactPreferencesDaysAndTime from "@/components/modules/ContactPreferencesDaysAndTime.vue";
import SpTitle from "@/components/ui/SpTitle.vue";
import SpSelect from "@/components/ui/SpSelect.vue";
import dialphone from "../../assets/json/dial_phone_.json"
import axios from 'axios'

export default defineComponent({
  name: "ContactModule",
  components: { SpButton, ContactPreferencesDaysAndTime, SpTitle, SpSelect },
  props: {
    uuid: { type: String, required: true, default: "" },
    debtorInfo: { type: Object, required: true },
    phoneCall: { type: Boolean, required: true, default: true },
    title: { type: Object, required: true },
    subtitle: { type: Object, required: true},
    moreInfo: {type: Boolean, required: true, default: true},
    saturday: {type: Boolean, required: true, default: true},
    sunday: {type: Boolean, required: true, default: true},
    hideAddressForm: {type: Boolean, required: true, default: true}
  },
  emits: ["update"],
  setup(props, { emit }) {
    const store = useStore();
    const modules = computed(()=> {
        return store.getters["modules/getModules"]
      })

    interface contactModule {
      activeDays: Array<any>;
      address: string;
      daysAndTimeSlots: Array<any>;
      name: string;
      email: string;
      message: string;
      isNoContactActive: boolean;
      loadingSend: boolean;
      loadingNoContact: boolean;
      loadingCustomerPreferences: boolean;
      phone: string;
      sinceTheTime: string;
      showPhoneOptions: boolean;
      untilTheTime: string;
      prefixes: Array<any>
      prefix: any | null
      loading: boolean,
      typeStreet: string,
      streetTypes: Array<any>,
      street: string,
      number: string,
      province: string,
      provinces: Array<any>,
      city: string,
      postalCode: string,
      country: string,
      countries: Array<any>,
    }

    const data = reactive<contactModule>({
      activeDays: ['monday','tuesday','wednesday','thursday','friday','saturday','sunday'],
      address: "",
      daysAndTimeSlots: [],
      name: "",
      email: "",
      message: "",
      isNoContactActive: false,
      loadingSend: false,
      loadingNoContact: false,
      loadingCustomerPreferences: false,
      phone: "",
      sinceTheTime: "06:00",
      showPhoneOptions: true,
      untilTheTime: "07:00",
      prefixes: [],
      prefix: "+34",
      loading: false,
      typeStreet: "",
      streetTypes: [],
      street: "",
      number: "",
      province: "",
      provinces: [],
      city: "",
      postalCode: "",
      country: "",
      countries: [],
    });

    const language = computed(() => {
      return store.getters["user/getLanguage"];
    })

    const contactData = computed(() => {
      return store.getters["modules/getContactData"];
    })

    const customApi = computed(() => {
      return store.getters['modules/getCustomApi']
    })
    const generalModule = computed(() => {
      return store.getters['modules/getGeneral']
    })
    const minValue = computed(()=> {
      return contactData.value.schedule.hours.range[0] ? contactData.value.schedule.hours.range[0] : []
    })

    const maxValue = computed(()=> {
      return contactData.value.schedule.hours.range[contactData.value.schedule.hours.range.length -1]
    })

    function callToPrefixs () {
      data.loading= true
      const prefix = dialphone.filter((c: any, index: any) => {
              return dialphone.indexOf(c) === index;
          }).sort((a,b) => parseInt(a) -  parseInt(b))
      data.prefixes = prefix.map( (prefix: any) => {
          return {
              label: prefix,
              value: prefix
          }
      })
      data.loading= false
    }
    

    function changeCountry (countryId: string) {
      data.country = countryId
    }

    function changeProvince (provinceId: string) {
      data.province = provinceId
    }

    function changeStreetType (streetTypeId: string) {
      data.typeStreet = streetTypeId
    }

    function changeSelectedPrefix (uuid: string | null) {
      data.prefix = uuid
    }

    const selectedCountry = computed(() => {
      let result = { id: '', name: '' }
      data.countries.forEach(function (country: any) {
        if (data.country === country.value) {
          result = {
            id: country.value,
            name: country.label
          }
        }
      })
      return result
    })

    const selectedProvince = computed(() => {
      let result = { id: '', name: '' }
      data.provinces.forEach(function (province: any) {
        if (data.province === province.value) {
          result = {
            id: province.value,
            name: province.label
          }
        }
      })
      return result
    })

    const selectedStreetType = computed(() => {
      let result = { id: '', name: '' }
      data.streetTypes.forEach(function (streetType: any) {
        if (data.typeStreet === streetType.value) {
          result = {
            id: streetType.value,
            name: streetType.label
          }
        }
      })
      return result
    })

    function addMoreDaysAndTimeSlots() {
      data.daysAndTimeSlots.push({
        id: String(Date.now()),
        days: ['monday','tuesday','wednesday','thursday','friday','saturday','sunday'],
        sinceTheTime: "06:00",
        untilTheTime: "07:00",
      });
    }

    const isMobile = computed(() => {
      return store.getters["user/getIsMobile"];
    });

    function changeDayStatus(day: string) {
      if (data.activeDays.indexOf(day) !== -1) {
        data.activeDays.splice(data.activeDays.indexOf(day), 1);
      } else {
        data.activeDays.push(day);
      }
    }

    function getAddressMappings () {
      const params = {
        uuid: store.getters['user/getUUID'],
        language: language.value,
        callbackSuccess: (callbackSuccessData: any) => {
          let countriesResult: Array<any> = []
          callbackSuccessData.data.countries.forEach(function (country: any) {
            countriesResult.push({
              value: country.id,
              label: country.name
            })
          })
          data.countries = countriesResult

          let provincesResult: Array<any> = []
          callbackSuccessData.data.provinces.forEach(function (province: any) {
            provincesResult.push({
              value: province.id,
              label: province.name
            })
          })
          data.provinces = provincesResult

          let streetTypeResult: Array<any> = []
          callbackSuccessData.data.streetTypes.forEach(function (streetType: any) {
            streetTypeResult.push({
              value: streetType.id,
              label: streetType.name
            })
          })
          data.streetTypes = streetTypeResult
        },
        callbackError: () => {}
      }
      store.dispatch('modules/getAddressMappings', params)
    }

    function postSendCustomerCallPreferences() {
      let result: Array<any> = [];
      if (!customApi.value) {
        postSendContactEmail();
      } else {
        data.daysAndTimeSlots.forEach(function (item: any) {
          result.push({
            days: item.days,
            sinceTheTime: item.sinceTheTime,
            untilTheTime: item.untilTheTime,
          });
        });

        data.loadingCustomerPreferences = true;
        const params = {
          uuid: props.uuid,
          data: result,
          phone: data.phone,
          prefix: data.prefix,
          callbackSuccess: () => {
            data.loadingCustomerPreferences = false;
            postSendContactEmail();
          },
          callbackError: () => {
            data.loadingCustomerPreferences = false;
          },
        };
        store.dispatch("modules/postSendCustomerCallPreferences", params);
      }
    }

    function postNoContact() {
      data.loadingNoContact = true;
      const params = {
        uuid: props.uuid,
        callbackSuccess: () => {
          data.loadingNoContact = false;
          emit("update");
        },
        callbackError: () => {
          data.loadingNoContact = false;
        },
      };
      store.dispatch("modules/postNoContact", params);
    }

    function postSendContactEmail() {
      // if (data.name.length == 0 || data.phone.length == 0 || data.email.length == 0 ||
      //     (!props.hideAddressForm && (data.typeStreet.length == 0 || data.street.length == 0 || data.province.length == 0 ||
      //     data.city == '' || data.country == '' || data.number == '' || data.postalCode == ''))) {
      //   store.commit("user/pushNotification", {
      //     id: Date.now(),
      //     icon: "alert-triangle",
      //     message: "notifications.contactError2",
      //     color: "warning",
      //   });
      //   return;
      // }
      // if (data.phone.length > 0 && !phonenumber(data.phone) && data.prefix == null) {
      //   store.commit("user/pushNotification", {
      //     id: Date.now(),
      //     icon: "alert-triangle",
      //     message: "notifications.contactError3",
      //     color: "warning",
      //   });
      //   return 
      // }
      // if (data.email.length > 0 && !validateEmail()) {
      //   store.commit("user/pushNotification", {
      //     id: Date.now(),
      //     icon: "alert-triangle",
      //     message: "notifications.contactError1",
      //     color: "warning",
      //   });
      //   return;
      // }


      data.loadingSend = true;
      const params = {
        uuid: store.getters["user/getUUID"],
        phone: {prefix: data.prefix, number: data.phone},
        name: data.name,
        email: data.email,
        message: data.message,
        address: {
          streetTypeId: props.hideAddressForm ? "hideAddressForm":selectedStreetType.value.id,
          streetType: props.hideAddressForm ? "hideAddressForm":selectedStreetType.value.name,
          street: props.hideAddressForm ? "hideAddressForm":data.street,
          number: props.hideAddressForm ? "hideAddressForm":data.number,
          provinceId: props.hideAddressForm ? "hideAddressForm":selectedProvince.value.id,
          province: props.hideAddressForm ? "hideAddressForm":selectedProvince.value.name,
          town: props.hideAddressForm ? "hideAddressForm":data.city,
          cp: props.hideAddressForm ? "hideAddressForm":data.postalCode,
          countryId: props.hideAddressForm ? "hideAddressForm":selectedCountry.value.id,
          country: props.hideAddressForm ? "hideAddressForm":selectedCountry.value.name
        },
        callbackSuccess: () => {
          store.commit("user/pushNotification", {
            id: Date.now(),
            icon: "check",
            message: "notifications.contactSuccess1",
            color: "success",
          });
          data.phone = "";
          data.name = "";
          data.email = "";
          data.address = "";
          data.message = "";

          data.typeStreet = "";
          data.street = "";
          data.number = "";
          data.province = "";
          data.city = "";
          data.postalCode = "";
          data.country = "";
          data.prefix = "+34";
          data.loadingSend = false;
          data.daysAndTimeSlots = [{
            id: String(Date.now()),
            days: ['monday','tuesday','wednesday','thursday','friday','saturday','sunday'],
            sinceTheTime: "06:00",
            untilTheTime: "07:00",
          }];
        },
        callbackError: () => {
          data.loadingSend = false;
          store.commit("user/pushNotification", {
            id: Date.now(),
            icon: "check",
            message: "notifications.contactError2",
            color: "danger",
          });
        },
      };
      store.dispatch("modules/postSendContactEmail", params);
    }

    const primaryColor = computed(() => {
      return store.getters['user/getPrimaryColor']
    })

    function phonenumber(inputtxt: any) {
      var phoneno = /^(\+\d{1,2}\s?)?\d{9}$/;
      if(phoneno.test(inputtxt)) {
        return true;
      }  
      else {
        return false;
      }
    }
    function removeDaysAndTimeSlots (id: string) {
      let indexOfSlot: number = 0
      data.daysAndTimeSlots.forEach(function (item: any, index: number) {
        if (item.id === id) indexOfSlot = index;
      });
      data.daysAndTimeSlots.splice(indexOfSlot, 1);
    }

    function updateDaysAndTimeSlots(itemData: any) {
      data.daysAndTimeSlots.forEach(function (item: any, index: number) {
        if (item.id === itemData.id) {
          item.days = itemData.days;
          item.sinceTheTime = itemData.sinceTheTime;
          item.untilTheTime = itemData.untilTheTime;
        }
      });
    }

    function validateEmail() {
      return String(data.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }
    function isNumber(evt:any) {
      evt = (evt) ? evt : window.event;
      const regex = /[0-9]|\+|\(|\)/
      if (!regex.test(evt.key)) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
    if (props.debtorInfo !== null) {
      if ("contactPreferences" in props.debtorInfo) {
        if ("timeSchedules" in props.debtorInfo.contactPreferences) {
          data.daysAndTimeSlots = props.debtorInfo.contactPreferences.timeSchedules;
        } else {
          data.daysAndTimeSlots.push({
            id: String(Date.now()),
            days: ['monday','tuesday','wednesday','thursday','friday','saturday','sunday'],
            sinceTheTime: minValue.value,
            untilTheTime: maxValue.value,
          });
        }
      } else {
        data.daysAndTimeSlots.push({
            id: String(Date.now()),
            days: ['monday','tuesday','wednesday','thursday','friday','saturday','sunday'],
            sinceTheTime: minValue.value,
            untilTheTime: maxValue.value,
          });
      }
    } else {
      data.daysAndTimeSlots.push({
        id: String(Date.now()),
        days: ['monday','tuesday','wednesday','thursday','friday','saturday','sunday'],
        sinceTheTime: minValue.value,
        untilTheTime: maxValue.value,
      });
    }

    watch(
      language, (newValue) => getAddressMappings()
    );

    callToPrefixs()
    getAddressMappings()

    // watch(props.debtorInfo, ( newValue, oldValue ) => {})

    return {
      ...toRefs(data),
      addMoreDaysAndTimeSlots,
      isMobile,
      changeCountry,
      changeProvince,
      changeStreetType,
      changeDayStatus,
      postNoContact,
      postSendContactEmail,
      postSendCustomerCallPreferences,
      primaryColor,
      removeDaysAndTimeSlots,
      selectedCountry,
      selectedProvince,
      selectedStreetType,
      updateDaysAndTimeSlots,
      phonenumber,
      isNumber,
      changeSelectedPrefix,
      callToPrefixs,
      language,
      contactData,
      generalModule,
      modules,
      minValue,
      maxValue,
    };
  },
});
