
import {computed, defineComponent, reactive, toRefs} from 'vue'
import * as Utils from '@/api/Utils'
import {useStore} from "vuex";
import SpIcon from "@/components/ui/SpIcon.vue";

export default defineComponent({
  name: "FileSelectorCard",
  components: {SpIcon},
  props: {
    externalId: { type: String, required: true, default: '' },
    indexOfFile: { type: Number, required: true, default: 0 },
    debtStart: { type: String, required: true, default: '' },
    debtOwner: { type: String, required: true, default: '' },
    paidDebt: { required: true, default: 0 },
    pendingDebt: { required: true, default: 0 },
    totalDebt: { required: true, default: 0 },
    isActive: { type: Boolean, required: true, default: false },
    currency: { type: String, required: true, default: "EUR" },
    legalAgent: { type: Object, required: false
 },
  },
  emits: ['select'],
  setup (props, { emit }) {
    const store = useStore()

    const primaryColor = computed(() => {
      return store.getters['user/getPrimaryColor']
    })

    const debtSelector = computed(() => {
      return store.getters["modules/getDebtSelector"]
    })

    const language = computed(() => {
      return store.getters["user/getLanguage"]
    });

    const title = computed( ()=> {
      let result: string = debtSelector.value.card.title.en.slice()
      if (store.getters['user/getLanguage'] === 'es') result = debtSelector.value.card.title.es.slice()
      // if (store.getters['user/getLanguage'] === 'ru') result = debtSelector.value.card.title.ru.slice()
      
      result = result.replaceAll('{numOfDebt}', props.indexOfFile.toString())
      result = result.replaceAll('{debtAmount}',  props.legalAgent ? props.legalAgent.fpt.legalAgent : '')
      result = result.replaceAll('{legalAgent}',  props.legalAgent ? props.legalAgent.fpt.legalAgent : '')

      // for(let fpt in store.getters['modules/getSelectedFileData'].fpt) {
      //   result = result.replaceAll('{' + fpt + '}', )
      // }

      return result
    })

    const subtitle = computed( ()=> {
      let result: string = debtSelector.value.card.subtitle.en.slice()
      if (store.getters['user/getLanguage'] === 'es') result = debtSelector.value.card.subtitle.es.slice()
      if (store.getters['user/getLanguage'] === 'ru') result = debtSelector.value.card.subtitle.ru.slice()

      result = result.replaceAll('{debtStartDate}', Utils.formatDate(props.debtStart))
      result = result.replaceAll('{transferor}', props.debtOwner)

      for(let fpt in store.getters['modules/getSelectedFileData'].fpt) {
        result = result.replaceAll('{' + fpt + '}', store.getters['modules/getSelectedFileData'].fpt[fpt])
      }
      
      return result
    })

    function selectFile () {
      emit('select', props.externalId)
    }

    return {
      primaryColor,
      selectFile,
      Utils,
      debtSelector,
      title,
      subtitle,
      language
    }
  }
})
