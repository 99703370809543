interface userStore {
    isMobile: boolean,
    language: string,
    primaryColor: string,
    token: string | null,
    notifications: Array<any>,
    uuid: string,
    payment: number,
    customApi: boolean,
    stripeKey: string,
    mobilePrimaryColor: string,
    mobileSecondaryColor: string,
}

const UserStore = {
    namespaced: true,
    state: () => ({
        isMobile: false,
        language: localStorage.getItem("language") || 'en' || 'es',
        primaryColor: '#EC0000',
        token: '13c9988f065520060085154b1d66ee8c750b0a723c2d823be1ebe658db70671786df69577c91a8464fff35f6fa626d33727b724349c9397f9927cae0',
        notifications: [],
        uuid: '',
        payment: 0,
        customApi: false,
        stripeKey: '',
        mobilePrimaryColor: '#006DFF',
        mobileSecondaryColor: '',
    }),
    actions: {},
    getters: {
        getIsMobile (state: userStore): boolean {
            return state.isMobile
        },
        getLanguage (state: userStore): string {
            return state.language
        },
        getNotifications (state: userStore) {
            return state.notifications
        },
        getPrimaryColor (state: userStore) {
            return state.primaryColor
        },
        getToken (state: userStore): string | null {
            return state.token
        },
        getUUID (state: userStore): string | null {
            return state.uuid
        },
        getPaymentAmount (state: userStore) {
            return state.payment
        },
        getCustomApi (state: userStore) {
            return state.customApi
        },
        getStripePublicKey (state: userStore) {
            return state.stripeKey
        },
        getMobileColors (state: userStore) {
            return { primary: state.mobilePrimaryColor, secundary: state.mobileSecondaryColor }
        },
    },
    mutations: {
        pushNotification (state: userStore, data: Object) {
            state.notifications.push(data)
            let index: number = state.notifications.indexOf(data)
            setTimeout(function () {
                if (state.notifications.indexOf(data) >= 0) {
                    state.notifications.splice(state.notifications.indexOf(data), 1)
                }
            }, 5000)
        },
        setIsMobile (state: userStore, isMobile: boolean) {
            state.isMobile = isMobile
        },
        setPaymentAmount (state: userStore, payment: number) {
            state.payment = payment
        },
        setLanguage (state: userStore, language: string) {
            state.language = language
        },
        setCustomApi (state: userStore, customApi: boolean) {
            state.customApi = customApi
        },
        setPrimaryColor (state: userStore, primaryColor: string) {
            state.primaryColor = primaryColor
        },
        setToken (state: userStore, token: string) {
            state.token = token
        },
        spliceNotification (state: userStore, id: string) {
            let result: number = -1
            state.notifications.forEach(function (notification: any, index: any) {
                if (notification.id === id) result = index
            })
            if (result !== -1) state.notifications.splice(result, 1)
        },
        setUUID (state: userStore, uuid: string) {
            state.uuid = uuid
        },
        setStripePublicKey (state: userStore, stripeKey: string) {
            state.stripeKey = stripeKey
        },
    }
}

export default UserStore
