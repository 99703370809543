import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { colspan: "5" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_icon = _resolveComponent("sp-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative flex justify-start items-start flex-col", _ctx.disabled ? 'opacity-50': ''])
  }, [
    (_ctx.label.length > 0)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass([_ctx.titleGray ? 'text-sm text-gray-500' : 'text-sm'])
        }, _toDisplayString(_ctx.label), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showCalendar && _ctx.showCalendar(...args))),
      class: _normalizeClass(_ctx.siritSelectClass)
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.value), 1),
      _createVNode(_component_sp_icon, {
        model: _ctx.showContent ? 'chevron-up':'chevron-down',
        class: "ml-2"
      }, null, 8, ["model"])
    ], 2),
    _createElementVNode("table", {
      class: _normalizeClass(`sp-calendar absolute ${_ctx.showContent ? 'visible':'hidden'}`)
    }, [
      _createElementVNode("thead", {
        style: _normalizeStyle(`background-color: ${_ctx.colorToUse}`)
      }, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", {
            class: "cursor-pointer",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.prevMonth && _ctx.prevMonth(...args)))
          }, [
            _createVNode(_component_sp_icon, { model: "chevron-left" })
          ]),
          _createElementVNode("th", _hoisted_1, _toDisplayString(_ctx.$t(`months.${_ctx.month+1}`)) + " - " + _toDisplayString(_ctx.year), 1),
          _createElementVNode("th", {
            class: "cursor-pointer",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.nextMonth && _ctx.nextMonth(...args)))
          }, [
            _createVNode(_component_sp_icon, { model: "chevron-right" })
          ])
        ]),
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.days, (day, index) => {
            return (_openBlock(), _createElementBlock("th", { key: index }, _toDisplayString(day), 1))
          }), 128))
        ])
      ], 4),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.calendar, (week, index) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: index+'-week'
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(week, (day, index) => {
              return (_openBlock(), _createElementBlock("td", {
                key: index+'-day',
                class: _normalizeClass(`${_ctx.isDisableDate(day.date)?'disabled':''} ${_ctx.itIsMonth(day.date[1])? 'no-is-month':''}`),
                onClick: ($event: any) => (_ctx.selectDate(day.date))
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(`date ${_ctx.selectDay === day.date[2]? 'select':''}`),
                  style: _normalizeStyle(`background-color: ${_ctx.selectDay === day.date[2]?_ctx.colorToUse:'white'}`)
                }, _toDisplayString(day.date[2]), 7)
              ], 10, _hoisted_2))
            }), 128))
          ]))
        }), 128))
      ])
    ], 2),
    (_ctx.showContent)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.showCalendar && _ctx.showCalendar(...args))),
          class: "z-10 fixed w-screen h-screen right-0 top-0 opacity-50"
        }))
      : _createCommentVNode("", true)
  ], 2))
}