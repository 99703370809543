
import { computed, defineComponent, reactive, toRefs } from "vue";
import SpButton from "@/components/ui/SpButton.vue";
import SpTimePicker from "@/components/ui/SpTimePicker.vue";
import SpButtonIcon from "@/components/ui/SpButtonIcon.vue";
import store from "@/store";

export default defineComponent({
  name: "ContactPreferencesDaysAndTime",
  components: { SpTimePicker, SpButton },
  props: {
    id: { type: String, default: "" },
    days: { type: Array },
    sinceTime: { type: String, default: "" },
    untilTime: { type: String, default: "" },
    saturday: { type: Boolean, default: true},
    sunday: { type: Boolean, default: true},
  },
  emits: ["remove", "update"],
  setup(props, { emit }) {
    interface contactPreferencesDaysAndTime {
      activeDays: Array<any>;
      since: string;
      until: string;
    }

    const data = reactive<contactPreferencesDaysAndTime>({
      activeDays: props.days || ['monday','tuesday','wednesday','thursday','friday','saturday','sunday'],
      since: props.sinceTime,
      until: props.untilTime,
    });

    function changeDayStatus(day: string) {
      if (data.activeDays.indexOf(day) !== -1) {
        data.activeDays.splice(data.activeDays.indexOf(day), 1);
      } else {
        data.activeDays.push(day);
      }
      updateData();
    }
    const language = computed(() => {
      return store.getters["user/getLanguage"];
    })

    const contactData = computed(() => {
      return store.getters["modules/getContactData"];
    })

    function removeDaysAndTime() {
      emit("remove", props.id);
    }

    function updateData() {
      const split = data.since.split(":")[0]
      emit("update", {
        id: props.id,
        days: data.activeDays,
        sinceTheTime: data.since,
        untilTheTime: (parseInt(split) +1 < 10 ? "0"+(parseInt(split) +1):parseInt(split) +1)+":00"
      });
    }

    return {
      ...toRefs(data),
      changeDayStatus,
      removeDaysAndTime,
      updateData,
      contactData,
      language,
    };
  },
});
