
import {computed, defineComponent, reactive, toRefs} from 'vue'
import SpButton from '@/components/ui/SpButton.vue'
import SpButtonIcon from "@/components/ui/SpButtonIcon.vue";
import {useStore} from "vuex";
import SpTitle from "@/components/ui/SpTitle.vue";

export default defineComponent({
  name: "SupportModule",
  components: {SpButtonIcon, SpButton, SpTitle},
  props: {
    uuid: { type: String, required: true, default: '' },
    showVideoConference: { type: Boolean, required: true, default: true },
    videoconferenceUrl: { type: String, required: true, default: '' },
    showChat: { type: Boolean, required: true, default: true },
    chatUrlEn: { type: String, required: true, default: '' },
    chatUrlEs: { type: String, required: true, default: '' },
    chatUrlRu: { type: String, required: true, default: '' },
    title: { type: Object, required: true},
    subtitle: { type: Object, required: true },
    channels: { type: Object, required: true}
  },
  setup (props) {
    const store = useStore()

    const isMobile = computed(() => {
      return store.getters['user/getIsMobile']
    })

    const language = computed(() => {
      return store.getters['user/getLanguage']
    })

    const primaryColor = computed(() => {
      return store.getters['user/getPrimaryColor']
    })

    const modules = computed(()=> {
        return store.getters["modules/getModules"]
      })


    interface supportModule {
      content: string,
      loadingSendSupportContent: boolean,
      config: {
        videoconferenceUrl: string,
        chatUrlEn: string,
        chatUrlEs: string,
        chatUrlRu: string
      },
      loading: boolean
    }

    

    const data = reactive<supportModule>({
      content: '',
      loadingSendSupportContent: false,
      config: {
        videoconferenceUrl: props.videoconferenceUrl,
        chatUrlEn: props.chatUrlEn,
        chatUrlEs: props.chatUrlEs,
        chatUrlRu: props.chatUrlRu
      },
      loading: false
    })

    function openChat () {
      if (language.value === 'en') window.open(data.config.chatUrlEn, '_blank')
      if (language.value === 'es') window.open(data.config.chatUrlEs, '_blank')
      if (language.value === 'ru') window.open(data.config.chatUrlRu, '_blank')
    }

    

    function getJistiUrl() {
      data.loading = true;
      const params = {
        uuid: props.uuid,
        callbackSuccess: (callbackSuccessData: any) => {
          openVideoconference(callbackSuccessData.data.url)
          data.loading = false;
        },
        callbackError: () => {
          data.loading = false;
        },
      };
      store.dispatch("modules/getJistiUrl", params);
    }

    function openVideoconference (url: any) {
      window.open(url, '_blank')
    }

    function sendSupportContent () {
      if (data.content.length === 0) {
        store.commit('user/pushNotification', {
          id: Date.now(),
          icon: 'check',
          message: 'notifications.errorSendSupportMessage',
          color: 'warning'
        })
        return
      }

      data.loadingSendSupportContent = true
      const params = {
        uuid: props.uuid,
        message: data.content,
        callbackSuccess: () => {
          store.commit('user/pushNotification', {
            id: Date.now(),
            icon: 'check',
            message: 'notifications.successSendSupportMessage',
            color: 'success'
          })
          data.content = ''
          data.loadingSendSupportContent = false
        },
        callbackError: () => {
          data.loadingSendSupportContent = false
        }
      }
      store.dispatch('modules/postSendSupportContent', params)
    }

    return {
      ...toRefs(data),
      isMobile,
      language,
      primaryColor,
      openChat,
      openVideoconference,
      sendSupportContent,
      getJistiUrl,
      modules
    }
  }
})
