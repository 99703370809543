
import {computed, defineComponent, reactive, toRefs} from 'vue'
import SpButton from '@/components/ui/SpButton.vue'
import SpIcon from "@/components/ui/SpIcon.vue";
import {useStore} from "vuex";
import {postSendDebtConsolidationEmail} from "@/store/actions/ModulesActions";
import SpTitle from "@/components/ui/SpTitle.vue";

export default defineComponent({
  name: "DebtConsolidation",
  components: { SpIcon, SpButton, SpTitle },
  setup () {
    const store = useStore()

    interface debtConsolidation {
      text: string,
      loading: boolean
    }

    const data = reactive<debtConsolidation>({
      text: '',
      loading: false
    })

    const isMobile = computed(() => {
      return store.getters['user/getIsMobile']
    })

    function postSendDebtConsolidationEmail () {
      if (data.text.length === 0) {
        store.commit('user/pushNotification', {
          id: Date.now(),
          icon: 'alert-triangle',
          message: 'notifications.debtConsolidationError1',
          color: 'warning'
        })
        return
      }

      data.loading = true
      const params = {
        uuid: store.getters['user/getUUID'],
        text: data.text,
        callbackSuccess: () => {
          data.text = ''
          store.commit('user/pushNotification', {
            id: Date.now(),
            icon: 'check',
            message: 'notifications.debtConsolidationSuccess1',
            color: 'success'
          })
          data.loading = false
        },
        callbackError: () => {
          data.loading = false
        }
      }
      store.dispatch('modules/postSendDebtConsolidationEmail', params)
    }

    return {
      ...toRefs(data),
      isMobile,
      postSendDebtConsolidationEmail
    }
  }
})
