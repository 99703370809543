
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import { useStore } from "vuex";
import SpIcon from "@/components/ui/SpIcon.vue";
import SpButton from "@/components/ui/SpButton.vue";
import SupportPageHeaderAx from "@/components/axactorModulesMobile/SupportPageHeaderAx.vue";
import BottomNavigationBarAx from "@/components/axactorModulesMobile/BottomNavigationBarAx.vue";
import SpSelect from "@/components/ui/SpSelect.vue";
import SpInputRange from "@/components/axactorModulesMobile/SpInputRangeAxactor.vue";
import router from "@/router";
import * as Utils from "@/api/Utils";
import moment from "moment";

export default {
  name: "PaymentMethods",
  components: {
    SpIcon,
    SpButton,
    SpSelect,
    SpInputRange,
  },
  props: {
    uuid: { type: String, required: true },
  },
  setup(props: any) {
    const store = useStore();
    const modules = computed(() => {
      return store.getters["modules/getModules"];
    });

    interface PaymentMethods {
      loading: boolean;
      loadingPayment: boolean;
      languages: Array<any>;
      seeInterveners: boolean;
      selectedMethod: string;
      months: number;
      quantity: number;
      totalToPage: number;
      activePayment: boolean;
      quantityPartial: string;
      minValue: number;
      invalidValue: boolean;
      invalidNumber: boolean;
      minMonthsFinal: number;
      maxMonthsFinal: number;
      minAmountFinal: number;
      maxAmountFinal: number;
      valoresPermitidos: Array<any>;
      valueChangeProportional: boolean;
      paymentPlans: Array<any>;
    }

    const data = reactive<PaymentMethods>({
      loading: false,
      loadingPayment: false,
      languages: [],
      seeInterveners: false,
      selectedMethod: "PARTIAL",
      months: modules?.value?.paymentPlan?.minMonths,
      quantity: 0,
      totalToPage: 0,
      activePayment: true,
      quantityPartial: "",
      minValue: 50,
      invalidValue: false,
      invalidNumber: false,
      minMonthsFinal: props.minMonths,
      maxMonthsFinal: props.maxMonths,
      minAmountFinal: 0,
      maxAmountFinal: 0,
      valoresPermitidos: [],
      valueChangeProportional: false,
      paymentPlans: [],
    });

    function months() {
      if (
        Math.round(data.totalToPage / paymentPlanData.value.maxMonths) <
        paymentPlanData.value.minAmount
      ) {
        data.minAmountFinal = paymentPlanData.value.minAmount;
        data.maxMonthsFinal = Math.floor(
          data.totalToPage / data.minAmountFinal
        );

        data.minMonthsFinal = paymentPlanData.value.minMonths;
        data.minMonthsFinal > data.maxMonthsFinal
          ? (data.maxMonthsFinal = data.minMonthsFinal)
          : "";

        data.maxAmountFinal = data.totalToPage / data.minMonthsFinal;
        data.minAmountFinal =
          Math.ceil((data.totalToPage / data.maxMonthsFinal) * 100) / 100;

        for (let i = 2; i <= data.maxMonthsFinal; i++) {
          data.valoresPermitidos.push(data.totalToPage / i);
        }
      } else {
        data.maxAmountFinal =
          data.totalToPage / paymentPlanData.value.minMonths;
        data.minAmountFinal =
          Math.ceil(
            (data.totalToPage / paymentPlanData.value.maxMonths) * 100
          ) / 100;
        data.minMonthsFinal = paymentPlanData.value.minMonths;
        data.maxMonthsFinal = paymentPlanData.value.maxMonths;

        for (let i = 2; i <= data.maxMonthsFinal; i++) {
          data.valoresPermitidos.push(data.totalToPage / i);
        }
      }
    }

    const languageComputed = computed(() => data.languages);

    const paymentPlanData = computed(() => {
      return store.getters["modules/getPaymentPlanDataModule"]
        ? store.getters["modules/getPaymentPlanDataModule"]
        : "";
    });

    function getPaymentPlanData() {
      data.loadingPayment = true;
      const params = {
        uuid: "/api/landing_pages/" + props.uuid,
        // fileID: store.getters['modules/getFileUuid'],
        fileID: null,
        callbackSuccess: (callbackSuccessData: any) => {
          if (callbackSuccessData.data.length > 0)
            data.paymentPlans = callbackSuccessData.data;
          data.loadingPayment = false;
        },
        callbackError: () => {
          data.loadingPayment = false;
        },
      };
      store.dispatch("modules/getPaymentPlanData", params);
    }

    getPaymentPlanData();

    const seeButtons = computed(() => {
      let seeBoolean = true;
      data.paymentPlans.forEach((element: any) => {
        element.status === "approved" ? seeBoolean = false : ''
      });
      return seeBoolean;
    });

    const asignStatus = computed(() => {
      paymentPlanData.value.paymentPlansData.forEach((item: any) => {
        if (item.status === "approved")
          store.commit(
            "user/setPaymentAmount",
            item.paymentPlanDeadline.amount
          );
      });
    });

    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });

    const loading = computed(() => {
      return store.getters["modules/getLoading"];
    });

    function goTo(value: string) {
      router.push({ name: value });
    }

    function isValidValue(event: any) {
      const { target } = event;
      if (
        parseInt(target.value) < paymentPlanData.value.partialPayment.minAmount
      ) {
        data.invalidValue = true;
        return;
      }
      data.invalidValue = false;
    }

    function changePaymentMonths(months: number) {
      data.months = Number(months);
      data.quantity =
        data.months === 0
          ? data.totalToPage
          : Math.ceil((data.totalToPage / data.months) * 100) / 100;
    }

    function changePaymentQuantity(quantity: number) {
      if (modules.value.paymentPlan.proportionalSlider) {
        const valorActual = parseFloat(`${quantity}`);
        const valorCercano = data.valoresPermitidos.reduce((a, b) => {
          return Math.abs(b - valorActual) < Math.abs(a - valorActual) ? b : a;
        });
        data.quantity = valorCercano;
        data.valueChangeProportional = !data.valueChangeProportional;
        data.months = Math.ceil(data.totalToPage / data.quantity);
      } else {
        data.quantity = parseFloat(`${quantity}`);
        data.months = Math.ceil(data.totalToPage / quantity);
      }
    }

    function postCreatePaymentPlanData() {
      let type = "";

      if (data.selectedMethod === "PLAN") {
        type = "custom";
      }
      if (data.selectedMethod === "PARTIAL") {
        type = "partial";
      }
      if (data.selectedMethod === "UNIC") {
        type = "unified";
      }

      let frequency = "";
      if (data.selectedMethod === "PLAN") {
        frequency = "monthly";
      }
      if (data.selectedMethod === "PARTIAL") {
        frequency = "partial";
      }
      if (data.selectedMethod === "UNIC") {
        frequency = "single";
      }

      if (data.quantity > 0) {
        data.loadingPayment = true;
        const params = {
          uuid: "/api/landing_pages/" + props.uuid,
          customPaymentAmount:
            data.selectedMethod === "UNIC"
              ? data.totalToPage
              : parseInt(data.quantityPartial),
          customPaymentPeriodMonths: 1,
          preferredDueDay: moment().format("YYYY-MM-DD"),
          type: type,
          paymentFrequency: frequency,
          files: store.getters["modules/getFilesInfo"].map((file: any) => {
            return file.fileUuid;
          }),
          totalDebt: data.totalToPage,
          callbackSuccess: () => {
            goTo("PaymentPending");
            data.loadingPayment = false;
          },
          callbackError: () => {
            data.loadingPayment = false;
          },
        };
        store.dispatch("modules/postCreatePaymentPlanData", params);
      } else {
        store.commit("user/pushNotification", {
          id: Date.now(),
          icon: "alert-triangle",
          message: "notifications.payError",
          color: "warning",
        });
      }
    }

    function changeSelectedMethod(method: string) {
      data.selectedMethod = method;
    }

    function postTableData() {
      let frequency = "";
      if (data.selectedMethod === "PLAN") {
        frequency = "monthly";
      }
      if (data.selectedMethod === "PARTIAL") {
        frequency = "partial";
      }
      if (data.selectedMethod === "UNIC") {
        frequency = "single";
      }

      let type = "";
      if (data.selectedMethod === "PLAN") {
        type = "custom";
      }
      if (data.selectedMethod === "PARTIAL") {
        type = "partial";
      }
      if (data.selectedMethod === "UNIC") {
        type = "unified";
      }
      data.loadingPayment = true
      const params = {
        preferred_day: moment().format("YYYY-MM-DD"),
        monthly_cost:
          data.selectedMethod === "PARTIAL"
            ? data.quantityPartial
            : data.quantity,
        months: data.selectedMethod === "UNIC" ? 1 : data.months,
        itemsPerPage: modules.value.paymentPlan.maxMonths,
        page: 1,
        totalDebt: data.totalToPage,
        callbackSuccess: (callbackSuccessData: any) => {
          store.commit("modules/setPaymentPlansData", callbackSuccessData);
          store.commit("modules/setPaymentType", type);
          store.commit("modules/setPaymentFrequency", frequency);
          store.commit("modules/setPaymentMonths", data.months);
          data.loadingPayment = false;
          goTo("PaymentsResume");
        },
        callbackError: () => {
          data.loadingPayment = false;
        },
      };
      store.dispatch("modules/postPaymentPlanTable", params);
    }

    function postGetTotalToPay() {
      const params = {
        uuid: "/api/landing_pages/" + props.uuid,
        fileID: store.getters["modules/getFileUuid"],
        callbackSuccess: (callbackSuccessData: any) => {
          if (store.getters["modules/getIsTemplate"]) {
            data.totalToPage =
              store.getters["modules/getDefaultFileInfo"].pendingDebt;
            data.quantity =
              paymentPlanData.value && paymentPlanData.value.minMonths
                ? store.getters["modules/getDefaultFileInfo"].pendingDebt /
                  paymentPlanData.value.minMonths
                : 0;
            months();
          } else {
            data.totalToPage = callbackSuccessData.data.pendingTotalDebt;
            data.quantity =
              paymentPlanData.value && paymentPlanData.value.minMonths
                ? callbackSuccessData.data.pendingTotalDebt /
                  paymentPlanData.value.minMonths
                : 0;
            months();
          }
        },
        callbackError: () => {},
      };
      store.dispatch("modules/postGetTotalToPay", params);
    }

    watch(
      () => store.getters["modules/getFileUuid"],
      (first, second) => {
        postGetTotalToPay();
      }
    );

    function isNumber(evt: any) {
      evt = evt ? evt : window.event;
      const regex = /[0-9]|\+|\(|\)/;
      if (!regex.test(evt.key)) {
        evt.preventDefault();
        data.invalidNumber = true;
      } else {
        data.invalidNumber = false;
      }
    }

    const mobileColors = computed(() => {
      return store.getters["user/getMobileColors"];
    });

    const radioColors = computed(() => {
      return {
        "--primary-color": `${mobileColors.value.primary}`,
      };
    });

    postGetTotalToPay();

    return {
      ...toRefs(data),
      languageComputed,
      changePaymentMonths,
      changePaymentQuantity,
      goTo,
      Utils,
      postCreatePaymentPlanData,
      postGetTotalToPay,
      isValidValue,
      paymentPlanData,
      asignStatus,
      seeButtons,
      postTableData,
      modules,
      language,
      isNumber,
      changeSelectedMethod,
      loading,
      mobileColors,
      radioColors,
    };
  },
};
