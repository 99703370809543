
import { computed, defineComponent, reactive, toRefs } from "vue";
import SpButton from "@/components/ui/SpButton.vue";
import SpFloatingWindow from "@/components/ui/SpFloatingWindow.vue";
import { useStore } from "vuex";
import SpButtonIcon from "@/components/ui/SpButtonIcon.vue";
import SpTitle from "@/components/ui/SpTitle.vue";

export default defineComponent({
  name: "WhoIsSirit",
  components: { SpButtonIcon, SpButton, SpFloatingWindow, SpTitle },
  props: {
    showWhoIs: { type: Boolean, required: true, default: true },
    allowTitle: { type: Boolean, required: true, default: true },
    titleFontSize: { type: String, required: true, default: 'text-3xl' },
    titleColor: { type: String, required: true, default: '#000000' },
    titleContentEn: { type: String, required:true, default: '' },
    titleContentEs: { type: String, required:true, default: '' },
    titleContentRu: { type: String, required:true, default: '' },
    allowContent: { type: Boolean, required: true, default: true },
    contentColor: { type: String, required: true, default: '#000000' },
    contentFontSize: { type: String, required: true, default: 'text-3xl' },
    contentContentEn: { type: String, required:true, default: '' },
    contentContentEs: { type: String, required:true, default: '' },
    contentContentRu: { type: String, required:true, default: '' }
  },
  emits: ['goToContactUs'],
  setup (props, { emit }) {
    const store = useStore()

    const isMobile = computed(() => {
      return store.getters['user/getIsMobile']
    })

    const language = computed(() => {
      return store.getters['user/getLanguage']
    })
   
    function goToContactUs () {
      emit('goToContactUs')
    }

    return {
      isMobile,
      language,
      goToContactUs
    }
  }
})
