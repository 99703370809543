
import {computed, defineComponent, reactive, toRefs} from 'vue'
import SpButtonIcon from '@/components/ui/SpButtonIcon.vue'
import SpIcon from '@/components/ui/SpIcon.vue'
import {useStore} from 'vuex'

export default defineComponent({
  name: "SpNotificationCard",
  components: { SpButtonIcon, SpIcon },
  props: {
    notification: { type: Object, required: true }
  },
  setup (props) {
    const store = useStore()

    interface spNotificationCard {
      percentage: number
    }

    const data = reactive<spNotificationCard>({
      percentage: 0
    })


    function closeNotification (id: string) {
      store.commit('user/spliceNotification', id)
    }

    const percentageSize = computed(() => {
      if (data.percentage < 100) {
        return data.percentage
      } else {
        return 100
      }
    })

    function showPercentage () {
      if (data.percentage < 100) {
        data.percentage += 10
        setTimeout(function () {
          showPercentage()
        }, 500)
      }
    }

    const spNotificationCardClass = computed(() => {
      if ('color' in props.notification) {
        return 'sp-notification-card-' + props.notification.color
      } else {
        return 'sp-notification-card-primary'
      }
    })

    const spNotificationCardFooterClass = computed(() => {
      if ('color' in props.notification) {
        return 'sp-notification-card-' + props.notification.color + '-footer'
      } else {
        return 'sp-notification-card-primary-footer'
      }
    })

    showPercentage()

    return {
      ...toRefs(data),
      closeNotification,
      percentageSize,
      spNotificationCardClass,
      spNotificationCardFooterClass
    }
  }
})
