import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c5b154e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-table flex justify-center items-center" }
const _hoisted_2 = { style: {"font-weight":"bold","margin-left":".2em"} }
const _hoisted_3 = {
  key: 0,
  class: "flex justify-center items-center"
}
const _hoisted_4 = { class: "text-xs w-full ml-1 mr-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_icon = _resolveComponent("sp-icon")!
  const _component_sp_button_icon = _resolveComponent("sp-button-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("section", {
        key: JSON.stringify(item)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(item), ([label, value], index) => {
          return (_openBlock(), _createElementBlock("article", {
            key: `${index}${label}`
          }, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(label), 1),
            _createElementVNode("p", null, _toDisplayString(value), 1)
          ]))
        }), 128))
      ]))
    }), 128)),
    (_ctx.pagination)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_sp_icon, {
            model: "refresh-cw",
            class: "",
            rotate: ""
          }),
          _createVNode(_component_sp_button_icon, {
            icon: "chevrons-left",
            color: "clean",
            size: "17",
            disabled: _ctx.disablePreviousPage,
            onOnClick: _ctx.getFirstPage
          }, null, 8, ["disabled", "onOnClick"]),
          _createVNode(_component_sp_button_icon, {
            onOnClick: _ctx.getPreviousPage,
            icon: "chevron-left",
            color: "clean",
            disabled: _ctx.disablePreviousPage,
            class: "ml-1 mr-1",
            size: "17"
          }, null, 8, ["onOnClick", "disabled"]),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.page) + " of " + _toDisplayString(_ctx.maxPages), 1),
          _createVNode(_component_sp_button_icon, {
            onOnClick: _ctx.getNextPage,
            icon: "chevron-right",
            color: "clean",
            class: "ml-1 mr-1",
            size: "17"
          }, null, 8, ["onOnClick"]),
          _createVNode(_component_sp_button_icon, {
            onOnClick: _ctx.getLastPage,
            icon: "chevrons-right",
            color: "clean",
            size: "17"
          }, null, 8, ["onOnClick"])
        ]))
      : _createCommentVNode("", true)
  ]))
}