
import {computed, defineComponent, reactive, toRefs} from 'vue'
import {useStore} from "vuex";
import SpTitle from "@/components/ui/SpTitle.vue";
import * as Utils from "@/api/Utils";

export default defineComponent({
  name: "ActionsHistory",
  components: { SpTitle },
  props: {
    uuid: { type: String, required: true, default: "" },
    currency: { type: String, required: true, default: "EUR" },
    title: { type: Object, required: true },
    titleColor: { type: String, required: true},
    subtitle: { type: Object, required: true },
    infoText: { type: Object, required: true },
    textAmount: { type: Object, required: true },
    textDate: { type: Object, required: true },
    noRecordsText: { type: Object, required: true },
  },
  setup (props, { emit }) {
    const store = useStore()

    const isMobile = computed(() => {
      return store.getters['user/getIsMobile']
    })

    interface actionsHistory {
      actions: Array<any>
    }

    const data = reactive<actionsHistory>({
      actions: []
    })

    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });

    function getHistoryPayment () {
      const params = {
        uuid: props.uuid,
        callbackSuccess: (callbackSuccessData: any) => {
          data.actions = callbackSuccessData.data.items ? callbackSuccessData.data.items : []
        },
        callbackError: () => {}
      }
      store.dispatch('modules/getHistoryPayment', params)
    }

    getHistoryPayment()

    return {
      ...toRefs(data),
      isMobile,
      getHistoryPayment,
      Utils,
      language
    }
  }
})
