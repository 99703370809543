
import {computed, defineComponent, reactive, toRefs} from 'vue'
import SpButtonIcon from '@/components/ui/SpButtonIcon.vue'
import {useStore} from 'vuex'

export default defineComponent({
  name: "MinimizedVideoChatBot",
  components: { SpButtonIcon },
  props: {
    firstTime: { type: Boolean, required: false, default: false }
  },
  setup () {
    const store = useStore()

    const isMobile = computed(() => {
      return store.getters['user/getIsMobile']
    })

    interface minimizedVideoChatBot {
      language: string,
      show: boolean
    }

    const data = reactive<minimizedVideoChatBot>({
      language: store.getters['user/getLanguage'],
      show: true
    })

    return {
      ...toRefs(data),
      isMobile
    }
  }
})
