
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import { useStore } from "vuex";
import i18n from "@/main";
import SpIcon from "@/components/ui/SpIcon.vue";
import SpButtonIcon from "@/components/ui/SpButtonIcon.vue";
import router from "@/router";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "SupportPageHeaderAx",
  components: { SpIcon, SpButtonIcon },
  props: {
    arrowIcon: { type: Boolean, required: false, default: false },
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();

    const viewsForBottomNavigationBar = computed(() => {
      let excludedPathViews = [
        "check_pin_ko",
        "check_id_ko",
        "no_data",
        "authentication"
      ];

      let result = false;
      excludedPathViews.forEach((view) => {
        if (route.fullPath.includes(view)) result = true;
      });
      return result;
    });

    const mobileColors = computed(() => {
      return store.getters["user/getMobileColors"];
    });

    const isMobile = computed(() => {
      return store.getters["user/getIsMobile"];
    });

    const fallbackContactInfo = computed(() => {
      return store.getters["security/getFallbackContactInfo"];
    });

    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });

    const modules = computed(()=> {
      return store.getters["modules/getModules"]
    })

    interface supportPageHeaderAx {
      languageObject: {
        en: boolean;
        es: boolean;
        ru: boolean;
      };
      defaultLanguge: string;
      show: boolean;

      showBottomFixedNavbar: boolean;
      currentSection: string;
      showDebtSelector: number;
      loadingAuth: boolean;
      authMode: string;
      actionsHistory: {
        index: number;
        enabled: boolean;

        title: string;
        subtitle: string;
        items: Array<any>;
      };
      callMeNowKey: number;
      contactUs: {
        index: number;
        enabled: boolean;
        title: string;
        description: string;
        termsOfUse: boolean;
        privacyPolicy: boolean;
        cookies: boolean;
        moreInfo: boolean;
        saturday: boolean;
        sunday: boolean;
        hideAddressForm: boolean;
        callMeNow: any;
      };
      currency: string;
      debtor: {
        index: number;
        enabled: boolean;
        debtors: Array<any>;
      };
      debtAmount: number;
      debtConsolidation: {
        index: number;
        enabled: boolean;
        title: string;
        description: string;
      };
      debtorInfo: any;
      debtOwner: string;
      defaultData: {
        phone: string;
        email: string;
        address: string;
      };
      lastVisited: "";
      languages: Array<any>;
      modulesToShow: Array<any>;
      files: {
        index: number;
        enabled: boolean;
        title: any;
        subtitle: any;
        requestMessage: string;
      };
      fileExternalId: string;
      firstLoading: boolean;
      faqs: {
        index: number;
        enabled: boolean;
        title: string;
        subtitle: string;
        items: Array<any>;
      };
      footer: {
        index: number;
        enabled: boolean;
        allowContactUs: boolean;
        allowWhoIsSirit: boolean;
        cookiesPolicyUrl: string;
        privacyPolicyUrl: string;
        termsOfUsePolicyUrl: string;
      };
      jsonData: any;
      landingPageUuid: string;
      loading: boolean;
      paidDebt: number;
      paymentMethod: string;
      paymentPlan: {
        index: number;
        enabled: true;
        title: string;
        subtitle: string;
        moduleHeader: string;
        minMonths: number;
        maxMonths: number;
        maxFeeToPay: number;
        minFeeToPay: number;
        options: Array<any>;
        allowSelection: boolean;
        removalsLimitDate: string;
        removalsPercentage: number;
        planLimitDays: number;
        paymentPlanTableHtml: any;
      };
      paymentPlanMethod: {
        index: number;
        enabled: boolean;
        allowBizum: boolean;
        allowPayPal: boolean;
        allowCreditCard: boolean;
        allowVenmo: boolean;
        title: any;
        subtitle: any;
      };
      paymentPlantProposal: string;
      pinAuthenticationIsFinished: boolean;
      primaryColor: string;
      removals: {
        enabled: boolean;
        index: number;
      };
      saveAsFavourite: {
        index: number;
        enabled: boolean;
        icon: string;
        color: string;
        fontSize: string;
        url: string;
        message: string;
      };
      support: {
        index: number;
        enabled: boolean;
        allowVideoConference: boolean;
        videoconferenceUrl: string;
        allowChat: boolean;
        chatUrlEn: string;
        chatUrlEs: string;
        chatUrlRu: string;
        title: any;
        subtitle: any;
        channels: any;
      };
      supportPageHeader: {
        enabled: boolean;
        index: number;
        platformName: {
          enabled: boolean;
          logo: string;
        };
        supportBy: {
          enabled: false;
          companies: Array<any>;
        };
      };
      updatedDate: any;
      uploadDocuments: Array<any>;
      welcomeTitle: {
        enabled: boolean;
        index: number;
        url: string;
        title: {
          enabled: boolean;
          color: string;
          fontSize: string;
          message: string;
        };
        description: {
          enabled: boolean;
          color: string;
          fontSize: string;
          message: string;
        };
      };
      welcomeText: {
        index: number;
        enabled: boolean;
        icon: string;
        fontSize: string;
        userName: string;
        errorLink: string;
        debtAmount: number;
        currency: string;
        taxType: string;
        message: string;
        debtType: string;
        debtContractedWith: string;
        debtDate: string;
        debtorName: string;
        pendingDebt: string;
        otherFiles: Array<any>;
        agent: string;
        procecuted: any;
        debtSelector: any;
      };
      whoIs: {
        index: number;
        enabled: boolean;
        title: {
          allow: boolean;
          color: string;
          fontSize: string;
          content: {
            en: string;
            es: string;
            ru: string;
          };
        };
        content: {
          allow: boolean;
          color: string;
          fontSize: string;
          content: {
            en: string;
            es: string;
            ru: string;
          };
        };
      };
      pinContentHtml: object;
      isCustomApi: boolean;
      variablesForVerifyPayment: {
        Ds_SignatureVersion: any;
        Ds_MerchantParameters: any;
        Ds_Signature: any;
        deadLineId: any;
        fileId: any;
      };
      isTemplate: boolean;
      isStripe: boolean;
      query: {
        state: any;
        deadline: any;
        landing: any;
        amount: any;
        file: any;
      };
      showPaymentStripeModal: boolean;
      phonesContact: any;
      emailsContact: any;
      sendMethod: string;
      pinPageCustomization: any;
      customApi: boolean;
      dynamicTemplate: string;
      authModeNew: string;
    }

    const data = reactive<supportPageHeaderAx>({
      languageObject: {
        en: false,
        es: false,
        ru: false,
      },
      defaultLanguge: "",
      show: false,

      showBottomFixedNavbar: false,
      currentSection: "",
      showDebtSelector: 0,
      authMode: "",
      loadingAuth: false,
      actionsHistory: {
        index: 0,
        enabled: false,
        title: "",
        subtitle: "",
        items: [],
      },
      callMeNowKey: 0,
      contactUs: {
        index: 0,
        enabled: false,
        title: "",
        description: "",
        termsOfUse: false,
        privacyPolicy: false,
        cookies: false,
        moreInfo: true,
        saturday: true,
        sunday: true,
        hideAddressForm: false,
        callMeNow: {},
      },
      currency: "USD",
      debtAmount: 23392,
      debtConsolidation: {
        index: 0,
        enabled: false,
        title: "",
        description: "",
      },
      debtor: {
        index: 0,
        enabled: true,
        debtors: [],
      },
      debtorInfo: null,
      debtOwner: "",
      defaultData: {
        phone: "",
        email: "",
        address: "",
      },
      files: {
        index: 0,
        enabled: true,
        requestMessage: "",
        title: { en: "", es: "", ru: "" },
        subtitle: { en: "", es: "", ru: "" },
      },
      fileExternalId: store.getters["modules/getFileExternalId"],
      firstLoading: false,
      faqs: {
        index: 0,
        enabled: false,
        title: "",
        subtitle: "",
        items: [],
      },
      footer: {
        index: 0,
        enabled: true,
        allowContactUs: true,
        allowWhoIsSirit: true,
        cookiesPolicyUrl: "",
        privacyPolicyUrl: "",
        termsOfUsePolicyUrl: "",
      },
      lastVisited: "",
      languages: [],
      modulesToShow: [],
      jsonData: {},
      landingPageUuid: "",
      loading: false,
      paidDebt: 0,
      paymentMethod: "",
      paymentPlan: {
        index: 0,
        enabled: true,
        title: "",
        subtitle: "",
        moduleHeader: "",
        minMonths: 0,
        maxMonths: 0,
        maxFeeToPay: 0,
        minFeeToPay: 0,
        options: [],
        allowSelection: true,
        removalsLimitDate: "",
        removalsPercentage: 0,
        planLimitDays: 1,
        paymentPlanTableHtml: null,
      },
      paymentPlanMethod: {
        index: 7,
        enabled: true,
        allowBizum: true,
        allowPayPal: true,
        allowCreditCard: true,
        allowVenmo: true,
        title: { es: "", en: "", ru: "" },
        subtitle: { es: "", en: "", ru: "" },
      },
      paymentPlantProposal: "",
      pinAuthenticationIsFinished: false,
      primaryColor: "#EC0000",
      removals: {
        enabled: true,
        index: 0,
      },
      saveAsFavourite: {
        index: 0,
        enabled: false,
        icon: "",
        color: "",
        fontSize: "",
        url: "",
        message: "",
      },
      support: {
        index: 0,
        enabled: true,
        title: { en: "", es: "", ru: "" },
        subtitle: { en: "", es: "", ru: "" },
        channels: {
          video: {},
          chat: {},
          message: {},
        },
        allowVideoConference: true,
        videoconferenceUrl: "",
        allowChat: true,
        chatUrlEn: "",
        chatUrlEs: "",
        chatUrlRu: "",
      },
      supportPageHeader: {
        enabled: false,
        index: 0,
        platformName: {
          enabled: false,
          logo: "",
        },
        supportBy: {
          enabled: false,
          companies: [],
        },
      },
      updatedDate: new Date(),
      uploadDocuments: [],
      welcomeTitle: {
        enabled: false,
        index: 0,
        url: "",
        title: {
          enabled: false,
          color: "",
          fontSize: "6xl",
          message: "",
        },
        description: {
          enabled: false,
          color: "",
          fontSize: "",
          message: "",
        },
      },
      welcomeText: {
        index: 0,
        enabled: false,
        icon: "",
        fontSize: "",
        userName: "",
        errorLink: "",
        debtAmount: 0,
        currency: "",
        taxType: "",
        message: "",
        debtType: "",
        debtContractedWith: "",
        debtDate: "",
        debtorName: "",
        pendingDebt: "",
        otherFiles: [],
        agent: "",
        procecuted: "",
        debtSelector: {},
      },
      whoIs: {
        index: 0,
        enabled: false,
        title: {
          allow: true,
          color: "#000000",
          fontSize: "",
          content: {
            en: "",
            es: "",
            ru: "",
          },
        },
        content: {
          allow: false,
          color: "",
          fontSize: "#000000",
          content: {
            en: "",
            es: "",
            ru: "",
          },
        },
      },
      pinContentHtml: {
        en: "",
        es: "",
        ru: "",
      },
      isCustomApi: false,
      variablesForVerifyPayment: {
        Ds_SignatureVersion: "",
        Ds_MerchantParameters: "",
        Ds_Signature: "",
        deadLineId: "",
        fileId: "",
      },
      isTemplate: false,
      isStripe: false,
      query: {
        state: "",
        deadline: "",
        landing: "",
        amount: 0,
        file: "",
      },
      showPaymentStripeModal: false,
      phonesContact: [],
      emailsContact: [],
      sendMethod: "",
      pinPageCustomization: {
        colorId: "",
        colorPin: "",
      },
      customApi: false,
      dynamicTemplate: "",
      authModeNew: "",
    });

    const showLanguages = computed(()=> {
      return data.authMode === 'default' || data.authMode === 'pin+uniqueId' || data.authMode === 'noAuth'|| data.authMode === 'dni+birthday' && data.supportPageHeader.enabled
    })

    const { query } = router.currentRoute.value;
    if (query) {
      store.commit("user/setUUID", route.params.uuid);
      getLandingAuth();
    }

    const id = computed(() => route.params.uuid);

    function goTo(value: string) {
      data.show = false;
      router.push({ name: value });
    }
    function changeLocale(locale: any) {
      store.commit("user/setLanguage", locale);
      localStorage.setItem("language", locale);
      i18n.global.locale = locale;
    }

    function setEnabledLanguages() {
      data.languages.forEach((lang: any) => {
        data.languageObject = {
          ...data.languageObject,
          [lang]: true,
        };
      });
    }

    const showButtons = () => {
      data.show = !data.show;
    };

    const isLogin = computed(()=> {
      const url = useRoute().path?.toString()
      if(!url) return false
      return url.includes('/authentication')
    })

    function getLandingAuth() {
      data.loadingAuth = true;
      store.commit( "modules/setLoading", data.loadingAuth);
      const params = {
        uuid: store.getters["user/getUUID"],
        callbackSuccess: (callbackSuccessData: any) => {
          data.authMode = callbackSuccessData.data.authMode;
          if (callbackSuccessData.data.authMode === "default") {
            // postSendPin()
            data.defaultLanguge = callbackSuccessData.data.defaultLanguage;
          } else if (callbackSuccessData.data.authMode === "pin+uniqueId") {
            // data.authMode = callbackSuccessData.data.authMode;
            data.phonesContact = callbackSuccessData.data.phones.map(
              (phone: any, index: number) => ({
                label: phone,
                value: index,
              })
            );
            data.emailsContact = callbackSuccessData.data.emails.map(
              (email: any, index: number) => ({
                label: email,
                value: index,
              })
            );
            data.sendMethod = callbackSuccessData.data.sendMethod;
            store.commit(
              "modules/setPhonesContacts",
              callbackSuccessData.data.phones.map(
                (phone: any, index: number) => ({
                  label: phone,
                  value: index,
                })
              )
            );
            store.commit(
              "modules/setEmailsContacts",
              callbackSuccessData.data.emails.map(
                (email: any, index: number) => ({
                  label: email,
                  value: index,
                })
              )
            );
            store.commit(
              "modules/setSendMethod",
              callbackSuccessData.data.sendMethod
            );
          } else if ("token" in callbackSuccessData.data) {
            store.commit("security/setToken", callbackSuccessData.data.token);
            data.pinAuthenticationIsFinished = true;
            getLandingConfiguration()
          }
          data.loadingAuth = false;
          getGeneralModule()
        },

        callbackError: (callbackErrorData: any) => {
          data.loadingAuth = false;
          if (callbackErrorData.data.text) {
            store.commit(
              "security/setFallbackContactInfoLogo",
              callbackErrorData.data.logo
            );
            store.commit(
              "security/setFallbackContactInfoAddress",
              callbackErrorData.data.address
            );
            store.commit(
              "security/setFallbackContactInfoPhone",
              callbackErrorData.data.phone
            );
            store.commit(
              "security/setFallbackContactInfoEmail",
              callbackErrorData.data.email
            );
            store.commit(
              "security/setFallbackContactInfoTextRu",
              callbackErrorData.data.text.ru
            );
            store.commit(
              "security/setFallbackContactInfoTextEs",
              callbackErrorData.data.text.es
            );
            store.commit(
              "security/setFallbackContactInfoTextEn",
              callbackErrorData.data.text.en
            );
            router.push({ name: "GoError403" });
          }
        },
      };
      // redirectToViewBasedOnTemplate(props.uuid);
      store.dispatch("modules/getLandingAuth", params);
    }

    function getLandingConfiguration() {
      data.loading = true;
      const params = {
        uuid: store.getters["user/getUUID"],
        callbackSuccess: (callbackSuccessData: any) => {
          data.firstLoading = true;
          if (callbackSuccessData.data.debtorInfo !== null) {
            data.debtorInfo = callbackSuccessData.data.debtorInfo;
            store.commit(
              "modules/setDebtorInfo",
              callbackSuccessData.data.debtorInfo
              );
            }
            data.landingPageUuid = callbackSuccessData.data["@id"];
            store.commit("modules/setModules", callbackSuccessData.data.modules);
            store.commit("user/setUUID", callbackSuccessData.data["@id"]);
            store.commit(
              "user/setLanguage",
              callbackSuccessData.data.modules.general.defaultLanguage
              );
              data.languages = callbackSuccessData.data.modules.general.extraLanguages
              data.isCustomApi = callbackSuccessData.data.modules.general.customApi;
              store.commit(
                "user/setCustomApi",
                callbackSuccessData.data.modules.general.customApi
                );
                data.currency = callbackSuccessData.data.modules.general.currency;
                data.isStripe =
                callbackSuccessData.data.modules.general.paymentConfig.platform ===
                "stripe"
                ? true
                : false;
                if (
                  callbackSuccessData.data.modules.general.paymentConfig.platform ===
                  "stripe"
                  ) {
                    store.commit(
                      "user/setStripePublicKey",
                      callbackSuccessData.data.modules.general.paymentConfig.public_key
                      );
          }

          i18n.global.locale =
            callbackSuccessData.data.modules.general.defaultLanguage;
          data.languages =
            callbackSuccessData.data.modules.general.extraLanguages;
          data.updatedDate = callbackSuccessData.data.updated;
          data.jsonData = callbackSuccessData.data.modules;
          data.primaryColor =
            callbackSuccessData.data.modules.general.primaryColor;
          store.commit(
            "user/setPrimaryColor",
            callbackSuccessData.data.modules.general.primaryColor
          );
          data.showBottomFixedNavbar =
            callbackSuccessData.data.modules.general.showBottomFixedNavbar;
          store.commit(
            "modules/setSendMethod",
            callbackSuccessData.data.authMode
          );
          store.commit(
            "modules/setGeneral",
            callbackSuccessData.data.modules.general
          );

          data.isTemplate = callbackSuccessData.data.template;
          store.commit("modules/setIsTemplate", callbackSuccessData.data.template)
          // getFilesToAddToPayment();

          let filesInfoResult: Array<any> = [];
          for (let fileKey in callbackSuccessData.data.filesInfo) {
            filesInfoResult.push(callbackSuccessData.data.filesInfo[fileKey]);
          }

          store.commit("modules/setFilesInfo", filesInfoResult);

          if (callbackSuccessData.data.fileUuid !== null)
            store.commit(
              "modules/setFileUuid",
              callbackSuccessData.data.fileUuid
            );
          store.commit(
            "modules/setDefaultFileInfo",
            callbackSuccessData.data.modules.intervenerAndFilesInfo.filesInfo
          );

          store.commit("modules/setFaqs", callbackSuccessData.data.modules.faqs.collection)

          if (callbackSuccessData.data.lastVisited !== null)
            data.lastVisited = callbackSuccessData.data.lastVisited;

          data.modulesToShow = [];
          if ("documents" in callbackSuccessData.data) {
            data.uploadDocuments = callbackSuccessData.data.documents;
          }

          data.supportPageHeader =
            callbackSuccessData.data.modules.supportPageHeader;
          if (data.supportPageHeader.enabled)
            data.modulesToShow[
              callbackSuccessData.data.modules.supportPageHeader.index
            ] = "supportPageHeader";

          if(callbackSuccessData.data.modules.supportPageHeader && callbackSuccessData.data.modules.supportPageHeader.logo) {
            store.commit(
              "security/setFallbackContactInfoLogo",
              callbackSuccessData.data.modules.supportPageHeader.logo
            );
          }

          data.welcomeTitle = callbackSuccessData.data.modules.welcomeTitle;
          if (data.welcomeTitle.enabled)
            data.modulesToShow[
              callbackSuccessData.data.modules.welcomeTitle.index
            ] = "welcomeTitle";

          data.welcomeText = callbackSuccessData.data.modules.welcomeMessage;
          if (data.welcomeText.enabled)
            data.modulesToShow[
              callbackSuccessData.data.modules.welcomeMessage.index
            ] = "welcomeText";
          if (data.showDebtSelector === 0)
            data.showDebtSelector =
              callbackSuccessData.data.modules.welcomeMessage.index;
          store.commit(
            "modules/setDebtSelector",
            callbackSuccessData.data.modules.welcomeMessage.debtSelector
          );

          if (
            "debtorInfo" in callbackSuccessData.data &&
            callbackSuccessData.data.debtorInfo !== null
          ) {
            let firstname: string =
              callbackSuccessData.data.debtorInfo.firstname === null
                ? ""
                : callbackSuccessData.data.debtorInfo.firstname;
            let lastname: string =
              callbackSuccessData.data.debtorInfo.lastname === null
                ? ""
                : callbackSuccessData.data.debtorInfo.lastname;
            data.welcomeText.debtorName = firstname + " " + lastname;
          }

          if (
            "filesInfo" in callbackSuccessData.data &&
            callbackSuccessData.data.filesInfo !== null &&
            callbackSuccessData.data.filesInfo.length > 0
          ) {
            data.welcomeText.debtAmount =
              callbackSuccessData.data.filesInfo[
                store.getters["modules/getFileUuid"]
              ].totalDebt;
            data.welcomeText.pendingDebt =
              callbackSuccessData.data.filesInfo[
                store.getters["modules/getFileUuid"]
              ].pendingDebt;
            data.welcomeText.otherFiles =
              callbackSuccessData.data.filesInfo[
                store.getters["modules/getFileUuid"]
              ].otherFiles;
            data.welcomeText.debtDate =
              callbackSuccessData.data.filesInfo[
                store.getters["modules/getFileUuid"]
              ].debtStartDate;
            data.welcomeText.debtType =
              callbackSuccessData.data.filesInfo[
                store.getters["modules/getFileUuid"]
              ].debtType;
            data.welcomeText.agent =
              callbackSuccessData.data.filesInfo[
                store.getters["modules/getFileUuid"]
              ].agent;
            data.welcomeText.procecuted =
              callbackSuccessData.data.filesInfo[
                store.getters["modules/getFileUuid"]
              ].procecuted;
            data.paidDebt =
              callbackSuccessData.data.filesInfo[
                store.getters["modules/getFileUuid"]
              ].paidDebt;
            if (
              callbackSuccessData.data.filesInfo[
                store.getters["modules/getFileUuid"]
              ].transferor !== null
            )
              data.debtOwner =
                callbackSuccessData.data.filesInfo[
                  store.getters["modules/getFileUuid"]
                ].transferor;
          }

          data.saveAsFavourite = callbackSuccessData.data.modules.bookmark;
          if (data.saveAsFavourite.enabled)
            data.modulesToShow[
              callbackSuccessData.data.modules.bookmark.index
            ] = "saveAsFavourite";
          if (data.showDebtSelector === 0)
            data.showDebtSelector =
              callbackSuccessData.data.modules.welcomeMessage.index;

          data.paymentPlan = callbackSuccessData.data.modules.paymentPlan;
          store.commit(
            "modules/setCard",
            callbackSuccessData.data.modules.paymentPlan.card
          );
          store.commit(
            "modules/setPaymentPlanDataModule",
            callbackSuccessData.data.modules.paymentPlan
          );
          data.paymentPlan.paymentPlanTableHtml =
            callbackSuccessData.data.modules.paymentPlan.paymentPlanTableHtml;
          if (data.paymentPlan.enabled)
            data.modulesToShow[
              callbackSuccessData.data.modules.paymentPlan.index
            ] = "paymentPlan";
          if (data.showDebtSelector === 0)
            data.showDebtSelector =
              callbackSuccessData.data.modules.welcomeMessage.index;

          data.paymentPlanMethod =
            callbackSuccessData.data.modules.paymentMethod;
          store.commit(
            "modules/setPaymentMethodsTitle",
            callbackSuccessData.data.modules.paymentMethod.methods
          );

          if (data.paymentPlanMethod.enabled)
            data.modulesToShow[
              callbackSuccessData.data.modules.paymentMethod.index
            ] = "paymentPlanMethod";
          if (data.showDebtSelector === 0)
            data.showDebtSelector =
              callbackSuccessData.data.modules.welcomeMessage.index;

          data.support = callbackSuccessData.data.modules.support;
          store.commit("modules/setSupport", data.support);
          if (data.support.enabled)
            data.modulesToShow[callbackSuccessData.data.modules.support.index] =
              "support";
          if (data.showDebtSelector === 0)
            data.showDebtSelector =
              callbackSuccessData.data.modules.welcomeMessage.index;

          data.contactUs = callbackSuccessData.data.modules.contact;
          if (data.contactUs.enabled)
            data.modulesToShow[callbackSuccessData.data.modules.contact.index] =
              "contactUs";
          if (data.showDebtSelector === 0)
            data.showDebtSelector =
              callbackSuccessData.data.modules.welcomeMessage.index;
          store.commit(
            "modules/setContactData",
            callbackSuccessData.data.modules.contact
          );

          data.actionsHistory = callbackSuccessData.data.modules.paymentRecords;
          if (data.actionsHistory.enabled)
            data.modulesToShow[
              callbackSuccessData.data.modules.paymentRecords.index
            ] = "actionsHistory";

          data.debtConsolidation =
            callbackSuccessData.data.modules.debtConsolidation;
          if (data.debtConsolidation.enabled)
            data.modulesToShow[
              callbackSuccessData.data.modules.debtConsolidation.index
            ] = "debtConsolidation";
          if (data.showDebtSelector === 0)
            data.showDebtSelector =
              callbackSuccessData.data.modules.welcomeMessage.index;

          data.files = callbackSuccessData.data.modules.files;
          if (data.files.enabled)
            data.modulesToShow[callbackSuccessData.data.modules.files.index] =
              "files";
          if (data.showDebtSelector === 0)
            data.showDebtSelector =
              callbackSuccessData.data.modules.welcomeMessage.index;

          data.faqs = callbackSuccessData.data.modules.faqs;
          if (data.faqs.enabled)
            data.modulesToShow[callbackSuccessData.data.modules.faqs.index] =
              "faqs";
          if (data.showDebtSelector === 0)
            data.showDebtSelector =
              callbackSuccessData.data.modules.welcomeMessage.index;

          data.footer = callbackSuccessData.data.modules.footer;
          if (data.footer.enabled)
            data.modulesToShow[callbackSuccessData.data.modules.footer.index] =
              "footer";
          store.commit(
            "modules/setFooter",
            callbackSuccessData.data.modules.footer
          );

          data.debtor = callbackSuccessData.data.modules.debtor;
          if (data.debtor.enabled)
            data.modulesToShow[callbackSuccessData.data.modules.debtor.index] =
              "debtor";
          if (data.showDebtSelector === 0)
            data.showDebtSelector =
              callbackSuccessData.data.modules.welcomeMessage.index;
          store.commit(
            "modules/setDebtModule",
            callbackSuccessData.data.modules.debtor
          );

          // data.removals = callbackSuccessData.data.modules.removals
          // if (data.removals.enabled) data.modulesToShow[callbackSuccessData.data.modules.removals.index] = 'removals'

          if (callbackSuccessData.data.modules.chatBot.enabled)
            data.modulesToShow[callbackSuccessData.data.modules.chatBot.index] =
              "chatBot";

          data.whoIs = callbackSuccessData.data.modules.whoIs;
          data.loading = false;
          store.commit( "modules/setLoading", data.loading 
            );
          // redirectToViewBasedOnTemplate(props.uuid);
          setEnabledLanguages();
        },
        callbackError: (callbackErrorData: any) => {
          data.loading = false;
          if (callbackErrorData.data.text) {
            store.commit(
              "security/setFallbackContactInfoLogo",
              callbackErrorData.data.logo
            );
            store.commit(
              "security/setFallbackContactInfoAddress",
              callbackErrorData.data.address
            );
            store.commit(
              "security/setFallbackContactInfoPhone",
              callbackErrorData.data.phone
            );
            store.commit(
              "security/setFallbackContactInfoEmail",
              callbackErrorData.data.email
            );
            store.commit(
              "security/setFallbackContactInfoTextRu",
              callbackErrorData.data.text.ru
            );
            store.commit(
              "security/setFallbackContactInfoTextEs",
              callbackErrorData.data.text.es
            );
            store.commit(
              "security/setFallbackContactInfoTextEn",
              callbackErrorData.data.text.en
            );
            router.push({ name: "GoError403" });
          }
        },
      };
      store.dispatch("modules/getLandingConfiguration", params);
    }
    function getGeneralModule() {
      let id: any = store.getters["user/getUUID"]
      const params = {
        uuid: store.getters["user/getUUID"],
        callbackSuccess: (callbackSuccessData: any) => {
          data.authMode = callbackSuccessData.data.authMode
          store.commit('security/setFallbackContactInfoLogo', callbackSuccessData.data.fallbackContactInfo.logo)
          store.commit('security/setFallbackContactInfoAddress', callbackSuccessData.data.fallbackContactInfo.address)
          store.commit('security/setFallbackContactInfoPhone', callbackSuccessData.data.fallbackContactInfo.phone)
          store.commit('security/setFallbackContactInfoEmail', callbackSuccessData.data.fallbackContactInfo.email)
          store.commit('security/setFallbackContactInfoTextRu', callbackSuccessData.data.fallbackContactInfo.text.ru)
          store.commit('security/setFallbackContactInfoTextEs', callbackSuccessData.data.fallbackContactInfo.text.es)
          store.commit('security/setFallbackContactInfoTextEn', callbackSuccessData.data.fallbackContactInfo.text.en)
          store.commit('modules/setAuthMode', callbackSuccessData.data.authMode)
          store.commit('modules/setCustomApi', callbackSuccessData.data.customApi)
          store.commit('modules/setPinPageCustomization', callbackSuccessData.data.pinPageCustomization)
          data.languages = callbackSuccessData.data.extraLanguage;
          if(callbackSuccessData.data.uxMode === "template1") {
            router.push({ name: "DebtsView"});
          }
          if (
            callbackSuccessData.data.uxMode === "template1" &&
            callbackSuccessData.data.authMode !== "noAuth"
          ) {
            router.push({ name: "PinAuthenticationAx", params: id });
            data.loading = false;
           store.commit( "modules/setLoading", data.loading )
          }
          setEnabledLanguages()
          // data.loading = false;
          // redirectToViewBasedOnTemplate(props.uuid);
        },
        callbackError: () => {},
      };
      store.dispatch("modules/getGeneralModule", params);
    }

    setEnabledLanguages();

    return {
      ...toRefs(data),
      changeLocale,
      isMobile,
      fallbackContactInfo,
      language,
      setEnabledLanguages,
      goTo,
      showButtons,
      viewsForBottomNavigationBar,
      getLandingConfiguration,
      modules,
      isLogin,
      getGeneralModule,
      showLanguages,
      mobileColors
    };
  },
});
