
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import SpIcon from "@/components/ui/SpIcon.vue";
import SpButton from "@/components/ui/SpButton.vue";
import router from "@/router";

export default {
  name: "EmailSent",
  components: { SpIcon, SpButton },
  props: {
    uuid: { type: String, required: true },
  },
  setup(props: any) {
    const store = useStore();

    interface EmailSent {
      languages: Array<any>;
    }

    const data = reactive<EmailSent>({
      languages: [],
    });

    const languageComputed = computed(() => data.languages);

    function goTo(value: string) {
      router.push({ name: value });
    }

    function getLandingConfiguration() {
      const params = {
        uuid: props.uuid,
        callbackSuccess: (callbackSuccessData: any) => {
          data.languages =
            callbackSuccessData.data.modules.general.extraLanguages;
        },
        callbackError: (callbackErrorData: any) => {},
      };
      store.dispatch("modules/getLandingConfiguration", params);
    }

    getLandingConfiguration();

    return {
      ...toRefs(data),
      languageComputed,
      goTo,
    };
  },
};
