
import { computed, defineComponent, reactive, toRefs } from "vue";
import SpButtonIcon from "@/components/ui/SpButtonIcon.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "SpAccordionAx",
  components: { SpButtonIcon },
  props: {
    questions: { type: Array, default: [] },
  },
  setup(props) {
    const store = useStore();

    interface spAccordionAx {
      questionsData: Array<any>;
    }

    const data = reactive<spAccordionAx>({
      questionsData: [],
    });

    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });

    function parseData() {
      let result: Array<any> = [];
      props.questions?.forEach(function (question: any) {
        result.push({
          title: question.title,
          description: question.description,
          show: false,
        });
      });
      data.questionsData = result;
    }

    parseData();

    return {
      ...toRefs(data),
      language
    };
  },
});
