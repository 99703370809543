
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import SpIcon from "@/components/ui/SpIcon.vue";
import SpButton from "@/components/ui/SpButton.vue";
import router from "@/router";

export default {
  name: "Interveners",
  components: { SpButton },
  props: {
    uuid: { type: String, required: true },
  },
  setup(props: any) {
    const store = useStore();

    interface Interveners {
      languages: Array<any>;
      seeInterveners: boolean;
      // interveners: Array<any>;
      // productName: string
    }

    const data = reactive<Interveners>({
      languages: [],
      seeInterveners: false,
      // interveners: store.getters["modules/getRelatedInterveners"],
      // productName: store.getters["modules/getProductName"],
    });

    const interveners = computed(() => store.getters["modules/getRelatedInterveners"]);
    const loading = computed(() => {
      return store.getters["modules/getLoading"];
    });
    const productName = computed(() => store.getters["modules/getProductName"]);
    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });
    const languageComputed = computed(() => data.languages);
    const modules = computed(()=> {
        return store.getters["modules/getModules"]
    })

    function goTo(value: string) {
      router.push({ name: value });
    }

    const headerMobileColors = computed(() => {
      return {
        '--primary-color': `${store.getters["user/getMobileColors"].primary}`,
      };
    });

    const mobileColors = computed(() => {
      return store.getters["user/getMobileColors"];
    });

    return {
      ...toRefs(data),
      languageComputed,
      loading,
      interveners,
      productName,
      goTo,
      language,
      modules,
      mobileColors,
      headerMobileColors
    };
  },
};
