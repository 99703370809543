import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-screen h-screen flex justify-center items-center flex-col" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "mt-8 font-semibold text-2xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_icon = _resolveComponent("sp-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.fallbackContactInfo.logo.length === 0)
      ? (_openBlock(), _createBlock(_component_sp_icon, {
          key: 0,
          model: "alert-triangle",
          size: "96"
        }))
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: $setup.fallbackContactInfo.logo,
          style: {"height":"200px"},
          alt: "",
          class: "mb-2"
        }, null, 8, _hoisted_2)),
    _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('error404.title')), 1)
  ]))
}