export default {
    callAx: {
        scheduledCalls: 'Запланированный звонок',
        callSoon: 'Мы перезвоним вам в ближайшее время по ',
        close: 'Закрывать',
        weCallYou: 'Мы звоним вам!',
        indicatePhone: 'Введите свой номер телефона и мы перезвоним вам в ближайшие несколько минут.',
        enterPhone: 'Введите свой телефон',
        callNow: 'Позвони мне сейчас!',
        scheduleYourCalls: 'Или, если хотите, запланируйте звонок.',
        enterMail: 'Введите адрес электронной почты',
        selectTime: 'Выберите время',
        schedule: 'Запланируйте звонок',
        selectDay: 'Выберите день',
        apply: 'Применять',
    },
    debtRemoval: {
        title: 'Списание долга',
        subtitle: 'Отправьте предложение с суммой, которую вы запрашиваете, чтобы вычесть из вашего долга',
        amountToRelief: 'Сумма помощи (%)',
        sendProposal: 'Отправить предложение'
    },
    debtsAx: {
        paymentsMade: 'Платежи произведены',
        pendingPayments: 'Ожидающие платежи',
        greating: 'Привет',
        title1: 'Обновлять',
        title2: 'в моих платежах',
        welcomeMessage: 'Добро пожаловать на ваш частный сайт. Отсюда мы поможем вам оставаться в курсе ваших платежей.',
        paymentPlan: 'Примите меры по всем своим долгам или выберите долги, которые вы хотите оплатить.',
        myProducts: 'Мои продукты',
        interveners: 'Интервенторы',
        total: 'Общий',
        moreInfo: '+информация',
        pay: 'Платить',
        next: 'Следующий'
    },
    error403: {
        title: 'похоже эта страница больше не доступна',
        subtitle: 'Срок доступа к этой странице истек. Если у вас есть какие-либо вопросы, пожалуйста, свяжитесь с нами'
    },
    error404: {
        title: 'Кажется, что то, что вы ищете, сейчас недоступно',
    },
    fileInterveners: {
        title: 'Участники в вашем файле',
        subtitle: 'Другие участники вашего файла подробно описаны ниже.',
        debtor: 'Вмешатель'
    },
    fileSelector: {
        title: 'Селектор долга',
        subtitle: 'Выберите долг, который хотите просмотреть',
        message: 'У вас есть долги {debts}, из которых вы заплатили {paidDebt} из {totalDebt}',
        messageShort: 'У вас есть долги {debts}',
        messageSingle: 'У вас есть долг {debt}, из которых вы выплатили {paidDebt} из {totalDebt}',
        messageSingleShort: 'У вас есть долг {debt}',
        card: {
            debt: 'Долг',
            debtContracted: 'Долг заключен {debtStart} с {debtOwner}',
            debtContracted2: 'Долг заключен {debtStart}',
            paidDebt: 'Оплаченный долг',
            pendingDebt: 'Незавершенный долг',
            totalDebt: 'Общая задолженность'
        },
        notifications: {
            changeFileSuccess: 'Долг был изменен для корректного отображения.'
        }
    },
    general: {
        save: 'Сохранять',
        date: 'ДАТА',
        amount: 'КОЛИЧЕСТВО',
        supportPage: 'Страница поддержки',
        platformSupportedBy: 'Платформа поддерживается',
        virtualAvatarTitle: 'Мы можем помочь вам урегулировать ваш долг',
        virtualAvatarDescription: 'Расскажите нам, как и когда вы хотите оплатить',
        saveAsFavourite1:
            "Сохраните эту ссылку у себя в закладках, чтобы иметь возможность проверить статус своего долга в любое время.",
        saveAsFavourite2: "кликните сюда",
        paymentPlan: 'План оплаты',
        paymentPlanProposalSingleOption: 'платить сразу',
        paymentPlanProposalSingleOption1: 'Платить',
        paymentPlanProposalSingleOptionDescription: 'Если вы можете это сделать, посетите нас в тот день, когда сможете, нажмите на эту опцию и погасите долг.',
        paymentPlanProposalPartialOption: 'Частичная оплата',
        paymentPlanProposalPartialOptionDescription: 'Возьмите на себя ответственность за долг путешествие! Совершайте меньшие платежи и наблюдайте, как растет ваш прогресс.',
        paymentPlanProposal0: 'План оплаты',
        paymentPlanProposal1Question: 'Как вы предпочитаете это делать?',
        paymentPlanProposal1: 'Мы предлагаем вам эти варианты, чтобы вы могли выбрать тот, который вам больше всего подходит.',
        paymentPlanProposal2: 'Помните, что мы здесь, чтобы облегчить вам жизнь',
        paymentPlanProposal3: 'Как часто вы хотите платить',
        paymentPlanProposal4: 'Одна игра',
        paymentPlanProposal5: 'В эту модальность включена скидка 10%',
        paymentPlanProposal6: 'Через 2 месяца',
        paymentPlanProposal7: 'Через 4 месяца',
        paymentPlanProposal8: 'Предложить план оплаты',
        paymentPlanProposal9: 'Мы слышим вас. Предложите, как вы могли бы ее решить.',
        paymentPlanProposal10: 'Отправить предложение по оплате',
        paymentPlanProposal11: 'Или просто предложите другой способ оплаты',
        paymentPlanProposal12: 'Сумма к оплате в месяц',
        paymentPlanProposal13: 'Месячный период для оплаты',
        paymentPlanProposal14: '5 месяцев',
        paymentPlanProposal15: '32 месяцев',
        paymentPlanProposal16: 'Сводка платежей',
        months: 'месяцы',
        paymentMethod1: 'Выберите способ оплаты',
        paymentMethod2: 'Выберите наиболее удобный для вас способ оплаты',
        paymentMethod3: 'Добавить другой способ оплаты',
        paymentMethod4: 'Сделайте предложение, мы вас слушаем',
        paymentMethod5: 'Введите способ оплаты, который вы хотите использовать, и наша команда свяжется с вами, чтобы попытаться помочь с ним.',
        paymentMethod6: 'Способ использования',
        paymentMethod7: 'Отправьте способ оплаты',
        paymentMethod8: "Поздравляем! Ваш платеж был совершен правильно. Вы близки к списанию долга.",
        paymentMethod9: "Что-то не так с вашим платежом. Проверьте данные о способе оплаты.",
        support1: 'Поддерживать',
        support2: 'Найти ответ',
        support3: 'Видео-конференция',
        support4: 'Чат',
        support5: 'Поговорите с одним из консультантов нашей команды',
        support6: 'Если у вас есть какие-либо сомнения и вы хотите поговорить с одним из наших консультантов, это идеальный способ сделать это. Всегда найдется человек, готовый вам помочь.',
        support7: 'Начните с видеоконференций',
        support8: 'Поговорите с одним из агентов нашего справочного центра',
        support9: 'Привет! Как мы можем вам помочь?',
        support10: 'Написать сообщение',
        contact1: "Вы хотите, чтобы мы связались с вами?",
        contact2: "Пожалуйста, сообщите нам, когда мы сможем связаться с вами.",
        contact3: "скажи нам свой телефон",
        contact4: "Например, 612285745",
        contact5: "сообщите нам свой адрес электронной почты",
        contact6a: "Например, информация",
        contact6b: "youremail.com",
        contact7: "Сообщите нам свой адрес",
        contact8: "Например, Калле Марли, 17 лет.",
        contact9: "Отправлять",
        contact10: "Настройки контактов",
        contact11: "Я не хочу, чтобы ты связывался со мной",
        contact12: "Добавить больше дней и временных интервалов",
        contact13: "Вы не хотите, чтобы мы связались с вами?",
        contact14: "Вы хотите сообщить нам что-то еще, что мы должны знать, прежде чем снова связаться с вами?",
        contact15: "Здравствуйте, мне нужно больше информации",
        contact16: "Введите свое имя и фамилию",
        contact16a: "Jhon Smith / Sara Smith ",
        contact17: "Тип улицы",
        contact18: "Улица",
        contact19: "Число",
        contact20: "Провинция",
        contact21: "Город",
        contact22: "Почтовый индекс",
        contact23: "Страна",
        history1: 'Рекорды',
        history2: 'Проверьте, что вы уже сделали',
        history3: '01.01.2022',
        history4: 'Контактное письмо отправлено',
        history5: '10.02.2022',
        history6: 'Заявка на оплату в рассрочку',
        history7: '20.03.2022',
        history8: 'Видеоконференцсвязь с Педро из Sirit',
        debtConsolidation1: 'У вас есть еще долг для консолидации? Мы можем помочь',
        debtConsolidation2: 'Если у вас есть еще долги, которые вы хотите консолидировать вместе, либо потому, что вы скоро окажетесь в такой ситуации, либо потому, что мы не знаем об этом, свяжитесь с нами, и мы поможем вам консолидировать весь долг.',
        debtConsolidation3: 'Расскажите нам, как мы могли бы вам помочь',
        debtConsolidation4: 'Отправить',
        faqs1: 'У вас есть сомнения?',
        faqs2: 'Найти ответ',
        faqs3: 'Почему я получил доступ к этой целевой странице?',
        faqs4: 'Если вы получили доступ к этой странице, значит, у вас есть непогашенная задолженность, и она не была выплачена. Поэтому миссия этой страницы — помочь вам оплатить долг.',
        faqs5: 'Могу ли я получить списание долга?',
        faqs6: 'Да, если вы погасите долг одним платежом.',
        faqs7: 'Могу ли я изменить свои рассрочки и ежемесячный платеж?',
        faqs8: 'Вы можете выбрать, сколько взносов и сумму, которую вы хотите платить ежемесячно. Важно то, что у вас есть выбор для выбора способа оплаты, который соответствует вашим потребностям и уменьшает ваш долг.',
        whoIsSirit1: 'Что такое Сирит?',
        whoIsSirit2: 'Мы — компания, призванная помочь вам с неоплаченными счетами. Наша миссия — предоставить вам инструменты для погашения вашего долга и избавиться от всех предыдущих переживаний по поводу вашего долга.',
        whoIsSirit3: 'Одна из наших многочисленных целей — сделать управление вашей задолженностью простым и избавиться от сложных банковских процедур, которые не очень подходят для ваших текущих потребностей.',
        whoIsSirit4: 'Свяжитесь с нами',
        whoIsSirit5: 'Если у вас есть какие-либо сомнения, не стесняйтесь обращаться к нам.',
        whoIsSirit6: 'Свяжитесь с нами',
        whoIsSirit7: 'Условия использования целевой страницы',
        whoIsSirit8: 'Политика конфиденциальности',
        whoIsSirit9: 'Политика использования файлов cookie',
        welcome1: 'Привет',
        welcome2: 'Juan Suñer Parra',
        welcome3: 'Если это не ты',
        welcome4: 'пожалуйста, нажмите здесь',
        welcome5: 'На этой целевой странице вы сможете управлять своим долгом. Наша миссия — помочь вам с этим, чтобы вы могли оплатить его максимально удобным, гибким и простым способом. Если вам нужна какая-либо помощь, не стесняйтесь обращаться к нам.',
        welcome6: 'Задолженность составляет',
        welcome7: '23.392€ + НДС',
        welcome8: 'Задолженность по контракту',
        welcome9: '30 января 1993 г. с BBVA,',
        welcome10: 'ссылаясь на ипотечный кредит.',
        error404: 'Ошибка 404',
        pageNotFound: 'Страница не найдена',
        videoChatBot1: 'Видеочат-бот',
        videoChatBot2: "Начните видеочат с одним из наших виртуальных помощников. Это похоже на беседу с помощником, но с удобством шаг за шагом.",
        videoChatBot3: 'Начать видеочат',
        videoChatBot4: 'Начинать',
        totalDebt: 'Общая задолженность {debtAmount}.',
        aSinglePaymentSummary: 'Единый платеж, оплатить { debtAmount }.',
        inTwoMonthsPaymentSummary: 'Разделите платеж на два месяца, чтобы заплатить { debtAmount } per month.',
        inFourMonthsPaymentSummary: 'Разделите платеж на четыре месяца, чтобы заплатить { debtAmount } per month.',
        summary: 'Резюме',
        pay: 'Платить',
        payWith: 'с { method }',
        paymentRecords1: 'История платежей',
        paymentRecords2: 'Получить информацию о совершенных платежах',
        paymentRecords3: 'Платеж не производился',
        paymentRecords4: 'Платеж может занять до 24 часов, прежде чем он будет отражен',
        files1: 'Файлы',
        files2: 'Документы, связанные с задолженностью',
        files3: 'Uploaded files',
        files4: 'Файлы еще не загружены',
        files5: 'Перетащите файлы сюда',
        files6: 'или же',
        files7: 'Просмотр файлов',
        files8: 'Загрузка файлов..',
        maxFileSize: 'Максимальный размер одного файла – 100 МБ.',
        paymentSubtitle: 'Мы предлагаем вам различные способы оплаты вашего долга',
        debtAmountTo: 'Ваш следующий платеж',
        selectPayment: 'выберите способ оплаты',
        transaction: 'перевод между счетами',
        transfer: 'Сделка',
        creditCard: 'Кредитная карта',
        payPal: 'Paypal',
        bizum: 'Bizum',
        amountToPay: 'Сумма к оплате',
        sourceBankAccount: 'Напишите реквизиты банковского счета, с которого собираетесь осуществить перевод',
        placeholderSourceBankAccount: 'Введите исходный банковский счет',
        cardNumber: 'Номер карты',
        name: 'Имя',
        placeholderName: 'Введите название карты',
        expiryDate: 'Срок действия',
        cvv: 'CVV / CVC',
        email: 'Электронное письмо',
        emailPlaceholder: 'Введите адрес электронной почты',
        password: 'Пароль',
        passwordPlaceholder: 'Введите свой пароль',
        paymentWith: 'Оплата произведена с',
        paymentWithCard: 'Оплатить картой',
        paymentWithBizum: 'Оплата с bizum',
        cannotPay: 'Вы не можете произвести оплату, пожалуйста, свяжитесь с нами',
        paymentWithTransfer: 'Оплатить банковским переводом',
        paymentWithPaypal: 'Оплата с payPal',
        paymentGenerated: 'Платеж сгенерирован на',
        amountPaid: 'Выплаченная сумма: ',
        contactMe: 'Свяжитесь со мной',
        dontContactMe: 'Не связывайся со мной',
        showContactOptions: 'Показать варианты контактов',
        monday: 'Понедельник',
        tuesday: 'Вторник',
        wednesday: 'Среда',
        thursday: 'Четверг',
        friday: 'Пятница',
        saturday: 'Суббота',
        sunday: 'Воскресенье',
        from: 'Из',
        inRangeHour: 'в этом диапазоне часов',
        to: 'К',
        savePreferences: 'Сохранить настройки',
        bestContactHoursByPhone: 'Лучшее время для связи',
        fileType0: 'Выберите тег',
        fileType1: 'начисление заработной платы',
        fileType2: 'НДФЛ (форма 100). (при содействии налоговой службы) или справка о доходах',
        fileType3: 'Справка о пенсионном доходе (при содействии Национального института социального обеспечения)',
        fileType4: 'Справка о доходах SEPE (при содействии Государственной службы занятости)',
        fileType5: 'Трудовая жизнь (при содействии Национального института социального обеспечения)',
        fileType6: 'Сертификат, подтверждающий, что вы не получаете пособия от INSS (при содействии Национального института социального обеспечения)',
        fileType7: 'Трудовая жизнь (при содействии Национального института социального обеспечения)',
        fileType8: 'КРЭР (форма 130 или 131). (при содействии Налогового агентства)',
        fileType9: 'Годовая сводка НДС по НДС (Mod. 303 / Mod. 390). (при содействии Налогового агентства)',
        fileType10: 'ДНР – ИНЭ – паспорт',
        fileType11: 'Другие',
        selectCategory: 'Выбрать категорию',
        dataUpdatedOn: 'Данные обновлены ',
        paymentPlanApplication: 'Заявка на план платежей',
        prefix: 'Выберите префикс',
        true: 'истинный',
        false: 'Фальшивый',
        selectItem: 'выбрать элемент',
        noData: 'нет данных',
        searching: 'В поисках',
        needPaymentPlan: 'Перед оплатой необходимо создать план платежей',
        alreadyPlan: 'Для этого файла уже существует тарифный план.',
        amountError: 'Выбранное количество меньше минимального значения',
        birthDate: 'Дата рождения'
    },
    generalAx:{
        spinnerMessage: 'Загрузка страницы поддержки',
    },
    legalTexts: {
        title: 'Юридические тексты',
        legalWarning: 'Юридическое предупреждение',
        policy: 'политика конфиденциальности',
        cookies: 'Политика использования файлов cookie',
    },
    interveners: {
        debtIntervener: 'Вмешавшиеся в ваш долг',
        people: 'Ниже вы можете просмотреть остальных людей, вовлеченных в ваш долг %{keyDate}.',
        interveners: 'Интервенторы'
    },
    notifications: {
        contactError1: 'Вы должны ввести действующий адрес электронной почты. Пожалуйста, введите действительный адрес электронной почты и повторите попытку.',
        contactError2: 'Необходимо заполнить обязательные поля формы. Добавьте недостающие данные и снова отправьте форму.',
        contactError3: 'Вы должны ввести действующий телефон. Введите действительный телефон и повторите попытку.',
        contactSuccess1: 'Мы получили ваши контактные данные. Мы свяжемся с вами как можно скорее.',
        filesSuccess1: 'Загруженные файлы были успешно загружены.',
        filesSuccess2: 'Загруженный файл успешно удален.',
        filesError1: 'Размер загружаемых файлов не может превышать 100 МБ.',
        debtConsolidationError1: 'Вы должны указать, какие долги вы хотите консолидировать. Введите текст и повторите попытку.',
        debtConsolidationSuccess1: 'Мы получили информацию о вашей задолженности. Мы свяжемся с вами как можно скорее.',
        successPayment: 'Поздравляем! Ваш платеж был сделан правильно. Вы на один шаг ближе к победе над своим долгом. Отражение платежа может занять до 24 часов.',
        wrongPayment: 'Упс, кажется, что-то пошло не так при обработке вашего платежа. Пожалуйста, проверьте информацию о способе оплаты.',
        accept: "принимать",
        successChangeTag: 'Тип загружаемого файла был изменен правильно.',
        successRemoval: 'Ваше предложение по списанию задолженности успешно отправлено. После анализа они свяжутся с вами, чтобы сообщить о результате.',
        successSendSupportMessage: 'Ваше сообщение было отправлено успешно.',
        errorSendSupportMessage: 'Вы должны написать свое сообщение, прежде чем сможете его отправить. Введите сообщение, которое хотите отправить, и повторите попытку.',
        payError:  'Сумма к оплате должна быть больше 0',
        paymentSuccess: 'Спасибо за ваш платеж! Ваша транзакция была успешно обработана.',
        paymentError: 'Приносим извинения за неудобства. Произошла ошибка с вашим платежом. Пожалуйста, попробуйте еще раз позже или свяжитесь с нашей службой поддержки для получения помощи.',
        callMeNow: 'Телефон успешно отправлен',
        errorTotal: 'Введенная сумма меньше минимальной',
        inNumber: 'Введенная сумма не является числом',
    },
    redsysErrors: {
        msg1: 'Вам необходимо заполнить данные карты.',
        msg2: 'Карта обязательна.',
        msg3: 'Карта должна быть числовой.',
        msg4: 'Карта не может быть отрицательной.',
        msg5: 'Месяц действия карты обязателен.',
        msg6: 'Месяц истечения срока действия карты должен быть числовым.',
        msg7: 'Неверный месяц истечения срока действия карты.',
        msg8: 'Год истечения срока действия карты обязателен.',
        msg9: 'Год истечения срока действия карты должен быть числовым.',
        msg10: 'Год истечения срока действия карты не может быть отрицательным.',
        msg11: 'Код безопасности карты имеет неправильную длину.',
        msg12: 'Код безопасности карты должен быть числовым.',
        msg13: 'Код безопасности карты не может быть отрицательным.',
        msg14: 'Код безопасности не требуется для вашей карты.',
        msg15: 'Длина карты неверна.',
        msg16: 'Необходимо ввести действительный номер карты (без пробелов и тире).',
        msg17: 'Неправильная проверка продавцом.',
        msg18: 'Не удалось запланировать телефонный звонок для данного интервента',
        msg19: 'Введенный номер телефона недействителен',
    },
    pinAuthentication: {
        title: 'PIN-аутентификация',
        description: 'Чтобы убедиться, что это вы, введите PIN-код, который мы отправим на ваш номер телефона или электронную почту.',
        pin: 'ПРИКОЛОТЬ',
        pinPlaceholder: 'Введите свой PIN-код',
        enter: 'Залезай',
        sendPin: 'повторно отправить PIN-код',
        message1: 'После того, как вы войдете в систему, мы сможем предоставить вам подробную информацию о задолженности, о которой нам сообщили.',
        message2: 'Мы можем предоставить вам несколько каналов, чтобы вы могли выбрать тот, который вам больше нравится, связаться с нами и решить эту проблему.',
        message3: 'Мы можем предложить различные способы отмены сегодня или в ближайшие дни',
        message4: 'Если у вас возникли трудности со входом в систему, напишите нам {mail} или позвоните нам по номеру {phone}',
        successSendSupportMessage: 'Ваше сообщение было отправлено правильно.',
        errorSendSupportMessage: 'Вы должны написать свое сообщение, прежде чем сможете его отправить. Введите сообщение, которое хотите отправить, и повторите попытку.',

    },
    paymentMethodsAx: {
        paymentPlan: 'Способы оплаты',
        importToPay: 'Сумма к оплате',
        selectMethod: 'Выберите способ оплаты, который лучше всего соответствует вашей ситуации.',
        singlePayment: 'Одноразовый платеж',
        partialPayment: 'Частичная оплата',
        total: 'Общий',
        min: 'Минимальная сумма',
        month: 'месяцы',
        amountPerMonth: 'Сумма в месяц',
        continue: 'Продолжать',
        enterAmount: 'Введите количество...',
        payment: 'План оплаты'
    },
    pinUnique: {
        title: 'PIN-аутентификация',
        description: 'Мы делаем этот частный веб-сайт доступным для вас, чтобы вы могли управлять своим долгом. ',
        instructions: 'Чтобы убедиться, что это вы, введите свой идентификатор и нажмите «Запросить PIN-код», чтобы получить PIN-код для доступа к вашему #способу связи#, и нажмите «Доступ».',
        labelId: 'DNI / NIE / CIF',
        placeholderId: 'Введите свой DNI/NIE/CIF',
        labelPin: 'Запросить PIN-код',
        placeholderPin: 'PIN-код',
        phone: 'Телефон',
        email: 'Электронная почта',
        selectPhone: 'Выберите телефон',
        selectEmail: 'Выберите адрес электронной почты',
        errorId: 'Данный идентификатор документа не соответствует нашим данным для этой страницы ссылок',
        toastMessage: 'PIN-код был успешно запрошен',
    },
    support: {
        title: 'Свяжитесь с нами',
        subtitle: 'Мы предлагаем вам множество способов общения, чтобы вы могли выбрать наиболее удобный для вас.',
        videoconference: 'Видеоконференция',
        chat: 'Чат',
        start: 'Старт',
        writeUs: 'Напишите нам',
        writeUsPlaceholder: 'Напишите нам сообщение с тем, что вы хотите нам сообщить',
        send: 'Отправить'
    },
    ui: {
        clickHere: 'кликните сюда'
    },
    table: {
        amount: "Количество",
        months: "Месяцы",
        frequency: "Частота",
        status: "Положение дел",
        paidDeadlines: 'платные сроки',
        deadLineDate: 'Крайний срок',
        totalToPay: 'Всего к оплате',
        origin: 'Источник',
    },
    paymentFrequency: {
        Monthly: 'Ежемесячно',
        single: 'одинокий'
    },
    status: {
        approved: 'ОДОБРЕННЫЙ',
        failed: 'неуспешный',
        closed: 'ЗАКРЫТО'
    },
    months: {
        1: 'Январь',
        2: 'Февраль',
        3: 'Март',
        4: 'Апрель',
        5: 'май',
        6: 'июнь',
        7: 'июля',
        8: 'Август',
        9: 'Сентябрь',
        10: 'Октябрь',
        11: 'Ноябрь',
        12: 'Декабрь'
    },
    buttomNavar: {
        debt: 'Ваш долг',
        payment: 'Оплата',
        files: 'Файлы',
        contact: 'Контакт',
        faqs: 'Часто задаваемые вопросы',
    },
    pinAuthenticationAx: {
        greating: 'Привет!',
        intro: 'Мы делаем этот частный веб-сайт доступным, чтобы вы могли управлять своим долгом.',
        description: 'Чтобы убедиться, что это вы, введите свой идентификатор и нажмите «Запросить PIN-код», чтобы получить PIN-код для доступа к вашему #способу связи#',
    },
    files: {
        myFiles: 'Мои документы',
        payRoll: 'Начисление заработной платы',
        landingSent: 'Лендинг отправлен',
        to: 'для',
        yourNotification: 'Ваши уведомления',
        reminder: 'Напоминание',
        pending: 'Серьги',
        accepted: 'Принятые способы оплаты',
        noData: 'Извините за неудобства, в наших системах нет данных',
        account: 'Мой счет',
        myPayments: 'Мои платежи произведены',
        pendingPayment: 'Мои ожидающие платежи',
        help: 'Помогать',
        calls: 'Запланированные звонки',
        getOut: 'Выйти из аккаунта',
        myCalls: 'Мои запланированные звонки',
        sentEmail: 'Отправить нам письмо',
        email: 'Укажите свой адрес электронной почты и сообщение, которое вы хотите нам отправить, и мы ответим вам как можно скорее.',
        canHelp: 'Расскажите нам, как мы можем вам помочь',
    },
}
