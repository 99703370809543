
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import { useStore } from "vuex";
import SpIcon from "@/components/ui/SpIcon.vue";
import SpButton from "@/components/ui/SpButton.vue";
import SpTabMenu from "@/components/ui/SpTabMenu.vue";
import SpSelect from "@/components/ui/SpSelect.vue";
import SpButtonIcon from "@/components/ui/SpButtonIcon.vue";
import router from "@/router";
import SpFloatingWindow from "@/components/ui/SpFloatingWindow.vue";

export default {
  name: "LegalTexts",
  components: {
    SpIcon,
    SpButton,
    SpSelect,
    SpTabMenu,
    SpButtonIcon,
    SpFloatingWindow
  },
  props: {
    uuid: { type: String, required: true },
  },
  setup(props: any) {
    const store = useStore();

    interface LegalTexts {
      footer: Array<any>;
      termsOfUseContent: string,
      privacyPolicyContent: string,
      legalAdviceContent: string,
      selectedLegalInformation: string
    }

    const data = reactive<LegalTexts>({
      footer: store.getters["modules/getFooter"],
      termsOfUseContent: '',
      privacyPolicyContent: '',
      legalAdviceContent: '',
      selectedLegalInformation: ''
    });
    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });
    const modules = computed(()=> {
        return store.getters["modules/getModules"]
      })
    const customApi = computed(() => {
      return store.getters['modules/getCustomApi']
    })

    function goTo(value: string) {
      router.push({ name: value });
    }
    function getLegalInformation (type: string) {
      if (customApi.value) {
        const params = {
          uuid: store.getters['user/getUUID'],
          type: type,
          languageCode: store.getters['user/getLanguage'],
          callbackSuccess: (callbackSuccessData: any) => {
            if (type === 'termsOfUse') data.termsOfUseContent = callbackSuccessData.data.legalInformation
            if (type === 'privacyPolicy') data.privacyPolicyContent = callbackSuccessData.data.legalInformation
            if (type === 'legalAdvice') data.legalAdviceContent = callbackSuccessData.data.legalInformation
          },
          callbackError: () => {}
        }
        store.dispatch('modules/getLegalInformation', params)
      }
    }
    getLegalInformation('termsOfUse')
    getLegalInformation('privacyPolicy')
    getLegalInformation('legalAdvice')

    watch(() => store.getters['user/getLanguage'],() => {
      getLegalInformation('termsOfUse')
      getLegalInformation('privacyPolicy')
      getLegalInformation('legalAdvice')
    })


    return {
      ...toRefs(data),
      goTo,
      modules, 
      language,
      getLegalInformation,
      customApi
    };
  },
};
