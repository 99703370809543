
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import SpIcon from "@/components/ui/SpIcon.vue";
import SpButton from "@/components/ui/SpButton.vue";
import SpSelect from "@/components/ui/SpSelect.vue";
import SpInputRange from "@/components/axactorModulesMobile/SpInputRangeAxactor.vue";
import SpButtonIcon from "@/components/ui/SpButtonIcon.vue";
import router from "@/router";
import * as Utils from "@/api/Utils";

export default {
  name: "PaymentsMade",
  components: {
    SpIcon,
    SpButton,
    SpSelect,
    SpInputRange,
    SpButtonIcon,
  },
  props: {
    uuid: { type: String, required: true },
  },
  setup(props: any) {
    const store = useStore();

    interface paymentsMade {
      actions: Array<any>;
    }

    const data = reactive<paymentsMade>({
      actions: [],
    });

    const modules = computed(() => {
      return store.getters["modules/getModules"];
    });

    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });

    function getHistoryPayment() {
      const params = {
        uuid: "/api/landing_pages/" + props.uuid,
        callbackSuccess: (callbackSuccessData: any) => {
          data.actions = callbackSuccessData.data.items;
        },
        callbackError: () => {},
      };
      store.dispatch("modules/getHistoryPayment", params);
    }

    getHistoryPayment();

    function goTo(value: string) {
      router.push({ name: value });
    }

    const headerMobileColors = computed(() => {
      return {
        "--primary-color": `${modules.value.paymentPlan.templates[0].configuration.profileMenu.paymentsMade.card.backgroundColor}`, // Definir una variable CSS
        // Otros estilos según sea necesario
      };
    });

    return {
      ...toRefs(data),
      goTo,
      Utils,
      modules,
      headerMobileColors,
      language,
    };
  },
};
