
import { computed, defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import SpIcon from "@/components/ui/SpIcon.vue";
import SpButton from "@/components/ui/SpButton.vue";
import SpSelect from "@/components/ui/SpSelect.vue";
import SpInputRange from "@/components/axactorModulesMobile/SpInputRangeAxactor.vue";
import router from "@/router";
import * as Utils from "@/api/Utils";
import moment from "moment";


export default {
  name: "PaymentsResume",
  components: {
    SpButton,
    SpSelect,
    SpInputRange,
    SpIcon,
  },
  props: {
    uuid: { type: String, required: true },
  },
  setup(props: any) {
    const store = useStore();

    interface PaymentsResume {
      seeInterveners: boolean;
      selectedMethod: string;
      paymentPlans: Array<any>;
      paymentNumber: number;
      loading: boolean;
      totalToPage: number
    }

    const data = reactive<PaymentsResume>({
      seeInterveners: false,
      selectedMethod: "UNIC",
      paymentPlans: [],
      paymentNumber: 0,
      loading: false,
      totalToPage: 0
    });

    function goTo(value: string) {
      router.push({ name: value });
    }

    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });

    const modules = computed(() => {
      return store.getters["modules/getModules"];
    });

    const paymentPlanData = computed(()=> {
      return store.getters['modules/getPaymentPlansData'].data.data
    })
    
    const paymentType = computed(()=> {
      return store.getters['modules/getPaymentType']
    })

    const paymentFrequency = computed(()=> {
      return store.getters['modules/getPaymentFrequency']
    })

    const getPaymentMonths = computed(()=> {
      return store.getters['modules/getPaymentMonths']
    })

    function postGetTotalToPay() {
      const params = {
        uuid: "/api/landing_pages/" + props.uuid,
        fileID: store.getters["modules/getFileUuid"],
        callbackSuccess: (callbackSuccessData: any) => {
          data.totalToPage = callbackSuccessData.data.pendingTotalDebt;
        },
        callbackError: () => {},
      };
      store.dispatch("modules/postGetTotalToPay", params);
    }
    
    
    function postCreatePaymentPlanData() {
      const params = {
        uuid: "/api/landing_pages/" + props.uuid,
        customPaymentAmount: paymentPlanData.value[0].amount,
        customPaymentPeriodMonths: getPaymentMonths.value,
        preferredDueDay: moment().format('YYYY-MM-DD'),
        type: paymentType.value,
        paymentFrequency: paymentFrequency.value,
        files: store.getters["modules/getFilesInfo"].map((file: any) => {
            return file.fileUuid;
          }),
        totalDebt: data.totalToPage,
        callbackSuccess: (callbackSuccessData: any) => {
          store.commit('modules/setPaymentPlansData', callbackSuccessData );
          goTo('PaymentPending')
            data.loading = false;
        },
        callbackError: () => {},
      };
      store.dispatch("modules/postCreatePaymentPlanData", params);

    }

    postGetTotalToPay()

    return {
      ...toRefs(data),
      goTo,
      paymentPlanData,
      postCreatePaymentPlanData,
      Utils,
      modules,
      language
    };
  },
};
